// Corresponds to the wallrus url for now. (Comment to be deleted)
export const API_URL = process.env.REACT_APP_API_URL
export const APP_URL = process.env.REACT_APP_URL

export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_API_KEY

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

// Correspond to the different plans available
export const BASIC_PLAN = 1
export const PRO_PLAN = 2
export const AGENCY_PLAN = 4

// Correspond to the different keyword types available
export const TWITTER = 'twitter'
export const INSTAGRAM = 'instagram'
export const TWITTER_KEYWORD = `${TWITTER}-keyword`
export const TWITTER_USERNAME = `${TWITTER}-username`
export const INSTAGRAM_KEYWORD = `${INSTAGRAM}-keyword`
export const INSTAGRAM_USERNAME = `${INSTAGRAM}-username`

// Correspond to the different aggregator types available form the back end

export const AGGREGATOR_SOURCE_TWITTERAPI = 'Aggregator::Source::Twitterapi'
export const AGGREGATOR_SOURCE_TWITTERTIMELINE = 'Aggregator::Source::TwitterTimeline'
export const AGGREGATOR_SOURCE_INSTAGRAMAPI = 'Aggregator::Source::Instagramapi'
export const AGGREGATOR_SOURCE_INSTAGRAMUSER = 'Aggregator::Source::InstagramUser'


export const CLOUDFRONT_ASSETS_URL = process.env.REACT_APP_CLOUDFRONT_DOMAIN
export const AVATAR_IMG_404_URL = CLOUDFRONT_ASSETS_URL + 'assets/placeholder/user404.png'
export const SOCIAL_IMG_404_URL = CLOUDFRONT_ASSETS_URL + 'assets/placeholder/img404.png'
export const LOGO_PLACEHOLDER_URL =
  CLOUDFRONT_ASSETS_URL + 'assets/images/logos/logo-placeholder.jpg'

// AWS
export const AWS_BUCKET_URL = process.env.REACT_APP_AWS_BUCKET_URL
