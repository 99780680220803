import React from 'react'
import Button from 'components/Button/Button'
import Colors from 'setup/colors.scss'
import './SubmitButton.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ disabled, children, successMessage, ...rest }) => (
  <div className="SubmitButton">
    <div className="SubmitButtonContainer">
      <Button disabled={disabled} type="submit" {...rest}>
        {children}
      </Button>
    </div>
    {successMessage ? (
      <div className="SuccessMessage">
        <FontAwesomeIcon
          icon={['far', 'check-circle']}
          style={{ width: 20, height: 20, color: Colors.info }}
        />
        <span> {successMessage}</span>
      </div>
    ) : null}
  </div>
)
