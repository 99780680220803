import React from 'react'

const UserDeletedImage = props => (
  <svg width={120} height={120} viewBox="0 0 120 120" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="silver" d="M0 0h120v120H0z" />
      <text
        fill="#FFF"
        fontFamily="SofiaPro-SemiBold, Sofia Pro"
        fontSize={16}
        fontWeight={500}
        letterSpacing={1}>
        <tspan x={20.052} y={71}>
          USER HAS
        </tspan>
        <tspan x={23.92} y={89}>
          DELETED
        </tspan>
        <tspan x={11.5} y={107}>
          THIS IMAGE
        </tspan>
      </text>
      <g fill="#FFF" fillRule="nonzero" transform="translate(42 13)">
        <path d="M0 9.18v16.31A4.517 4.517 0 0 0 4.516 30h27.968A4.516 4.516 0 0 0 37 25.49V9.18a4.297 4.297 0 0 0-4.297-4.292h-6.01l-.144-.626A5.488 5.488 0 0 0 21.173 0H15.82a5.5 5.5 0 0 0-5.376 4.262l-.143.626H4.297A4.302 4.302 0 0 0 0 9.18zm11.04-2.444c.43 0 .8-.294.898-.716l.31-1.343a3.652 3.652 0 0 1 3.571-2.829h5.354a3.652 3.652 0 0 1 3.572 2.829l.31 1.343a.928.928 0 0 0 .898.716h6.743a2.445 2.445 0 0 1 2.446 2.444v16.31a2.664 2.664 0 0 1-2.665 2.662H4.516a2.664 2.664 0 0 1-2.666-2.663V9.18a2.445 2.445 0 0 1 2.447-2.444h6.743z" />
        <circle cx={6.5} cy={10.5} r={1.5} />
        <path d="M18.5 25c4.133 0 7.5-3.367 7.5-7.5S22.633 10 18.5 10 11 13.36 11 17.5c0 4.14 3.367 7.5 7.5 7.5zm0-13.214a5.724 5.724 0 0 1 5.714 5.714 5.724 5.724 0 0 1-5.714 5.714 5.724 5.724 0 0 1-5.714-5.714 5.724 5.724 0 0 1 5.714-5.714z" />
      </g>
    </g>
  </svg>
)

export default UserDeletedImage
