import React from 'react'
import Colors from 'setup/colors.scss'
import 'components/Checkbox/Checkbox.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import PropTypes from 'prop-types'

/**
 *
 * The Checkbox component is a custom checkbox used in Account Info form
 */
const Checkbox = ({ checked, children, onCheck, ...rest }) => {
  return (
    <div className="Checkbox" onClick={onCheck} {...rest}>
      {checked ? (
        <FontAwesomeIcon icon={['far', 'check-square']} style={{ color: Colors.brand }} />
      ) : (
        <FontAwesomeIcon icon={['far', 'square']} style={{ color: Colors.grey }} />
      )}
      <label>{children}</label>
    </div>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onCheck: PropTypes.func,
  /** you can use **onClick**.. */
  rest: PropTypes.object
}

export default Checkbox
