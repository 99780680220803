import React from 'react'

const MagnifyingGlass = props => (
  <svg width={25} height={26} viewBox="0 0 25 26" {...props}>
    <g fill="#333" fillRule="nonzero">
      <path
        d="M15 1c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zm0 17.182c-4.511 0-8.182-3.67-8.182-8.182 0-4.511 3.67-8.182 8.182-8.182 4.511 0 8.182 3.67 8.182 8.182 0 4.512-3.67 8.182-8.182 8.182z"
        stroke="#171717"
      />
      <path
        d="M10.878 17.622l-2.5-2.5a.416.416 0 0 0-.59 0l-6.15 6.15a2.186 2.186 0 0 0 3.09 3.089l6.15-6.15a.416.416 0 0 0 0-.589zm-6.739 6.15a1.352 1.352 0 0 1-1.911-1.91l5.855-5.856 1.911 1.91-5.855 5.856z"
        stroke="#333"
      />
    </g>
  </svg>
)

export default MagnifyingGlass
