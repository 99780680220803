import PlanService from 'services/api/PlanService'

const ADD_PLANS = 'ADD_PLANS'
const REQUEST_PLANS = 'REQUEST_PLANS'

const initState = {
  items: []
}

export const doFetchAllPlansAction = () => async dispatch => {
  try {
    dispatch(requestPlansAction())
    const plans = await PlanService.getAll()
    dispatch(addPlansAction(plans))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

const addPlansAction = plans => ({
  type: ADD_PLANS,
  payload: plans
})

export const requestPlansAction = () => ({
  type: REQUEST_PLANS
})

export default (state = initState, action) => {
  switch (action.type) {
    case REQUEST_PLANS:
      return {
        ...state,
        isFetching: true
      }

    case ADD_PLANS:
      return {
        ...state,
        items: [...action.payload],
        isFetching: false
      }

    default:
      return state
  }
}
