import React from 'react'
import Title from 'components/Title/Title'
import './SupportModal.scss'

const SupportModal = props => {
  return (
    <div className="SupportModal">
      <Title className="medium">Support</Title>
      <div className="Block">
        <p>
          If you need help, get in touch with us. <br />
        </p>
      </div>
      <div className="Block">
        <Title className="small no-bg">Sales</Title>
        <a href="mailto:hello@the-wallrus.com">hello@the-wallrus.com</a>
      </div>
      <div className="Block">
        <Title className="small no-bg">Support</Title>
        <a href="mailto:support@the-wallrus.com">support@the-wallrus.com</a>
      </div>
    </div>
  )
}

export default SupportModal
