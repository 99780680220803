import React from 'react'
import { Link } from 'react-router-dom'
import './WallListItem.scss'
import Countdown from '../../Countdown/Countdown'
import { truncateString } from 'setup/utils'
import Colors from 'setup/colors.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDeleteWallModalAction } from 'store/modal/modalReducer'
import { connect } from 'react-redux'
import { isSubuser } from 'setup/utils'

const WallListItem = ({
  id,
  name,
  live,
  live_until,
  plan,
  openDeleteWallModalAction,
  user
}) => (
  <div className="WallListItem" key={id}>
    <Link to={`/wall/${id}`} title={`Go to ${name}`}>
      <span className="wall-name">{name && truncateString(name, 30)}</span>
      <span className="wall-plan">
        {plan.name}
        {live ? <Countdown liveUntil={live_until} /> : null}
      </span>
    </Link>
    {!live && !isSubuser(user) ? (
      <div>
        <FontAwesomeIcon
          icon={['fas', 'times-circle']}
          onClick={() => openDeleteWallModalAction({ id, name })}
          style={{ height: 20, width: 20, color: Colors.danger }}
        />
      </div>
    ) : null}
  </div>
)

export default connect(
  null,
  { openDeleteWallModalAction }
)(WallListItem)
