import React from 'react'
import { connect } from 'react-redux'
import { doSignupAction } from 'store/user/userReducer'
import { withRouter } from 'react-router'
import Title from 'components/Title/Title'
import 'components/Forms/Forms.scss'
import Button from 'components/Button/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import { RECAPTCHA_KEY, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from 'setup/constants'

export const SignupForm = ({
  doSignupAction,
  active,
  toggleState,
  sendPassword,
  handleBackToInitial,
  responseGoogle,
  responseFacebook
}) => {
  let usernameNode
  let passwordNode
  let emailNode
  let passwordConfirmationNode

  const recaptchaRef = React.createRef()

  const submitSignup = (event) => {
    event.preventDefault()
    const recaptchaToken = recaptchaRef.current.getValue()

    if (
      (usernameNode.value,
      passwordNode.value,
      emailNode.value,
      passwordConfirmationNode.value,
      recaptchaToken)
    ) {
      doSignupAction(
        usernameNode.value,
        emailNode.value,
        passwordNode.value,
        passwordConfirmationNode.value,
        recaptchaToken
      )
    }
  }

  return (
    <div
      className={`Forms Signup ${active ? 'expand' : 'collapse'}`}
      style={{ display: 'flex' }}>
      <form onSubmit={submitSignup}>
        {sendPassword !== true ? (
          <Title className={active ? 'large' : 'small'} handleClickFunction={toggleState}>
            {active ? 'Sign up' : 'create an account'}
          </Title>
        ) : (
          <Title className="small" handleClickFunction={handleBackToInitial}>
            go to login
          </Title>
        )}

        <label className="label" htmlFor="username">
          <input
            type="text"
            name="username"
            placeholder="Desired Username"
            ref={node => (usernameNode = node)}
          />
          <div className="messageErrorText" id="usernameErrorText" />
        </label>
        <label className="label" htmlFor="email">
          <input
            type="email"
            name="email"
            placeholder="Email address"
            ref={node => (emailNode = node)}
          />
          <div className="messageErrorText" id="emailErrorText" />
        </label>
        <label className="label" htmlFor="password">
          <input
            type="password"
            name="password"
            placeholder="Password"
            ref={node => (passwordNode = node)}
          />
          <div className="messageErrorText" id="passwordErrorText" />
        </label>
        <label className="label" htmlFor="password">
          <input
            type="password"
            name="password"
            placeholder="Password confirmation"
            ref={node => (passwordConfirmationNode = node)}
          />
          <div className="messageErrorText" id="passwordConfErrorText" />
        </label>

        <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
        <Button className="ButtonLogin" type="submit">
          create my account
        </Button>
        <div className="Or">OR</div>
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          textButton="Sign up in With Facebook"
          icon="fa-2x fa-facebook-official"
          onClick={responseFacebook}
          callback={responseFacebook}
        />
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Sign Up With Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </form>
    </div>
  )
}
export default withRouter(connect(null, { doSignupAction })(SignupForm))
