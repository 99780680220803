import React from 'react'
import { connect } from 'react-redux'
import './ZoomModal.scss'

export const ZoomLayoutModal = ({ layoutSelected }) => {
  return (
    <div className="ZoomLayoutModal">
      <div className="Slider">{layoutSelected.content}</div>
    </div>
  )
}
export default connect(state => ({
  layoutSelected: state.modal.layoutSelected
}))(ZoomLayoutModal)
