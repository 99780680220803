import React from 'react'
import { Route } from 'react-router-dom'
import { freeRoutes, routes, subrUsersRoutes } from 'routes/routes'
import Sidebar from 'layouts/Sidebar/Sidebar'
import MainView from 'layouts/MainView/MainView'
import { Switch } from 'react-router'
import Header from 'layouts/Header/Header'
import { isHomePage, isAppearancePages, isFullscreenPage } from 'routes/routesUtils'
import SelectPlanList from 'components/SelectPlanList/SelectPlanList'
import Wallbar from 'components/Wallbar/Wallbar'
import Modal from 'components/Modal/Modal'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { connect } from 'react-redux'
import { isSubuser } from 'setup/utils'
import NextStepBar from 'components/NextStepBar/NextStepBar'

const AppContainer = ({ user, planId }) => {
  const _routes = isSubuser(user) ? subrUsersRoutes : planId === 1 ? freeRoutes : routes
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App-container">
        {!isHomePage() && !isFullscreenPage() && <Sidebar />}
        <Modal />
        {!isFullscreenPage() && <Header />}
        <Switch>
          {_routes.map(({ path, exact, viewToRender }, index) => {
            return (
              <Route
                key={index}
                path={path}
                exact={exact}
                render={({ match }) => (
                  <MainView wallId={match.params.id} routeRender={viewToRender} />
                )}
              />
            )
          })}
        </Switch>
        {isHomePage() ? <SelectPlanList /> : null}

        {isAppearancePages() ? (
          <>
            <Wallbar />
            <NextStepBar />
          </>
        ) : null}
      </div>
    </DndProvider>
  )
}

export default connect(
  state => ({
    sidebar: state.sidebar,
    currentWall: state.currentWall,
    user: state.user,
    planId: state.currentWall.plan_id
  }),
  {}
)(AppContainer)
