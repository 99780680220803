import React from 'react'
import 'components/FloatingActions/FloatingActions.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

/** A HOC used on multiple pages to display contexual elements on top right hand corner */
const FloatingActions = ({ className, children, style }) => (
  <div className={classNames('FloatingActions', className)} style={style}>
    {children}
  </div>
)

FloatingActions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default FloatingActions
