import { setupFontAwesome } from 'setup/setupFontAwesome'
import setupPusher from 'setup/setupPusher'
import { setupFonts } from 'setup/setupFonts'
import setupGeolocator from 'setup/setupGeolocator'

export default () => {
  setupFontAwesome()
  setupPusher()
  setupFonts()
  setupGeolocator()
}
