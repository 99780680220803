import {
  TWITTER_KEYWORD,
  TWITTER_USERNAME,
  INSTAGRAM_KEYWORD,
  INSTAGRAM_USERNAME,
  TWITTER,
  INSTAGRAM,
  AGGREGATOR_SOURCE_TWITTERAPI,
  AGGREGATOR_SOURCE_TWITTERTIMELINE,
  AGGREGATOR_SOURCE_INSTAGRAMAPI,
  AGGREGATOR_SOURCE_INSTAGRAMUSER
} from 'setup/constants'

export default new Map([
  [AGGREGATOR_SOURCE_TWITTERAPI, TWITTER_KEYWORD],
  [AGGREGATOR_SOURCE_TWITTERTIMELINE, TWITTER_USERNAME],
  [AGGREGATOR_SOURCE_INSTAGRAMAPI, INSTAGRAM_KEYWORD],
  [AGGREGATOR_SOURCE_INSTAGRAMUSER, INSTAGRAM_USERNAME]
])

export const socialMediaType = new Map([
  [AGGREGATOR_SOURCE_TWITTERAPI, TWITTER],
  [AGGREGATOR_SOURCE_TWITTERTIMELINE, TWITTER],
  [AGGREGATOR_SOURCE_INSTAGRAMAPI, INSTAGRAM],
  [AGGREGATOR_SOURCE_INSTAGRAMUSER, INSTAGRAM]
])
