import { UPDATE_STYLE } from './currentWallReducerActionTypes'

export default (styles = {}, action) => {
  switch (action.type) {
    case UPDATE_STYLE:
      return [
        ...styles.map(style => {
          if (style.id === action.payload.id) {
            style.value = action.payload.value
          }
          return style
        })
      ]

    default:
      return styles
  }
}
