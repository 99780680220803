import React from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Social.scss'
import BiggerText from 'components/BiggerText/BiggerText'
import {
  getHashtagEnableStyle,
  getDisplayedHashtagStyle,
  getHashtagColorStyle
} from 'services/app/screen'

export const Social = ({
  displayHashtag = undefined,
  fontSizeMax = 90,
  screenStyles,
  styles
}) => {
  const hashtag = getDisplayedHashtagStyle(styles)
  const hashtagColor = getHashtagColorStyle(screenStyles)

  const hashtagToDisplay = hashtag === null || hashtag === '' ? '#hashtag' : hashtag
  return displayHashtag || getHashtagEnableStyle(screenStyles) ? (
    <div className="Social">
      <div className="icons">
        <FontAwesomeIcon style={{ color: hashtagColor }} icon={['fab', 'twitter']} />
        <FontAwesomeIcon style={{ color: hashtagColor }} icon={['fab', 'instagram']} />
      </div>

      <div className="hashtag">
        <BiggerText style={{ color: hashtagColor }} fontSizeMax={fontSizeMax}>
          {hashtagToDisplay}
        </BiggerText>
      </div>
    </div>
  ) : null
}

export default connect(
  state => ({
    styles: state.currentWall.styles
  }),
  {}
)(Social)
