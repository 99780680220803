import React from 'react'
import Title from 'components/Title/Title'
import SubTitle from 'components/SubTitle/SubTitle'
import 'components/Modal/Modal.scss'
import { CLOUDFRONT_ASSETS_URL } from 'setup/constants'

const HelpModal = () => {
  return (
    <div className="HelpModal">
      <div className="SectionTitle">
        <Title className="medium">setup</Title>
      </div>
      <div>
        <SubTitle>Social media access and privacy</SubTitle>
        <div className="SectionContent">
          Since changes to Facebook and Instagram in November 2018, you now have to
          authenticate yourself in order to get content from those platforms. This will
          give us some information about your account. The only information that we store
          are your username and unique ID (used to search for # on Instagram). We do not
          store, use, sell or even look at anything else. No content will ever be posted
          by us under your name. We take your privacy very seriously.
        </div>
        <SubTitle className="Section">Facebook won't connect</SubTitle>
        <div className="SectionContent">
          Sometimes, when you try to connect your Facebook account, the pop-up will open
          and close right away after your first attempt. You will be stuck in a loop,
          unable to continue. Fortunately, there is a simple fix that you can{' '}
          <a
            href={`${CLOUDFRONT_ASSETS_URL}assets/documents/facebook-connect.pdf`}
            rel="noopener noreferrer"
            target="_blank">
            <b>find here</b>
          </a>
          . If you are still experiencing problems after this, please give us a call +1
          (844) 811-1125.
        </div>
      </div>
    </div>
  )
}

export default HelpModal
