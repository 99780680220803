import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Payment from 'payment'

import Title from 'components/Title/Title'

import './PlaceOrder.scss'
import Button from 'components/Button/Button'
import { DATE_FORMAT, TIME_FORMAT } from '../PayPageContants'
import Icon from 'components/Icon/Icon'
import { openPayModalAction } from 'store/modal/modalReducer'

const createFromToDates = (datePickerDate, timePickerTime, duration) => {
  const fromDate = moment(datePickerDate).format(DATE_FORMAT)
  const fromTime = moment(timePickerTime).format(TIME_FORMAT)
  const toDate = moment(datePickerDate)
    .add(duration.value, 'days')
    .format(DATE_FORMAT)
  const toTime = moment(timePickerTime)
    .add(duration.value, 'days')
    .format(TIME_FORMAT)

  return {
    fromDate,
    fromTime,
    toDate,
    toTime
  }
}

const PlaceOrder = ({
  currentWall,
  payForm: {
    address_addr1,
    address_addr2,
    address_city,
    address_company,
    address_country,
    address_email,
    address_firstname,
    address_lastname,
    address_province,
    address_zip,
    card_expm,
    card_expy,
    card_name,
    card_number,
    card_security,
    couponCode,
    datePickerDate,
    duration,
    live_start,
    price,
    taxes,
    timePickerTime,
    timezone
  },
  openPayModalAction
}) => {
  const dates = createFromToDates(datePickerDate, timePickerTime, duration)

  const number = card_number.value
  const cardEnding = number.slice(number.length - 4, number.length)
  const taxesTotal = taxes.reduce((acc, tax) => acc + parseFloat(tax.value), 0)
  const cardType = Payment.fns.cardType(number)

  const getDiscountValue = () => {
    switch (couponCode.coupon_type) {
      case 'fixed_amount':
        return couponCode.coupon_value
      case 'percentage':
        return (price * couponCode.coupon_value) / 100
      default:
        return 0
    }
  }

  const pay = () => {
    const data = {
      live_start,
      payment_form: {
        coupon: couponCode.coupon,
        event_id: currentWall.id,
        duration: duration.value,
        card_name: card_name.value,
        card_number: card_number.value,
        card_expm: card_expm,
        card_expy: card_expy,
        card_security: card_security.value,
        address_firstname: address_firstname.value,
        address_lastname: address_lastname.value,
        address_email: address_email.value,
        address_addr1: address_addr1.value,
        address_addr2: address_addr2.value,
        address_city: address_city.value,
        address_company: address_company.value,
        address_country: address_country.value,
        address_province: address_province.value,
        address_zip: address_zip.value
      },
      timezone: moment()
        .tz(timezone)
        .format('Z')
    }
    openPayModalAction(data)
  }

  return (
    <div>
      <div className="Step">
        <div className="PayPageBox PlaceOrder">
          <Title className="small">order summary</Title>
          <div className="OrderResume">
            <p>Your wall will be active</p>
            <div className="FromTo">
              <div className="DateLine">
                <span>from</span>
                <span className="Date">{dates.fromDate}</span>
                <span className="Time">{dates.fromTime}</span>
              </div>
              <div className="DateLine">
                <span>to</span>
                <span className="Date">{dates.toDate}</span>
                <span className="Time">{dates.toTime}</span>
              </div>
            </div>
            <div className="WithCard">
              <div className="CreditCard">
                <div className="CardContainer">
                  <span>Card ending in {cardEnding}</span>
                  {cardType !== null ? (
                    <Icon size="lg" type="brand" icon={`cc-${cardType}`} />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="OrderSummaryLine">
              <div className="description">License length</div>
              <div className="value">{duration.value}</div>
            </div>
            <div className="OrderSummaryLine">
              <div className="description">Price</div>
              <div className="value">{`$${price}`}</div>
            </div>
            {couponCode.coupon_value > 0 ? (
              <div className="OrderSummaryLine">
                <div className="description">Discount</div>
                <div className="value">-{`$${getDiscountValue()}`}</div>
              </div>
            ) : null}
            <div className="OrderSummaryLine">
              <div className="description">Taxes</div>
              {taxes.length ? (
                <div className="TaxList">
                  {taxes.map((tax, index) => (
                    <div className="TaxListItem" key={index}>
                      <div>{tax.name}: </div>
                      <div className="value">{`$${tax.value}`}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="value">NA</div>
              )}
            </div>
            <hr />
            <div className="OrderSummaryLine">
              <div className="description bold uppercase">Total</div>
              <div className="value">{`$${price - getDiscountValue() + taxesTotal}`}</div>
            </div>
          </div>
        </div>
      </div>
      <Button className="NextStep" onClick={pay}>
        pay & activate
      </Button>
    </div>
  )
}

export default connect(
  state => ({
    payForm: state.payForm,
    currentWall: state.currentWall
  }),
  { openPayModalAction }
)(PlaceOrder)
