import request from 'services/requestWrapper.js'
import storage from 'services/app/storage.js'

function get() {
  const userId = storage.get('wallrus') ? storage.get('wallrus').user.id : ''
  return userId ? request({ url: `/users/${userId}`, method: 'GET' }) : ''
}

function getSyncNewsletter() {
  const userId = storage.get('wallrus').user.id
  return request({
    url: `/users/${userId}/sync_newsletter`,
    method: 'GET'
  })
}

function linkInstagram(token) {
  const userId = storage.get('wallrus').user.id

  const data = {
    access_token: token
  }
  return request({
    url: `/users/${userId}/link_instagram`,
    method: 'POST',
    data
  })
}

function unlinkInstagram() {
  const userId = storage.get('wallrus').user.id
  return request({
    url: `/users/${userId}/unlink_instagram`,
    method: 'GET'
  })
}

function unlinkTwitter() {
  const userId = storage.get('wallrus').user.id
  return request({
    url: `/users/${userId}/unlink_twitter`,
    method: 'GET'
  })
}

function updateAddressInfo(addressId, address) {
  return request({
    url: `/addresses/${addressId}.json`,
    method: 'PUT',
    data: {
      address
    }
  })
}

function updateNewsletterInfo(value) {
  const userId = storage.get('wallrus').user.id
  return request({
    url: `/users/${userId}.json`,
    method: 'PUT',
    data: {
      user: {
        newsletter: value
      }
    }
  })
}

const UserService = {
  get,
  linkInstagram,
  unlinkInstagram,
  unlinkTwitter,
  updateAddressInfo,
  getSyncNewsletter,
  updateNewsletterInfo
}

export default UserService
