import React, { Component } from 'react'

import LayoutThumbnail from 'components/LayoutThumbnail/LayoutThumbnail'
import UpgradeButton from 'components/UpgradeButton/UpgradeButton'
import './LayoutPage.scss'
import { connect } from 'react-redux'
// import { doFetchAllLayoutsAction } from 'store/layout/layoutReducer'
import Container from 'components/Container/Container'
import Title from 'components/Title/Title'
import ScreenService from 'services/api/ScreenService'
import {
  addLayoutToCurrentWallAction,
  doUpdateScreen,
  doUpdateWall
} from 'store/currentWall/actions/index'
import { openModalAction, openUpgradePlanModalAction } from 'store/modal/modalReducer'
class LayoutPage extends Component {
  createLayout = async (layoutId = 1) => {
    if (this.planIsGreaterThanCurrentPlan(layoutId)) {
      this.showUpgradePlanModal(layoutId)
      return
    }

    return await this.createLayoutFromId(layoutId)
  }
  createLayoutFromId = async (layoutId) => {
    return await ScreenService.create(this.props.wallId, layoutId).then(screen => {
      this.props.addLayoutToCurrentWallAction(screen)
      return screen
    })
  }

  planIsGreaterThanCurrentPlan = layoutId => {
    const { layouts, currentWallPlanId } = this.props
    return layouts.find(layout => layout.id === layoutId).plan_id > currentWallPlanId
  }

  showUpgradePlanModal(layoutId) {
    const planId = this.props.layouts.find(l => l.id === layoutId).plan_id
    this.props.openUpgradePlanModalAction({
      upgradeToPlan: planId,
      createLayout: layoutId
    })
  }

  renderPlan(plan, index){
    const { currentWallPlanId, currentWall, layouts } = this.props;
    const planLayouts = layouts.filter((layout) => layout.plan_id === plan.id);

    if(planLayouts && planLayouts.length > 0) {
      return (
        <div className="LayoutGroup" key={index}>
          <div className="LayoutGroupTitle">
            <Title className={`small`}>{plan.name}</Title>
            {currentWall.plan.event_type !== plan.event_type &&
              plan.event_type !== 'basic' && (
                <UpgradeButton upgradeToPlan={{ upgradeToPlan: plan.id }} />
            )}
          </div>

          <div className="LayoutGroupContent" key={index}>
            {planLayouts.map((layout, index) => (
              <LayoutThumbnail
                key={index}
                currentWall={currentWall}
                createLayout={this.createLayout}
                doUpdateScreen={this.props.doUpdateScreen}
                {...layout}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  render() {
    const { plans } = this.props

    return (
      <Container className="LayoutPage">
        <div className="LayoutThumbnailsList">
          {plans.isFetching ? 'Loading' : plans.items.map((plan, index) => this.renderPlan(plan, index))}
        </div>
      </Container>
    )
  }
}

export default connect(
  state => ({
    currentWall: state.currentWall,
    currentWallPlanId: state.currentWall.plan.id,
    layouts: state.layouts,
    layoutSelected: state.modal.layoutSelected,
    plans: state.plans,
    layouts: state.layouts,
    // basicLayouts: getFilteredPlans(state, BASIC_PLAN),
    // proLayouts: getFilteredPlans(state, PRO_PLAN)
  }),
  {
    addLayoutToCurrentWallAction,
    // doFetchAllLayoutsAction,
    doUpdateScreen,
    doUpdateWall,
    openModalAction,
    openUpgradePlanModalAction
  }
)(LayoutPage)

function getFilteredPlans(state, plan) {
  console.log(plan, state.layouts, state.layouts.map(l => [l.plan_id, parseInt(l.plan_id) === parseInt(plan)]))
  return state.layouts.length ? state.layouts.filter(l => parseInt(l.plan_id) === parseInt(plan)) : undefined
}
