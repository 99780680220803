import React from 'react'
import Title from 'components/Title/Title'
import SubTitle from 'components/SubTitle/SubTitle'
import '../Modal.scss'

const PlaylistHelpModal = () => {
  return (
    <div className="HelpModal">
      <div className="SectionTitle">
        <Title className="medium">playlist</Title>
      </div>
      <div>
        <SubTitle>Getting a Yellow or red status</SubTitle>
        <div className="SectionContent">
          You will get a yellow or red status if you do not meet the required amount of
          content to display a screen properly. This will resolve itself as more content
          is approved. Wallrus will simply skip those screens until the requirements are
          met. There is nothing to worry about.
        </div>
        <SubTitle>What is a content update</SubTitle>
        <div className="SectionContent">
          Content update is the amount of time a social post will stay on the screen. For
          example, if you’ve set your screen to 90 seconds and content update to 15
          seconds, 6 posts will rotate before switching to your next screen.
        </div>
      </div>
    </div>
  )
}

export default PlaylistHelpModal
