import React, { Component } from 'react'

import './DurationPicker.scss'
import Colors from 'setup/colors.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const utils = {
  hoursToMs: time => {
    return time * 60 * 60 * 1000
  },
  minutesToMs: time => {
    return time * 60 * 1000
  },
  secondsToMs: time => {
    return time * 1000
  }
}

/** A time picker used on Playlist page to set Layout duration with a min value of 20 sec and a max value of 24h */
class DurationPicker extends Component {
  state = {
    duration: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    prevTime: 0,
    minValue: utils.secondsToMs(20),
    maxValue: utils.hoursToMs(24),
    maxInputHours: 24,
    maxInputMinutes: 59,
    maxInputSeconds: 59
  }

  async componentDidMount() {
    await this.updateTime(this.props.duration)
    this.setState({ prevTime: this.state.duration })
  }

  getUpdatedTime(type, event) {
    //return new duration if the value typed by user is empty
    if (event.target.value === '') {
      if (this.state.duration <= this.state.minValue) {
        return this.state.maxValue
      } else {
        switch (type) {
          case 'seconds':
            return this.state.duration - utils.secondsToMs(this.state.seconds)
          case 'minutes':
            return this.state.duration - utils.minutesToMs(this.state.minutes)
          case 'hours':
            return this.state.duration - utils.hoursToMs(this.state.hours)
          default:
            return this.state.duration
        }
      }
    }
    //return new duration if the value typed by user is not a number
    if (!event.target.validity.valid) {
      return this.state.duration
    }

    //return new duration if the value typed by user is superior to its max attribute
    if (parseInt(event.target.value, 10) > parseInt(event.target.max, 10)) {
      return this.state.duration
    }

    //return a new duration based on hours, minutes and seconds typed by the user
    let newTime =
      type === 'hours'
        ? this.state.duration -
          utils.hoursToMs(this.state.hours) +
          utils.hoursToMs(parseInt(event.target.value, 10))
        : type === 'minutes'
        ? this.state.duration -
          utils.minutesToMs(this.state.minutes) +
          utils.minutesToMs(parseInt(event.target.value, 10))
        : this.state.duration -
          utils.secondsToMs(this.state.seconds) +
          utils.secondsToMs(parseInt(event.target.value, 10))
    return newTime
  }

  //update hours, minutes and seconds based on the duration of the screen
  updateTime = duration => {
    if (duration === 'INCREASE') {
      duration =
        this.state.duration + utils.secondsToMs(20) < this.state.maxValue
          ? this.state.duration + utils.secondsToMs(20)
          : this.state.maxValue
    } else if (duration === 'DECREASE') {
      duration =
        this.state.duration - utils.secondsToMs(20) > this.state.minValue
          ? this.state.duration - utils.secondsToMs(20)
          : this.state.minValue
    }
    if (duration !== this.state.duration) {
      //prevent user to input more than 24h
      if (duration > this.state.maxValue) {
        duration = this.state.maxValue
      }
      let time = parseInt(duration, 10)
      time = time / 1000
      const seconds = time % 60
      time = Math.floor(time / 60)
      const minutes = time % 60
      time = Math.floor(time / 60)
      const hours = time

      this.setState({ duration, hours, minutes, seconds })
    }
  }

  decreaseTime = async () => {
    await this.updateTime('DECREASE')
    this.sendTime()
  }

  increaseTime = async () => {
    await this.updateTime('INCREASE')
    this.sendTime()
  }

  //send new duration to API
  sendTime = async () => {
    if (this.state.prevTime === this.state.duration) return
    let time = this.state.duration

    // Round time to closest 20 seconds
    const seconds = (time / 1000) % 60
    if (seconds % 20 !== 0) {
      let closest20 = Math.round(seconds / 20) * 20
      if (closest20 - seconds > 10) {
        closest20 -= 10
      }
      time = (time / 1000 - seconds + closest20) * 1000
    }

    if (this.state.duration < this.state.minValue) {
      time = this.state.minValue
    }

    this.updateTime(time)
    await this.props.onChange(time)
    this.setState({ prevTime: time })
  }

  render() {
    return (
      <div className="DurationPicker">
        <div className="arrow arrow-top">
          <FontAwesomeIcon
            icon={['fas', 'chevron-up']}
            style={{ height: 14, width: 14, color: Colors.brand }}
            onClick={this.increaseTime}
          />
        </div>
        <div className="Timers">
          <Timer
            type="hours"
            label="h"
            value={this.state.hours}
            max={this.state.maxInputHours}
            onBlur={this.sendTime}
            onChange={event => this.updateTime(this.getUpdatedTime('hours', event))}
          />
          <Timer
            type="minutes"
            label="m"
            value={this.state.minutes}
            max={this.state.maxInputMinutes}
            onBlur={this.sendTime}
            onChange={event => this.updateTime(this.getUpdatedTime('minutes', event))}
          />
          <Timer
            type="seconds"
            label="s"
            value={this.state.seconds}
            max={this.state.maxInputSeconds}
            onBlur={this.sendTime}
            onChange={event => this.updateTime(this.getUpdatedTime('seconds', event))}
          />
        </div>
        <div className="arrow arrow-bottom">
          <FontAwesomeIcon
            icon={['fas', 'chevron-down']}
            style={{ height: 14, width: 14, color: Colors.brand }}
            onClick={this.decreaseTime}
          />
        </div>
      </div>
    )
  }
}

DurationPicker.propTypes = {
  duration: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  planId: PropTypes.number.isRequired
}

const Timer = ({ label, max, onBlur, onChange, type, value }) => {
  return (
    <div className={`Timer ${type === 'hours' && value === 0 ? 'hide' : ''}`}>
      <input
        className={type}
        type="text"
        title={type}
        min="0"
        value={value}
        max={max}
        pattern="[0-9]*"
        onBlur={event => onBlur()}
        onChange={event => onChange(event)}
      />
      <span className="spacer">{label}</span>
    </div>
  )
}

export default DurationPicker
