import React from 'react'
import Title from 'components/Title/Title'
import SubTitle from 'components/SubTitle/SubTitle'
import '../Modal.scss'

const ModerateHelpModal = () => {
  return (
    <div className="HelpModal">
      <div className="SectionTitle">
        <Title className="medium">moderate</Title>
      </div>
      <div className="overflow-scroll">
        <div>
          <SubTitle>Why is there no content? </SubTitle>
          <div className="SectionContent">
            <div>
              Sometimes, even if there is content under your selected #, we won’t have
              access to it. This can be explained by two things.
            </div>
            <div>
              First, we can only get content considered recent, generally meaning the last
              72h. This is not an absolute as Facebook, Instagram and Twitter all have
              their own idea of what recent mean. Nonetheless, we have no control over it.
            </div>
            <div>
              Second, we cannot have access to any private content. By default, all
              Instagram accounts are private. So any content posted from such an account
              will not appear in our platform. There really is no way around this. Privacy
              is really important after all.
            </div>
          </div>
          <SubTitle className="Section">Getting a yellow or red status</SubTitle>
          <div className="SectionContent">
            You will get a yellow or red status if you do not meet the required amount of
            content to display a screen properly. This will resolve itself as more content
            is approved. Wallrus will simply skip those screens until the requirements are
            met. There is nothing to worry about. USER
          </div>
          <SubTitle className="Section">User has deleted a content</SubTitle>
          <div className="SectionContent">
            Sometimes, the original user of a post will delete his content. When this
            happens, we are not allowed to display it anymore. It is after all his/her
            content. That post will become red in your moderation to indicate that it has
            been deleted. Any such content will not display on your wall.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModerateHelpModal
