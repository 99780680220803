import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { API_URL, INSTAGRAM_KEYWORD, AVATAR_IMG_404_URL } from 'setup/constants'
import { doCreateKeywordAction, doRemoveKeywordAction, doUpdateWall } from 'store/currentWall/actions/index'
import { doLoginOrFetchUserAction } from 'store/user/userReducer'
import UserService from 'services/api/UserService'
import storage from 'services/app/storage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChipsContainer from 'components/Chips/ChipsContainer'
import instagramLogo from 'assets/images/instagram-logo.png'
import facebookLogo from 'assets/images/facebook-logo.png'
import Title from 'components/Title/Title'
import Toggle from 'components/Toggle/Toggle'
import Button from 'components/Button/Button'

const SetupPageInstagram = ({
  doCreateKeywordAction,
  doRemoveKeywordAction,
  doLoginOrFetchUserAction,
  doUpdateWall,
  from_instagram_page,
  instagramUser,
  keywords,
  wallId
}) => {
  const [linked, setLinked] = useState(false)
  const [loading, setLoading] = useState(false)
  const connectWindow = useRef(null)
  const instagramKeywords = keywords.filter(keyword => keyword.type === INSTAGRAM_KEYWORD)
  let interval

  useEffect(() => {
    const checkStatus = async () => {
      if (instagramUser.status) {
        setLinked(instagramUser.status === 'CONNECTED')
      }
    }
    checkStatus()
  }, [instagramUser.status])

  const checkWindow = async () => {
    console.log(connectWindow.current.closed)
    if (connectWindow.current.closed) {
      clearInterval(interval)
      await doLoginOrFetchUserAction()
      setLoading(false)
    }
  }

  const getUserPhoto = (imgSrc) => {
    return imgSrc !== null &&
      imgSrc !== undefined &&
      imgSrc !== '' &&
      imgSrc !== 'http://graph.facebook.com/v3.2/102472994206572/picture'
      ? imgSrc
      : AVATAR_IMG_404_URL
  }

  const handleMessage = async (event) => {
    if (event.origin === `${API_URL}`) {
      if(event.data.status === 'success') {
        await doLoginOrFetchUserAction();

        setLoading(false);

        window.removeEventListener('message', handleMessage.bind(this));
      }
    }
  }

  const link = () => {
    setLoading(true);
    const { token } = storage.getUserObject();
    const url = `${API_URL}/auth/facebook/start?token=${token}`;

    connectWindow.current = window.open(url, 'Twitter', 'width=800,height=600');

    window.removeEventListener('message', handleMessage.bind(this));
    window.addEventListener('message', handleMessage.bind(this));
    interval = setInterval(checkWindow, 1000);
  }

  const toggleFetchContentFromMyAccount = () => {
    if (from_instagram_page) {
      //TODO: the API need to accept a parameter to keep or discard previously fetched content...
      // in the mean time the toggle simply toggle the boolean
      // when the API will be ready, you will need to add a modal
      doUpdateWall(wallId, { from_instagram_page: false })
    } else {
      doUpdateWall(wallId, { from_instagram_page: true })
    }
  }
  const unlink = () => {
    UserService.unlinkInstagram().then(async () => {
      await doLoginOrFetchUserAction()
    })
  }

  return linked ? (
    <>
      <div className="social-connected instagram">
        <span>Linked Facebook account</span>
        <div>
          <img
            src={getUserPhoto(instagramUser.img)}
            alt={`@${instagramUser.username}'s avatar`}
          />
          <div>
            <strong>{`${instagramUser.username}`}</strong>
          </div>
        </div>
        <Button className="small inverted" onClick={unlink}>
          unlink
        </Button>
      </div>
      <div className="SetupPanel instagram">
        <div className="SetupPanelWrapper linked">
          <>
            <ChipsContainer
              title="Instagram keyword"
              placeholder="add your keyword"
              type="instagram"
              wallId={wallId}
              onAdd={doCreateKeywordAction}
              onDelete={doRemoveKeywordAction}
              showModal={true}
              items={instagramKeywords}
            />
            <div className="logo">
              <img
                // className="Instagram"
                src={instagramLogo}
                alt="Instagram-logo"
                style={{ width: 60, height: 60 }}
              />
            </div>
            <div className="content-from-my-account">
              {from_instagram_page !== undefined ? (
                <>
                  <Title className="small">Get content from my account</Title>
                  <div className="SetupToggle">
                    <div>no</div>
                    <Toggle
                      isChecked={from_instagram_page}
                      onToggle={toggleFetchContentFromMyAccount}
                    />
                    <div>yes</div>
                  </div>
                </>
              ) : null}
            </div>
          </>
        </div>
      </div>
    </>
  ) : (
    <div className="SetupPanel instagram">
      <div className="SetupPanelWrapper unlinked">
        <div className="logo">
          <img
            src={facebookLogo}
            alt="Instagram-logo"
            style={{ width: 60, height: 60 }}
          />
          <span>+</span>
          <img
            src={instagramLogo}
            alt="Instagram-logo"
            style={{ width: 60, height: 60 }}
          />
        </div>

        {!loading ? (
          <Button onClick={link}>link my Facebook account</Button>
        ) : (
          <FontAwesomeIcon
            icon={['fas', 'sync']}
            className="fa-spin"
            style={{ width: 20, height: 20, color: '#969b9e' }}
          />
        )}

        <p>
          To search for hastags and keywords on Instagram or get content from a Facebook
          page you administer, please link your Facebook account.
        </p>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    from_instagram_page: state.currentWall.from_instagram_page,
    instagramUser: state.user.instagram,
    keywords: state.currentWall.keywords
  }),
  {
    doCreateKeywordAction,
    doRemoveKeywordAction,
    doLoginOrFetchUserAction,
    doUpdateWall
  }
)(SetupPageInstagram)
