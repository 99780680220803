import React, { useRef, useState } from 'react'
import _debounce from 'lodash/debounce'

const Textarea = ({ value, updateText }) => {
  const [text, setText] = useState(value)
  const textareaRef = useRef(null)

  const saveText = useRef(
    _debounce(_text => {
      updateText(_text)
    }, 500)
  )

  const changeText = () => {
    setText(textareaRef.current.value)
    saveText.current(textareaRef.current.value)
  }

  return <textarea ref={textareaRef} rows={4} value={text} onChange={changeText} />
}

export default Textarea
