import {
  ADD_KEYWORDS,
  ADD_KEYWORD,
  REMOVE_KEYWORD
} from '../currentWallReducerActionTypes'

import KeywordService from 'services/api/KeywordService'
import { getType } from 'setup/utils'

export const fetchAll = wallId => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let data, keywords
    try {
      data = await KeywordService.getAll(wallId)
      keywords = formatKeywords(data)
      dispatch(addKeywordsAction(keywords))
    } catch (error) {
      console.log(error)
    } finally {
      resolve(keywords)
    }
  })
}

export const create = (wallId, word, type) => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let data, keyword
    try {
      data = await KeywordService.create(wallId, word, type)
      let [finalKeword] = formatKeywords([data])
      keyword = finalKeword
      dispatch(addKeywordAction(finalKeword))
    } catch (error) {
      reject(error.data)
    } finally {
      resolve(keyword)
    }
  })
}
export const remove = (keywordId, deleteContent) => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let keyword
    try {
      KeywordService.destroy(keywordId, deleteContent)
      dispatch(removeKeywordAction(keywordId))
    } catch (error) {
      console.log(error)
    } finally {
      resolve(keyword)
    }
  })
}

function formatKeywords(data) {
  return data.map(item => ({
    id: item.id,
    value: item.keyword,
    type: getType(item.keywordable_type)
  }))
}

const addKeywordsAction = keywords => ({
  type: ADD_KEYWORDS,
  payload: keywords
})

const addKeywordAction = keyword => ({
  type: ADD_KEYWORD,
  payload: keyword
})

const removeKeywordAction = id => ({
  type: REMOVE_KEYWORD,
  payload: id
})
