import { ADD_SCREEN, DELETE_SCREEN, UPDATE_SCREEN } from './currentWallReducerActionTypes'

export default (screens = [], action) => {
  switch (action.type) {
    case ADD_SCREEN:
      return [...screens, action.payload]

    case DELETE_SCREEN:
      return [...screens.filter(screen => screen.id !== action.payload)]

    case UPDATE_SCREEN:
      return screens.map((screen, index) => {
        if (screen.id !== action.payload.id) {
          // This isn't the screen we care about - keep it as-is
          return screen
        }
        // Otherwise, this is the one we want - return an updated value
        return {
          ...screen,
          ...action.payload
        }
      })

    default:
      return screens
  }
}
