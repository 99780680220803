import React from 'react'
import 'components/LayoutThumbnail/LayoutThumbnail.scss'
import { DragSource } from 'react-dnd'
import Button from 'components/Button/Button'
import Uploader from 'components/Uploader/Uploader'

import CallToAction1Thumbnail from 'assets/images/thumbnails/call-to-action-1.png'
import Image1Thumbnail from 'assets/images/thumbnails/image-1.png'
import Masonry1Thumbnail from 'assets/images/thumbnails/masonry-1.png'
import Mosaic1Thumbnail from 'assets/images/thumbnails/mosaic-1.png'
import MultipleImages1Thumbnail from 'assets/images/thumbnails/multiple-images-1.png'
import PicturePostOnly1Thumbnail from 'assets/images/thumbnails/picture-post-only-1.png'
import PicturePostOnly2Thumbnail from 'assets/images/thumbnails/picture-post-only-2.png'
import TextPostOnly1Thumbnail from 'assets/images/thumbnails/text-post-only-1.png'
import TextPic1Thumbnail from 'assets/images/thumbnails/text-picture-1.png'
import TextPic2Thumbnail from 'assets/images/thumbnails/text-picture-2.png'
import Video1Thumbnail from 'assets/images/thumbnails/video-1.png'
import WallService from 'services/api/WallService'
import { CLOUDFRONT_ASSETS_URL } from 'setup/constants'

const screenSource = {
  beginDrag(props) {
    return {
      id: props.id
    }
  },

  endDrag(props, monitor) {
    const item = monitor.getItem()
    const dropResult = monitor.getDropResult()

    if (dropResult) {
      props.createLayout(item.id)
    }
  }
}

export const LayoutThumbnail = ({
  id,
  name,
  connectDragSource,
  createLayout,
  isDragging,
  screen_type,
  preview_layout,
  doUpdateScreen,
  currentWall,
  currentScreenId
}) => {

  const getPreviewThumbnail = (screenType) => {
    switch (screenType) {
      case "TextPostOnly1":
        return TextPostOnly1Thumbnail;
      case "TextPic1":
        return TextPic1Thumbnail;
      case "PicturePostOnly1":
        return PicturePostOnly1Thumbnail;
      case "CallToAction1":
        return CallToAction1Thumbnail;
      case "TextPic2":
        return TextPic2Thumbnail;
      case "PicturePostOnly2":
        return PicturePostOnly2Thumbnail;
      case "Image1":
        return Image1Thumbnail;
      case "MultipleImages1":
        return MultipleImages1Thumbnail;
      case "Mosaic1":
        return Mosaic1Thumbnail;
      case "Masonry1":
        return Masonry1Thumbnail;
      case "Video1":
        return Video1Thumbnail;
      default:
        return;
    }
  };

  const updateStyle = (screenId, url) => {
    WallService.get(currentWall.id).then(wall => {
      const urlObject = new URL(url);
      const pathname = urlObject.pathname.split('/')
      pathname.splice(1, 1)
      url = `${CLOUDFRONT_ASSETS_URL}${pathname.join('/')}`
      const styleId = wall.screens.filter(screen => screen.id === screenId)[0]?.styles[0]?.id
      updateScreenImageStyle(styleId, url, screenId)
    })
  }

  const updateScreenImageStyle = (id, data, screenId) => {
    doUpdateScreen(
      screenId !== undefined && screenId !== null
        ? screenId
        : currentWall.screens[currentScreenId].id,
      {
        styles_attributes: [
          {
            id,
            value: data
          }
        ]
      }
    )
  };

  const renderAction = (type) => {
    switch(type) {
      case 'MultipleImages1':
        return <>
                  <Uploader
                    currentScreenId={null}
                    currentWallId={currentWall.id}
                    selfReset={true}
                    type="image"
                    multiple={true}
                    layoutId={id}
                    createLayout={createLayout}
                    updateStyle={updateStyle}
                    updateFile={async (url, screenId) => {
                      const screen = await createLayout(id)
                      updateScreenImageStyle(screen.styles[0].id, url, screen.id);
                    }}
                  />
                  <button type="button" className="Button">Upload</button>
                </>;
      case 'Image1':
        return <>
                  <Uploader
                    currentScreenId={null}
                    currentWallId={currentWall.id}
                    selfReset={true}
                    type="image"
                    layoutId={id}
                    createLayout={createLayout}
                    updateFile={async (url, screenId) => {
                      const screen = await createLayout(id)
                      updateScreenImageStyle(screen.styles[0].id, url, screen.id);
                    }}
                    updateStyle={updateStyle}
                  />
                  <button type="button" className="Button">Upload</button>
                </>;
      case 'Video1':
        return <>
                  <Uploader
                    currentScreenId={null}
                    currentWallId={currentWall.id}
                    selfReset={true}
                    type="video"
                    layoutId={id}
                    createLayout={createLayout}
                    updateFile={async (url, screenId) => {
                      const screen = await createLayout(id)
                      updateScreenImageStyle(screen.styles[0].id, url, screen.id);
                    }}
                    updateStyle={updateStyle}
                  />
                  <button type="button" className="Button">Upload</button>
                </>;
      default:
        return <Button onClick={() => createLayout(id)}>Use</Button>;
    }
  };

  return connectDragSource(
    <div className={`LayoutThumbnail`} id={id}>
      <div className={`LayoutThumbnailImage`}>
        <div className="bg-img" style={{ backgroundImage: `url(${getPreviewThumbnail(screen_type)}` }}>
          <div className={`ButtonContainer ${isDragging ? 'HideButton' : ''}`}>
            {renderAction(screen_type)}
          </div>
        </div>
      </div>

      <div className="LayoutThumbnailTitle">{name.toLowerCase()}</div>
    </div>
  )
}

export default DragSource('layout', screenSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))(LayoutThumbnail)
