import request from 'services/requestWrapper.js'

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of current wall to get stats from
 */

function get(wallId) {
  return request({
    url: `/stats/${wallId}.json`,
    method: 'GET'
  })
}

const StatsService = {
  get
}

export default StatsService
