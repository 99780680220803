import request from 'services/requestWrapper.js'

/**
 * Returns all plans.
 */
function getAll() {
  return request({
    url: `/plans`,
    method: 'GET'
  })
}

/**
 *
 * @param {number} id Get a single plan by passing in it's id.
 */
function get(id) {
  return request({
    url: `/plans/${id}`,
    method: 'GET'
  })
}

const PlanService = {
  get,
  getAll
}

export default PlanService
