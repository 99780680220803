import React from 'react'

import {
  TEXT_POST_ONLY_1,
  PIC_POST_ONLY_1,
  PIC_POST_ONLY_2,
  MASONRY_1,
  MOSAIC_1,
  TEXT_PIC_1,
  TEXT_PIC_2,
  IMAGE_1,
  MULTIPLES_IMAGES_1,
  VIDEO_1,
  CTA_1,
  getBackgroundColorStyle,
  getFontFamily1Style,
  getTextColorStyle,
  getBackgroundVideoStyle
} from 'services/app/screen'
import TextPostScreen from '../Visualizer/Screens/TextPostScreen/TextPostScreen'
import FadeScreen from '../Visualizer/Screens/FadeScreen/FadeScreen'
import TextPicScreen from '../Visualizer/Screens/TextPicScreen/TextPicScreen'
import CTAScreen from '../Visualizer/Screens/CTAScreen/CTAScreen'
import VideoScreen from '../Visualizer/Screens/VideoScreen/VideoScreen'
import ImageScreen from '../Visualizer/Screens/ImageScreen/ImageScreen'
import { CARDS_PLACEHOLDER } from 'services/app/cardsPlaceholder'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WallbarItem = ({
  defaultStyles,
  isSelected,
  layout,
  previewLayout,
  onClick,
  openDeleteModal,
  screen,
  type
}) => {
  const screensParams = {
    cards: CARDS_PLACEHOLDER,
    defaultStyles,
    isRunning: false,
    screen,
    showPlaceholder: true
  }

  const scaledDownScreen = {
    [TEXT_POST_ONLY_1]: <TextPostScreen {...screensParams} />,
    [PIC_POST_ONLY_1]: <FadeScreen screenType={PIC_POST_ONLY_1} {...screensParams} />,
    [PIC_POST_ONLY_2]: <FadeScreen screenType={PIC_POST_ONLY_2} {...screensParams} />,
    [MOSAIC_1]: <FadeScreen screenType={MOSAIC_1} {...screensParams} />,
    [MASONRY_1]: <FadeScreen screenType={MASONRY_1} {...screensParams} />,
    [TEXT_PIC_1]: <FadeScreen screenType={TEXT_PIC_1} {...screensParams} />,
    [TEXT_PIC_2]: <TextPicScreen {...screensParams} />,
    [IMAGE_1]: <ImageScreen {...screensParams} />,
    [MULTIPLES_IMAGES_1]: <ImageScreen {...screensParams} />,
    [VIDEO_1]: <VideoScreen {...screensParams} />,
    [CTA_1]: <CTAScreen {...screensParams} />
  }

  const getStyle = () => {
    return type === 'PLACEHOLDER'
      ? {
          backgroundImage: `url(${previewLayout})`
        }
      : {
          fontFamily: `${getFontFamily1Style(defaultStyles, 'family')}`,
          backgroundColor: getBackgroundColorStyle(defaultStyles),
          color: getTextColorStyle(defaultStyles)
        }
  }

  return (
    <div className={`WallbarItem ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <div className="trash" onClick={openDeleteModal}>
        <FontAwesomeIcon icon={['fas', 'trash-alt']} style={{ color: '#fff' }} />
      </div>
      <div className="layout" style={getStyle()}>
        {type !== 'PLACEHOLDER' && screen && screen.screen_type ? (
          getBackgroundVideoStyle(defaultStyles) ? (
            <video
              src={getBackgroundVideoStyle(defaultStyles)}
              className="screen-video-bg"
              muted
            />
          ) : null
        ) : null}

        {type !== 'PLACEHOLDER' && screen && screen.screen_type
          ? scaledDownScreen[screen.screen_type]
          : null}
      </div>
      <span>{layout.name}</span>
    </div>
  )
}

export default WallbarItem
