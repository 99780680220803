import React from 'react'
import './VideoScreen.scss'
import { getBackgroundVideoStyle } from 'services/app/screen'
import { CLOUDFRONT_ASSETS_URL } from 'setup/constants'

const VideoScreen = ({ screen: { styles: screenStyles }, isRunning = true }) => {
  const videoUrl =
    getBackgroundVideoStyle(screenStyles) !== false &&
    getBackgroundVideoStyle(screenStyles).toString() !== ''
      ? getBackgroundVideoStyle(screenStyles).toString()
      : null

  return (
    <div className="VideoScreen">
      {!isRunning && videoUrl === null ? (
        <img alt="" src={`${CLOUDFRONT_ASSETS_URL}assets/layouts/empty-video-1.jpg`} />
      ) : null}
      {videoUrl !== null ? <video loop autoPlay muted src={videoUrl} /> : null}
    </div>
  )
}

export default VideoScreen
