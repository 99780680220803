import React from 'react'
import { connect } from 'react-redux'
import { doLoginOrFetchUserAction } from 'store/user/userReducer'
import { withRouter } from 'react-router'
import Title from 'components/Title/Title'
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from 'setup/constants'

import Button from 'components/Button/Button'
import 'components/Forms/Forms.scss'

/** The component used on Login page */
export const LoginForm = ({
  active,
  doLoginOrFetchUserAction,
  handleResetPassword,
  responseFacebook,
  responseGoogle,
  history,
  toggleState
}) => {
  let usernameNode
  let passwordNode

  return (
    <div
      className={`Forms Login ${active ? 'expand' : 'collapse'}`}
      style={{ display: 'flex' }}>
      <form onSubmit={e => {
        e.preventDefault();
        console.log(usernameNode);
        console.log(passwordNode);
        if (usernameNode.value && passwordNode.value) {
          doLoginOrFetchUserAction(usernameNode.value, passwordNode.value);
          history.push('/');
        }
      }}>
        <Title className={active ? 'large' : 'small'} handleClickFunction={toggleState}>
          {active ? 'Login' : 'already have an account'}
        </Title>
        <label className="label" htmlFor="username">
          <input
            type="text"
            name="username"
            placeholder="Email or Username"
            ref={node => (usernameNode = node)}
          />
        </label>
        <label className="label" htmlFor="password">
          <input
            type="password"
            name="password"
            placeholder="Password"
            ref={node => (passwordNode = node)}
          />
          <div className="messageErrorText" id="errorLoginText" />
        </label>

        <span className="forgotPassword" onClick={handleResetPassword}>
          forgot your password?
        </span>
        <Button className="ButtonLogin" type="submit">
          Login
        </Button>
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          textButton="Log in With Facebook"
          icon="fa-2x fa-facebook-official"
          onClick={responseFacebook}
          callback={responseFacebook}
        />
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Log In With Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </form>
    </div>
  )
}
export default withRouter(connect(null, { doLoginOrFetchUserAction })(LoginForm))
