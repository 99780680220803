import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { updatePayFormAction } from 'store/payFormReducer/payFormReducer'
import { getFieldIsEmptyError } from 'setup/formUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Input from 'components/Input/Input'
import Title from 'components/Title/Title'
import 'react-datepicker/dist/react-datepicker.css'
import './ActivationInfo.scss'

import DatePicker from 'react-datepicker'
import Select from 'react-select'
import CouponService from 'services/api/CouponService'
import moment from 'moment'
import { TIME_FORMAT, DATE_FORMAT } from '../PayPageContants'
import TimezoneSelector from 'components/TimezoneSelector/TimezoneSelector'

const ActivationInfo = ({
  dailyPrice,
  payForm: {
    couponCode,
    datePickerDate,
    duration,
    live_start,
    options,
    price,
    showCoupon,
    showTimezone,
    timePickerTime,
    timezone
  },
  updatePayFormAction
}) => {
  const getDiscountValue = () => {
    switch (couponCode.coupon_type) {
      case 'fixed_amount':
        return couponCode.coupon_value
      case 'percentage':
        return (price * couponCode.coupon_value) / 100
      default:
        return 0
    }
  }

  const verifyCoupon = () => {
    CouponService.verify(couponCode.value)
      .then(data => data)
      .then(coupon => {
        updatePayFormAction({
          couponCode: { ...coupon, value: couponCode.value, dirty: true }
        })
      })
      .catch(error => {
        updatePayFormAction({
          couponCode: { dirty: true, errorMessage: error.data.error }
        })
      })
  }

  useEffect(() => {
    const price = dailyPrice * duration.value

    if (dailyPrice && price > 0) {
      updatePayFormAction({
        price
      })
    }
  }, [dailyPrice, duration.value, updatePayFormAction])

  useEffect(() => {
    const _live_start = moment(
      moment(datePickerDate).format(DATE_FORMAT) +
        ' ' +
        moment(timePickerTime).format(TIME_FORMAT)
    ).format()

    if (live_start !== _live_start) {
      updatePayFormAction({ live_start: _live_start })
    }
  }, [datePickerDate, live_start, timePickerTime, updatePayFormAction])

  return (
    <div>
      <div className="Step ActivationInfo">
        <div className="PayPageBox">
          <div className="PayPageBoxInner Wider">
            <Title className="small">wall starting date & time</Title>
            <div className="Row mb-2 2-columns">
              <div className="PickerContainer">
                <DatePicker
                  minDate={new Date()}
                  className="DatePicker"
                  selected={datePickerDate}
                  onChange={datePickerDate => {
                    updatePayFormAction({
                      datePickerDate
                    })
                  }}
                  dateFormat="MMM d, yyyy"
                />

                <FontAwesomeIcon
                  icon={['far', 'calendar-alt']}
                  style={{ width: 15, height: 15 }}
                />
              </div>
              <div className="PickerContainer">
                <DatePicker
                  selected={timePickerTime}
                  onChange={timePickerTime => {
                    updatePayFormAction({
                      timePickerTime
                    })
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="h:mm a"
                />
                <FontAwesomeIcon
                  icon={['far', 'clock']}
                  style={{ width: 15, height: 15 }}
                />
              </div>
            </div>
            <div className="Row center">
              {showTimezone ? (
                <TimezoneSelector
                  storeValue={timezone}
                  handleSubmit={updatePayFormAction}
                />
              ) : (
                <button
                  onClick={() =>
                    updatePayFormAction({
                      showTimezone: true
                    })
                  }>
                  change my timezone
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="PayPageBox Activation">
          <div className="PayPageBoxInner">
            <Title className="small">activation duration</Title>
            <Select
              menuPortalTarget={document.body}
              classNamePrefix="react-select"
              value={duration}
              onChange={duration => {
                updatePayFormAction({
                  duration,
                  price: dailyPrice && dailyPrice && dailyPrice * duration.value
                })
              }}
              options={options}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                control: styles => ({
                  ...styles,
                  borderColor: '#7617ce!important',
                  boxShadow: 'none'
                }),
                option: (styles, state) => ({
                  ...styles,
                  backgroundColor: state.isSelected
                    ? '#7617ce!important'
                    : '#fff!important',
                  cursor: 'pointer',
                  color: state.isSelected ? '#fff' : '#000'
                })
              }}
            />
          </div>
        </div>
        <div className="PayPageBox Price">
          <div className="PayPageBoxInner">
            {couponCode.coupon_value > 0 && (
              <div className="PaySection">
                <div className="Label">Discount</div>
                <div className="PaySectionPrice">
                  <div className="Price">-${getDiscountValue()}</div>
                </div>
              </div>
            )}
            <div className={`PaySection ${showCoupon ? 'CouponShown' : ''}`.trim()}>
              <div className="Label">Total</div>
              <div className="PaySectionPrice">
                <div className="Price">${price - getDiscountValue()}</div>
                {!showCoupon && (
                  <button
                    onClick={() => updatePayFormAction({ showCoupon: !showCoupon })}>
                    coupon code?
                  </button>
                )}
              </div>
            </div>
            {showCoupon && (
              <Input
                name={couponCode.name}
                onChange={e => {
                  updatePayFormAction({
                    couponCode: {
                      value: e.target.value,
                      errorMessage: getFieldIsEmptyError(e.target.value),
                      dirty: false
                    }
                  })
                }}
                onBlur={() => !couponCode.dirty && verifyCoupon()}
                value={couponCode.value}
                errorMessage={couponCode.errorMessage}
                dirty={couponCode.dirty}
                className="simple"
                placeholder="coupon code"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default connect(
  state => ({
    dailyPrice: state.currentWall.plan.daily,
    payForm: state.payForm
  }),
  {
    updatePayFormAction
  }
)(ActivationInfo)
