import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCcAmex,
  faCcMastercard,
  faCcVisa,
  faFacebook,
  faInstagram,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowsAlt,
  faBars,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faExclamation,
  faFilm,
  faFont,
  faGripHorizontal,
  faGripLines,
  faHashtag,
  faMinusCircle,
  faQuestion,
  faStar,
  faSync,
  faSyncAlt,
  faTimesCircle,
  faTrashAlt,
  faVideo
} from '@fortawesome/free-solid-svg-icons'

import {
  faCalendarAlt,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faClock,
  faFileVideo,
  faImage,
  faQuestionCircle,
  faSquare,
  faTimesCircle as faTimesReg
} from '@fortawesome/free-regular-svg-icons'

export const setupFontAwesome = () => {
  library.add(
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowLeft,
    faArrowsAlt,
    faBars,
    faCalendarAlt,
    faCcAmex,
    faCcMastercard,
    faCcVisa,
    faCheck,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClock,
    faExclamation,
    faFacebook,
    faFileVideo,
    faFilm,
    faFont,
    faGripHorizontal,
    faGripLines,
    faHashtag,
    faImage,
    faInstagram,
    faMinusCircle,
    faQuestion,
    faQuestionCircle,
    faSquare,
    faStar,
    faSync,
    faSyncAlt,
    faTimesCircle,
    faTimesReg,
    faTrashAlt,
    faTwitter,
    faVideo
  )
}
