import axios from 'axios'
import { API_URL } from 'setup/constants'
import env from 'services/app/environment.js'

/**
 * Create an Axios Client with defaults
 */
export const client = axios.create({
  baseURL: API_URL
})

client.interceptors.request.use(
  config => {
    let token = null
    if (localStorage.getItem('wallrus')) {
      token = JSON.parse(localStorage.getItem('wallrus')).user.token
    }
    config.headers = config.headers || {}
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * Request Wrapper with default success/error actions
 */
const request = function(options) {
  const onSuccess = function(response) {
    return response.data
  }

  const onError = function(error) {
    if (env.showAPIErrorsInConsole()) {
      console.error('Request Failed:', error.config)

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.error('Status:', error.response.status)
        console.error('Data:', error.response.data)
        console.error('Headers:', error.response.headers)
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error('Error Message:', error.message)
      }
    }

    return Promise.reject(error.response || error.message)
  }

  return client(options)
    .then(onSuccess)
    .catch(onError)
}

export default request
