import React, { Component } from 'react'
import WallList from 'components/WallList/WallList'
import CreateWallForm from 'components/CreateWallForm/CreateWallForm'
import { connect } from 'react-redux'
import { doFetchAllPlansAction } from 'store/plans/plansReducer'
import { doFetchAllWallsAction } from 'store/walls/wallsReducer'
import { resetCurrentWallAction } from 'store/currentWall/actions/index'
import { setSidebarActiveRouteId } from 'store/sidebar/sidebarReducer'
import { isSubuser } from 'setup/utils'

import './DashboardPage.scss'
const ESCAPE_KEY = 27
class DashboardPage extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    this.props.doFetchAllWallsAction().then(() => {
      this.setState({ loading: false })
    })
    this.props.setSidebarActiveRouteId(1)
    this.props.resetCurrentWallAction()

    document.querySelector('body').addEventListener('keydown', this.handleKeyDown())
  }

  handleKeyDown() {
    return e => (e.keyCode === ESCAPE_KEY ? this.hidePlans() : null)
  }

  hidePlans = () => this.setState({ showPlans: false })

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, true) // Fails
  }

  showPlansList = (wallName, hashtag) => {
    this.setState({
      showPlans: true,
      wallName,
      hashtag
    })
  }

  render() {
    return !this.state.loading ? (
      <div className="DashboardPage">
        <WallList />
        {!isSubuser(this.props.user) ? (
          <CreateWallForm showPlanSelectionPanel={this.showPlansList} />
        ) : null}
      </div>
    ) : null
  }
}

export default connect(
  state => ({ walls: state.walls, currentWall: state.currentWall, user: state.user }),
  {
    doFetchAllPlansAction,
    doFetchAllWallsAction,
    resetCurrentWallAction,
    setSidebarActiveRouteId
  }
)(DashboardPage)
