import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import PublicHomePage from 'pages/PublicHomePage'
import SessionService from 'services/api/SessionService'
import AppContainer from 'layouts/AppContainer/AppContainer'
import Loader from 'components/Loader/Loader'
import { connect } from 'react-redux'
import './App.scss'
import { isFullscreenPage } from 'routes/routesUtils'
import FullscreenPage from 'pages/FullscreenPage/FullscreenPage'

const App = () => {
  return (
    <div className="App">
      <Router>
        {SessionService.isLoggedIn() ? (
          <AppContainer />
        ) : isFullscreenPage() ? (
          <FullscreenPage />
        ) : (
          <PublicHomePage />
        )}
      </Router>
      <Loader />
    </div>
  )
}

export default connect(state => ({
  user: state.user
}))(App)
