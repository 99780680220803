import {
  ADD_KEYWORDS,
  ADD_KEYWORD,
  REMOVE_KEYWORD
} from './currentWallReducerActionTypes'

export default (keywords = [], action) => {
  switch (action.type) {
    case ADD_KEYWORDS:
      return [...action.payload]

    case ADD_KEYWORD:
      return [...keywords, action.payload]

    case REMOVE_KEYWORD:
      return [...keywords.filter(keyword => keyword.id !== action.payload)]

    default:
      return keywords
  }
}
