import React from 'react'
import { connect } from 'react-redux'
import wallrusInfo from 'assets/images/wallrus-info.png'
import './FreeModerationModal.scss'
import { doUpdateWall } from 'store/currentWall/actions/index'
import { closeModalAction } from 'store/modal/modalReducer'

import Button from 'components/Button/Button'

const FreeModerationModal = ({ currentWall, closeModalAction, doUpdateWall }) => (
  <div className="FreeModerationModal">
    <div className="Block">
      <span>Moderation is only available in our Basic and Pro plan.</span>
      <img src={wallrusInfo} alt="wallrus info" />
    </div>

    <div className="ModalFooter">
      <Button
        onClick={() => {
          doUpdateWall(currentWall.id, {
            auto_approve: false,
            plan_id: 2
          }).then(() => closeModalAction())
        }}>
        upgrade to basic
      </Button>
    </div>
  </div>
)

export default connect(
  state => ({ currentWall: state.currentWall }),
  { closeModalAction, doUpdateWall }
)(FreeModerationModal)
