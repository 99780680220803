import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import './CustomizePage.scss'
import Collapser from 'components/Collapser/Collapser'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import Toggle from 'components/Toggle/Toggle'
import Uploader from 'components/Uploader/Uploader'
import { openModalAction } from 'store/modal/modalReducer'
import { doApplyThemeAction } from 'store/currentWall/actions/index'
import { setSidebarActiveRouteId } from 'store/sidebar/sidebarReducer'
import _sortBy from 'lodash/sortBy'
import _toString from 'lodash/toString'
import { Link } from 'react-router-dom'
import FontSelector from 'components/FontSelector/FontSelector'
import HashtagSelector from 'components/HashtagSelector/HashtagSelector'
import FloatingActions from 'components/FloatingActions/FloatingActions'
import FloatingButton from 'components/FloatingActions/FloatingButton'
import Colors from 'setup/colors.scss'
import {
  doFetchAllKeywordsAction,
  doUpdateScreen,
  doUpdateWall
} from 'store/currentWall/actions/index'
import { getColorFromString, isLightColor } from 'setup/utils'
import Title from 'components/Title/Title'
import { setupFonts } from 'setup/setupFonts'

import CustomizeScreen from './CustomizeScreen'
import Textarea from 'components/Textarea/Textarea'
const isTransparent = color =>
  color.length > 7 ? color.split(',')[3].split(')')[0] === '0' : color === ''

class CustomizePage extends Component {

  async componentDidMount() {
    if (this.props.currentWall.theme_id === null) {
      this.props.doApplyThemeAction(1, this.props.wallId)
    }
    setupFonts()
    if (this.props.currentWall.keywords.length === 0) {
      await this.props.doFetchAllKeywordsAction(this.props.wallId)
    }
  }

  getCollapserHeader = style => {
    let color = ''
    if (style.style_type === 'color_selector') {
      color = getColorFromString(style.value)
    }
    const currentScreen = this.props.currentWall.screens[this.props.currentScreenId]
    const currentBackgroundColor = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_color')
        ? currentScreen.styles.find(style => style.style_name === 'background_color')
            .value
        : null
      : null
    const currentBackgroundImage = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_image')
        ? currentScreen.styles.find(style => style.style_name === 'background_image')
            .value
        : null
      : null
    const icon = currentScreen ? (
      style.style_type === 'color_selector' ? (
        isTransparent(color) ? (
          <div className="transparent-circle" />
        ) : (
          <div
            className="color circle"
            style={{
              backgroundColor: color,
              boxShadow: `0 0 0 1px ${isLightColor(color) ? '#dadada' : '#fff'}`
            }}
          />
        )
      ) : style.style_type === 'text' ? (
        <FontAwesomeIcon
          icon={['fas', 'font']}
          style={{ width: 16, height: 16, color: Colors.brand }}
        />
      ) : style.style_type === 'upload_image' ? (
        <FontAwesomeIcon
          icon={['far', 'image']}
          style={{ width: 16, height: 16, color: Colors.brand }}
        />
      ) : style.style_type === 'upload_video' ? (
        <FontAwesomeIcon
          icon={['far', 'file-video']}
          style={{ width: 16, height: 16, color: Colors.brand }}
        />
      ) : style.style_type === 'background_color_image' ? (
        currentBackgroundImage !== '' ? (
          <FontAwesomeIcon
            icon={['far', 'file-video']}
            style={{ width: 16, height: 16, color: Colors.brand }}
          />
        ) : getColorFromString(currentBackgroundColor) === '' ? (
          <div className="transparent-circle" />
        ) : (
          <div
            className="color circle"
            style={{
              backgroundColor: getColorFromString(currentBackgroundColor),
              boxShadow: `0 0 0 1px ${
                isLightColor(getColorFromString(currentBackgroundColor))
                  ? '#dadada'
                  : '#fff'
              }`
            }}
          />
        )
      ) : null
    ) : null
    return (
      <>
        {icon}
        <span>{style.label}</span>
      </>
    )
  }

  getStyleContent = (style, open) => {
    const propsAttributes = {
      key: style.id,
      title: this.getCollapserHeader(style),
      animContent: false,
      iconSize: 14,
      open
    }
    const currentScreen = this.props.currentWall.screens[this.props.currentScreenId]
    const currentBackgroundColor = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_color')
        ? currentScreen.styles.find(style => style.style_name === 'background_color')
            .value
        : null
      : null
    const currentBackgroundColorID = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_color')
        ? currentScreen.styles.find(style => style.style_name === 'background_color').id
        : null
      : null
    const currentBackgroundImage = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_image')
        ? currentScreen.styles.find(style => style.style_name === 'background_image')
            .value
        : null
      : null
    const currentBackgroundImageID = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_image')
        ? currentScreen.styles.find(style => style.style_name === 'background_image').id
        : null
      : null

    return currentScreen ? (
      style.style_type === 'color_selector' ? (
        <Collapser {...propsAttributes}>
          <ColorPicker
            value={style.value}
            setColor={color => this.updateStyles(style.id, color)}
          />
        </Collapser>
      ) : style.style_type === 'font_selector' ? (
        <div className="Field" key={style.id}>
          <div className="Field-icon">
            <FontAwesomeIcon
              icon={['fas', 'font']}
              style={{ width: 16, height: 16, color: Colors.brand }}
            />
          </div>
          <FontSelector
            font={style.value}
            updateFont={font => this.updateStyles(style.id, font)}
          />
        </div>
      ) : style.style_type === 'hashtag_selector' ? (
        <div className="Field" key={style.id}>
          <div className="Field-icon">
            <FontAwesomeIcon
              icon={['fas', 'hashtag']}
              style={{ width: 16, height: 16, color: Colors.brand }}
            />
          </div>
          <HashtagSelector
            hashtags={this.props.currentWall.keywords}
            selectedHashtag={this.getSelectedHashtag()}
            updateHashtag={hashtag => this.updateStyles(style.id, hashtag)}
          />
        </div>
      ) : style.style_type === 'text' ? (
        <Collapser {...propsAttributes}>
          <div className="textarea">
            <Textarea
              value={style.value}
              updateText={text => this.updateStyles(style.id, text)}
            />
          </div>
        </Collapser>
      ) : style.style_type === 'boolean' ? (
        <div className="toggleOptions" key={style.id}>
          <Toggle
            isChecked={style.value}
            onToggle={checked => this.updateStyles(style.id, _toString(checked))}
            className="Toggle-small"
            textAfter={style.label}
          />
        </div>
      ) : style.style_type === 'upload_image' ? (
        <Collapser {...propsAttributes}>
          <Uploader
            file={style.value}
            currentScreenId={currentScreen.id}
            currentWallId={this.props.currentWall.id}
            updateFile={(url, screenId) => this.updateStyles(style.id, url, screenId)}
          />
        </Collapser>
      ) : style.style_type === 'upload_video' ? (
        <Collapser {...propsAttributes}>
          <Uploader
            file={style.value}
            currentScreenId={currentScreen.id}
            currentWallId={this.props.currentWall.id}
            type="video"
            updateFile={(url, screenId) => this.updateStyles(style.id, url, screenId)}
          />
        </Collapser>
      ) : style.style_type === 'background_color_image' ? (
        <Collapser {...propsAttributes}>
          <div className="uploader-colorpicker">
            <Uploader
              file={currentBackgroundImage}
              currentScreenId={currentScreen.id}
              currentWallId={this.props.currentWall.id}
              updateFile={(url, screenId) =>
                this.updateStyles(currentBackgroundImageID, url, screenId)
              }
            />
          </div>
          {currentBackgroundImage === '' ? (
            <ColorPicker
              value={currentBackgroundColor}
              setColor={color => this.updateStyles(currentBackgroundColorID, color)}
            />
          ) : null}
        </Collapser>
      ) : null
    ) : null
  }

  updateStyles = (id, data, screenId) => {
    this.props.doUpdateScreen(
      screenId !== undefined && screenId !== null
        ? screenId
        : this.props.currentWall.screens[this.props.currentScreenId].id,
      {
        styles_attributes: [
          {
            id,
            value: data
          }
        ]
      }
    )
  }

  getSelectedHashtag = () => {
    if (this.props.currentWall.styles.length > 0) {
      const value = this.props.currentWall.styles.find(
        style => style.style_name === 'displayed_hashtag'
      ).value
      return { value, label: value }
    }
    return ''
  }

  stylesInputs = styles => {
    if (styles !== undefined) {
      let _styles = styles.filter(style => style.style_name !== 'preview_img')

      if (_styles.length > 2) {
        _styles = _styles.filter(style => style.style_name !== 'background_video')
      }
      if (this.props.currentWall.plan_id !== 3) {
        _styles = _styles.filter(style => style.style_name !== 'top_bar_enable')
      }
      if (
        _styles.find(style => style.style_name === 'background_color') &&
        _styles.find(style => style.style_name === 'background_image')
      ) {
        _styles = _styles.filter(
          style =>
            style.style_name !== 'background_color' &&
            style.style_name !== 'background_image'
        )
        if (this.props.currentWall.plan_id === 3) {
          _styles.push({
            id: 'background_color_image',
            label: 'background color or image',
            stylable_id: 99,
            style_name: 'background_color_image',
            style_type: 'background_color_image'
          })
        }
      }
      _styles = _styles.filter(style => style.style_name !== 'displayed_hashtag')
      return this.customSort(_styles).map(style =>
        this.getStyleContent(style, _styles.length === 1)
      )
    } else {
      return null
    }
  }

  customSort = array => {
    const itemOrdered = [
      'font_family_1',
      'font_family_2',
      'displayed_hashtag',
      'logo',
      'background_color_image',
      'background_color',
      'background_image',
      'background_video',
      'text_1',
      'text_2',
      'text_color',
      'handle_color',
      'hashtag_color',
      'cards_background_color',
      'top_bar_background_color',
      'logo_enable',
      'hashtag_enable',
      'top_bar_enable'
    ]

    return [...array].sort(function(a, b) {
      var itemA = a['style_name'],
        itemB = b['style_name']

      if (itemOrdered.indexOf(itemA) > itemOrdered.indexOf(itemB)) {
        return 1
      } else {
        return -1
      }
    })
  }

  render() {
    const {
      currentWall: { id: wallId, screens, styles },
      currentScreenId,
      openModalAction,
      setSidebarActiveRouteId
    } = this.props

    const currentScreen = screens
      ? _sortBy([...screens], 'position')[currentScreenId]
      : null

    return (
      <div className="CustomizePage main-container-large">
        <FloatingActions>
          <FloatingButton onClick={() => openModalAction('OPTIONS_CUSTOMIZE')} label="options">
            <svg className="svg-inline--fa fa-w-16" viewBox="0 0 512 512"><path fill={Colors.brand} d="M96 0C78.3 0 64 14.3 64 32v54.7C35.7 99 16 127.2 16 160s19.7 61 48 73.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V233.3c28.3-12.3 48-40.5 48-73.3s-19.7-61-48-73.3V32c0-17.7-14.3-32-32-32zm0 128c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm160 192c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm80 32c0-32.8-19.7-61-48-73.3V32c0-17.7-14.3-32-32-32s-32 14.3-32 32v246.7c-28.3 12.3-48 40.5-48 73.3s19.7 61 48 73.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32v-54.7c28.3-12.3 48-40.5 48-73.3zm48-160c0-17.7 14.3-32 32-32s32 14.3 32 32-14.3 32-32 32-32-14.3-32-32zm64 73.3c28.3-12.3 48-40.5 48-73.3s-19.7-61-48-73.3V32c0-17.7-14.3-32-32-32s-32 14.3-32 32v86.7c-28.3 12.3-48 40.5-48 73.3s19.7 61 48 73.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V265.3z"/></svg>
          </FloatingButton>
          <FloatingButton onClick={() => openModalAction('HELP_CUSTOMIZE')} label="info">
            <FontAwesomeIcon icon={'question'} style={{ color: Colors.brand }} />
          </FloatingButton>
        </FloatingActions>
        <div className="main-container-inner">
          {screens.length > 0 ? (
            <>
              <div className="screenOverview">
                {styles !== undefined && currentScreen !== undefined ? (
                  <CustomizeScreen screen={currentScreen} />
                ) : null}
              </div>

              <div className="Tabs">
                <div className="TabsContent">
                  {currentScreen &&
                    (<div>{this.stylesInputs(currentScreen.styles)}</div>)
                  }
                </div>
              </div>
            </>
          ) : (
            <div className="NoScreenPage">
              <Title className="medium bold">where are the screens?</Title>
              <span>You currently do not have any screens.</span>
              <span>
                Go to{' '}
                <Link
                  to={`/wall/${wallId}/layout`}
                  onClick={() => setSidebarActiveRouteId(1)}>
                  <b>LAYOUT</b>
                </Link>{' '}
                to add more screens to your wall
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentWall: state.currentWall,
    currentScreenId: state.wallbar.selectedLayout
  }),
  {
    openModalAction,
    doApplyThemeAction,
    doFetchAllKeywordsAction,
    doUpdateScreen,
    doUpdateWall,
    setSidebarActiveRouteId
  }
)(CustomizePage)
