import React, { useState } from 'react'
import Moment from 'react-moment'
import './ModerationCard.scss'
import UserDeletedImage from 'assets/icons/UserDeletedImage.js'
import { truncateString } from 'setup/utils.js'
import Button from '../Button/Button.js'
import MagnifyingGlass from 'assets/icons/MagnifyingGlass.js'
import PlayIcon from 'assets/icons/PlayIcon.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { zoomModerationModalAction } from 'store/modal/modalReducer'

const CHARACTER_LIMIT = 282
const ModerationCard = ({
  approved,
  id,
  media_file_url,
  media_video_url,
  onApprove,
  onDelete,
  source,
  source_created_at,
  text,
  user_handle,
  user_name,
  zoomModerationModalAction
}) => {
  const [hasError, setHasError] = useState(false)
  const [showText, setShowText] = useState(false)
  text = text !== null ? text : ''

  return (
    <div id={id} className={`ModerationCard ${hasError ? 'HasError' : ''}`}>
      <div className="ModerationCard-header">
        <div className="ModerationCard-social">
          <FontAwesomeIcon icon={['fab', source]} />
          <div className="ModerationCard-fetch-time">
            <span>{<Moment format="MMM D">{source_created_at}</Moment>}</span>
            &nbsp;-&nbsp;
            <span>{<Moment format="h:mm a">{source_created_at}</Moment>}</span>
          </div>
        </div>
        <div className="ModerationCard-user-info">
          <div>{user_name}</div>
          <div>@{user_handle}</div>
        </div>
      </div>
      <div className="ModerationCard-content">
        {media_video_url ? (
          <div className="ModerationCard-media">
            {source === 'instagram' ? (
              <div className="missing-cover">CLICK TO PREVIEW</div>
            ) : !hasError ? (
              <img src={media_file_url} alt={source} onError={() => setHasError(true)} />
            ) : (
              <UserDeletedImage />
            )}
            <div onClick={() => zoomModerationModalAction(id)}>
              <div className="action-icon video">
                <PlayIcon />
              </div>
            </div>
          </div>
        ) : media_file_url ? (
          <div className="ModerationCard-media">
            {!hasError ? (
              <img src={media_file_url} alt={source} onError={() => setHasError(true)} />
            ) : (
              <UserDeletedImage />
            )}
            <div onClick={() => zoomModerationModalAction(id)}>
              <div className="action-icon">
                <MagnifyingGlass />
              </div>
            </div>
          </div>
        ) : null}

        <div className="ModerationCard-text">
          {!showText ? (
            truncateString(text, CHARACTER_LIMIT)
          ) : (
            <div>
              {text.slice(0, CHARACTER_LIMIT)}
              <span className="TruncatedText" onClick={() => setShowText(val => !val)}>
                {text.slice(CHARACTER_LIMIT, text.length)}
              </span>
              <div className="TruncatedTextWarning">
                <div className="ArrowContainer">
                  <div className="Arrow" />
                  The grey portion of the text will not show on your wall.
                </div>
              </div>
            </div>
          )}
          <div className="ReadMoreSection" />
        </div>
      </div>
      <div className="ReadMoreContainer">
        {text.length > CHARACTER_LIMIT && !showText ? (
          <button className="Read-More-Link" onClick={() => setShowText(val => !val)}>
            read more
          </button>
        ) : null}
      </div>

      {!approved ? (
        <div className="Actions">
          <div className="ActionsContainer">
            <Button className="ButtonDelete danger" onClick={onDelete}>
              Delete
            </Button>
            <Button className="ButtonDelete success" onClick={onApprove}>
              Approve
            </Button>
          </div>
        </div>
      ) : (
        <div className="Actions ButtonAlone">
          <Button className="ButtonDelete danger ButtonAlone" onClick={onDelete}>
            Delete
          </Button>
        </div>
      )}
    </div>
  )
  //   }
}

export default connect(
  state => ({}),
  { zoomModerationModalAction }
)(ModerationCard)
