const HIDE_SELECT_PLANS = 'HIDE_SELECT_PLANS'
const SHOW_SELECT_PLANS = 'SHOW_SELECT_PLANS'
const SET_WALL_NAME = 'SET_WALL_NAME'

export const hideSelectPlansAction = plans => ({
  type: HIDE_SELECT_PLANS
})

export const showSelectPlansAction = id => ({
  type: SHOW_SELECT_PLANS
})

export const createWallAction = wallName => ({
  type: SET_WALL_NAME,
  payload: {
    wallName
  }
})

const initState = {
  showPlans: false,
  newWall: {
    name: ''
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_WALL_NAME:
      return {
        ...state,
        newWall: {
          name: action.payload.wallName
        }
      }

    case HIDE_SELECT_PLANS:
      return {
        ...state,
        showPlans: false
      }

    case SHOW_SELECT_PLANS:
      return {
        ...state,
        showPlans: true
      }

    default:
      return state
  }
}
