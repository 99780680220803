import React from 'react'
import './ImageScreen.scss'
import { getBackgroundImageStyle } from 'services/app/screen'
import { CLOUDFRONT_ASSETS_URL } from 'setup/constants'

const ImageScreen = ({ screen: { styles: screenStyles }, isRunning = true }) => {
  const imageUrl =
    getBackgroundImageStyle(screenStyles) !== false &&
    !!getBackgroundImageStyle(screenStyles)  &&
    getBackgroundImageStyle(screenStyles) !== ''
      ? getBackgroundImageStyle(screenStyles).toString()
      : null

    console.log('imageUrl', imageUrl)

  return (
    <div
      className="ImageScreen"
      style={{
        backgroundImage: imageUrl
      }}>
      {!isRunning && imageUrl === null ? (
        <img alt="" src={`${CLOUDFRONT_ASSETS_URL}/assets/layouts/empty-image-1.jpg`} />
      ) : null}

      {imageUrl !== null ? (
        <img
          src={imageUrl}
          alt=""
          onError={event =>
            (event.target.src =
              'https://static.collectui.com/shots/2424315/daily-ui-008-404-page-large')
          }
        />
      ) : null}
    </div>
  )
}

export default ImageScreen
