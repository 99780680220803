import React from 'react'
import Title from 'components/Title/Title'
import Button from 'components/Button/Button'
import { connect } from 'react-redux'

import { closeModalAction } from 'store/modal/modalReducer'
import { doDeleteWallAction } from 'store/walls/wallsReducer'
import './DeleteWallModal.scss'

export const DeleteWallModal = ({
  closeModalAction,
  doDeleteWallAction,
  wallToDelete
}) => {
  return (
    <div className="DeleteWallModal">
      <Title className="medium">deleting your wall</Title>
      <div className="ModalDescription">
        Are you sure want to delete <strong>{wallToDelete.name}</strong>?
      </div>
      <div className="ModalActions">
        <Button onClick={() => closeModalAction()}>keep my wall</Button>
        <Button
          className="danger"
          onClick={() => {
            doDeleteWallAction(wallToDelete.id).then(() => closeModalAction())
          }}>
          delete
        </Button>
      </div>
    </div>
  )
}
export default connect(state => ({ wallToDelete: state.modal.wallToDelete }), {
  closeModalAction,
  doDeleteWallAction
})(DeleteWallModal)
