const SET_SIDEBAR_ACTIVE_ROUTE = 'SET_SIDEBAR_ACTIVE_ROUTE'

export const setSidebarActiveRouteId = id => {
  return {
    type: SET_SIDEBAR_ACTIVE_ROUTE,
    payload: id
  }
}
export default (
  state = {
    activeRouteId: 1
  },
  action
) => {
  switch (action.type) {
    case SET_SIDEBAR_ACTIVE_ROUTE:
      return {
        activeRouteId: action.payload
      }

    default:
      return state
  }
}
