import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import 'components/BiggerText/BiggerText.scss'
import PropTypes from 'prop-types'

/**
 *
 * BiggerText component is designed to resize a text to fill its parent
 */

export const BiggerText = ({ children, fontFamily, fontSizeMax, style = null }) => {
  const parent = useRef(null)
  const child = useRef(null)
  const checkTimeout = useRef(null)
  useEffect(() => {
    const findSize = count => {
      child.current.style.opacity = 0
      for (let size = fontSizeMax; size > 0; size--) {
        child.current.style.fontSize = size + 'px'
        if (
          child.current.offsetHeight < parent.current.offsetHeight &&
          parent.current.scrollWidth <= parent.current.offsetWidth
        )
          break
      }

      if (count < 2) checkTimeout.current = setTimeout(() => findSize(count + 1), 100)
      child.current.style.opacity = 1
    }
    findSize(0)
    return () => clearTimeout(checkTimeout.current)
  }, [fontFamily, fontSizeMax, children])

  return (
    <div className="BiggerText" ref={parent} style={style}>
      <div className="child" ref={child}>
        {children}
      </div>
    </div>
  )
}

BiggerText.propTypes = {
  children: PropTypes.node.isRequired,
  fontFamily: PropTypes.string,
  fontSizeMax: PropTypes.number.isRequired,
  style: PropTypes.object
}

export default connect(state => ({
  fontFamily:
    state.currentWall.styles.length &&
    state.currentWall.styles.find(style => style.style_name === 'font_family_1').value
}))(BiggerText)
