import { VIDEO_1 } from './screen'
import { CLOUDFRONT_ASSETS_URL } from 'setup/constants'

export const AdvertScreens = [
  {
    id: 99999991,
    name: 'Advertisement',
    duration: 5000,
    screen_type: VIDEO_1,
    repeat: true,
    enabled: true,
    styles: [
      {
        id: 999999911,
        style_name: 'background_video',
        style_type: 'upload_video',
        label: 'upload background video',
        value: CLOUDFRONT_ASSETS_URL + 'assets/videos/wallrus-commercial-icons.mp4',
        stylable_type: 'Screen',
        stylable_id: 45
      }
    ]
  },
  {
    id: 99999992,
    name: 'Advertisement',
    duration: 10000,
    screen_type: VIDEO_1,
    repeat: true,
    enabled: true,
    styles: [
      {
        id: 999999922,
        style_name: 'background_video',
        style_type: 'upload_video',
        label: 'upload background video',
        value: CLOUDFRONT_ASSETS_URL + 'assets/videos/wallrus-commercial-mosaic.mp4',
        stylable_type: 'Screen',
        stylable_id: 45
      }
    ]
  }
]
