import request from 'services/requestWrapper.js'

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of the wall to get the keyword from.
 */

function getAll(wallId) {
  return request({
    url: `/keywords?event_id=${wallId}`,
    method: 'GET'
  })
}

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of the wall to get the keyword from.
 * @param {string} source Corresponds to the source it comes from can be twitter or instagram.
 */

function getByType(wallId, source) {
  return request({
    url: `/events/${wallId}/keywords/${source}`,
    method: 'GET'
  })
}

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of the wall to add a keyword to.
 * @param {Object} data - The payload the send to the server.
 * @param {string} data.keyword - The actual keyword, can be any string.
 * @param {string} data.type - The type, values can be 'twitter' | 'instagram'.
 * Example usage : postKeyword( 1, { keyword: '#hello', type: 'twitter' })
 */

function create(wallId, keyword, type) {
  const data = {
    keyword: {
      keyword,
      type
    }
  }
  return request({
    url: `/events/${wallId}/keywords`,
    method: 'POST',
    data
  })
}

/**
 * Returns a promise
 * @param {number} keywordId Corresponds to the id of the wall to get the keyword from.
 * @param {boolean} deleteCards If true, it will delete the content associated to the keywords
 * as well. i.e if I have 3 tweets associated to the keyword, il will be deleted.
 *
 */

function destroy(keywordId, deleteCards = false) {
  return request({
    url: `/keywords/${keywordId}?delete_cards=${deleteCards}`,
    method: 'DELETE'
  })
}

const KeywordService = {
  create,
  getAll,
  getByType,
  destroy
}

export default KeywordService
