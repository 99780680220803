import React, { useEffect } from 'react'
import './MainView.scss'
import { connect } from 'react-redux'
import { doFetchCurrentWall } from 'store/currentWall/actions/index'
import { doFetchAllPlansAction } from 'store/plans/plansReducer'

const MainView = ({
  currentWallId,
  doFetchAllPlansAction,
  doFetchCurrentWall,
  isFetchingPlans,
  isFetchingWall,
  plans,
  routeRender,
  wallId
}) => {
  useEffect(() => {
    if (!currentWallId && !isFetchingWall && wallId) {
      doFetchCurrentWall(wallId)
    }
    if (!plans.length && !isFetchingPlans) {
      doFetchAllPlansAction()
    }
  }, [
    currentWallId,
    doFetchAllPlansAction,
    doFetchCurrentWall,
    isFetchingPlans,
    isFetchingWall,
    plans.length,
    wallId
  ])
  return <div className="MainView">{routeRender({ wallId })}</div>
}

export default connect(
  state => ({
    currentWallId: state.currentWall.id,
    isFetchingPlans: state.plans.isFetching,
    isFetchingWall: state.currentWall.isFetching,
    plans: state.plans.items
  }),
  {
    doFetchAllPlansAction,
    doFetchCurrentWall
  },
  null,
  { areStatesEqual: () => true }
)(MainView)
