import React from 'react'
import Title from 'components/Title/Title'

import Button from 'components/Button/Button'
import 'components/Forms/Forms.scss'

const ResetPassword = ({
  active,
  handleSendPassword,
  sendPassword,
  onChangeResetPassword,
  resetEmail
}) => {
  return (
    <div
      className={`Forms ResetPassword ${active ? 'expand' : 'collapse'}`}
      style={{ display: 'flex' }}>
      <form onSubmit={handleSendPassword}>
        {sendPassword !== true ? (
          <>
            <Title className="medium">reset my password</Title>
            <label className="label" htmlFor="username">
              <input
                type="email"
                name="username"
                placeholder="Email"
                onChange={onChangeResetPassword}
              />
            </label>
            <div className="errorLoginText" id="errorResetPassword" />
            <Button className="ButtonLogin" type="submit">
              send reset instructions
            </Button>
          </>
        ) : (
          <>
            <Title className="medium">Lost password</Title>
            <div className="ConfirmationEmail">An email was send to</div>
            <span>{resetEmail}</span>
          </>
        )}
      </form>
    </div>
  )
}
export default ResetPassword
