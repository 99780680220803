import React, { useState } from 'react'
import _isBoolean from 'lodash/isBoolean'

import './Toggle.scss'

const Toggle = ({ className = '', isChecked, name = '', onToggle, textAfter = null }) => {
  const _checked = _isBoolean(isChecked) ? isChecked : isChecked === 'true' ? true : false
  const [checked, toggle] = useState(_checked)

  const handleInputChange = () => {
    let toggleValue = !checked
    toggle(prev => !prev)
    if (onToggle) onToggle(toggleValue)
  }

  return (
    <>
      <div className={`Toggle ${className}`}>
        <input
          type="checkbox"
          className="switch-toggle"
          name={name}
          checked={checked}
          onChange={handleInputChange}
        />
        <label className="checkbox-label" htmlFor={name} onClick={handleInputChange}>
          <div className="TogglingBall" />
        </label>
      </div>
      {textAfter !== null ? (
        <span
          className={`label ${checked ? 'checked' : 'unchecked'}`}
          onClick={handleInputChange}>
          {textAfter}
        </span>
      ) : null}
    </>
  )
}

export default Toggle
