import request from 'services/requestWrapper.js'

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of the wall to add a layout to.
 * @param {number} layoutId - The id of layout to be added.
 */
function create(wallId, layoutId) {
  return request({
    url: `/events/${wallId}/layouts/${layoutId}/create_screen`,
    method: 'POST'
  })
}

/**
 *
 * @param {number} screenId a DELETE request: Represents the actual id, to be deleted in the database.
 */

function destroy(screenId) {
  return request({
    url: `/screens/${screenId}`,
    method: 'DELETE'
  })
}

/**
 *
 * @param {number} theme_id Insert the ID of the theme.
 * @param {number} event_id Insert the plan ID of the wall.
 */

function applyTheme(theme_id, event_id) {
  const data = { theme_id, event_id }
  return request({
    url: `/theme_applicator/create`,
    method: 'POST',
    data
  })
}

/**
 *
 * @param {number} eventId Insert the event ID
 * @param {array} sortedScreens should be an array of screen id
 */

function sort(eventId, sortedScreens) {
  const data = { screens: sortedScreens }
  return request({
    url: `/events/${eventId}/sort_screens`,
    method: 'POST',
    data
  })
}

function update(screenId, data) {
  return request({
    url: `/screens/${screenId}`,
    method: 'PUT',
    data: { screen: data }
  })
}

const ScreenService = {
  applyTheme,
  create,
  destroy,
  sort,
  update
}

export default ScreenService
