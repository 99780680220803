import React, { Component } from 'react'
import { connect } from 'react-redux'
import './PreviewPage.scss'
import Container from 'components/Container/Container'
import GoTo from 'components/Goto/GoTo'
import Button from 'components/Button/Button'
import FloatingActions from 'components/FloatingActions/FloatingActions'
import { openModalAction } from 'store/modal/modalReducer'
import FloatingButton from 'components/FloatingActions/FloatingButton'
import Colors from 'setup/colors.scss'
import AlertOutline from 'assets/icons/AlertOutline'
import Exclamation from 'assets/icons/Exclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Visualizer from 'components/Visualizer/Visualizer'
import { PREVIEW_PATH } from 'routes/routePaths'

const icons = {
  danger: <Exclamation style={{ width: 22, height: 22, fill: Colors.danger }} />,
  valid: <FontAwesomeIcon icon={'check'} style={{ color: Colors.info }} />,
  warning: (
    <AlertOutline style={{ width: 22, height: 22, fill: Colors.warning, top: -1 }} />
  )
}

class PreviewPage extends Component {
  constructor(props) {
    super(props)
    this.visualizer = React.createRef()
  }

  onClickNext = () => {
    this.visualizer.current.nextScreen()
  }

  onClickPrev = () => {
    this.visualizer.current.prevScreen()
  }

  render() {
    const { isLive, openModalAction, plan_id, status } = this.props

    return (
      <Container className="PreviewPage">
        <FloatingActions>
          <FloatingButton onClick={() => openModalAction('HELP_MODERATION')} label="info">
            <FontAwesomeIcon icon={'question'} style={{ color: Colors.brand }} />
          </FloatingButton>

          {status !== '' ? (
            <FloatingButton
              onClick={() => openModalAction('CONTENT_STATUS_MODERATION')}
              className={status}
              label="status">
              {icons[status]}
            </FloatingButton>
          ) : null}
        </FloatingActions>
        <div className="WallPreview">
          <Visualizer
            customParentSize={752}
            parentPage={PREVIEW_PATH}
            ref={this.visualizer}
          />
          <div className="Arrow Previous" onClick={this.onClickPrev} />
          <div className="Arrow Next" onClick={this.onClickNext} />
        </div>
        <div className="Buttons">
          {!isLive && plan_id !== 1 ? (
            <GoTo route="pay">
              <Button className="info">activate my event</Button>
            </GoTo>
          ) : null}
          <GoTo route="fullscreen">
            <Button className="danger">go in fullscreen mode</Button>
          </GoTo>
        </div>
      </Container>
    )
  }
}

export default connect(
  state => ({
    isLive: state.currentWall.live,
    plan_id: state.currentWall.plan_id,
    status: state.currentWall.status,
    wallName: state.currentWall.name
  }),
  { openModalAction }
)(PreviewPage)
