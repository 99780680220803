import React from 'react'
import 'components/Badge/Badge.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

/**
 * Badge component used to display Movie or Star icon in Themes page and layout position in Playlist page.
 *
 */
const Badge = ({ children, className, position = 'top-left', type }) => (
  <div className={classNames('Badge', className, position, type)}>{children}</div>
)

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Can be set to **top-left**, **top-right**, **bottom-left** or **bottom-right**  */
  position: PropTypes.string,
  /** Can be set to **image** or **text**  */
  type: PropTypes.string.isRequired
}

export default Badge
