import request from 'services/requestWrapper.js'

function pay(data) {
  return request({
    url: `/payment`,
    method: 'POST',
    data
  })
}

const PaymentService = {
  pay
}

export default PaymentService
