import SessionService from 'services/api/SessionService'
import storage from 'services/app/storage'
import UserService from 'services/api/UserService'

const initialState = {
  username: '',
  id: null,
  email: '',
  token: '',
  instagram: { status: 'NOT CONNECTED' },
  twitter: { status: 'NOT CONNECTED' },
  newsletter: false
}

const UPDATE_USER = 'UPDATE_USER'

export const doLoginOrFetchUserAction = (
  name = null,
  password = null
) => async dispatch => {
  try {
    let user =
      name !== null && password !== null
        ? await SessionService.login(name, password)
        : await UserService.get()

    let { instagramkey, token, twitterkey } = user
    instagramkey = instagramkey ? instagramkey : initialState.instagram
    twitterkey = twitterkey ? twitterkey : initialState.twitter
    user.token =
      name !== null && password !== null ? token : storage.getUserObject().token

    storage.setUserObject(user)
    storage.setInstagramObject(instagramkey)
    storage.setTwitterObject(twitterkey)

    dispatch(updateUserAction(storage.getUserObject()))
  } catch (error) {
    const message =
      error && error.data
        ? error.data.error
        : 'Our server is currently down, try again later.'
    if (document.getElementById('errorLoginText')) {
      document.getElementById('errorLoginText').innerHTML = message
    }
  }
}

export const doSocialLoginAction = (email, id, name, socialType) => async dispatch => {
  try {
    const user =
      socialType === 'google'
        ? await SessionService.googleLogin(email, id, name)
        : await SessionService.facebookLogin(email, id, name)

    let { instagramkey, twitterkey } = user
    instagramkey = instagramkey ? instagramkey : initialState.instagram
    twitterkey = twitterkey ? twitterkey : initialState.twitter

    storage.setUserObject(user)
    storage.setInstagramObject(instagramkey)
    storage.setTwitterObject(twitterkey)

    dispatch(updateUserAction(storage.getUserObject()))
  } catch (error) {
    const message = error ? error.data.error : null
    document.getElementById('errorLoginText').innerHTML = message
  }
}

export const doSignupAction = (
  name,
  email,
  password,
  passwordConfirmation,
  recaptchaToken
) => async dispatch => {
  try {
    await SessionService.signUp(
      name,
      email,
      password,
      passwordConfirmation,
      recaptchaToken
    )
    let user = await SessionService.login(name, password)
    let { instagramkey, twitterkey } = user
    instagramkey = instagramkey ? instagramkey : initialState.instagram
    twitterkey = twitterkey ? twitterkey : initialState.twitter

    storage.setUserObject(user)
    storage.setInstagramObject(instagramkey)
    storage.setTwitterObject(twitterkey)

    dispatch(updateUserAction(storage.getUserObject()))
  } catch (error) {
    const passwordConfirmationError =
      error.data.password_confirmation && error.data.password_confirmation !== undefined
        ? error.data.password_confirmation[0]
        : ''
    const usernameError =
      error.data.username && error.data.username !== undefined
        ? error.data.username[0]
        : ''
    const emailError =
      error.data.email && error.data.email !== undefined ? error.data.email[0] : ''
    const passwordError =
      error.data.password && error.data.password !== undefined
        ? error.data.password[0]
        : ''

    document.getElementById('passwordConfErrorText').innerHTML = passwordConfirmationError
    document.getElementById('usernameErrorText').innerHTML = usernameError
    document.getElementById('emailErrorText').innerHTML = emailError
    document.getElementById('passwordErrorText').innerHTML = passwordError
  }
}

export const updateUserAction = user => ({
  type: UPDATE_USER,
  payload: user
})

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...action.payload
      }

    default:
      return state
  }
}
