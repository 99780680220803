import React from 'react'
import { connect } from 'react-redux'
import './ZoomModerationModal.scss'

export const ZoomModerationModal = ({ cards, cardId }) => {
  const card = cards.find(_card => _card.id === cardId)

  return card !== null ? (
    <div className="ZoomModerationModal">
      <div className="Slider">
        <div>
          {card.media_video_url ? (
            <video
              className="MediaPreview"
              autoPlay={true}
              controls
              controlsList="nodownload">
              <source src={card.media_video_url} type="video/mp4" />
            </video>
          ) : (
            <img className="MediaPreview" src={card.media_file_url} alt="media-preview" />
          )}
        </div>
      </div>
    </div>
  ) : null
}
export default connect(state => ({
  cards: state.currentWall.cards.items,
  cardId: state.modal.cardId
}))(ZoomModerationModal)
