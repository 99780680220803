import React, { useEffect, useState, useRef } from 'react'
import ReactS3Uploader from 'react-s3-uploader'
import './Uploader.scss'
import Button from 'components/Button/Button'
import uploadSVG from 'assets/images/upload.svg'
import { API_URL, AWS_BUCKET_URL, CLOUDFRONT_ASSETS_URL } from 'setup/constants'
import S3Service from 'services/api/S3'

const MAX_IMAGE_SIZE = 5 * 1000 * 1000
const MAX_VIDEO_SIZE = 100 * 1000 * 1000

const Uploader = ({
  layoutId = null,
  currentScreenId,
  currentWallId,
  file = null,
  createLayout,
  updateStyle = null,
  updateFile,
  selfReset = false,
  multiple = false,
  type = 'image'
}) => {
  const reg = /(?:\.([^.]+))?$/
  const uploaderRef = useRef(null)
  const [progress, setProgress] = useState(null)
  const [error, setError] = useState(null)
  const accept = type === 'video' ? 'video/mp4' : 'image/*'
  let extension = ''
  let uploadTotal = 0;

  useEffect(() => {
    if (file !== null) {
      setProgress(null)
    }
    return () => setProgress(null)
  }, [file])

  const removeFile = () => {
    if (file) {
      S3Service.getDeleteUrl(file.split(AWS_BUCKET_URL)[1]).then(
        response => {
          response.signedUrl &&
          fetch(response.signedUrl, { method: 'DELETE', mode: 'cors' }).then(response => {
            if (response.status === 204) {
              updateFile('')
            }
          }).catch((error) => {
            console.warn(error)
          });
        }
      )
    }
  }

  const onUploadStart = async (file, callback) => {
    uploadTotal = uploadTotal + 1;
    setError(null)
    console.log("UPLOAD START")
    let _error = ''
    if (file.type.split('/')[0] === 'image' && file.size > MAX_IMAGE_SIZE) {
      _error = `* Maximum ${MAX_IMAGE_SIZE / (1000 * 1000)}MB per image`
      setError(_error)
    } else if (file.type.split('/')[0] === 'video' && file.size > MAX_VIDEO_SIZE) {
      _error = `* Maximum ${MAX_VIDEO_SIZE / (1000 * 1000)}MB per video`
      setError(_error)
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          callback(file);
          resolve();
        }, uploadTotal * 500);
      });
    }
  }

  const getSignedUrl = async (file, callback) => {
    console.log("GET SIGNED URL")
    await createLayout(layoutId).then((screen) => {
      const params = {
        folder: `assets/wall-${currentWallId}/screen-${screen.id}`,
        objectName: scrubFilename(file.name),
        contentType: file.type,
      };
      S3Service.getSignedUrl(params).then(response => {
        console.log(response)
        currentScreenId = screen.id
        callback(response)
      }).catch(error => {
        console.error(error);
      })
    }).catch(error => {
      console.error(error)
    });

  }

  const scrubFilename = filename => {
    if (filename) {
      extension = reg.exec(filename)[0]
      if (extension !== undefined) {
        return Date.now() + extension
      } else {
        return filename.replace(/[^\w\d_\-.]+/gi, '')
      }
    }
  }

  const handleError = error => {
    console.log(error)
    uploadTotal = 0;
    setError('Error uploading file')
    // removeFile()
    setProgress(null)
  }

  // const updateStyle = async (currentWallId, url) => {
  //   // id = style id
  //   currentWall = await WallService.get(currentWallId)
  //   const id = currentWallId.screens[currentScreenId].styles[0].id
  //   updateScreenImageStyle(id, url, currentScreenId)
  // }

  return (
    <div className="Uploader">
      {file ? (
        <>
          {type === 'video' ? <video muted src={file} /> : <img src={file} alt="" />}
          <Button className="danger small" onClick={removeFile}>
            remove
          </Button>
        </>
      ) : (
        <div className="Uploader-drop">
          {progress ? (
            <div className="Uploader-drop-bg center">{progress}%</div>
          ) : (
            <>
              <div className="Uploader-drop-bg">
                <img src={uploadSVG} alt="upload" />
                choose <strong>&nbsp;{type} to upload</strong>
              </div>
              <ReactS3Uploader
                getSignedUrl={getSignedUrl}
                accept={accept}
                multiple={multiple}
                preprocess={onUploadStart}
                onChange={() => uploaderRef.current.uploadFile()}
                onProgress={progress => setProgress(progress)}
                onFinish={(response, b, c) => {
                  uploadTotal = 0;

                  updateStyle(currentScreenId, response.signedUrl)
                  if (selfReset) {
                    setProgress(null)
                    setError(null)
                  }
                }}
                onError={handleError}
                contentDisposition="auto"
                scrubFilename={scrubFilename}
                server={API_URL}
                autoUpload={!multiple}
                uploadRequestHeaders={{}}
                ref={uploaderRef}
              />
            </>
          )}
        </div>
      )}
      {error !== null ? <legend className="danger-text-color">{error}</legend> : null}
    </div>
  )
}

export default Uploader
