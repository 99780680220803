import request from 'services/requestWrapper.js'

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of a Moderation "feed"
 */

function getAll(wallId) {
  return request({
    url: `/events/${wallId}/banwords`,
    method: 'GET'
  })
}

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of a Moderation "feed"
 * @param {String} word The word to add as a banword to the feedId
 */

function create(wallId, word) {
  const data = {
    value: word,
    active: true
  }

  return request({
    url: `/events/${wallId}/banwords`,
    method: 'POST',
    data
  })
}

/**
 * Returns a promise
 * @param {number} banwordId with a DELETE request: Delete a banword from the moderation feed
 */

function destroy(banwordId) {
  return request({
    url: `/banwords/${banwordId}`,
    method: 'DELETE'
  })
}

const BanwordService = {
  create,
  getAll,
  destroy
}

export default BanwordService
