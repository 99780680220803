import React, { useState, useEffect } from 'react'
import Title from 'components/Title/Title'
import BillingService from 'services/api/BillingService'
import Moment from 'react-moment'
import './BillingHistoryModal.scss'
import { API_URL } from 'setup/constants'

const BillingHistoryModal = () => {
  const [invoices, setInvoices] = useState([])

  useEffect(() => {
    BillingService.get().then(response => setInvoices(response))
  }, [])

  return (
    <div className="BillingHistoryModal">
      <Title className="medium">billing history</Title>
      {invoices.length > 0 ? (
        <>
          <p>Here’s a complete list of your purchase history.</p>
          <div className="ScrollBox" style={{ maxHeight: 310, overflowY: 'scroll' }}>
            <div className="BillingList">
              {invoices.map(invoice => (
                <div className="Bill" key={invoice.unique_token}>
                  <span>{invoice.timed_license.event_name}</span>
                  <span>{`${invoice.timed_license.duration}h license`}</span>
                  <Moment format="MMM d, YYYY">{invoice.timed_license.created_at}</Moment>
                  <a
                    className="Button small"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${API_URL}/payments/${invoice.unique_token}/invoice`}>
                    view invoice
                  </a>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <p>We currently do not have any bills for your account.</p>
      )}
    </div>
  )
}

export default BillingHistoryModal
