import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import Button from 'components/Button/Button'
import ThumbnailPreviewer from 'components/ThumbnailPreviewer/ThumbnailPreviewer'
import './ThemePageItem.scss'
import { openUpgradePlanModalAction } from 'store/modal/modalReducer'
import { doUpdateWall } from 'store/currentWall/actions/index'
import { doApplyThemeAction } from 'store/currentWall/actions/index'
import Badge from 'components/Badge/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from 'react-slick'
import { LeftNavButton, RightNavButton } from 'components/ThumbnailPreviewer/NavButtons'
import _uniqBy from 'lodash/uniqBy'

const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true
}

const ThemeSliderImage = ({ name, src }) => {
  const [loaded, setLoaded] = useState(false)
  return (
    <div className={`layoutIndex ${loaded ? 'loaded' : ''} `}>
      <img alt={name} className="themeImage" onLoad={() => setLoaded(true)} src={src} />
      <span className="layoutName">{name}</span>
    </div>
  )
}

const ThemeSlider = ({ layouts, screens, theme }) => {
  const getLayoutImage = screenType => {
    const style = theme.theme_styles.find(theme => theme.name === screenType)
    return style ? style.preview_img : ''
  }

  return (
    <>
      {theme.animated ? (
        <video
          autoPlay={true}
          className="videoBackground"
          loop={true}
          src={
            theme.theme_styles.find(style => style.name === 'General').background_video
          }
        />
      ) : null}
      <Slider {...settings} prevArrow={<LeftNavButton />} nextArrow={<RightNavButton />}>
        {screens.length > 0
          ? _uniqBy(screens, 'screen_type').map((layout, index) => (
              <ThemeSliderImage
                key={index}
                name={layout.name}
                src={getLayoutImage(layout.screen_type)}
              />
            ))
          : theme.theme_styles
              .filter(theme => theme.name !== 'General')
              .map(theme => (
                <ThemeSliderImage
                  key={theme.id}
                  name={layouts.find(layout => layout.screen_type === theme.name).name}
                  src={theme.preview_img}
                />
              ))}
      </Slider>
    </>
  )
}

const MovieIcon = () => (
  <div className="round-background-danger">
    <FontAwesomeIcon
      icon="film"
      className="logoFilm"
      style={{ width: 16, height: 16, color: '#fff' }}
    />
  </div>
)

class ThemePageItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  planIsGreaterThanCurrentPlan(id) {
    const { currentWallPlanId, themePlanId } = this.props
    return themePlanId > currentWallPlanId
  }

  handleUse = () => {
    if (this.planIsGreaterThanCurrentPlan(this.props.theme.plan_id)) {
      this.showUpgradePlanModal(this.props.theme.plan_id)
      return
    }
    this.props.doApplyThemeAction(this.props.theme.id, this.props.wallId)
  }

  showUpgradePlanModal = planId => {
    this.props.openUpgradePlanModalAction({
      upgradeToPlan: planId,
      applyTheme: this.props.theme.id
    })
  }

  render() {
    const { theme, wallThemeId, screens, layouts } = this.props
    return (
      <div
        className={`listTheme-item  ${this.state.loaded ? 'loaded' : ''} ${
          wallThemeId === theme.id ? ' active' : ''.trim()
        }`}>
        <div>
          <img
            className={`bg-img `}
            alt=""
            onLoad={() => this.setState({ loaded: true })}
            src={theme.theme_styles.find(theme => theme.name === 'General').preview_img}
          />
          <ThumbnailPreviewer
            type="theme"
            id={theme.id}
            content={
              <ThemeSlider
                layouts={layouts}
                screens={screens}
                theme={theme}
              />
            }
          />
          <Button className="small" onClick={this.handleUse}>
            Use
          </Button>
           {theme.animated ? (
            <Badge position="top-right" type="image">
              <MovieIcon />
            </Badge>
          ) : null}
          <p>{theme.name}</p>
        </div>
      </div>
    )
  }
}

export default connect(
  (state, props) => ({
    currentWallPlanId: state.currentWall.plan.id,
    wallId: state.currentWall.id,
    wallThemeId: state.currentWall.theme_id,
    themes: state.themes.all,
    themePlanId: state.themes.all.find(theme => theme.plan_id === props.theme.plan_id)
      .plan_id,
    screens: state.currentWall.screens
      ? state.currentWall.screens.filter(
          layout => layout.screen_type !== 'Image1' && layout.screen_type !== 'Video1' && layout.screen_type !== 'MultipleImages1'
        )
      : [],
    layouts: state.layouts.filter(
      layout => layout.screen_type !== 'Image1' && layout.screen_type !== 'Video1' && layout.screen_type !== 'MultipleImages1'
    ),
    layoutSelected: state.modal.layoutSelected
  }),
  {
    doApplyThemeAction,
    openUpgradePlanModalAction,
    doUpdateWall
  }
)(ThemePageItem)
