import {
  ADD_BANWORDS,
  REQUEST_BANWORDS,
  REMOVE_BANWORD,
  ADD_BANWORD
} from '../currentWallReducerActionTypes'

import BanwordService from 'services/api/BanwordService'

/**
 * @param {number} wallId Corresponds to the feed id for now, it should be the wallId eventually
 */
export const fetchAll = wallId => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let banwords
    try {
      dispatch(requestBanwords())
      banwords = await BanwordService.getAll(wallId)
      dispatch(addBanwords(banwords))
    } catch (error) {
      console.log(error)
    } finally {
      resolve(banwords)
    }
  })
}

export const create = (wallId, word) => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let banword
    try {
      banword = await BanwordService.create(wallId, word)
      dispatch(addBanword(banword))
    } catch (error) {
      console.log(error)
    } finally {
      resolve(banword)
    }
  })
}

export const remove = banwordId => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let banwords
    try {
      BanwordService.destroy(banwordId)
      dispatch(removeBanword(banwordId))
    } catch (error) {
      console.log(error)
    } finally {
      resolve(banwords)
    }
  })
}

const addBanwords = banwords => ({
  type: ADD_BANWORDS,
  payload: banwords
})

const addBanword = banword => ({
  type: ADD_BANWORD,
  payload: banword
})

const removeBanword = id => ({
  type: REMOVE_BANWORD,
  payload: id
})

const requestBanwords = () => ({
  type: REQUEST_BANWORDS
})
