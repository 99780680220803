import request from 'services/requestWrapper.js'

function get() {
  return request({
    url: `/payments`,
    method: 'GET'
  })
}

const BillingService = { get }
export default BillingService
