import request from 'services/requestWrapper.js'
import storage from 'services/app/storage.js'

/**
 *
 * @param {string} username Represents the username for the login.
 * @param {string} password Represents the password for the login.
 */
function login(username, password) {
  return request({
    url: `/session.json`,
    method: 'POST',
    data: {
      utf8: '✓',
      username: username.trim(),
      password,
      commit: 'Login'
    }
  })
}

function signUp(username, email, password, passwordConfirmation, recaptchaToken) {
  return request({
    url: `/auth/sign_up`,
    method: 'POST',
    data: {
      user:{
        utf8: '✓',
        username: username.trim(),
        email,
        password,
        password_confirmation: passwordConfirmation,
      },
      'g-recaptcha-response': recaptchaToken,
      commit: 'Signup'
    }
  })
}

function resetPassword(email) {
  return request({
    url: `/password_resets`,
    method: 'POST',
    data: {
      email
    }
  })
}

const googleLogin = (email, id, name) => {
  return request({
    url: `/users/social_auth.json`,
    method: 'POST',
    data: { email, id, name, created_from: 'google' },
    headers: [{
      key: "Cross-Origin-Opener-Policy",
      value: "same-origin allow-popups",
    }]
  })
}

const facebookLogin = (email, id, name) => {
  return request({
    url: `/users/social_auth.json`,
    method: 'POST',
    data: { email, id, name, created_from: 'facebook' }
  })
}

const isLoggedIn = () => {
  return (
    localStorage.getItem('wallrus') &&
    !!JSON.parse(localStorage.getItem('wallrus')).user.token
  )
}

const isFromCanada = () => storage.getCountryCode() === 'Canada'

const logOut = () => {
  localStorage.removeItem('wallrus')
  window.location.reload()
}

const SessionService = {
  signUp,
  login,
  resetPassword,
  isLoggedIn,
  isFromCanada,
  logOut,
  googleLogin,
  facebookLogin
}

export default SessionService
