import React, { Component } from 'react'
import { connect } from 'react-redux'
import _throttle from 'lodash/throttle'
import Pusher from 'react-pusher'

import './Visualizer.scss'
import FadeScreen from './Screens/FadeScreen/FadeScreen'
import TextPicScreen from './Screens/TextPicScreen/TextPicScreen'
import TextPostScreen from './Screens/TextPostScreen/TextPostScreen'
import {
  CTA_1,
  IMAGE_1,
  MULTIPLES_IMAGES_1,
  MASONRY_1,
  MOSAIC_1,
  PIC_POST_ONLY_1,
  PIC_POST_ONLY_2,
  TEXT_POST_ONLY_1,
  TEXT_PIC_1,
  TEXT_PIC_2,
  VIDEO_1,
  getBackgroundVideoStyle,
  getFontFamily1Style,
  getTextColorStyle,
  isValidScreen
} from 'services/app/screen'
import { AdvertScreens } from 'services/app/advertisement'
import { Transition, animated } from 'react-spring/renderprops'
import ImageScreen from './Screens/ImageScreen/ImageScreen'
import VideoScreen from './Screens/VideoScreen/VideoScreen'
import CTAScreen from './Screens/CTAScreen/CTAScreen'
import { CLOUDFRONT_ASSETS_URL } from 'setup/constants'
import { FULLSCREEN_PATH, PREVIEW_PATH } from 'routes/routePaths'
import {
  doFetchAllCardsAction,
  doFetchCurrentWallForVisualizer,
  updateCardAction
} from 'store/currentWall/actions/index'
import {
  resetImageCardsAction,
  resetTextCardsAction,
  deleteCardAction
} from 'store/currentWall/actions/currentWallCardsActions'
import environment from 'services/app/environment'

const REFRESH_RATE_DATA = 15000
const REFRESH_RATE_NO_SCREEN = 5000
const CHECK_WATERMARK_TIME = 5000
const ADS_TIME = 150000 // 150 000 = 2min30s

const checkWatermark = ({ live, plan_id }, parentPage) => {
  if (parentPage !== PREVIEW_PATH && plan_id > 1) {
    if (live) {
      if (document.querySelector('#WatermarkImg')) {
        const elem = document.querySelector('#WatermarkImg')
        elem.parentNode.removeChild(elem)
      }
    } else {
      let visualizer = document.getElementById('Visualizer')
      if (visualizer !== null && !document.querySelector('#WatermarkImg')) {
        let watermarkImg = document.createElement('img')
        watermarkImg.id = 'WatermarkImg'
        watermarkImg.src = CLOUDFRONT_ASSETS_URL + 'assets/images/watermark.png'
        watermarkImg.setAttribute(
          'style',
          'z-index: 999 !important; user-select: none; pointer-events: none; display: block !important; position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto;'
        )
        visualizer.appendChild(watermarkImg)
      }
    }
  }
}

class Visualizer extends Component {
  constructor(props) {
    super(props)
    this.screenId = null
    this.timeToAds = 0
    this.adsId = 0
    this.wallId = parseInt(window.location.pathname.split('wall/')[1].split('/')[0])

    this.customParentSize = this.props.customParentSize || null
    this.nbValidScreens = null
    this.parentPage = this.props.parentPage || FULLSCREEN_PATH

    this.intervals = {
      watermark: null,
      data: null
    }
    this.timeouts = {
      screen: null
    }
    this.hidden = null
    this.visibilityChange = null

    this.state = {
      currentScreen: null,
      scale: 1
    }

    this.setScaleThrottled = _throttle(this.setScale, 2000)

    this.screens = [
      {
        name: 'NO_ACTIVE_SCREEN',
        component: style => (
          <animated.div style={{ ...style }}>
            {this.nbValidScreens === 0 ? (
              <img
                style={{ zIndex: 1000, position: 'relative' }}
                alt="No active screen"
                src={CLOUDFRONT_ASSETS_URL + 'assets/placeholder/no-valid-screen.jpg'}
              />
            ) : null}
          </animated.div>
        )
      },
      {
        name: TEXT_POST_ONLY_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <TextPostScreen
              cards={this.getApprovedTextCards()}
              getNextCard={this.getNextCard}
              screen={this.getScreens()[this.screenId]}
            />
          </animated.div>
        )
      },
      {
        name: MOSAIC_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <FadeScreen
              cards={this.getApprovedImageCards()}
              getNextCard={this.getNextCard}
              screen={this.getScreens()[this.screenId]}
              screenType={MOSAIC_1}
            />
          </animated.div>
        )
      },
      {
        name: MASONRY_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <FadeScreen
              cards={this.getApprovedImageCards()}
              getNextCard={this.getNextCard}
              screen={this.getScreens()[this.screenId]}
              screenType={MASONRY_1}
            />
          </animated.div>
        )
      },
      {
        name: PIC_POST_ONLY_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <FadeScreen
              cards={this.getApprovedImageCards()}
              getNextCard={this.getNextCard}
              screen={this.getScreens()[this.screenId]}
              screenType={PIC_POST_ONLY_1}
            />
          </animated.div>
        )
      },
      {
        name: PIC_POST_ONLY_2,
        component: style => (
          <animated.div style={{ ...style }}>
            <FadeScreen
              cards={this.getApprovedImageCards()}
              getNextCard={this.getNextCard}
              screen={this.getScreens()[this.screenId]}
              screenType={PIC_POST_ONLY_2}
            />
          </animated.div>
        )
      },
      {
        name: TEXT_PIC_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <FadeScreen
              cards={this.getApprovedCards()}
              getNextCard={this.getNextCard}
              screen={this.getScreens()[this.screenId]}
              screenType={TEXT_PIC_1}
            />
          </animated.div>
        )
      },
      {
        name: TEXT_PIC_2,
        component: style => (
          <animated.div style={{ ...style }}>
            <TextPicScreen
              cards={this.getApprovedCards()}
              getNextCard={this.getNextCard}
              screen={this.getScreens()[this.screenId]}
            />
          </animated.div>
        )
      },
      {
        name: IMAGE_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <ImageScreen screen={this.state.currentScreen} />
          </animated.div>
        )
      },
      {
        name: MULTIPLES_IMAGES_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <ImageScreen screen={this.state.currentScreen} />
          </animated.div>
        )
      },
      {
        name: VIDEO_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <VideoScreen screen={this.state.currentScreen} />
          </animated.div>
        )
      },
      {
        name: CTA_1,
        component: style => (
          <animated.div style={{ ...style }}>
            <CTAScreen screen={this.getScreens()[this.screenId]} />
          </animated.div>
        )
      }
    ]
  }

  async componentDidMount() {
    await this.getdata()

    this.intervals.data = setInterval(() => {
      this.getdata()
    }, REFRESH_RATE_DATA)

    this.rotateScreen()

    checkWatermark(this.getCurrentWall(), this.parentPage)
    this.intervals.watermark = setInterval(() => {
      checkWatermark(this.getCurrentWall(), this.parentPage)
    }, CHECK_WATERMARK_TIME)

    window.addEventListener('resize', this.setScaleThrottled)
    this.setScale()

    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      this.hidden = 'hidden'
      this.visibilityChange = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      this.hidden = 'msHidden'
      this.visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden'
      this.visibilityChange = 'webkitvisibilitychange'
    }
  }

  componentWillUnmount() {
    this.props.resetTextCardsAction()
    this.props.resetImageCardsAction()
    Object.keys(this.intervals).map(interval => clearInterval(this.intervals[interval]))
    Object.keys(this.timeouts).map(timeout => clearTimeout(this.timeouts[timeout]))
  }

  setScale = () => {
    let parentWidth =
      this.customParentSize !== null ? this.customParentSize : window.innerWidth
    var scale = parentWidth / 1920
    this.setState({ scale })
  }

  nextScreen = () => {
    clearTimeout(this.timeouts.screen)
    this.rotateScreen()
  }

  prevScreen = () => {
    clearTimeout(this.timeouts.screen)
    this.rotateScreen(true)
  }

  getdata = async () => {
    if (!document[this.hidden]) {
      await this.props.doFetchAllCardsAction(this.wallId, true)
      await this.props.doFetchCurrentWallForVisualizer(this.wallId)

      const screens = this.getScreens()
      this.nbValidScreens = screens.filter(s =>
        isValidScreen(s, this.getApprovedCards())
      ).length

      if (
        this.state.currentScreen !== null &&
        !isValidScreen(this.state.currentScreen, this.getApprovedCards())
      ) {
        this.rotateScreen()
      }
    }
  }

  getCurrentWall = () => this.props.currentWall

  getApprovedCards = () => this.props.approvedCards

  getApprovedImageCards = () =>
    this.getApprovedCards().filter(card => card.media_file_url !== null)

  getApprovedTextCards = () =>
    this.getApprovedCards().filter(card => card.media_file_url === null)

  getScreens = () => this.getCurrentWall().screens

  nextScreenId = () => {
    if (this.nbValidScreens > 0) {
      if (this.screenId === null) {
        this.screenId = 0
      } else {
        this.screenId = (this.screenId + 1) % this.getScreens().length
      }
      if (
        !this.getScreens()[this.screenId].enabled ||
        !isValidScreen(this.getScreens()[this.screenId], this.getApprovedCards())
      ) {
        this.nextScreenId()
      }
    } else {
      this.screenId = null
    }
  }

  prevScreenId = () => {
    if (this.nbValidScreens > 0) {
      if (this.screenId === null) {
        this.screenId = 0
      } else {
        this.screenId =
          this.screenId - 1 < 0 ? this.getScreens().length - 1 : this.screenId - 1
      }
      if (
        !this.getScreens()[this.screenId].enabled ||
        !isValidScreen(this.getScreens()[this.screenId], this.getApprovedCards())
      ) {
        this.prevScreenId()
      }
    } else {
      this.screenId = null
    }
  }

  rotateScreen = (reverse = false) => {
    if (!document[this.hidden]) {
      const { plan_id } = this.getCurrentWall()

      let time = REFRESH_RATE_NO_SCREEN
      let currentScreen = null
      if (plan_id === 1 && this.timeToAds >= ADS_TIME) {
        currentScreen = AdvertScreens[this.adsId]
        time = AdvertScreens[this.adsId].duration
        this.adsId = (this.adsId + 1) % AdvertScreens.length
        this.timeToAds = 0
      } else if (this.nbValidScreens > 0) {
        if (reverse) this.prevScreenId()
        else this.nextScreenId()
        time = this.getScreens()[this.screenId]?.duration
        currentScreen = this.getScreens()[this.screenId]
        if (plan_id === 1) {
          this.timeToAds += time
        }
      }
      this.setState({ currentScreen }, () => {
        clearTimeout(this.timeouts.screen)
        this.timeouts.screen = setTimeout(() => {
          this.rotateScreen()
        }, time)
      })
    } else {
      clearTimeout(this.timeouts.screen)
      this.timeouts.screen = setTimeout(() => {
        this.rotateScreen()
      }, this.getScreens()[this.screenId]?.duration)
    }
  }

  getActiveScreenIndex = () =>
    this.state.currentScreen !== null
      ? this.screens.findIndex(
          _screen => _screen.name === this.state.currentScreen.screen_type
        )
      : 0

  getNextCard = (count = 1, type) => {
    const _shouldLogToConsole = environment.isDev() && false

    if (_shouldLogToConsole) console.clear()

    const _approvedCards =
      type === 'TEXT'
        ? this.getApprovedTextCards()
        : type === 'IMAGE'
        ? this.getApprovedImageCards()
        : this.getApprovedCards()

    const _availableCards = _approvedCards.filter(card => !card.shown)

    const _logToConsole = () => {
      console.table(
        _approvedCards.map(_approvedCard => ({
          id: _approvedCard.id,
          type: _approvedCard.media_file_url === null ? 'TEXT' : 'IMAGE',
          shown: _approvedCard.shown
        }))
      )
    }

    const _reset = () => {
      if (type !== 'IMAGE') this.props.resetTextCardsAction()
      if (type !== 'TEXT') this.props.resetImageCardsAction()
    }

    const _getNextCard = () => {
      let _nextCard = {}
      if (_availableCards.length > 0) {
        _nextCard = _availableCards[0]
      } else {
        _nextCard = _approvedCards[0]
        _reset()
      }
      this.props.updateCardAction({ ..._nextCard, shown: true })
      if (_shouldLogToConsole) _logToConsole()
      return _nextCard
    }

    const _getNextCards = () => {
      let _cards = []
      if (_availableCards.length > count) {
        _cards = _availableCards
      } else {
        _cards = _approvedCards
        _reset()
      }
      const _nextCards = []
      for (let i = 0; i < count; i++) {
        _nextCards.push(_cards[i])
        this.props.updateCardAction({ ..._cards[i], shown: true })
      }
      if (_shouldLogToConsole) _logToConsole()
      return _nextCards
    }

    return count === 1 ? _getNextCard() : _getNextCards()
  }

  render() {
    const { styles, id } = this.getCurrentWall()
    return (
      <div
        id="Visualizer"
        className="Visualizer"
        style={{
          fontFamily: `${getFontFamily1Style(styles, 'family')}`,
          color: getTextColorStyle(styles),
          transform: `scale(${this.state.scale})`
        }}>
        {getBackgroundVideoStyle(styles) ? (
          <video
            src={getBackgroundVideoStyle(styles)}
            className="screen-video-bg"
            autoPlay
            muted
            loop
          />
        ) : null}
        <Transition
          native
          items={this.getActiveScreenIndex()}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}>
          {index => this.screens[index].component}
        </Transition>
        {id !== undefined ? (
          <Pusher
            channel={`presence-wallrus-${id}`}
            event="Disapprove"
            onUpdate={event => this.props.deleteCardAction(JSON.parse(event.card).id)}
          />
        ) : null}
      </div>
    )
  }
}

export default connect(
  state => ({
    approvedCards: state.currentWall.cards.items.filter(card => card.approved),
    currentWall: state.currentWall
  }),
  {
    deleteCardAction,
    doFetchAllCardsAction,
    doFetchCurrentWallForVisualizer,
    resetImageCardsAction,
    resetTextCardsAction,
    updateCardAction
  },
  null,
  { forwardRef: true }
)(Visualizer)
