import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  showSelectPlansAction,
  createWallAction
} from 'store/selectPlanList/selectPlanListReducer'
import Button from 'components/Button/Button'
import Input from 'components/Input/Input'
import Title from 'components/Title/Title'
import 'components/CreateWallForm/CreateWallForm.scss'
import {
  getFieldIsEmptyError,
  isBlurEvent,
  fieldsAreEmpty,
  fieldsHaveError
} from 'setup/formUtils'

const CreateWallForm = ({ createWallAction, showSelectPlansAction, walls }) => {
  const [wallName, setWallName] = useState({ value: '', errorMessage: '', dirty: false })

  const handleChange = ({ target, type }) => {
    setWallName(wallname => ({
      ...wallname,
      value: target.value,
      errorMessage: handleErrors(target),
      ...(isBlurEvent(type) && { dirty: true })
    }))
  }

  const handleErrors = target => {
    return (
      getFieldIsEmptyError(target.value) ||
      (wallAlreadyExists(target.value) && 'this wall name already exists.') ||
      ''
    )
  }

  const wallAlreadyExists = name => {
    return walls.map(wall => wall.name.toLowerCase()).includes(name.toLowerCase())
  }

  const isDisabled = () => {
    return fieldsAreEmpty({ wallName }) || fieldsHaveError({ wallName })
  }

  const handleSubmit = e => {
    e.preventDefault()
    createWallAction(wallName.value)
    document.activeElement.blur()
  }

  return (
    <div className="WallForm">
      <form autoComplete="off" onSubmit={handleSubmit} className="CreateWallForm">
        <Title>create a new wall</Title>

        <div className="center">
          <Input
            required
            onBlur={handleChange}
            value={wallName.value}
            dirty={wallName.dirty}
            onChange={handleChange}
            errorMessage={wallName.errorMessage}
            placeholder="name your wall"
          />
        </div>

        <Button onClick={showSelectPlansAction} disabled={isDisabled()} type="submit">
          Pick a plan
        </Button>
      </form>
    </div>
  )
}

export default connect(
  state => ({ walls: state.walls }),
  {
    showSelectPlansAction,
    createWallAction
  }
)(CreateWallForm)
