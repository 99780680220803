import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  TWITTER_KEYWORD,
  TWITTER_USERNAME,
} from 'setup/constants'
import { doCreateKeywordAction } from 'store/currentWall/actions/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChipsContainer from 'components/Chips/ChipsContainer'

const SetupPageTwitter = ({
  doCreateKeywordAction,
  keywords,
  wallId
}) => {
  const tweetKeywords = keywords.filter(keyword => keyword.type === TWITTER_KEYWORD)
  const tweetUsernames = keywords.filter(keyword => keyword.type === TWITTER_USERNAME)

  return (
    <>
      <div className="SetupPanel twitter">
        <div className="SetupPanelWrapper linked">
          <ChipsContainer
            title="Twitter Keyword"
            placeholder="add your keyword"
            type="twitter"
            wallId={wallId}
            onAdd={doCreateKeywordAction}
            items={tweetKeywords}
            showModal={true}
          />
          <div className="logo">
            <FontAwesomeIcon
              icon={['fab', 'twitter']}
              style={{ width: 60, height: 60, color: '#1DA1F2' }}
            />
          </div>
          <ChipsContainer
            placeholder="add your username"
            title="Twitter username"
            prefix="@"
            type="twitter_timeline"
            wallId={wallId}
            onAdd={doCreateKeywordAction}
            items={tweetUsernames}
            showModal={true}
          />
        </div>
      </div>
    </>
  )
}

export default connect(
  state => ({
    keywords: state.currentWall.keywords,
    twitterUser: state.user.twitter
  }),
  {
    doCreateKeywordAction,
  }
)(SetupPageTwitter)
