import { CUSTOMIZE_PATH, LAYOUT_PATH, ROOT_PATH, SETUP_PATH, WALL_BASE_ROUTE, MODERATION_PATH, THEME_PATH, STATS_PATH, FULLSCREEN_PATH, PLAYLIST_PATH } from 'routes/routePaths.js';

export const isHomePage = () => window.location.pathname === ROOT_PATH;
export const isAppearancePages = () => {
    const splitedPath = window.location.pathname.split('/')
    if (splitedPath.length > 2){
        const pathName = splitedPath[splitedPath.length -1];
        return pathName === CUSTOMIZE_PATH || pathName === LAYOUT_PATH ||pathName === THEME_PATH
    }
}

export const isModerationPage  = () => window.location.pathname.includes(MODERATION_PATH)

export const isSetupPage  = () => window.location.pathname.includes(SETUP_PATH)

export const isLayoutPage  = () => window.location.pathname.includes(LAYOUT_PATH)

export const isCustomizePage  = () => window.location.pathname.includes(CUSTOMIZE_PATH)

export const isPlaylistPage  = () => window.location.pathname.includes(PLAYLIST_PATH)

export const isFullscreenPage  = () => window.location.pathname.includes(FULLSCREEN_PATH)

export const isStatsPage  = () => window.location.pathname.includes(STATS_PATH)

export const isSingleWall = () =>
  window.location.pathname === WALL_BASE_ROUTE;
