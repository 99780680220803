import omit from 'lodash/omit'
const initState = {}

const set = (key, value, stringify = false) => {
  localStorage.setItem(key, stringify ? JSON.stringify(value) : value)
}

const get = key => !!localStorage.getItem(key) && JSON.parse(localStorage.getItem(key))

const setUserObject = ({ username, id, email, token, newsletter, moderators }) => {
  let wallrusStorage = {
    ...(get('wallrus') || initState),
    user: { username, id, email, token, newsletter, moderators }
  }
  set('wallrus', wallrusStorage, true)
}

const setCountryCode = countryCode => {
  const wallrusStorage = {
    ...(get('wallrus') || initState),
    user: {
      ...get('wallrus').user,
      countryCode: countryCode
    }
  }
  set('wallrus', wallrusStorage, true)
}

const removeLocalStorageKey = key => {
  const wallrusStorage = {
    ...(get('wallrus') || initState),
    user: {
      ...omit(get('wallrus').user, key)
    }
  }
  set('wallrus', wallrusStorage, true)
}

const setInstagramObject = instagram => {
  let store = get('wallrus') || initState
  const { username, img } = instagram
  store.user.instagram = { username, img, status: instagram.status || 'CONNECTED' }
  set('wallrus', store, true)
}

const setTwitterObject = twitter => {
  let store = get('wallrus') || initState
  const { username, img } = twitter
  store.user.twitter = { username, img, status: twitter.status || 'CONNECTED' }
  set('wallrus', store, true)
}

const updateNewsLetter = user => {
  let store = {
    ...get('wallrus'),
    user: {
      ...get('wallrus').user,
      newsletter: user.newsletter
    }
  }
  set('wallrus', store, true)
}

const hasCountryCode = () => Object.keys(getUserObject()).includes('countryCode')

const getCountryCode = () => getUserObject().countryCode

const getUserObject = () => (get('wallrus') ? get('wallrus').user : {})

export default {
  set,
  get,
  setInstagramObject,
  setTwitterObject,
  setUserObject,
  updateNewsLetter,
  getUserObject,
  setCountryCode,
  getCountryCode,
  hasCountryCode,
  removeLocalStorageKey
}
