import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { setSidebarActiveRouteId } from 'store/sidebar/sidebarReducer'
import { closeModalAction } from 'store/modal/modalReducer'
import { SIDEBAR } from 'setup/sidebar'
import _compact from 'lodash/compact'
import PropTypes from 'prop-types'

/** A HOC used to return a proper link NavLink from react-router. */
const GoTo = ({ children, closeModalAction, route, setSidebarActiveRouteId, wallId }) => {
  const foundItem = SIDEBAR.map(panel => panel.items.find(item => item.route === route))
  const activeItem = _compact(foundItem).length > 0 ? _compact(foundItem)[0].id : 0

  return activeItem ? (
    <NavLink
      onClick={() => {
        setSidebarActiveRouteId(activeItem)
        closeModalAction()
      }}
      to={`/wall/${wallId}/${route}`}>
      {children}
    </NavLink>
  ) : (
    <NavLink target="_blank" rel="noopener noreferrer" to={`/wall/${wallId}/${route}`}>
      {children}
    </NavLink>
  )
}

GoTo.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string.isRequired
}

export default connect(
  state => ({
    wallId: state.currentWall.id
  }),
  { closeModalAction, setSidebarActiveRouteId }
)(GoTo)
