import Colors from 'setup/colors.scss'

export const generateLineOptions = (data, labels) => {
  return {
    data: canvas => {
      let ctx = canvas.getContext('2d')
      let gradient = ctx.createLinearGradient(0, 0, 0, 400)
      gradient.addColorStop(0, Colors.brand)
      gradient.addColorStop(1, Colors.darkbrand)
      return {
        labels,
        datasets: [
          {
            data,
            backgroundColor: gradient,
            hoverBackgroundColor: gradient,
            borderColor: Colors.brand
          }
        ]
      }
    },
    options: {
      legend: {
        display: false
      },
      elements: {
        point: {
          radius: 0
        }
      },
      scales: {
        xAxes: [
          {
            display: false,
            gridLines: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            display: false
          }
        ]
      }
    }
  }
}
export const generateDoughnutOptions = data => {
  const labels = [' Twitter', ' Instagram']
  return {
    data: canvas => {
      let ctx = canvas.getContext('2d')
      let gradient = ctx.createLinearGradient(0, 0, 0, 400)
      let gradient2 = ctx.createLinearGradient(0, 0, 0, 400)
      gradient.addColorStop(0, Colors.info)
      //   gradient.addColorStop(1, '#3023ae')
      gradient2.addColorStop(0, Colors.brand)
      gradient2.addColorStop(1, Colors.darkbrand)
      return {
        labels,
        datasets: [
          {
            data,
            backgroundColor: [gradient, gradient2],
            hoverBackgroundColor: [gradient, gradient2]
          }
        ]
      }
    },
    legend: {
      display: false
    },
    options: {
      tooltips: {
        enabled: true,
        mode: 'single',
        displayColors: false,
        bodyFontSize: 16,
        bodyFontFamily: "'Montserrat','Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        cornerRadius: 0,
        caretSize: 0,
        backgroundColor: Colors.darkgrey,
        callbacks: {
          label: (tooltipItem, { labels, datasets }) => {
            let label = labels[tooltipItem.index]
            let datasetLabel = datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
            return `${label}:  ${datasetLabel}%`
          }
        }
      }
    }
  }
}
