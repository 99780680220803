import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  WALL_BASE_ROUTE,
  ROOT_PATH,
  LAYOUT_ROUTE,
  THEME_ROUTE,
  CUSTOMIZE_ROUTE,
  SETUP_ROUTE,
  PLAYLIST_ROUTE,
  MODERATION_ROUTE,
  STATS_ROUTE,
  PREVIEW_ROUTE,
  PAY_ROUTE,
  EMBED_ROUTE,
  LAYOUT_PATH,
  MODERATION_PATH,
  FULLSCREEN_ROUTE
} from 'routes/routePaths.js';
import LayoutPage from 'pages/LayoutPage/LayoutPage';
import ThemePage from 'pages/ThemePage/ThemePage';
import CustomizePage from 'pages/CustomizePage/CustomizePage';
import SetupPage from 'pages/SetupPage/SetupPage';
import PlaylistPage from 'pages/PlaylistPage/PlaylistPage';
import ModerationPage from 'pages/ModerationPage/ModerationPage';
import StatsPage from 'pages/StatsPage/StatsPage';
import PreviewPage from 'pages/PreviewPage/PreviewPage';
import PayPage from 'pages/PayPage/PayPage';
import EmbedPage from 'pages/EmbedPage';
import FullscreenPage from 'pages/FullscreenPage/FullscreenPage';
import DashboardPage from 'pages/DashboardPage/DashboardPage';

export const routes = [
  {
    path: ROOT_PATH,
    exact: true,
    viewToRender: ()  =>  <DashboardPage />
  },
  {
    exact: true,
    path: WALL_BASE_ROUTE,
    viewToRender: ({wallId}) =>  <Redirect to={`/wall/${wallId}/${LAYOUT_PATH}`} />
  },
  {
    path: LAYOUT_ROUTE,
    exact: true,
    viewToRender: (props) =>  <LayoutPage {...props} />
  },
  {
    path: THEME_ROUTE,
    exact: true,
    viewToRender: () =>  <ThemePage />
  },
  {
    path: CUSTOMIZE_ROUTE,
    exact: true,
    viewToRender: (props) =>  <CustomizePage {...props}/>
  },
  {
    path: SETUP_ROUTE,
    exact: true,
    viewToRender: (props) =>  <SetupPage {...props}/>
  },
  {
    path: PLAYLIST_ROUTE,
    exact: true,
    viewToRender: (props) =>  <PlaylistPage {...props}/>
  },
  {
    path: MODERATION_ROUTE,
    exact: true,
    viewToRender: (props) =>  <ModerationPage {...props} />
  },
  {
    path: STATS_ROUTE,
    exact: true,
    viewToRender: (props) =>  <StatsPage {...props} />
  },
  {
    path: FULLSCREEN_ROUTE,
    exact: true,
    viewToRender: (props) => <FullscreenPage {...props} />
  },
  {
    path: PREVIEW_ROUTE,
    exact: true,
    viewToRender: (props) =>  <PreviewPage {...props}/>
  },
  {
    path: PAY_ROUTE,
    exact: true,
    viewToRender: () =>  <PayPage />
  },
  {
    path: EMBED_ROUTE,
    viewToRender: () =>  <EmbedPage />
  },
  {
    exact: true,
    viewToRender: () =>  <Redirect to={ROOT_PATH} />
  }
];

export const freeRoutes = routes.filter(route=>route.path!== PAY_ROUTE)


export const subrUsersRoutes =[
  {
    path: ROOT_PATH,
    exact: true,
    viewToRender: ()  =>  <DashboardPage />
  },
  {
    exact: true,
    path: WALL_BASE_ROUTE,
    viewToRender: ({wallId}) =>  <Redirect to={`/wall/${wallId}/${MODERATION_PATH}`} />
  },
  {
    path: MODERATION_ROUTE,
    exact: true,
    viewToRender: (props) =>  <ModerationPage {...props} />
  },

]
