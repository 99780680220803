import React from 'react'
import { connect } from 'react-redux'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import Button from 'components/Button/Button'
import { zoomModalAction } from 'store/modal/modalReducer'

export const ThumbnailPreviewer = ({ type, content, zoomModalAction, id}) => {
  return (
    <div className="ThumbnailPreviewer" component={type === 'theme' ? content : null}>
      <div className="ThumnailPreviewer-Inner">
        <Button className="small" onClick={() => zoomModalAction({ id, content, type })}>
          zoom
        </Button>
      </div>
    </div>
  )
}

export default connect(null, { zoomModalAction })(ThumbnailPreviewer)
