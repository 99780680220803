import React from 'react'
import './SelectPlanItem.scss'
import Title from 'components/Title/Title'
import Button from 'components/Button/Button'
import Colors from 'setup/colors.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'

import { openModalAction } from 'store/modal/modalReducer'

const isAgencyPlan = event_type => event_type === 'agency'

export const SelectPlanItem = ({
  name,
  daily_discount,
  description,
  daily,
  selectPlan,
  id,
  event_type,
  selected,
  openModalAction
}) => {
  let selectedClass = selected && !isAgencyPlan(event_type) ? 'selected' : ''
  let agencyClass = isAgencyPlan(event_type) ? 'agency' : ''

  return (
    <div
      onTransitionEnd={e => e.stopPropagation()}
      className={`SelectPlanItem ${selectedClass} ${agencyClass}`.trim()}
      onClick={() => (!isAgencyPlan(event_type) ? selectPlan(id) : null)}>
      {daily_discount ? <div className="SpecialOfferHeader">Special Offer</div> : null}
      <Title className="small">{name}</Title>
      <div
        dangerouslySetInnerHTML={{ __html: description ? removeQuotes(description) : '' }}
      />
      {daily !== null ? (
        <div className="SelectPlanItemPrice">
          <div className="from">from</div>
          <div className={`price ${daily_discount ? 'brand-color' : ''}`.trim()}>
            ${parseInt(daily_discount ? daily_discount : daily, 10)}
          </div>
          {daily_discount ? (
            <div className="price barred">
              ${parseInt(daily, 10)}
              <div className="PriceBar" />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="CheckMarkContainer">
        {selected ? (
          <FontAwesomeIcon
            icon={['far', 'check-circle']}
            style={{ width: 20, height: 20, color: Colors.info }}
          />
        ) : null}
      </div>
      {isAgencyPlan(event_type) ? (
        <Button onClick={() => openModalAction('SUPPORT')} tabIndex="-1">
          Contact Us
        </Button>
      ) : null}
    </div>
  )
}

function removeQuotes(string) {
  return string.replace(/\\/gi, '')
}

export default connect(null, { openModalAction })(SelectPlanItem)
