import React from 'react'
import Title from 'components/Title/Title'

import './ContentStatusModal.scss'
import { connect } from 'react-redux'

import Colors from 'setup/colors.scss'

import AlertOutline from 'assets/icons/AlertOutline'
import Exclamation from 'assets/icons/Exclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _uniqBy from 'lodash/uniqBy'

const icons = {
  danger: <Exclamation style={{ width: 22, height: 22, fill: Colors.danger }} />,
  valid: <FontAwesomeIcon icon={'check'} style={{ color: Colors.info }} />,
  warning: (
    <AlertOutline
      style={{
        width: 22,
        height: 22,
        fill: Colors.warning,
        position: 'absolute',
        top: '2px',
        left: '4px'
      }}
    />
  )
}

const ContentStatusModal = ({ className = '', currentWall: { status, status_info } }) => {
  let modal
  if (status === 'valid') {
    modal = (
      <div className="ContentStatusContent">
        <div className="ContentStatusDescription">
          All your layouts will display properly. You are in the <br />
          greeb, keep it up
        </div>
        <div className="Icon" style={{ borderColor: Colors.info }}>
          {icons.valid}
        </div>
      </div>
    )
  } else if (status === 'warning') {
    modal = (
      <div className="ContentStatusContent">
        <div className="ContentStatusDescription">
          You do not have enough approved content to display all of your layouts. They
          will be skipped until the requirements are met.
        </div>
        <div className={`${className} ContentStatusMessage`}>
          <div>
            {_uniqBy(status_info, 'type').map(s => (
              <div key={s.id} className="StatusMessage">
                {s.valid !== true ? <div className="ScreenName">{s.name}:</div> : null}
                <span className="ScreenMessage" style={{ color: Colors.warning }}>
                  {s.valid !== true ? (
                    <div className="Icon" style={{ borderColor: Colors.warning }}>
                      {icons.warning}
                    </div>
                  ) : null}
                  {s.valid}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  } else if (status === 'danger') {
    modal = (
      <div className="ContentStatusContent">
        <div className="ContentStatusDescription">
          You do not have enough approved content to display all of your layouts. They
          will be skipped until the requirements are met.
        </div>
        <div className={`${className} ContentStatusMessage`}>
          <div
            className="Icon"
            style={{ borderColor: Colors.danger, color: Colors.danger }}>
            {icons.danger}
          </div>
          <div style={{ color: Colors.danger }}>
            none of your social layouts will display
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="ContentStatusModal">
      <div>
        <Title className="medium">content status</Title>
      </div>
      {modal}
    </div>
  )
}

export default connect(state => ({
  status_info: state.currentWall.status_info,
  status: state.currentWall.status,
  currentWall: state.currentWall
}))(ContentStatusModal)
