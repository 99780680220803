import React from 'react'
import Title from 'components/Title/Title'
import Button from 'components/Button/Button'
import { connect } from 'react-redux'

import { closeModalAction } from 'store/modal/modalReducer'
import './DeleteLayoutModal.scss'
import ScreenService from 'services/api/ScreenService'
import { deleteLayoutFromCurrentWallAction } from 'store/currentWall/actions/index'

export const DeleteLayoutModal = ({
  closeModalAction,
  deleteLayoutFromCurrentWallAction,
  id
}) => {
  const deleteLayout = () =>
    ScreenService.destroy(id).then(() => {
      deleteLayoutFromCurrentWallAction(id)
    })
  return (
    <div className="DeleteLayoutModal">
      <Title className="medium">deleting your layout</Title>
      <div className="ModalDescription">Are you sure want to delete this layout?</div>
      <div className="ModalActions">
        <Button className="grey" onClick={() => closeModalAction()}>
          cancel
        </Button>
        <Button
          className="danger"
          onClick={() => {
            deleteLayout().then(() => closeModalAction())
          }}>
          delete
        </Button>
      </div>
    </div>
  )
}
export default connect(state => ({ id: state.modal.layoutSelected }), {
  closeModalAction,
  deleteLayoutFromCurrentWallAction
})(DeleteLayoutModal)
