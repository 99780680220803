import React, { Component } from 'react'
import LoginForm from 'components/Forms/LoginForm'
import SignupForm from 'components/Forms/SignupForm'
import ResetPassword from 'components/Forms/ResetPassword'
import './PublicHomePage.scss'
import Header from 'layouts/Header/Header'
import SessionService from 'services/api/SessionService'
import { doSocialLoginAction } from 'store/user/userReducer'
import { connect } from 'react-redux'

const LOGIN = 'LOGIN'
const SIGN_UP = 'SIGN_UP'
class PublicHomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resetPassword: false,
      sendPassword: false,
      formState: LOGIN,
      resetEmail: ''
    }
  }

  handleBackToInitial = () => {
    this.setState({ sendPassword: false, resetPassword: false })
  }

  handleSendPassword = e => {
    let resetEmail = this.state.resetEmail
    e.preventDefault()
    SessionService.resetPassword(resetEmail)
    this.setState({ sendPassword: !this.state.sendPassword })
  }

  onChangeResetPassword = e => {
    this.setState({ resetEmail: e.target.value })
  }

  handleResetPassword = () => {
    this.setState({ resetPassword: !this.state.resetPassword, sendPassword: false })
  }

  toggleState = formState =>
    formState !== this.state.formState &&
    this.setState({ formState, resetPassword: false, sendPassword: false })

  isActiveForm = state => this.state.formState === state

  responseGoogle = async response => {
    let email = response?.profileObj?.email
    let id = response?.profileObj?.googleId
    let name = response?.profileObj?.name
    if (email && id && name) {
      this.props.doSocialLoginAction(email, id, name, 'google')
    } else {
      console.log("An error occured with gapi.");
    }
  }

  responseFacebook = async response => {
    let id = response.userID
    let email = response.email
    let name = response.name

    this.props.doSocialLoginAction(email, id, name, 'facebook')
  }

  render() {
    const { resetEmail, sendPassword } = this.state
    return (
      <div
        className="PublicHomePage"
        style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '100vh'
        }}>
        <Header />
        <div className="HomeTitle">
          <div className="MaintTitle">
            <span>Easiest</span> social media wall to set up and just as easy to use.
          </div>
          <div className="ContentContainer">
            <div className="ContentTitle">
              <span className="title">WE LOVE</span>
              <span className="title">OUR USERS</span>
            </div>
            <div className="ContentDescription">
              <span>
                We know time is a luxury for you. So we made Wallrus the easiest social
                media wall to set up.
              </span>
              <span>
                Create event, select Hashtag, Select theme, Go live, Yep, you are done.
              </span>
            </div>
          </div>
        </div>
        <div className="HomeLogins">
          {this.state.resetPassword !== true ? (
            <LoginForm
              active={this.isActiveForm(LOGIN)}
              handleResetPassword={this.handleResetPassword}
              toggleState={() => this.toggleState(LOGIN)}
              responseGoogle={this.responseGoogle}
              responseFacebook={this.responseFacebook}
            />
          ) : (
            <ResetPassword
              active={this.isActiveForm(LOGIN)}
              sendPassword={this.state.sendPassword}
              handleResetPassword={this.handleResetPassword}
              handleSendPassword={this.handleSendPassword}
              onChangeResetPassword={this.onChangeResetPassword}
              resetEmail={resetEmail}
            />
          )}

          <SignupForm
            active={this.isActiveForm(SIGN_UP)}
            toggleState={() => this.toggleState(SIGN_UP)}
            sendPassword={sendPassword}
            handleBackToInitial={this.handleBackToInitial}
            responseGoogle={this.responseGoogle}
            responseFacebook={this.responseFacebook}
          />
        </div>
      </div>
    )
  }
}
export default connect(
  null,
  { doSocialLoginAction }
)(PublicHomePage)
