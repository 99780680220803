import React from 'react'
import 'components/FloatingActions/FloatingButton.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const FloatingButton = ({ children, className, label, onClick }) => (
  <div className={classNames('FloatingButton', className)} onClick={onClick}>
    {children}
    {label ? (
      <div className="content">
        <div className="label">{label}</div>
      </div>
    ) : null}
  </div>
)

FloatingButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default FloatingButton
