import React from 'react'
import Button from 'components/Button/Button'
import { connect } from 'react-redux'
import './NextStepBar.scss'
import { withRouter } from 'react-router'
import { setSidebarActiveRouteId } from 'store/sidebar/sidebarReducer'
import { SIDEBAR } from 'setup/sidebar'

export const NextStepBar = ({
  history,
  setSidebarActiveRouteId,
  activeRouteId,
  currentWall
}) => {
  const nextItem = SIDEBAR[0].items[activeRouteId]

  const navigate = () => {
    if (nextItem) {
      setSidebarActiveRouteId(nextItem.id)
      history.push(`/wall/${currentWall.id}/${nextItem.route}`)
    }
  }

  const hasMeetConditions = () => {
    return (
      (activeRouteId === 1 && currentWall.screens.length > 0) ||
      (currentWall.screens.length > 0 &&
        activeRouteId === 2 &&
        currentWall.theme_id !== null) ||
      (currentWall.screens.length > 0 &&
        activeRouteId === 3 &&
        currentWall.theme_id !== null)
    )
  }

  return nextItem && hasMeetConditions() ? (
    <div className="NextStepBar">
      <Button onClick={navigate}>
        {nextItem !== undefined ? (
          <div>Go to {nextItem.name}</div>
        ) : (
          <div>Go to management</div>
        )}
      </Button>
    </div>
  ) : null
}

export default withRouter(
  connect(
    state => ({
      activeRouteId: state.sidebar.activeRouteId,
      currentWall: state.currentWall
    }),
    { setSidebarActiveRouteId }
  )(NextStepBar)
)
