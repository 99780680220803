import React, { useState } from 'react'
import Input from 'components/Input/Input'
import 'components/Chips/ChipsContainer.scss'
import Title from 'components/Title/Title'

import PlusCircle from 'assets/icons/PlusCircle'
import Chip from 'components/Chips/Chip'
import PropTypes from 'prop-types'

/**
 *
 * a component used as a container around Chip component
 */

const ChipsContainer = ({
  items,
  limit,
  onAdd,
  onDelete,
  placeholder,
  prefix,
  showModal,
  title,
  type,
  wallId
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [inputValue, setInputValue] = useState('')

  const handleChange = e => {
    const itemAlreadyExists = items
      .map(item => item.value.toLowerCase())
      .includes(compareInputValue(e.target.value.toLowerCase()))
    setInputValue(e.target.value)
    setErrorMessage(itemAlreadyExists ? 'keyword already added' : '')
  }

  const handleSubmit = e => {
    e.preventDefault()
    const finalValue =
      prefix && !inputValue.startsWith(prefix) ? prefix + inputValue : inputValue

    if (finalValue && !errorMessage) {
      onAdd(wallId, finalValue, type)
        .then(() => {})
        .catch(error => setErrorMessage(error.keyword[0]))
      setInputValue('')
    }
  }

  const compareInputValue = _value => {
    return prefix ? `${prefix + _value}` : _value
  }

  const isBelowLimit = () => {
    return limit ? items.length < limit : true
  }

  return (
    <form className="ChipsContainer" onSubmit={handleSubmit}>
      {title ? <Title className="small">{title}</Title> : null}

      <Input
        name="word"
        placeholder={placeholder}
        className="adder"
        onChange={handleChange}
        value={inputValue}
        errorMessage={errorMessage}>
        {inputValue !== '' && isBelowLimit() ? (
          <PlusCircle
            style={{ width: 18, height: 18 }}
            className="ChipsContainerAddItem"
            onClick={handleSubmit}
          />
        ) : null}
      </Input>
      {type !== 'facebook' ? (
        <div className="ChipsContainer-list">
          {items.map((item, index) => (
            <Chip key={index} {...item} showModal={showModal} removeItem={onDelete}>
              {item.value}
            </Chip>
          ))}
        </div>
      ) : null}
    </form>
  )
}

ChipsContainer.propTypes = {
  items: PropTypes.array.isRequired,
  limit: PropTypes.number,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  /** the placeholder used by the input */
  placeholder: PropTypes.string,
  /** an optional prefix for the input */
  prefix: PropTypes.string,
  /** if you want to display a modal before deleting the chip */
  showModal: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  wallId: PropTypes.number
}

export default ChipsContainer
