import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default ({ icon, type, ...rest }) => {
  const brandName = type === 'brand' ? 'fab' : ''
  return (
    icon && (
      <span className="Icon">
        <FontAwesomeIcon icon={type ? [brandName, icon] : icon} {...rest} />
      </span>
    )
  )
}
