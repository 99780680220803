const SET_SELECTED_LAYOUT = 'SET_SELECTED_LAYOUT'

const initState = {
  selectedLayout: 0
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_SELECTED_LAYOUT:
      return { ...state, selectedLayout: action.payload }

    default:
      return state
  }
}

export const setSelectedLayout = selectedLayout => ({
  type: SET_SELECTED_LAYOUT,
  payload: selectedLayout
})
