import React from 'react'
import ReactModal from 'react-modal'
import { closeModalAction } from 'store/modal/modalReducer'
import { connect } from 'react-redux'
import SupportModal from 'components/Modal/SupportComponent/SupportModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Colors from 'setup/colors.scss'
import BillingHistoryModal from 'components/Modal/BillingHistoryModal/BillingHistoryModal'
import ChangePasswordModal from 'components/Modal/ChangePasswordModal/ChangePasswordModal'
import AccountInfoModal from 'components/Modal/AccountInfoModal/AccountInfoModal'
import SubUsersModal from 'components/Modal/SubUsersModal/SubUsersModal'
import DeleteWallModal from 'components/Modal/DeleteWallModal/DeleteWallModal'
import DeleteLayoutModal from 'components/Modal/DeleteLayoutModal/DeleteLayoutModal'
import ContentStatusModal from 'pages/ModerationPage/ContentStatusModal'
import PlaylistModal from 'pages/PlaylistPage/PlaylistModal'
import ModerationModal from 'pages/ModerationPage/ModerationModal'
import UpgradePlanModal from 'components/Modal/UpgradePlanModal/UpgradePlanModal'
import 'components/Modal/Modal.scss'
import StatsModal from 'pages/StatsPage/StatsModal'
import ZoomModal from 'components/Modal/ZoomModal/ZoomModal'
import ModerateHelpModal from 'components/Modal/ModerateHelpModal/ModerateHelpModal'
import CustomizeHelpModal from 'components/Modal/CustomizeHelpModal/CustomizeHelpModal'
import CustomizeOptionsModal from 'components/Modal/CustomizeOptionsModal/CustomizeOptionsModal'
import PlaylistHelpModal from 'components/Modal/PlaylistHelpModal/PlaylistHelpModal'
import HelpModal from 'components/Modal/HelpModal/HelpModal'
import DeleteKeywordModal from 'components/Modal/DeleteKeywordModal/DeleteKeywordModal'
import FreeModerationModal from 'pages/ModerationPage/FreeModerationModal'
import PayModal from 'components/Modal/PayModal/PayModal'
import ZoomModerationModal from 'components/Modal/ZoomModerationModal/ZoomModerationModal'

ReactModal.setAppElement('body')

const MODAL_SIZES = { auto: 'auto', xlarge: 990, large: 830, regular: 580, zoom: '50vw' }
const MODALS = {
  ACCOUNT_INFO: {
    component: <AccountInfoModal />,
    canBeClosed: true,
    fullWidth: true,
    width: MODAL_SIZES.large
  },
  BILLING_HISTORY: {
    component: <BillingHistoryModal />,
    canBeClosed: true,
    fullWidth: true,
    width: MODAL_SIZES.large
  },
  PASSWORD_CHANGE: {
    component: <ChangePasswordModal />,
    canBeClosed: true,
    fullWidth: true,
    width: MODAL_SIZES.regular
  },
  SUB_USERS: {
    component: <SubUsersModal />,
    canBeClosed: true,
    fullWidth: true,
    width: MODAL_SIZES.large
  },
  SUPPORT: {
    component: <SupportModal />,
    canBeClosed: true,
    fullWidth: true,
    width: MODAL_SIZES.regular
  },
  DELETE_KEYWORD: {
    component: <DeleteKeywordModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  DELETE_LAYOUT: {
    component: <DeleteLayoutModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  DELETE_WALL: {
    component: <DeleteWallModal />,
    canBeClosed: true,
    fullWidth: true,
    width: MODAL_SIZES.regular
  },
  HELP_CUSTOMIZE: {
    component: <CustomizeHelpModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  OPTIONS_CUSTOMIZE: {
    component: <CustomizeOptionsModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  HELP_PLAYLIST: {
    component: <PlaylistHelpModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  HELP_SETUP: {
    component: <HelpModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  OPTIONS_PLAYLIST: {
    component: <PlaylistModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  OPTIONS_MODERATION: {
    component: <ModerationModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  HELP_MODERATION: {
    component: <ModerateHelpModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  FREE_MODERATION: {
    component: <FreeModerationModal />,
    canBeClosed: false,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  CONTENT_STATUS_MODERATION: {
    component: <ContentStatusModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  UPGRADE_PLAN: {
    component: <UpgradePlanModal />,
    canBeClosed: true,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  STATS: {
    component: <StatsModal />,
    canBeClosed: false,
    fullWidth: false,
    width: MODAL_SIZES.regular
  },
  ZOOM_MODAL: {
    component: <ZoomModal />,
    canBeClosed: true,
    className: 'zoom-modal',
    fullWidth: false,
    width: MODAL_SIZES.zoom
  },
  ZOOM_MODERATION_MODAL: {
    component: <ZoomModerationModal />,
    canBeClosed: true,
    className: 'zoom-modal',
    fullWidth: false,
    width: MODAL_SIZES.zoom
  },
  PAY_MODAL: {
    component: <PayModal />,
    canBeClosed: false,
    fullWidth: true,
    width: MODAL_SIZES.regular
  }
}

const isMobile = () => document.querySelector('body').offsetWidth < 1200

const getStyles = modalName => {
  return modalName
    ? {
        content: {
          zIndex: 2001,
          background: '#fff',
          position: 'relative',
          padding: 50,
          borderRadius: 15,
          border: 'none',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: MODALS[modalName].width,
          height: MODALS[modalName].height ? MODALS[modalName].height : 'auto'
        },
        overlay: {
          zIndex: 1000,
          top: 0,
          left: MODALS[modalName].fullWidth ? 0 : isMobile() ? 80 : 240,
          backgroundColor: 'rgba(35, 35, 35, 0.7)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    : {}
}

const Modal = ({ className, closeModalAction, modal }) => {
  return (
    <ReactModal
      isOpen={modal.isOpen}
      className={MODALS[modal.modalName] ? MODALS[modal.modalName].className : ''}
      onRequestClose={() => MODALS[modal.modalName].canBeClosed && closeModalAction()}
      shouldCloseOnOverlayClick={true}
      style={{ ...getStyles(modal.modalName) }}>
      {modal.isOpen && MODALS[modal.modalName].canBeClosed ? (
        <div className="close-modal-button">
          <FontAwesomeIcon
            icon={['far', 'times-circle']}
            onClick={() => closeModalAction()}
            style={{ width: 20, height: 20, color: Colors.brand }}
          />
        </div>
      ) : null}
      {modal.modalName && MODALS[modal.modalName].component}
    </ReactModal>
  )
}

export default connect(
  state => ({
    modal: state.modal
  }),
  {
    closeModalAction
  }
)(Modal)
