import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
/**
 *
 * @param {String} value takes in a target value
 */
export const getFieldIsEmptyError = value => (isEmpty(value) ? 'cannot be empty.' : '')
/**
 *
 * @param {Object} fields
 */
export const fieldsAreEmpty = fields =>
  !!Object.keys(fields)
    .map(key => isEmpty(fields[key].value))
    .filter(n => n).length

export const fieldsHaveError = fields =>
  !!Object.keys(fields)
    .map(key => {
      return fields[key].errorMessage || fields[key].errorMessage !== ''
    })
    .filter(n => n).length

export const isBlurEvent = value => {
  return (isString(value) && value === 'blur') || value.type === 'blur'
}
