import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.scss'
import registerServiceWorker from './registerServiceWorker'
import { Provider } from 'react-redux'
import store from 'store/store'
import initAppSetup from 'setup/setup'
initAppSetup()

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
registerServiceWorker()
