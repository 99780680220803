import React from 'react'
import './TopBar.scss'
import Social from '../../Social/Social'
import Logo from '../../Logo/Logo'
import { getTopBarBackgroundColorStyle } from 'services/app/screen'
import classNames from 'classnames'

const TopBar = ({ className = '', screenStyles }) => (
  <div
    className={classNames('TopBar', className)}
    style={{ backgroundColor: getTopBarBackgroundColorStyle(screenStyles) }}>
    <Logo />
    <Social displayHashtag={true} screenStyles={screenStyles} fontSizeMax={70} />
  </div>
)

export default TopBar
