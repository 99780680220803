import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTransition, animated, config } from 'react-spring'
import useInterval from 'services/app/useInterval'
import './FadeScreen.scss'
import TopBar from 'components/Visualizer/TopBar/TopBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BiggerText from 'components/BiggerText/BiggerText'
import { truncateString } from 'setup/utils'
import {
  MOSAIC_1,
  MASONRY_1,
  PIC_POST_ONLY_1,
  PIC_POST_ONLY_2,
  TEXT_PIC_1,
  getBackgroundColorStyle,
  getBackgroundImageStyle,
  getHandleColorStyle,
  getTextColorStyle,
  getCardsBackgroundColorStyle,
  getTopBarEnableStyle,
  getLogoStyle,
  getLogoEnableStyle
} from 'services/app/screen'
import Social from 'components/Social/Social.js'
import Logo from 'components/Logo/Logo.js'
import { AVATAR_IMG_404_URL, SOCIAL_IMG_404_URL } from 'setup/constants'
import classNames from 'classnames'
import _shuffle from 'lodash/shuffle'

export const MasonryGrid = [
  {
    top: 0,
    left: 0,
    width: 18.75 + '%',
    height: 33.33 + '%'
  },
  {
    top: 33.33 + '%',
    left: 0,
    width: 18.75 + '%',
    height: 33.33 + '%'
  },
  {
    top: 66.66 + '%',
    left: 0,
    width: 11.93 + '%',
    height: 33.33 + '%'
  },
  {
    top: 0,
    left: 18.75 + '%',
    width: 22.4 + '%',
    height: 39.8 + '%'
  },
  {
    top: 39.8 + '%',
    left: 18.75 + '%',
    width: 11.2 + '%',
    height: 26.85 + '%'
  },
  {
    top: 39.8 + '%',
    left: 29.95 + '%',
    width: 11.2 + '%',
    height: 19.9 + '%'
  },
  {
    top: 59.7 + '%',
    left: 29.95 + '%',
    width: 28.49 + '%',
    height: 40.28 + '%'
  },
  {
    top: 0,
    left: 41.15 + '%',
    width: 17.29 + '%',
    height: 31.3 + '%'
  },
  {
    top: 0,
    left: 58.44 + '%',
    width: 41.56 + '%',
    height: 45.56 + '%'
  },
  {
    top: 45.56 + '%',
    left: 58.44 + '%',
    width: 21.1 + '%',
    height: 54.44 + '%'
  },
  {
    top: 45.56 + '%',
    left: 79.53 + '%',
    width: 20.47 + '%',
    height: 27.59 + '%'
  },
  {
    top: 73.15 + '%',
    left: 79.53 + '%',
    width: 20.47 + '%',
    height: 26.85 + '%'
  }
]
export const TweetPicGrid = [
  {
    top: 5 + '%',
    left: 49 + '%',
    width: 49 + '%',
    height: 42 + '%'
  },
  {
    top: 53 + '%',
    left: 49 + '%',
    width: 49 + '%',
    height: 42 + '%'
  },
  {
    top: 5 + '%',
    left: 2.5 + '%',
    width: 44 + '%',
    height: 90 + '%'
  }
]

export const generateGrid = (rows = 1, columns = 1) => {
  let grid = []
  let currentRow = 0
  let currentColumn = 0
  for (let position = 0; position < rows * columns; position++) {
    grid.push({
      top: (currentRow / rows) * 100 + '%',
      left: (currentColumn / columns) * 100 + '%',
      width: (1 / columns) * 100 + '%',
      height: (1 / rows) * 100 + '%'
    })

    currentRow = (position + 1) % columns === 0 ? currentRow + 1 : currentRow
    currentColumn = (position + 1) % columns === 0 ? 0 : currentColumn + 1
  }
  return grid
}

export const grids = {
  [MASONRY_1]: {
    grid: MasonryGrid,
    positions: _shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
  },
  [MOSAIC_1]: {
    grid: generateGrid(2, 4),
    positions: _shuffle([0, 1, 2, 3, 4, 5, 6, 7])
  },
  [PIC_POST_ONLY_1]: {
    grid: generateGrid()
  },
  [PIC_POST_ONLY_2]: {
    grid: generateGrid()
  },
  [TEXT_PIC_1]: {
    grid: TweetPicGrid,
    positions: _shuffle([0, 1, 2])
  }
}

export const FadeScreen = ({
  animatedTheme = false,
  cards: nextCards,
  forceBackground = false,
  getNextCard = null,
  isRunning = true,
  play_video,
  rotation_time,
  screen: { styles: screenStyles },
  screenType,
  showPlaceholder = false,
  styles
}) => {
  let grid = grids[screenType].grid
  let positions = grids[screenType].positions ? grids[screenType].positions : [0]

  const [running, setRunning] = useState(isRunning)
  const gridSize = grid.length

  if (screenType !== TEXT_PIC_1) {
    nextCards = nextCards.filter(card => card.media_file_url !== null)
  }

  const [cards, setCards] = useState(
    showPlaceholder
      ? nextCards
          .slice(0, gridSize)
          .map((item, position) => ({ ...item, position: positions[position] }))
      : []
  )
  const [position, setPosition] = useState(0)

  const screenCanRun = () => nextCards.length > gridSize
  const increase = () => {
    if (gridSize > 1) {
      const _cards = cards.map((card, _index) => {
        if (_index !== position) {
          return card
        } else {
          let _card = getNextCard(1, screenType !== TEXT_PIC_1 ? 'IMAGE' : undefined)
          return { ..._card, position: positions[position] }
        }
      })
      setCards(_cards)
    } else {
      const _card = getNextCard(1, screenType !== TEXT_PIC_1 ? 'IMAGE' : undefined)
      setCards(_card)
    }

    setPosition((position + 1) % gridSize)
  }

  useInterval(() => increase(), running ? rotation_time : null)

  useEffect(() => {
    if (!showPlaceholder) {
      let hidden, visibilityChange
      if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden'
        visibilityChange = 'visibilitychange'
      } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden'
        visibilityChange = 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        visibilityChange = 'webkitvisibilitychange'
      }
      const handleVisibilityChange = () => {
        if (document[hidden]) {
          setRunning(false)
        } else {
          setRunning(true)
        }
      }
      document.addEventListener(visibilityChange, handleVisibilityChange, false)
      return () => document.removeEventListener(visibilityChange, handleVisibilityChange)
    }
  }, [showPlaceholder])

  useEffect(() => {
    if (!showPlaceholder && cards.length === 0) {
      const initialize = () => {
        if (gridSize > 1) {
          const _cards = getNextCard(
            gridSize,
            screenType !== TEXT_PIC_1 ? 'IMAGE' : undefined
          ).map((card, index) => ({
            ...card,
            position: positions[index]
          }))
          setCards(_cards)
        } else {
          const _card = getNextCard(1, screenType !== TEXT_PIC_1 ? 'IMAGE' : undefined)
          setCards(_card)
        }
      }
      initialize()
    }
  }, [cards.length, getNextCard, gridSize, positions, screenType, showPlaceholder])

  const transitions = useTransition(cards, card => card.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow,
    unique: false
  })

  const currentStyles =
    animatedTheme && !forceBackground
      ? { color: getTextColorStyle(screenStyles) }
      : {
          backgroundColor: getBackgroundColorStyle(screenStyles),
          backgroundImage: `url('${getBackgroundImageStyle(screenStyles)}')`,
          color: getTextColorStyle(screenStyles)
        }

  const shouldDisplayTopBar = () =>
    screenType === PIC_POST_ONLY_1 || screenType === TEXT_PIC_1 || screenType === MOSAIC_1

  return (
    <div className={classNames('FadeScreen', screenType)} style={currentStyles}>
      {shouldDisplayTopBar() ? (
        <TopBar
          className={getTopBarEnableStyle(screenStyles) ? '' : 'hide'}
          screenStyles={screenStyles}
        />
      ) : null}
      {screenType === MASONRY_1 ? (
        <>
          <Logo enabled={getLogoEnableStyle(screenStyles) && getLogoStyle(styles)} />
          <Social screenStyles={screenStyles} />
        </>
      ) : null}
      <div className="CardsContainer">
        {screenCanRun() ? (
          transitions.map(({ item, props, key }) => (
            <Card
              card={item}
              className={`grid-` + item.position}
              key={screenType + key + item.id}
              playVideo={play_video}
              screenStyles={screenStyles}
              screenType={screenType}
              style={{
                ...props,
                ...grid[item.position]
              }}
            />
          ))
        ) : (
          <p style={{ color: '#fff' }}>Not enough cards</p>
        )}
      </div>
    </div>
  )
}

export const Card = ({ card, className, playVideo, screenStyles, screenType, style }) => {
  return (
    <animated.div
      className={classNames(
        'Card',
        className,
        card.media_file_url !== null ? 'img' : 'txt'
      )}
      style={{
        ...style,
        backgroundColor:
          screenType === PIC_POST_ONLY_1 || screenType === PIC_POST_ONLY_2
            ? 'transparent'
            : getCardsBackgroundColorStyle(screenStyles)
      }}>
      <div className="media">
        <img
          src={card.media_file_url}
          alt=""
          onError={e => (e.target.src = SOCIAL_IMG_404_URL)}
        />
        {card.media_video_url !== null ? (
          <video loop={playVideo} autoPlay={playVideo} muted src={card.media_video_url} />
        ) : null}
      </div>

      {card.source === 'twitter' ? (
        <div className="content">
          <img
            className="profil"
            src={card.profile_image_url}
            onError={e => (e.target.src = AVATAR_IMG_404_URL)}
            alt=""
          />
          <div className="name">
            <p>{truncateString(card.user_name, 20)}</p>
            <p style={{ color: getHandleColorStyle(screenStyles) }}>
              {`@${truncateString(card.user_handle, 25)}`}
            </p>
          </div>
          <BiggerText fontSizeMax={60}>{truncateString(card.text, 280)}</BiggerText>
          <div className="source">
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </div>
        </div>
      ) : (
        <div className="content">
          <div className="name" />
          <BiggerText fontSizeMax={60}>{truncateString(card.text, 282)}</BiggerText>
          <div className="source">
            <FontAwesomeIcon icon={['fab', 'instagram']} />
          </div>
        </div>
      )}
    </animated.div>
  )
}

export default connect(state => ({
  animatedTheme: state.currentWall.theme && state.currentWall.theme.animated,
  //   cards: state.currentWall.cards.items.filter(card => card.approved),
  play_video: state.currentWall.play_video,
  rotation_time: state.currentWall.rotation_time.time,
  styles: state.currentWall.styles
}))(FadeScreen)
