import React from 'react'
import Title from 'components/Title/Title'
import './UpgradePlanModal.scss'

import Button from 'components/Button/Button'
import { connect } from 'react-redux'

import { closeModalAction } from 'store/modal/modalReducer'
import {
  addLayoutToCurrentWallAction,
  doApplyThemeAction,
  doUpdateWall
} from 'store/currentWall/actions/index'
import ScreenService from 'services/api/ScreenService'

export const UpgradePlanModal = ({
  addLayoutToCurrentWallAction,
  closeModalAction,
  currentWall,
  doApplyThemeAction,
  doUpdateWall,
  modal,
  planId,
  plans
}) => {
  const newPlan = plans.find(plan => plan.id === (modal.upgradeToPlan || planId + 1))
  return (
    <div className="UpgradePlanModal">
      <Title className="medium">upgrade your wall</Title>
      <div className="ModalDescription">
        You are about to upgrade your wall, giving you access to more layouts, themes and
        functionalities.
      </div>
      <div className="ModalActions">
        <Button className="grey" onClick={() => closeModalAction()}>
          cancel
        </Button>
        <Button
          onClick={() => {
            doUpdateWall(currentWall.id, {
              auto_approve: false,
              plan_id: newPlan.id
            }).then(() => {
              if (modal.createLayout !== null) {
                ScreenService.create(currentWall.id, modal.createLayout).then(screen => {
                  addLayoutToCurrentWallAction(screen)
                })
              }
              if (modal.applyTheme !== null) {
                doApplyThemeAction(modal.applyTheme, currentWall.id)
              }
              closeModalAction()
            })
          }}>
          upgrade to {newPlan && newPlan.name.toLowerCase()}
        </Button>
      </div>
    </div>
  )
}
export default connect(
  state => ({
    currentWall: state.currentWall,
    modal: state.modal,
    plans: state.plans.items,
    planId: state.currentWall.plan.id
  }),
  { addLayoutToCurrentWallAction, closeModalAction, doApplyThemeAction, doUpdateWall }
)(UpgradePlanModal)
