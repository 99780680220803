import React, { useEffect, useState } from 'react'
import { openModalAction } from 'store/modal/modalReducer'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import './StatsPage.scss'
import StatsService from 'services/api/StatsService'
import Title from 'components/Title/Title'
import Colors from 'setup/colors.scss'

import { Line, Doughnut } from 'react-chartjs-2'
import { generateLineOptions, generateDoughnutOptions } from './StatsPageChartOptions.js'
import { getSocialMediaType } from 'setup/utils'
import placeholderStats from './StatsPlaceholder.json'
import initialStats from './StatsInitial.json'
import Container from 'components/Container/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import _isEmpty from 'lodash/isEmpty'
import _orderBy from 'lodash/orderBy'

const FORMAT = 'MMM DD, YYYY, h:mm:ss A'

function getPosts(stats, socialMedia) {
  return stats !== undefined
    ? Object.keys(stats)
        .filter(
          val =>
            val.toLowerCase().includes(socialMedia) &&
            val.toLowerCase().includes('generated')
        )
        .map(val => stats[val])
        .reduce((a, b) => a + b, 0)
    : 0
}

function graphDougnutPercentageData({ tweetGenerated, instaGenerated }) {
  return [
    Math.round((tweetGenerated / (tweetGenerated + instaGenerated)) * 100) || 0,
    Math.round((instaGenerated / (tweetGenerated + instaGenerated)) * 100) || 0
  ]
}

const getContentTypeData = (stats, totalGenerated) => {
  const { instaGenerated, tweetGenerated, tweetPicGenerated, videoGenerated } = stats
  const maxTotalGenerated = Math.max(
    tweetGenerated,
    instaGenerated + tweetPicGenerated,
    videoGenerated
  )
  return [
    {
      generated: tweetGenerated,
      icon: 'font',
      percentage: Math.ceil((tweetGenerated * 100) / maxTotalGenerated),
      title: 'text only'
    },
    {
      generated: instaGenerated + tweetPicGenerated,
      icon: ['far', 'image'],
      percentage: Math.ceil(
        (instaGenerated + tweetPicGenerated * 100) / maxTotalGenerated
      ),
      title: 'images with text'
    },
    {
      generated: videoGenerated,
      icon: 'video',
      percentage: Math.ceil((videoGenerated * 100) / maxTotalGenerated),
      title: 'video with text'
    }
  ]
}

const StatsPageBox = ({ title, children, className, showIf = undefined }) => {
  return (
    <div className="StatsPageBox">
      <Title className="small">{title}</Title>
      <div className={classNames('StatsPageBoxContent', className)}>
        {showIf !== undefined ? children : 'no data'}
      </div>
    </div>
  )
}

const FromTo = ({ from, to }) => {
  const fromMoment = from ? <Moment format={FORMAT}>{from}</Moment> : null
  const toMoment = to ? <Moment format={FORMAT}>{to}</Moment> : null
  return fromMoment && toMoment ? (
    <div className="FromTo">
      From: <span className="bold">{fromMoment}</span> to:{' '}
      <span className="bold">{toMoment}</span>
    </div>
  ) : null
}

const StatsPage = ({ currentWall, openModalAction }) => {
  const currentWallId = currentWall.id
  const planId = currentWall.plan_id
  const eventType = currentWall.plan.event_type
  const from = currentWall.last_live_start
  const to = currentWall.last_live_until
  const isLive = currentWall.live

  const [statistics, setStatistics] = useState(initialStats)
  const [showMoreHashtags, setShowMoreHashtags] = useState(5)

  useEffect(() => {
    if (currentWallId !== undefined && eventType !== undefined) {
      if (eventType !== 'pro') {
        setStatistics(placeholderStats)
        openModalAction('STATS')
      } else {
        if (isLive || from !== null) {
          StatsService.get(currentWallId).then(response => {
            if (!_isEmpty(response)) setStatistics(response)
          })
        } else {
          openModalAction('STATS')
          setStatistics(placeholderStats)
        }
      }
    }
  }, [currentWallId, from, isLive, openModalAction, planId])

  const totalGenerated = statistics.hashtags.reduce((a, b) => a + b.hit, 0)
  const getMaxHit = () =>
    statistics.hashtags.length > 0
      ? _orderBy(statistics.hashtags, 'hit', 'desc')[0].hit
      : 0

  const fetchedHashtags =
    statistics.hashtags.map(({ hashtagType, hashtag, hit }) => ({
      type: getSocialMediaType(hashtagType),
      hashtag,
      hit,
      percentage: Math.ceil((hit * 100) / getMaxHit())
    })) || []

  const twitterPosts = getPosts(statistics.stats, 'tweet')
  const instagramPosts = getPosts(statistics.stats, 'insta')

  const graphHoursData = Object.keys(statistics.hours)
    .filter(key => key.toLowerCase().includes('hour'))
    .sort((a, b) => a.localeCompare(b))
    .map(key => statistics.hours[key])
    .slice()

  const labels = graphHoursData ? graphHoursData.map((v, i) => `${i}:00`) : []

  const graphDoughnutData = graphDougnutPercentageData(statistics.stats)

  const contentType = getContentTypeData(statistics.stats, totalGenerated)

  return planId ? (
    <Container className="StatsPage">
      <FromTo from={from} to={to} />
      <StatsPageBox className="shadow" title="fetched content" showIf={totalGenerated}>
        <div className="DougnutGraph">
          <Doughnut {...generateDoughnutOptions(graphDoughnutData)} />
        </div>
        <div className="ContentAndHashTags">
          <div className="ContentLength">{totalGenerated}</div>
          <div className="HashTagCount">{`${statistics.hashtags.length} hashtags`}</div>
        </div>
        <div className="TweetsAndPosts">
          <div className="SocialModiaPostCount Twitter">
            <FontAwesomeIcon
              className="Logo"
              icon={['fab', 'twitter']}
              style={{ color: Colors.info }}
            />
            <span>{twitterPosts} posts</span>
          </div>
          <div className="SocialModiaPostCount Instagram">
            <FontAwesomeIcon
              className="Logo"
              icon={['fab', 'instagram']}
              style={{ color: Colors.brand }}
            />
            <span>{instagramPosts} posts</span>
          </div>
        </div>
      </StatsPageBox>
      <StatsPageBox
        title="fetched hashtags"
        className="pl-0 pr-0"
        showIf={fetchedHashtags.length}>
        <div className="FetchedContentList">
          {fetchedHashtags.slice(0, showMoreHashtags).map((hashtag, index) => (
            <div key={index} className={classNames('FetchedContentItem', hashtag.type)}>
              <FontAwesomeIcon
                icon={['fab', hashtag.type === 'twitter' ? 'twitter' : 'instagram']}
                style={{ width: 24, height: 24, color: 'black' }}
              />
              <div className="FetchedContentItemHashtag">{hashtag.hashtag}</div>
              <div className="FetchedContentItemCount">
                <div
                  className="Count"
                  style={{
                    minWidth: `${hashtag.percentage >= 10 ? hashtag.percentage : 10}%`
                  }}>
                  {hashtag.hit}
                </div>
              </div>
            </div>
          ))}
          {fetchedHashtags.length - showMoreHashtags > 0 ? (
            <button
              className="Button"
              onClick={() => setShowMoreHashtags(prevState => prevState + 5)}>
              load more
            </button>
          ) : null}
        </div>
      </StatsPageBox>
      <StatsPageBox
        title="content type"
        className="pl-0 pr-0"
        showIf={fetchedHashtags.length}>
        <div className="FetchedContentList">
          {contentType.map(content => (
            <div key={content.title} className="FetchedContentItem">
              <FontAwesomeIcon
                icon={content.icon}
                style={{ width: 24, height: 24, color: 'black' }}
              />
              <div className="FetchedContentItemHashtag">{content.title}</div>
              <div className="FetchedContentItemCount">
                <div
                  className="Count"
                  style={{
                    minWidth: `${content.percentage >= 10 ? content.percentage : 10}%`
                  }}>
                  {content.generated}
                </div>
              </div>
            </div>
          ))}
        </div>
      </StatsPageBox>
      <StatsPageBox
        title="most effective time of day"
        className="pl-0 pr-0"
        showIf={graphHoursData}>
        {graphHoursData ? (
          <div className="GraphSection">
            <Line {...generateLineOptions(graphHoursData, labels)} />
            <div className="HoursTimeline">
              {labels.map((h, key) => (
                <div key={key}>{h}</div>
              ))}
            </div>
          </div>
        ) : null}
      </StatsPageBox>
    </Container>
  ) : null
}

export default connect(
  state => ({ currentWall: state.currentWall }),
  { openModalAction }
)(StatsPage)
