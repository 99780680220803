import React from 'react'
import Button from 'components/Button/Button'
import { connect } from 'react-redux'
import { openUpgradePlanModalAction } from 'store/modal/modalReducer'

const UpgradeButton = ({
  className = '',
  currentWall,
  openUpgradePlanModalAction,
  planId,
  eventType,
  upgradeToPlan = null
}) => {
  return planId !== 0 && eventType !== 'pro' && !currentWall.live ? (
    <Button
      className={`small ${className}`}
      onClick={() => openUpgradePlanModalAction(upgradeToPlan)}>
      upgrade
    </Button>
  ) : null
}

export default connect(
  state => ({
    currentWall: state.currentWall,
    planId: state.currentWall.plan.id,
    eventType: state.currentWall.plan.event_type
  }),
  {
    openUpgradePlanModalAction
  }
)(UpgradeButton)
