/**
 * List of google fonts
 */
export const FontsList = [
  {
    id: 1,
    name: 'Abel',
    class: 'font-abel',
    type: 'sans-serif',
    slug: 'Abel',
    weight: '400'
  },
  {
    id: 2,
    name: 'Barlow Condensed',
    class: 'font-barlow',
    type: 'sans-serif',
    slug: 'Barlow+Condensed',
    weight: '400,700'
  },
  {
    id: 3,
    name: 'Karla',
    class: 'font-karla',
    type: 'sans-serif',
    slug: 'Karla',
    weight: '400,700'
  },
  {
    id: 4,
    name: 'Montserrat',
    class: 'font-montserrat',
    type: 'sans-serif',
    slug: 'Montserrat',
    weight: '400,700'
  },
  {
    id: 5,
    name: 'Nanum Myeongjo',
    class: 'font-nanum',
    type: 'serif',
    slug: 'Nanum+Myeongjo',
    weight: '400,700'
  },
  {
    id: 6,
    name: 'Old Standard TT',
    class: 'font-old-standard',
    type: 'serif',
    slug: 'Old+Standard+TT',
    weight: '400,700'
  },
  {
    id: 7,
    name: 'Open Sans',
    class: 'font-open',
    type: 'sans-serif',
    slug: 'Open+Sans',
    weight: '400,700'
  },
  {
    id: 8,
    name: 'Oswald',
    class: 'font-oswald',
    type: 'sans-serif',
    slug: 'Oswald',
    weight: '400,600'
  },

  {
    id: 9,
    name: 'Permanent Marker',
    class: 'font-marker',
    type: 'special',
    slug: 'Permanent+Marker',
    weight: '400'
  },
  {
    id: 10,
    name: 'Playfair Display',
    class: 'font-playfair-display',
    type: 'serif',
    slug: 'Playfair+Display',
    weight: '400,700'
  },
  {
    id: 11,
    name: 'Poppins',
    class: 'font-poppins',
    type: 'sans-serif',
    slug: 'Poppins',
    weight: '400,700'
  },
  {
    id: 12,
    name: 'PT Serif',
    class: 'font-pt',
    type: 'serif',
    slug: 'PT+Serif',
    weight: '400,700'
  },
  {
    id: 13,
    name: 'Quicksand',
    class: 'font-quicksand',
    type: 'sans-serif',
    slug: 'Quicksand',
    weight: '400,700'
  },
  {
    id: 14,
    name: 'Raleway',
    class: 'font-raleway',
    type: 'sans-serif',
    slug: 'Raleway',
    weight: '400,700'
  },
  {
    id: 15,
    name: 'Roboto',
    class: 'font-roboto',
    type: 'sans-serif',
    slug: 'Roboto',
    weight: '400,700'
  },
  {
    id: 16,
    name: 'Work Sans',
    class: 'font-work',
    type: 'sans-serif',
    slug: 'Work+Sans',
    weight: '400,700'
  }
]

export const sansSerifFonts = FontsList.filter(font => font.type === 'sans-serif')

export const serifFonts = FontsList.filter(font => font.type === 'serif')

export const specialFonts = FontsList.filter(font => font.type === 'special')

/**
 * Load all goolge fonts
 */
export const setupFonts = () => {
  let url = 'https://fonts.googleapis.com/css?family='
  FontsList.forEach(font => {
    url = url + font.slug
    if (font.weight !== '') {
      url = url + ':' + font.weight
    }
    url = url + '|'
  })
  let resource = document.createElement('link')
  resource.setAttribute('rel', 'stylesheet')
  resource.setAttribute('href', url)
  resource.setAttribute('type', 'text/css')
  let head = document.getElementsByTagName('head')[0]
  head.appendChild(resource)
}
