import React, { Component } from 'react'
import Toggle from 'components/Toggle/Toggle'
import './ModerationModal.scss'
import ChipsContainer from 'components/Chips/ChipsContainer'
import { connect } from 'react-redux'
import { updateCurrentWallAction } from 'store/currentWall/actions/index'

import {
  doFetchAllBanwordsAction,
  doCreateBanwordAction,
  doRemoveBanwordAction,
  doDestroyAllModerationContent,
  doUpdateWall
} from 'store/currentWall/actions/index'
import Title from 'components/Title/Title'
import Button from 'components/Button/Button'

class ModerationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDestroy: false
    }
  }

  toggleAreyouSure = () => {
    this.setState({
      showAreYouSure: !this.state.showAreYouSure
    })
  }
  componentDidMount() {
    if (!this.props.banwords.length)
      this.props.doFetchAllBanwordsAction(this.props.wallId)
  }

  render() {
    const {
      doCreateBanwordAction,
      doRemoveBanwordAction,
      banwords,
      wallId,
      auto_approve,
      no_retweet,
      play_video,
      filter_profanity = false,
      doDestroyAllModerationContent,
      doUpdateWall,
      unapprovedCardsId,
      retweetedCardsId
    } = this.props

    return (
      <div className="ModerationModal">
        <Title className="medium">moderation options</Title>
        <div className="ModerationOptionsContainer">
          <div className="ModerationOption">
            <Toggle
              isChecked={auto_approve}
              onToggle={() =>
                doUpdateWall(
                  wallId,
                  { auto_approve: !this.props.auto_approve },
                  unapprovedCardsId
                )
              }
              textAfter="auto approve all"
            />
          </div>
          <div className="ModerationOption">
            <Toggle
              isChecked={no_retweet}
              onToggle={() =>
                doUpdateWall(wallId, { no_retweet: !no_retweet }, retweetedCardsId)
              }
              textAfter="filter out retweets"
            />
          </div>
          <div className="ModerationOption">
            <Toggle
              isChecked={play_video}
              onToggle={() => doUpdateWall(wallId, { play_video: !play_video })}
              textAfter="play video"
            />
          </div>
          <div className="ModerationOption">
            <Toggle
              isChecked={filter_profanity}
              onToggle={() =>
                doUpdateWall(wallId, { filter_profanity: !filter_profanity })
              }
              textAfter="profanity filter"
            />
          </div>
        </div>

        <ChipsContainer
          placeholder="add a banned word"
          type="twitter"
          wallId={wallId}
          onAdd={doCreateBanwordAction}
          onDelete={doRemoveBanwordAction}
          items={banwords}
        />
        <div className="DestroyModerationContainer">
          <div>
            {this.state.confirmDestroy ? (
              <div className="confirm-destroy-moderation-content">
                <div>
                  <small>Are you sure?</small>
                </div>
                <div>
                  <Button
                    className="grey small half-width"
                    onClick={() => this.setState({ confirmDestroy: false })}>
                    no
                  </Button>
                  <Button
                    className="danger small half-width"
                    onClick={() => {
                      this.setState({ confirmDestroy: false })
                      doDestroyAllModerationContent(wallId)
                    }}>
                    yes
                  </Button>
                </div>
              </div>
            ) : (
              <Button
                className="small danger"
                onClick={() => this.setState({ confirmDestroy: true })}>
                Destroy all moderation content
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    auto_approve: state.currentWall.auto_approve,
    banwords: state.currentWall.banwords.items,
    play_video: state.currentWall.play_video,
    no_retweet: state.currentWall.no_retweet,
    feedId: state.currentWall.feed_id,
    wallId: state.currentWall.id,
    unapprovedCardsId: state.currentWall.cards.items
      .filter(c => !c.approved)
      .map(c => c.id),
    retweetedCardsId: state.currentWall.cards.items
      .filter(c => c.is_retweet)
      .map(c => c.id)
  }),
  {
    doFetchAllBanwordsAction,
    doCreateBanwordAction,
    doRemoveBanwordAction,
    doDestroyAllModerationContent,
    updateCurrentWallAction,
    doUpdateWall
  }
)(ModerationModal)
