import request from 'services/requestWrapper.js'

function getAll() {
  return request({
    url: `/themes`,
    method: 'GET'
  })
}

function getCategories() {
  return request({
    url: `/categories`,
    method: 'GET'
  })
}

const ThemeService = {
  getAll,
  getCategories
}

export default ThemeService
