import request from 'services/requestWrapper.js'

function verify(coupon) {
  return request({
    url: `/coupons/verify_coupon/${coupon}`,
    method: 'GET'
  })
}

const CouponService = {
  verify
}

export default CouponService
