import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import {
  doFetchAllCardsAction,
  doDeleteCardAction,
  deleteAllCards,
  doUpdateCard
} from 'store/currentWall/actions/index'
import { deleteCardAction } from 'store/currentWall/actions/currentWallCardsActions'

import FloatingActions from 'components/FloatingActions/FloatingActions'
import FloatingButton from 'components/FloatingActions/FloatingButton'

import Title from 'components/Title/Title'
import ModerationCard from 'components/ModerationCard/ModerationCard'
import GoTo from 'components/Goto/GoTo'
import Container from 'components/Container/Container'

import SettingsIcon from 'assets/icons/SettingsIcon'
import AlertOutline from 'assets/icons/AlertOutline'
import Exclamation from 'assets/icons/Exclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openModalAction } from 'store/modal/modalReducer'

import Colors from 'setup/colors.scss'
import './ModerationPage.scss'
import useInterval from 'services/app/useInterval'

const REFRESH_RATE = 15000
const MODERATION_ALL = 'MODERATION_ALL'
const MODERATION_TEXT = 'MODERATION_TEXT'
const MODERATION_IMAGES = 'MODERATION_IMAGES'
const MODERATION_VIDEO = 'MODERATION_VIDEO'

const icons = {
  danger: <Exclamation style={{ width: 22, height: 22, fill: Colors.danger }} />,
  valid: <FontAwesomeIcon icon={'check'} style={{ color: Colors.info }} />,
  warning: (
    <AlertOutline style={{ width: 22, height: 22, fill: Colors.warning, top: -1 }} />
  )
}
const ModerationPage = ({
  approvedCards,
  doDeleteCardAction,
  doFetchAllCardsAction,
  doUpdateCard,
  isFetchingWall,
  openModalAction,
  planId,
  status,
  unApprovedCards,
  wallId
}) => {
  const [filter, setFilter] = useState(MODERATION_ALL)
  const refRefresh = useRef(null)

  useEffect(() => {
    doFetchAllCardsAction(wallId)
  }, [doFetchAllCardsAction, wallId])

  useInterval(() => {
    doFetchAllCardsAction(wallId)
  }, REFRESH_RATE)

  const handleClickRefresh = () => {
    const refreshIcon = refRefresh.current.classList
    refreshIcon.add('refreshing')
    doFetchAllCardsAction(wallId).then(data => {
      setTimeout(() => {
        setTimeout(() => {
          refreshIcon.remove('refreshing')
        }, 400)
        refreshIcon.add('done')
        setTimeout(() => {
          refreshIcon.remove('done')
        }, 2000)
      }, 1000)
    })
  }

  const filterCards = (filter, card) => {
    switch (filter) {
      case MODERATION_IMAGES:
        return card.media_file_url && !card.media_video_url
      case MODERATION_TEXT:
        return card.text && !card.media_file_url && !card.media_video_url
      case MODERATION_VIDEO:
        return card.media_video_url
      default:
        return card
    }
  }

  return (
    <Container className="ModerationPage">
      <FloatingActions>
        <FloatingButton
          onClick={() => openModalAction('OPTIONS_MODERATION')}
          label="options">
          <SettingsIcon style={{ height: 20, width: 20, fill: Colors.brand }} />
        </FloatingButton>
        <FloatingButton onClick={handleClickRefresh} label="refresh">
          <div ref={refRefresh} className="refresh-button">
            <FontAwesomeIcon
              icon={'sync-alt'}
              className="sync-icon"
              style={{ color: Colors.brand }}
            />
            <FontAwesomeIcon
              className="check-icon"
              icon={'check'}
              style={{ color: Colors.info }}
            />
          </div>
        </FloatingButton>
        <FloatingButton label="info" onClick={() => openModalAction('HELP_MODERATION')}>
          <FontAwesomeIcon icon={'question'} style={{ color: Colors.brand }} />
        </FloatingButton>
        {status ? (
          <FloatingButton
            className={status}
            onClick={() => openModalAction('CONTENT_STATUS_MODERATION')}
            label="status">
            {icons[status]}
          </FloatingButton>
        ) : null}
      </FloatingActions>
      <div className="ModerationFilter">
        <div
          className={filter === MODERATION_ALL ? 'active' : ''}
          onClick={() => setFilter(MODERATION_ALL)}>
          all
        </div>
        <div
          className={filter === MODERATION_TEXT ? 'active' : ''}
          onClick={() => setFilter(MODERATION_TEXT)}>
          text only
        </div>
        <div
          className={filter === MODERATION_IMAGES ? 'active' : ''}
          onClick={() => setFilter(MODERATION_IMAGES)}>
          images
        </div>
        <div
          className={filter === MODERATION_VIDEO ? 'active' : ''}
          onClick={() => setFilter(MODERATION_VIDEO)}>
          videos
        </div>
      </div>
      <div className="ModerationPageContent">
        <div className="ModerationPageListContainer">
          <Title className="medium">unapproved content</Title>
          <div className="ModerationPageList">
            {isEmpty(unApprovedCards) ? (
              <div className="ModerationNoContentBox">
                <Title className="small no-bg">no content</Title>
                <div className="ModerationNoContentDescription">
                  Add some hashtag in the <GoTo route="setup">setup</GoTo> tab
                </div>
              </div>
            ) : null}
            {unApprovedCards
              .filter(c => filterCards(filter, c))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map(card => (
                <ModerationCard
                  onApprove={() =>
                    doUpdateCard(wallId, card.id, {
                      approved: !card.approved
                    })
                  }
                  onDelete={() => doDeleteCardAction(wallId, card.id)}
                  key={card.id}
                  {...card}
                />
              ))}
          </div>
        </div>
        <div className="ModerationPageListContainer">
          <Title className="medium">approved content</Title>
          <div className="ModerationPageList">
            {isEmpty(approvedCards) ? (
              <div className="ModerationNoContentBox">
                <Title className="small no-bg">no content</Title>
                <div className="ModerationNoContentDescription">Approve some content</div>
              </div>
            ) : (
              approvedCards
                .filter(c => filterCards(filter, c))
                .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                .map(card => (
                  <ModerationCard
                    key={card.id}
                    onDelete={() => doDeleteCardAction(wallId, card.id)}
                    {...card}
                  />
                ))
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default connect(
  state => ({
    status: state.currentWall.status,
    status_info: state.currentWall.status_info,
    approvedCards: state.currentWall.cards.items.filter(c => c.approved),
    unApprovedCards: state.currentWall.cards.items.filter(c => !c.approved),
    autoApprove: state.currentWall.auto_approve,
    planId: state.currentWall.plan_id,
    isFetchingWall: state.currentWall.isFetching
  }),
  {
    doFetchAllCardsAction,
    doDeleteCardAction,
    deleteCardAction,
    deleteAllCards,
    doUpdateCard,
    openModalAction
  }
)(ModerationPage)
