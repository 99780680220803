import React, { useState } from 'react'
import { ChromePicker, CirclePicker } from 'react-color'
import Button from 'components/Button/Button'
import 'components/ColorPicker/ColorPicker.scss'
import _startsWith from 'lodash/startsWith'
import PropTypes from 'prop-types'

const COLORPICKER_PALETTE = [
  '#ffd9d3',
  '#ffc4f1',
  '#bd99ff',
  '#a5b2ff',
  '#a1fbeb',
  '#9efcd3',
  '#d8ffa4',
  '#faffb1',
  '#ffffff',
  '#ff3e3e',
  '#fb04ff',
  '#8500ff',
  '#0049ff',
  '#00f6ff',
  '#00ffac',
  '#9eff00',
  '#fdff00',
  '#9b9b9b',
  '#ca2a2a',
  '#bd00b4',
  '#5700b7',
  '#0021c2',
  '#00c0cb',
  '#00b275',
  '#89ad49',
  '#c0ba3a',
  '#4c4c4c',
  '#690000',
  '#620162',
  '#2b005b',
  '#001f59',
  '#004c4e',
  '#003b29',
  '#293802',
  '#544e00',
  '#252525'
]

/**
 *
 * ColorPicker component used in Customize page.
 * It displays a container either colored circles or a Colorpicker
 */
const ColorPicker = ({ value, setColor }) => {
  const [displayColorPicker, setDisplay] = useState(false)
  const color = _startsWith(value, '{') ? JSON.parse(value) : value
  const saveColor = color => {
    color = !color ? '' : JSON.stringify(color.rgb)
    setColor(color)
  }

  return (
    <div className="ColorPicker">
      {displayColorPicker ? (
        <>
          <ChromePicker
            style={{ boxShadow: 'none' }}
            color={color}
            onChangeComplete={saveColor}
          />
          <div className="more-less">
            <Button className="xsmall" onClick={() => setDisplay(prev => !prev)}>
              back
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="transparent-circle picker" onClick={() => saveColor(null)} />
          <CirclePicker
            colors={COLORPICKER_PALETTE}
            color={color}
            circleSize={17}
            circleSpacing={11}
            onChangeComplete={saveColor}
          />

          <div className="more-less">
            <Button className="xsmall" onClick={() => setDisplay(prev => !prev)}>
              color picker
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  setColor: PropTypes.func
}

export default ColorPicker
