import request from 'services/requestWrapper.js'

/**
 * Returns Policy, Signature and Key to be delete media to S3
 */
function getDeleteUrl(objectName) {
  return request({
    url: `/s3/delete?objectName=${objectName}`,
    method: 'GET'
  })
}


function getSignedUrl(params) {
  return request({
    url: `/s3/sign`,
    method: 'GET',
    params
  })
}

const S3Service = {
  getDeleteUrl,
  getSignedUrl
}

export default S3Service
