import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { doLoginOrFetchUserAction } from 'store/user/userReducer'
import LogoutButton from 'components/LogoutButton/LogoutButton'
import './AccountMenu.scss'

import MinusCircle from 'assets/icons/MinusCircle'
import PlusCircle from 'assets/icons/PlusCircle'

import { openModalAction } from 'store/modal/modalReducer'

import { isSubuser } from 'setup/utils'

const AccountMenu = ({ doLoginOrFetchUserAction, openModalAction, user }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    doLoginOrFetchUserAction()
  }, [doLoginOrFetchUserAction])

  return (
    <div
      tabIndex="0"
      onBlur={() => setIsOpen(false)}
      className={`AccountMenu ${isOpen ? 'show' : ''}`}
      onClick={() => setIsOpen(prevState => !prevState)}>
      <span className="username">{user.username}</span>
      <PlusCircle
        className={isOpen ? 'hide' : 'show'}
        style={{ width: 16, height: 16 }}
      />
      <MinusCircle
        className={isOpen ? 'show' : 'hide'}
        style={{ width: 16, height: 16 }}
      />

      <div className="AccountMenu-submenu">
        {!isSubuser(user) ? (
          <div className="AccountMenu-item-container">
            <div
              className="AccountMenu-item"
              onClick={() => openModalAction('ACCOUNT_INFO')}>
              Account Info
            </div>
            <div
              className="AccountMenu-item"
              onClick={() => openModalAction('PASSWORD_CHANGE')}>
              Change my password
            </div>
            <div
              className="AccountMenu-item"
              onClick={() => openModalAction('BILLING_HISTORY')}>
              Billing history
            </div>
            {user.moderators === true ? (
              <div
                className="AccountMenu-item"
                onClick={() => openModalAction('SUB_USERS')}>
                Add sub-users
              </div>
            ) : null}
            <div className="AccountMenu-item" onClick={() => openModalAction('SUPPORT')}>
              Support
            </div>
          </div>
        ) : null}

        <LogoutButton />
      </div>
    </div>
  )
}

export default connect(
  state => ({ user: state.user }),
  {
    doLoginOrFetchUserAction,
    openModalAction
  }
)(AccountMenu)
