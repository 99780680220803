import request from 'services/requestWrapper.js'
import { BASIC_PLAN } from 'setup/constants'

/**
 *
 * @param {string} name Represents the name of the wall to be created,
 * The name cannot be already used, obviously, the API should return an error
 * if that's the case.
 * @param {number} planId Represent the type of plan create, by default it's always BASIC.
 */
function create(name, planId = BASIC_PLAN) {
  const data = {
    event: {
      name,
      plan_id: planId
    }
  }
  return request({
    url: `/packages/${planId}`,
    method: 'POST',
    data
  })
}

/**
 * Returns all walls.
 */
function getAll() {
  return request({
    url: `/events`,
    method: 'GET'
  })
}

/**
 *
 * @param {number} id Get a single wall by passing in it's id.
 */
function get(id) {
  return request({
    url: `/events/${id}.json`,
    method: 'GET'
  })
}
/**
 *
 * @param {number} id Insert the ID of the wall to delete.
 */
function destroy(id) {
  return request({
    url: `/events/${id}`,
    method: 'DELETE'
  })
}

/**
 * @param {number} wallId Insert the ID of the wall to approve.
 */
const autoApproveAll = wallId =>
  request({
    url: `/events/${wallId}/approve_all`,
    method: 'POST'
  })

/**
 * @param {number} wallId Insert the ID of the wall to remove the.
 */

const filterOutRetweet = wallId =>
  request({
    url: `/events/${wallId}/no_retweet`,
    method: 'POST'
  })

function update(wallId, data, params) {
  let url = params ? `/events/${wallId}${params}` : `/events/${wallId}`
  return request({
    url,
    method: 'PUT',
    data: { event: data }
  })
}

const WallService = {
  autoApproveAll,
  create,
  get,
  getAll,
  destroy,
  filterOutRetweet,
  update
}

export default WallService
