import React, { Component } from 'react'
import Title from 'components/Title/Title'
import Input from 'components/Input/Input'
import './AccountInfoModal.scss'
import UserService from 'services/api/UserService'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import storage from 'services/app/storage'
import { connect } from 'react-redux'
import { updateUserAction } from 'store/user/userReducer'
import { updatePayFormFullAddressAction } from 'store/payFormReducer/payFormReducer'
import Checkbox from 'components/Checkbox/Checkbox'

const firstNameFieldName = 'firstName'
const lastNameFieldName = 'lastName'
const emailFieldName = 'email'
const companyFieldName = 'company'
const address1FieldName = 'address1'
const address2FieldName = 'address2'
const cityFieldName = 'city'
const zipcodeFieldName = 'zipcode'
const provinceFieldName = 'province'
const countryFieldName = 'country'
const newsletterFieldName = 'newsletter'

export class AccountInfoModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      [firstNameFieldName]: {},
      [lastNameFieldName]: {},
      [emailFieldName]: {},
      [companyFieldName]: {},
      [address1FieldName]: {},
      [address2FieldName]: {},
      [cityFieldName]: {},
      [zipcodeFieldName]: {},
      [countryFieldName]: {},
      [provinceFieldName]: {},
      [newsletterFieldName]: {},
      successMessage: '',
      showSelectProvince: false
    }
  }

  handleBlurOrChange = e => {
    const target = e.target
    let value = target.value
    this.setState({
      [e.target.name]: {
        ...this.state[e.target.name],
        value,
        dirty: true
      }
    })
    if (this.state[e.target.name] !== '') {
      this.setState({ showSelectProvince: true })
    }
  }

  handleCheckNewsletter = e => {
    this.setState({
      newsletter: {
        value: !this.state.newsletter.value,
        dirty: true
      }
    })
  }

  async componentDidMount() {
    try {
      const { address } = await UserService.get()

      this.setState({
        [firstNameFieldName]: {
          value: address.firstname || ''
        },
        [lastNameFieldName]: {
          value: address.lastname || ''
        },
        [emailFieldName]: {
          value: address.email || ''
        },
        [companyFieldName]: {
          value: address.company || ''
        },
        [address1FieldName]: {
          value: address.addr1 || ''
        },
        [address2FieldName]: {
          value: address.addr2 || ''
        },
        [cityFieldName]: {
          value: address.city || ''
        },
        [zipcodeFieldName]: {
          value: address.zip || ''
        },
        [countryFieldName]: {
          value: address.country || ''
        },
        [countryFieldName]: {
          value: address.country || ''
        },
        [provinceFieldName]: {
          value: address.province || ''
        },
        [newsletterFieldName]: {
          value: this.props.user.newsletter || false
        },
        addressId: address.id
      })
    } catch (error) {
      return 'cannot find the address'
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    const address = {
      addr1: this.state[address1FieldName].value,
      addr2: this.state[address2FieldName].value,
      city: this.state[cityFieldName].value,
      company: this.state[companyFieldName].value,
      country: this.state[countryFieldName].value,
      email: this.state[emailFieldName].value,
      firstname: this.state[firstNameFieldName].value,
      lastname: this.state[lastNameFieldName].value,
      province: this.state[provinceFieldName].value,
      zip: this.state[zipcodeFieldName].value,
      newsletter: this.state[newsletterFieldName].value
    }

    UserService.updateAddressInfo(this.state.addressId, address)
      .catch(error => {
        console.log(error)
      })
      .then(response =>
        this.setState({
          successMessage: 'your information has been saved'
        })
      )
    if (address.newsletter && address.newsletter !== this.props.user.newsletter) {
      UserService.updateNewsletterInfo(address.newsletter).then(user => {
        storage.updateNewsLetter(user)
        this.props.updateUserAction(storage.getUserObject())
      })
    }
  }

  updateErrorStateAndFocusOn(elementName, errorMessage) {
    this.setState({
      [elementName]: {
        ...this.state[elementName],
        errorMessage,
        isValid: false
      }
    })
  }

  render() {
    return (
      <div className="AccountInfoModal">
        <Title className="medium">account info</Title>
        <p>This information will be used as the standard billing info.</p>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <div className="FormFields">
            <div className="LeftColumn">
              <Input
                name={firstNameFieldName}
                value={this.state[firstNameFieldName].value}
                className="simple"
                labelText="First Name"
                onChange={this.handleBlurOrChange}
              />
              <Input
                name={lastNameFieldName}
                value={this.state[lastNameFieldName].value}
                className="simple"
                labelText="Last Name"
                onChange={this.handleBlurOrChange}
              />
              <Input
                name={emailFieldName}
                type="email"
                value={this.state[emailFieldName].value}
                className="simple"
                labelText="Email Address"
                onChange={this.handleBlurOrChange}
              />
              <Checkbox
                name={newsletterFieldName}
                type="checkbox"
                checked={this.state[newsletterFieldName].value}
                onCheck={() => this.handleCheckNewsletter()}>
                Subscribe to our newsletter
              </Checkbox>
            </div>
            <div className="RightColumn">
              <Input
                name={companyFieldName}
                value={this.state[companyFieldName].value}
                className="simple"
                labelText="Company (optional)"
                onChange={this.handleBlurOrChange}
              />
              <Input
                name={address1FieldName}
                value={this.state[address1FieldName].value}
                className="simple"
                labelText="Address Line 1"
                onChange={this.handleBlurOrChange}
              />
              <Input
                name={address2FieldName}
                value={this.state[address2FieldName].value}
                className="simple"
                labelText="Address Line 2"
                onChange={this.handleBlurOrChange}
              />
              <Input
                name={cityFieldName}
                value={this.state[cityFieldName].value}
                className="simple"
                labelText="City"
                onChange={this.handleBlurOrChange}
              />
              <Input
                name={zipcodeFieldName}
                value={this.state[zipcodeFieldName].value}
                className="simple"
                labelText="Zip code / Postal Code"
                onChange={this.handleBlurOrChange}
              />
              <CountryDropdown
                name={countryFieldName}
                defaultOptionLabel="Country"
                value={this.state[countryFieldName].value}
                onChange={value => {
                  this.handleBlurOrChange({
                    target: { type: 'select', name: countryFieldName, value }
                  })
                }}
              />
              {this.state.showSelectProvince !== false ? (
                <RegionDropdown
                  name={provinceFieldName}
                  defaultOptionLabel="Province"
                  country={this.state[countryFieldName].value}
                  value={this.state[provinceFieldName].value}
                  onChange={value => {
                    this.handleBlurOrChange({
                      target: { type: 'select', name: provinceFieldName, value }
                    })
                  }}
                />
              ) : null}
            </div>
          </div>
          <SubmitButton successMessage={this.state.successMessage}>
            save your information
          </SubmitButton>
        </form>
      </div>
    )
  }
}

export default connect(state => ({ user: state.user }), {
  updateUserAction,
  updatePayFormFullAddressAction
})(AccountInfoModal)
