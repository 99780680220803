import React, { useEffect } from 'react'
import './FullscreenPage.scss'
import Vizualizer from 'components/Visualizer/Visualizer'

const FullscreenPage = () => {
  useEffect(() => {
    document.querySelector('body').classList.add('fullscreen')
    return () => {
      document.querySelector('body').classList.remove('fullscreen')
    }
  })
  return (
    <div className="FullscreenPage">
      <Vizualizer />
    </div>
  )
}

export default FullscreenPage
