import request from 'services/requestWrapper.js'
/**
 *
 * @param {string} oldPassword Represents the old password.
 * @param {string} newPassword Represents the new password.
 * @param {string} confirmationPassword Represents the confirmation password when changing password.
 * @param {string} userId Represents the user id.
 */

function update(oldPassword, newPassword, confirmationPassword, userId) {
  const data = {
    old_password: oldPassword,
    new_password: newPassword,
    password_confirmation: confirmationPassword
  }

  return request({
    url: `/users/${userId}`,
    method: 'PUT',
    data
  })
}

const PasswordService = {
  update
}

export default PasswordService
