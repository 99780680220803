import geolocator from 'geolocator'
import environment from 'services/app/environment.js'
import storage from 'services/app/storage.js'

const options = {
  enableHighAccuracy: false,
  timeout: 500,
  maximumWait: 1000, // max wait time for desired accuracy
  maximumAge: 0, // disable cache
  desiredAccuracy: 300, // meters
  fallbackToIP: true, // fallback to IP if Geolocation fails or rejected
  addressLookup: true // requires Google API key if true
}

const locate = () =>
  storage.hasCountryCode() ? storage.getCountryCode() : fetchCountryCode()

const fetchCountryCode = () => {
  return new Promise((resolve, reject) => {
    geolocator.locate(options, (err, location) => {
      storage.setCountryCode(location.address.country)
      resolve(location.address.country)
      environment.isDev() && err && console.log(err)
    })
  })
}

const GeolocatorService = {
  locate
}

export default GeolocatorService
