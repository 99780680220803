import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

/** A custom Select element used in Customize page to select displayed hashtag */
const HashtagSelector = ({ hashtags, selectedHashtag, updateHashtag }) => {
  const options = hashtags.map(hashtag => {
    return { ...hashtag, label: hashtag.value }
  })

  const update = hashtag => {
    updateHashtag(hashtag !== null ? hashtag.value : '')
  }

  const hashtagIcon = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      content: "' '",
      display: 'block',
      width: 28
    }
  })

  const styles = {
    container: styles => ({ ...styles, height: 30, minHeight: 30 }),

    control: styles => ({
      ...styles,
      height: 30,
      minHeight: 30,
      borderColor: 'transparent!important',
      boxShadow: '0 3px 7px 0 rgba(0, 0, 0, 0.15)',
      fontSize: 12,
      letterSpacing: 0.5
    }),
    indicatorsContainer: styles => ({ ...styles, height: 30 }),
    clearIndicator: styles => ({
      ...styles,
      padding: 0,
      fontSize: 12,
      '> svg': {
        height: 14,
        width: 14
      }
    }),
    indicatorSeparator: styles => ({ ...styles, backgroundColor: 'transparent' }),
    dropdownIndicator: styles => ({
      ...styles,
      ':hover': { color: '#ccc!important' }
    }),
    input: styles => ({ ...styles, ...hashtagIcon() }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? '#7617ce!important' : '#fff!important',
      cursor: 'pointer',
      fontSize: 12,
      letterSpacing: 0.5,
      color: state.isSelected ? '#fff' : '#000',
      transition: 'all .2s ease',
      ':hover': {
        color: state.isSelected ? '#fff!important' : '#333!important',
        backgroundColor: state.isSelected ? '#7617ce!important' : '#dedede!important'
      }
    }),
    placeholder: styles => ({
      ...styles,
      color: '#333',
      //   paddingLeft: 30,
      ...hashtagIcon()
    }),
    singleValue: styles => ({ ...styles, ...hashtagIcon() }),
    valueContainer: styles => ({ ...styles, height: 30, minHeight: 30 })
  }

  return (
    <Select
      classNamePrefix="react-select"
      isDisabled={options.length === 0}
      isClearable={true}
      isSearchable={false}
      menuPortalTarget={document.body}
      onChange={hashtag => {
        update(hashtag)
      }}
      options={options}
      placeholder="displayed hashtag"
      styles={styles}
      value={selectedHashtag.value === '' ? null : selectedHashtag}
    />
  )
}

HashtagSelector.propTypes = {
  hashtags: PropTypes.array.isRequired,
  selectedHashtag: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  updateHashtag: PropTypes.func.isRequired
}

export default HashtagSelector
