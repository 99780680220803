import React, { useEffect, useMemo, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import dropZoneImage from 'assets/images/drop-zone-image.png'
import { isCustomizePage } from 'routes/routesUtils'
import Colors from 'setup/colors.scss'
import { doFetchAllLayoutsAction } from 'store/layout/layoutReducer'
import { doFetchAllThemesAction } from 'store/themes/themesReducer'
import { openDeleteLayoutModalAction } from 'store/modal/modalReducer'
import { setSelectedLayout } from 'store/wallbar/wallbarReducer'
import './Wallbar.scss'
import _sortBy from 'lodash/sortBy'
import { DropTarget } from 'react-dnd'
import { compose } from 'redux'
import { animated, useSpring } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WallbarItem from './WallbarItem'

const BAR_ITEM_WIDTH = 190
const BAR_ITEM_MARGIN = 0
const LEFT = 'LEFT'
const RIGHT = 'RIGHT'

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
export const Wallbar = ({
  canDrop,
  connectDropTarget,
  currentWall,
  defaultStyles,
  doFetchAllLayoutsAction,
  doFetchAllThemesAction,
  layouts,
  openDeleteLayoutModalAction,
  selectedLayout,
  setSelectedLayout,
  screens,
  themes
}) => {
  const [expanded, setExpanded] = useState(false)
  const [slidePosition, setSlidePosition] = useState(0)

  const [wallbarWidth, setWallbarWidth] = useState(0)
  const [visibleScreens, setVisibleScreens] = useState(0)
  const minWidth =
    screens.length * BAR_ITEM_WIDTH + (screens.length - 1) * BAR_ITEM_MARGIN
  const wallbarInnerRef = useRef(null)

  const styles = useSpring({
    scrollLeft: slidePosition * (BAR_ITEM_WIDTH + BAR_ITEM_MARGIN)
  })

  const screensLength = usePrevious(screens.length)

  const closeWallbar = () => {
    setExpanded(false)
    setSlidePosition(0)
  }

  const getLayoutThumbnail = screen_type => {
    const layout = layouts.find(layout => layout.screen_type === screen_type)
    return layout ? layout.preview_layout : ''
  }

  const getWallbarItemType = (layout) => {
    // Always render image or video type of screen
    if (layout.screen_type === 'Video1' || layout.screen_type === 'MultipleImages1' || layout.screen_type === 'Image1') {
      return 'PREVIEW';
    } else {
      return currentWall.theme_id !== null ? 'PREVIEW' : 'PLACEHOLDER';
    }
  }

  const isSelectedLayout = index => selectedLayout === index

  const setWidth = () => {
    const visibleElements = parseInt(
      (wallbarInnerRef.current.offsetWidth - 200) / (BAR_ITEM_WIDTH + BAR_ITEM_MARGIN),
      10
    )

    setWallbarWidth(
      visibleElements * BAR_ITEM_WIDTH + (visibleElements - 1) * BAR_ITEM_MARGIN
    )
    setVisibleScreens(visibleElements)
  }

  const selectLayout = index => {
    setExpanded(false)
    if (index >= slidePosition + visibleScreens) {
      setSlidePosition(
        index < screens.length - visibleScreens - 1 ? index : index - visibleScreens + 1
      )
    }
    setSelectedLayout(index)
  }

  const slide = direction => {
    setSlidePosition(direction === RIGHT ? slidePosition + 1 : slidePosition - 1)
  }

  const toggleExpansion = () => {
    setExpanded(expanded => !expanded)
    setSlidePosition(0)
  }

  const shouldDisplayLeftControls = useMemo(() => slidePosition > 0, [slidePosition])

  const shouldDisplayRightControls = useMemo(
    () =>
      !expanded &&
      minWidth > wallbarWidth &&
      slidePosition <= screens.length - visibleScreens - 1,

    [expanded, minWidth, screens.length, slidePosition, visibleScreens, wallbarWidth]
  )

  const shouldDisplayExpander = useMemo(() => screens.length > visibleScreens, [
    screens.length,
    visibleScreens
  ])

  useEffect(() => {
    let timeout = null

    const resetWallbar = () => {
      closeWallbar()
      setWidth()
    }

    const handleWallbarResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(resetWallbar, 200)
    }

    window.addEventListener('resize', handleWallbarResize)

    return () => {
      window.removeEventListener('resize', handleWallbarResize)
      setSelectedLayout(0)
    }
  }, [setSelectedLayout])

  useEffect(() => {
    if (layouts.length === 0) {
      doFetchAllLayoutsAction()
    }
  }, [doFetchAllLayoutsAction, layouts.length])

  useEffect(() => {
    if (themes.all.length === 0) {
      doFetchAllThemesAction()
    }
  }, [doFetchAllThemesAction, themes.all.length])

  useEffect(() => {
    setWidth()
  }, [screens])

  useEffect(() => {
    if (screensLength > 0 && screensLength !== screens.length) {
      setSlidePosition(
        screensLength < screens.length
          ? screens.length - visibleScreens
          : position => position - 1
      )
    }
    if (selectedLayout >= screens.length) {
      setSelectedLayout(screens.length > 0 ? screens.length - 1 : 0)
    }
  }, [screens, screensLength, selectedLayout, setSelectedLayout, visibleScreens])

  useEffect(() => {
    if (screensLength > screens.length && screens.length === visibleScreens) {
      setExpanded(false)
    }
  }, [screens, screensLength, visibleScreens])

  return (
    <div className="Wallbar-container">
      {connectDropTarget(
        <div className={`Wallbar ${expanded ? 'expanded' : 'collapsed'}`}>
          <div className="Wallbar-inner" ref={wallbarInnerRef}>
            <div className="carousel-left" onClick={() => slide(LEFT)}>
              {shouldDisplayLeftControls ? (
                <FontAwesomeIcon
                  icon={['fas', 'chevron-left']}
                  style={{ height: 14, width: 14, color: Colors.brand }}
                />
              ) : null}
            </div>
            {canDrop || screens.length === 0 ? (
              <div
                className="Wallbar-dropzone"
                style={{ backgroundImage: `url(${dropZoneImage})` }}>
                <h1>drag & drop layouts here</h1>
              </div>
            ) : null}
            <animated.div
              native="true"
              scrollLeft={styles.scrollLeft}
              style={{
                width: !expanded ? wallbarWidth : wallbarInnerRef.current.offsetWidth
              }}
              className={`layouts-container ${
                isCustomizePage() ? 'display-selected' : ''
              }`}>
              <div
                className="Wallbar-Layouts"
                style={{
                  width: !expanded ? minWidth : wallbarWidth + 20
                }}>
                {screens &&
                  _sortBy(screens, 'position').map((layout, index) => (
                    <WallbarItem
                      index={index}
                      key={layout.id}
                      layout={layout}
                      onClick={() => selectLayout(index)}
                      openDeleteModal={() => openDeleteLayoutModalAction(layout.id)}
                      previewLayout={getLayoutThumbnail(layout.screen_type)}
                      isSelected={isSelectedLayout(index)}
                      selectedLayout={selectedLayout}
                      defaultStyles={defaultStyles}
                      screen={layout}
                      type={getWallbarItemType(layout)}
                    />
                  ))}
              </div>
            </animated.div>

            <div className="carousel-right" onClick={() => slide(RIGHT)}>
              {shouldDisplayRightControls ? (
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  style={{ height: 14, width: 14, color: Colors.brand }}
                />
              ) : null}
            </div>
          </div>
          {shouldDisplayExpander && (
            <div className="Wallbar-expander" onClick={() => toggleExpansion()}>
              <div className="Icon">
                <FontAwesomeIcon
                  icon={['fas', 'chevron-down']}
                  style={{
                    height: 14,
                    width: 14,
                    color: Colors.darkgrey,
                    transform: `rotate(${expanded ? 180 : 0}deg)`
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {expanded ? (
        <div className="Wallbar-backdrop" onClick={() => toggleExpansion()} />
      ) : null}
    </div>
  )
}

export default withRouter(
  compose(
    DropTarget('layout', {}, (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })),

    connect(
      state => ({
        currentWall: state.currentWall,
        screens: state.currentWall.screens,
        layouts: state.layouts,
        selectedLayout: state.wallbar.selectedLayout || 0,
        defaultStyles: state.currentWall.styles,
        themes: state.themes
      }),
      {
        doFetchAllLayoutsAction,
        doFetchAllThemesAction,
        openDeleteLayoutModalAction,
        setSelectedLayout
      }
    )
  )(Wallbar)
)
