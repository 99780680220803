import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { useTransition, animated, config } from 'react-spring'
import useInterval from 'services/app/useInterval'
import './TextPostScreen.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BiggerText from 'components/BiggerText/BiggerText'
import { truncateString } from 'setup/utils.js'
import {} from 'services/app/screen'
import Social from 'components/Social/Social'
import Logo from 'components/Logo/Logo'
import {
  getBackgroundColorStyle,
  getBackgroundImageStyle,
  getTextColorStyle,
  getLogoEnableStyle,
  getLogoStyle,
  getHashtagColorStyle,
  getHandleColorStyle,
  getCardsBackgroundColorStyle
} from 'services/app/screen'
import { AVATAR_IMG_404_URL } from 'setup/constants'

export const textPostScreenGrid = () => {
  const height = [375, 465]
  let grid = []
  for (let size = 0; size < 2; size++) {
    grid.push({
      from: {
        height: height[size],
        marginBottom: 50,
        opacity: 0
      },
      enter: {
        height: height[size],
        marginBottom: 50,
        opacity: 1
      },
      leave: {
        height: 0,
        marginBottom: 0,
        opacity: 0
      },
      update: {
        height: height[size],
        marginBottom: 50
      }
    })
  }
  return grid
}

const showedCardNbr = 4

const getSize = text => text && (text.length < 150 ? 0 : 1)

export const TextPostScreen = ({
  animatedTheme = false,
  cards: nextCards,
  forceBackground = false,
  getNextCard = null,
  isRunning = true,
  rotation_time,
  showPlaceholder = false,
  screen: { styles: screenStyles },
  styles
}) => {
  const grid = textPostScreenGrid()
  const [running, setRunning] = useState(isRunning)

  const increase = () => {
    let _cards = [...cards]
    _cards.shift()
    let _card = getNextCard(1, 'TEXT')

    _cards.push({ ..._card, size: getSize(_card.text) })

    setCards(_cards)
  }

  const [cards, setCards] = useState(
    showPlaceholder
      ? nextCards
          .slice(0, showedCardNbr)
          .map(card => ({ ...card, size: getSize(card.text) }))
      : []
  )

  const screenCanRun = () => nextCards.length > showedCardNbr + 1

  useInterval(() => increase(), running ? rotation_time : null)

  useEffect(() => {
    if (!showPlaceholder) {
      let hidden, visibilityChange
      if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden'
        visibilityChange = 'visibilitychange'
      } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden'
        visibilityChange = 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        visibilityChange = 'webkitvisibilitychange'
      }
      const handleVisibilityChange = () => {
        if (document[hidden]) {
          setRunning(false)
        } else {
          setRunning(true)
        }
      }
      document.addEventListener(visibilityChange, handleVisibilityChange, false)
      return () => document.removeEventListener(visibilityChange, handleVisibilityChange)
    }
  }, [showPlaceholder])

  useEffect(() => {
    if (!showPlaceholder && cards.length === 0) {
      const initialize = () => {
        const _cards = getNextCard(showedCardNbr, 'TEXT').map(card => ({
          ...card,
          size: getSize(card.text)
        }))
        setCards(_cards)
      }
      initialize()
    }
  }, [cards.length, getNextCard, showPlaceholder])

  const transitions = useTransition(
    cards.map(card => ({
      ...card,
      ...grid[card.size]
    })),
    card => card.id,
    {
      from: ({ from }) => ({ ...from }),
      enter: ({ enter }) => ({ ...enter }),
      update: ({ update }) => ({ ...update }),
      leave: ({ leave }) => ({ ...leave }),
      config: config.slow,
      unique: false
    }
  )

  const currentStyles =
    animatedTheme && !forceBackground
      ? { color: getTextColorStyle(screenStyles) }
      : {
          backgroundColor: getBackgroundColorStyle(screenStyles),
          backgroundImage: `url('${getBackgroundImageStyle(screenStyles)}')`,
          color: getTextColorStyle(screenStyles)
        }

  return (
    <div className="TextPostScreen" style={currentStyles}>
      <div className="rightColumn" style={{ color: getHashtagColorStyle(screenStyles) }}>
        <Logo enabled={getLogoEnableStyle(screenStyles) && getLogoStyle(styles)} />
        <Social screenStyles={screenStyles} />
      </div>
      <div className="CardsContainer">
        {screenCanRun() ? (
          transitions.map(({ item, props, key }) => (
            <Card
              card={item}
              key={'TextPostScreen' + key + item.id}
              screenStyles={screenStyles}
              style={{
                ...props
              }}
            />
          ))
        ) : (
          <p style={{ color: '#fff' }}>Not enough cards</p>
        )}
      </div>
    </div>
  )
}

export const Card = ({ card, screenStyles, style }) => {
  return (
    <animated.div
      native="true"
      id={card.id}
      className="Card"
      style={{
        ...style,
        backgroundColor: getCardsBackgroundColorStyle(screenStyles)
      }}>
      <div className={`content size-${card.size}`}>
        <BiggerText fontSizeMax={60}>{truncateString(card.text, 282)}</BiggerText>
        <div className="bottomCards">
          <img
            className="profil"
            src={card.profile_image_url}
            onError={event => (event.target.src = AVATAR_IMG_404_URL)}
            alt=""
          />
          <div className="name">
            <p>{truncateString(card.user_name, 25)}</p>
            <p style={{ color: getHandleColorStyle(screenStyles) }}>
              {`@${truncateString(card.user_handle, 34)}`}
            </p>
          </div>
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </div>
      </div>
    </animated.div>
  )
}

export default connect(
  state => ({
    animatedTheme: state.currentWall.theme && state.currentWall.theme.animated,
    rotation_time: state.currentWall.rotation_time.time,
    styles: state.currentWall.styles
  }),
  {}
)(TextPostScreen)
