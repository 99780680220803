import React, { Component } from 'react'
import Title from 'components/Title/Title'

import Input from 'components/Input/Input'
import './ChangePasswordModal.scss'
import PasswordService from 'services/api/PasswordService'
import storage from 'services/app/storage'
import isEmpty from 'lodash/isEmpty'
import camelcaseKeys from 'camelcase-keys'

import SubmitButton from 'components/SubmitButton/SubmitButton'
import { focusOnElementByName } from 'setup/utils'
import { getFieldIsEmptyError } from 'setup/formUtils'

const oldPasswordFieldName = 'oldPassword'
const newPasswordFieldName = 'newPassword'
const confirmPasswordFieldName = 'confirmPassword'

class ChangePasswordModal extends Component {
  state = {
    [oldPasswordFieldName]: { value: '' },
    [newPasswordFieldName]: { value: '' },
    [confirmPasswordFieldName]: { value: '' },
    successMessage: ''
  }

  handleBlurOrChange = e => {
    this.setState({
      [e.target.name]: {
        ...this.state[e.target.name],
        value: e.target.value,
        errorMessage: this.handleErrorMessage(e),
        isValid: isEmpty(this.handleErrorMessage(e)),
        dirty: true
      }
    })
  }

  handleErrorMessage = e => {
    let errorMessage
    errorMessage = getFieldIsEmptyError(e.target.value)
    if (!errorMessage) {
      errorMessage =
        e.target.name === confirmPasswordFieldName &&
        e.target.value !==
          document.querySelector(`input[name="${newPasswordFieldName}"]`).value
          ? 'password must match.'
          : ''
    }
    return errorMessage
  }

  handleSubmit = e => {
    e.preventDefault()
    const { value: oldPasswordValue } = this.state[oldPasswordFieldName]
    const { value: newPasswordValue } = this.state[newPasswordFieldName]
    const { value: confirmPasswordValue } = this.state[confirmPasswordFieldName]
    const userId = storage.getUserObject().id

    PasswordService.update(
      oldPasswordValue,
      newPasswordValue,
      confirmPasswordValue,
      userId
    )
      .catch(error => {
        const { passwordConfirmation, oldPassword, newPassword } = camelcaseKeys(
          error.data
        )
        if (passwordConfirmation) {
          this.updateErrorStateAndFocusOn(
            confirmPasswordFieldName,
            passwordConfirmation[0]
          )
        }
        if (oldPassword) {
          this.updateErrorStateAndFocusOn(oldPasswordFieldName, oldPassword[0])
        }
        if (newPassword) {
          this.updateErrorStateAndFocusOn(newPasswordFieldName, newPassword[0])
        }
      })
      .then(response =>
        this.setState({
          successMessage: 'your password has been changed'
        })
      )
  }

  updateErrorStateAndFocusOn(elementName, errorMessage) {
    this.setState({
      [elementName]: {
        ...this.state[elementName],
        errorMessage,
        isValid: false
      }
    })
    focusOnElementByName(elementName)
  }

  isDisabled = () => {
    return !(
      this.state[oldPasswordFieldName].isValid &&
      this.state[newPasswordFieldName].isValid &&
      this.state[confirmPasswordFieldName].isValid
    )
  }

  render() {
    return (
      <div className="ChangePasswordModal">
        <Title className="medium">Change my password</Title>
        <form onSubmit={this.handleSubmit}>
          <Input
            name={oldPasswordFieldName}
            type="password"
            className="simple"
            labelText="Old Password"
            errorMessage={this.state[oldPasswordFieldName].errorMessage}
            value={this.state[oldPasswordFieldName].value}
            onChange={this.handleBlurOrChange}
            onBlur={this.handleBlurOrChange}
            dirty={this.state[oldPasswordFieldName].dirty}
          />
          <Input
            name={newPasswordFieldName}
            type="password"
            className="simple"
            labelText="New Password"
            errorMessage={this.state[newPasswordFieldName].errorMessage}
            value={this.state[newPasswordFieldName].value}
            onBlur={this.handleBlurOrChange}
            onChange={this.handleBlurOrChange}
            dirty={this.state[newPasswordFieldName].dirty}
          />
          <Input
            name={confirmPasswordFieldName}
            type="password"
            className="simple"
            labelText="New Password Confirmation"
            errorMessage={this.state[confirmPasswordFieldName].errorMessage}
            value={this.state[confirmPasswordFieldName].value}
            onBlur={this.handleBlurOrChange}
            onChange={this.handleBlurOrChange}
            dirty={this.state[confirmPasswordFieldName].dirty}
          />
          <SubmitButton
            disabled={this.isDisabled()}
            successMessage={this.state.successMessage}>
            change password
          </SubmitButton>
        </form>
      </div>
    )
  }
}

export default ChangePasswordModal
