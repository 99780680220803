import React from 'react'

const PlusCircle = props => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="510px"
    height="510px"
    viewBox="0 0 510 510"
    {...props}>
    <g>
      <g id="add-circle-outline">
        <path
          d="M280.5,127.5h-51v102h-102v51h102v102h51v-102h102v-51h-102V127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255
			s255-114.75,255-255S395.25,0,255,0z M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)

export default PlusCircle
