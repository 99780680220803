import React from 'react'
import 'components/FloatingActions/FloatingActions.scss'
import { FontsList, sansSerifFonts, serifFonts, specialFonts } from 'setup/setupFonts'
import PropTypes from 'prop-types'

import Select from 'react-select'

const groupedOptions = [
  {
    label: 'Sans serif',
    options: sansSerifFonts
  },
  {
    label: 'Serif',
    options: serifFonts
  },
  {
    label: 'Special',
    options: specialFonts
  }
]

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}
const groupBadgeStyles = {
  backgroundColor: '#dedede',
  borderRadius: '50%',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 10,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center'
}
const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

/** An Select used on Customize page to select the main font for the user's wall */
const FontSelector = ({ font, updateFont }) => {
  const fontSlug = font.split(':').length === 2 ? font.split(':')[0] : font
  const selectedFont = FontsList.find(_font => _font.slug === fontSlug)

  const update = font => {
    updateFont(font !== null ? font.slug : '')
  }

  const fontIcon = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      content: "' '",
      display: 'block',
      width: 28
    }
  })

  const styles = {
    container: styles => ({ ...styles, height: 30, minHeight: 30 }),

    control: styles => ({
      ...styles,
      height: 30,
      minHeight: 30,
      borderColor: 'transparent!important',
      boxShadow: '0 3px 7px 0 rgba(0, 0, 0, 0.15)',
      fontSize: 12,
      letterSpacing: 0.5
    }),
    indicatorsContainer: styles => ({ ...styles, height: 30 }),
    indicatorSeparator: styles => ({ ...styles, backgroundColor: 'transparent' }),
    dropdownIndicator: styles => ({
      ...styles,
      ':hover': { color: '#ccc!important' }
    }),
    input: styles => ({ ...styles, ...fontIcon() }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isSelected ? '#7617ce!important' : '#fff!important',
      cursor: 'pointer',
      fontSize: 12,
      fontFamily: state.data.name,
      letterSpacing: 0.5,
      color: state.isSelected ? '#fff' : '#000',
      transition: 'all .2s ease',
      ':hover': {
        color: state.isSelected ? '#fff!important' : '#333!important',
        backgroundColor: state.isSelected ? '#7617ce!important' : '#dedede!important'
      }
    }),
    placeholder: styles => ({
      ...styles,
      color: '#333',
      ...fontIcon()
    }),
    singleValue: (styles, state) => ({
      ...styles,
      fontFamily: state.data.name,
      ...fontIcon()
    }),
    valueContainer: styles => ({ ...styles, height: 30, minHeight: 30 })
  }

  return (
    <Select
      classNamePrefix="react-select"
      isClearable={false}
      isSearchable={false}
      menuPortalTarget={document.body}
      onChange={font => update(font)}
      getOptionValue={option => option['slug']}
      getOptionLabel={option => option['name']}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      placeholder="font family"
      styles={styles}
      value={selectedFont}
    />
  )
}

FontSelector.propTypes = {
  font: PropTypes.string.isRequired,
  updateFont: PropTypes.func.isRequired
}

export default FontSelector
