import {
  REQUEST_BANWORDS,
  ADD_BANWORDS,
  ADD_BANWORD,
  REMOVE_BANWORD
} from './currentWallReducerActionTypes'

export default (banwords = {}, action) => {
  switch (action.type) {
    case REQUEST_BANWORDS:
      return {
        ...banwords
      }
    case ADD_BANWORDS:
      return {
        items: [...action.payload]
      }

    case ADD_BANWORD:
      return {
        ...banwords,
        items: [...banwords.items, action.payload]
      }

    case REMOVE_BANWORD:
      return {
        ...banwords,
        items: [...banwords.items.filter(item => item.id !== action.payload)]
      }

    default:
      return banwords
  }
}
