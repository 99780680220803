import _isString from 'lodash/isString'
import { getColorFromString } from 'setup/utils'
import { LOGO_PLACEHOLDER_URL } from 'setup/constants'
export const PIC_POST_ONLY_1 = 'PicturePostOnly1'
export const PIC_POST_ONLY_2 = 'PicturePostOnly2'
export const MOSAIC_1 = 'Mosaic1'
export const MASONRY_1 = 'Masonry1'
export const TEXT_POST_ONLY_1 = 'TextPostOnly1'
export const TEXT_PIC_1 = 'TextPic1'
export const TEXT_PIC_2 = 'TextPic2'
export const IMAGE_1 = 'Image1'
export const MULTIPLES_IMAGES_1 = 'MultipleImages1'
export const VIDEO_1 = 'Video1'
export const CTA_1 = 'CallToAction1'

export const isValidScreen = (
  screen,
  cards = [],
  shouldVerifyEnable = true,
  giveMeReason = false
) => {
  let reasons = []
  let screenValidity = null

  reasons.push(
    validScreenMinText(
      screen.text_card_nbr,
      cards.filter(c => c.approved && c.media_file_url === null).length
    )
  )
  reasons.push(
    validScreenMinImage(
      screen.image_card_nbr,
      cards.filter(c => c.approved && c.media_file_url !== null).length
    )
  )
  reasons.push(validScreenMinUnspecified(screen.unspecified_card_nbr, cards.length))
  // reasons.push(
  //   screen.bg !== null && currentOption.hasBackgroundImage
  //     ? true
  //     : "does't have a background image"
  // )
  if (screen.screen_type === IMAGE_1) {
    reasons.push(validScreenbackgroundImage(getBackgroundImageStyle(screen.styles)))
  }

  if (screen.screen_type === MULTIPLES_IMAGES_1) {
    reasons.push(validScreenbackgroundImage(getBackgroundImageStyle(screen.styles)))
  }

  if (screen.screen_type === VIDEO_1) {
    reasons.push(validScreenbackgroundVideo(getBackgroundVideoStyle(screen.styles)))
  }

  shouldVerifyEnable && reasons.push(screen.enabled ? true : 'not enable')

  if (giveMeReason) {
    screenValidity = {
      type: screen.screen_type,
      name: screen.name,
      id: screen.id,
      valid:
        reasons.filter(reason => _isString(reason)).length === 0
          ? true
          : reasons.filter(reason => _isString(reason))
    }
  } else {
    screenValidity = !(reasons.filter(reason => _isString(reason)).length > 0)
  }
  return screenValidity
}

const validScreenMinText = (a, b) => {
  return a <= b ? true : `approve at least ${a - b} text only contents`
}

const validScreenMinImage = (a, b) => {
  return a <= b ? true : `approve at least ${a - b} image only contents`
}

const validScreenMinUnspecified = (a, b) => {
  return a <= b ? true : `approve at least ${a - b} contents`
}

const validScreenbackgroundImage = hasImageBg => {
  return hasImageBg ? true : `upload an image`
}

const validScreenbackgroundVideo = hasVideoBg => {
  return hasVideoBg ? true : `upload a video`
}

const getStyle = (styles, name) => {
  if (styles !== undefined && styles.length > 0) {
    let value =
      styles !== null
        ? styles.find(s => s.style_name === name) !== undefined
          ? styles.find(s => s.style_name === name).value
          : null
        : null
    if (value !== undefined && value !== null) {
      return value
    } else {
      return false
    }
  }
}

export const getLogoStyle = styles => {
  return getStyle(styles, 'logo') === '' ? LOGO_PLACEHOLDER_URL : getStyle(styles, 'logo')
}

export const getBackgroundColorStyle = styles => {
  return getColorFromString(getStyle(styles, 'background_color'))
}

export const getBackgroundImageStyle = styles => {
  return getStyle(styles, 'background_image')
}

export const getBackgroundVideoStyle = styles => {
  return getStyle(styles, 'background_video')
}

export const getFontFamily1Style = (styles, type) => {
  if (styles !== undefined && styles.length > 0) {
    let value =
      styles !== null ? styles.find(s => s.style_name === 'font_family_1').value : null
    if (value !== undefined && value !== null) {
      value = value.split('+').join(' ')
      return type === 'weight'
        ? value.split(':')[1]
        : type === 'family'
        ? value.split(':')[0]
        : value
    } else {
      return false
    }
  }
}

export const getDisplayedHashtagStyle = styles => {
  return getStyle(styles, 'displayed_hashtag')
}

export const getHandleColorStyle = styles => {
  return getColorFromString(getStyle(styles, 'handle_color'))
}

export const getHashtagColorStyle = styles => {
  return getColorFromString(getStyle(styles, 'hashtag_color'))
}

export const getTextColorStyle = styles => {
  return getColorFromString(getStyle(styles, 'text_color'))
}

export const getCardsBackgroundColorStyle = styles => {
  return getColorFromString(getStyle(styles, 'cards_background_color'))
}

export const getTopBarBackgroundColorStyle = styles => {
  return getColorFromString(getStyle(styles, 'top_bar_background_color'))
}

export const getTopBarEnableStyle = styles => {
  return getStyle(styles, 'top_bar_enable') === 'true'
}

export const getHashtagEnableStyle = styles => {
  return getStyle(styles, 'hashtag_enable') === 'true'
}

export const getLogoEnableStyle = styles => {
  return getStyle(styles, 'logo_enable') === 'true'
}

export const getText1Style = styles => {
  return getStyle(styles, 'text_1')
}

export const getText2Style = styles => {
  return getStyle(styles, 'text_2')
}
