import 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Container from 'components/Container/Container'
import UserService from 'services/api/UserService'
import ActivationInfo from './ActivationInfo/ActivationInfo'
import './PayPage.scss'
import PaymentMethod from './PaymentMethod/PaymentMethod'
import Payment from 'payment'
import PlaceOrder from './PlaceOrder/PlaceOrder'
import WallActivated from './WallActivated/WallActivated'
import {
  updatePayFormAction,
  updatePayFormFullAddressAction
} from 'store/payFormReducer/payFormReducer'
import GeolocatorService from 'services/app/geolocatorService'
import Button from 'components/Button/Button'
import CartIcon from 'assets/icons/CartIcon'
import SuccessCheckmark from 'assets/icons/SuccessCheckmark'
import { getFieldIsEmptyError, fieldsAreEmpty, fieldsHaveError } from 'setup/formUtils'
import { userIsCanadian } from 'setup/utils'

const STEPS = ['ACTIVATION_INFO', 'PAYMENT_METHOD', 'PLACE_ORDER']

const getPaymentFields = ({
  card_name,
  cardExpiry,
  card_security,
  address_email,
  card_number,
  address_firstname,
  address_lastname,
  address_addr1,
  address_zip,
  address_city,
  address_province,
  address_country
}) => {
  const fields = {
    card_name,
    cardExpiry,
    card_security,
    card_number,
    address_email
  }
  return userIsCanadian()
    ? {
        ...fields,
        address_firstname,
        address_lastname,
        address_addr1,
        address_zip,
        address_city,
        address_province,
        address_country
      }
    : fields
}

const isPaymentFormValid = payForm => {
  const fields = getPaymentFields(payForm)
  const { card_number, cardExpiry, card_security } = payForm
  return (
    Payment.fns.validateCardNumber(card_number.value) &&
    Payment.fns.validateCardExpiry(cardExpiry.value) &&
    Payment.fns.validateCardCVC(
      card_security.value,
      Payment.fns.cardType(card_number.value)
    ) &&
    !fieldsAreEmpty(fields) &&
    !fieldsHaveError(fields)
  )
}

const PayPage = ({
  dailyPrice,
  payForm,
  updatePayFormAction,
  updatePayFormFullAddressAction,
  wallActivated
}) => {
  const [currentStep, setCurrentStep] = useState(STEPS[0])

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      const [{ address }, _address_country] = await Promise.all([
        UserService.get(),
        GeolocatorService.locate()
      ])
      if (!didCancel) {
        updatePayFormFullAddressAction({
          ...address,
          address_country: {
            ...payForm.address_country,
            value: _address_country
          }
        })
      }
    }

    if (payForm.address_country.value === '') {
      fetchData()
    }
    return () => {
      didCancel = true
    }
  }, [payForm, payForm.address_country, updatePayFormFullAddressAction])

  const setStep = nextStep => {
    if (nextStep === 'PLACE_ORDER' && currentStep === 'PAYMENT_METHOD') {
      validateFields()
      if (!isPaymentFormValid(payForm)) {
        return
      }
      setCurrentStep(nextStep)
    } else if (nextStep === 'PLACE_ORDER' && !isPaymentFormValid(payForm)) {
      return
    }
    setCurrentStep(nextStep)
    if (currentStep === 'PAYMENT_METHOD') {
      updateAddress()
    }
  }

  const updateAddress = () => {
    const address = {
      addr1: payForm.address_addr1.value,
      addr2: payForm.address_addr2.value,
      city: payForm.address_city.value,
      company: payForm.address_company.value,
      country: payForm.address_country.value,
      email: payForm.address_email.value,
      firstname: payForm.address_firstname.value,
      lastname: payForm.address_lastname.value,
      province: payForm.address_province.value,
      zip: payForm.address_zip.value
    }
    const addressId = payForm.address_id.value
    UserService.updateAddressInfo(addressId, address)
  }

  const validateFields = () => {
    const fields = getPaymentFields(payForm)
    const updatedFields = Object.keys(fields)
      .map(key => {
        return {
          [key]: {
            ...fields[key],
            errorMessage: getFieldIsEmptyError(fields[key].value),
            dirty: true
          }
        }
      })
      .reduce((prev, acc) => {
        return {
          ...prev,
          ...acc
        }
      }, {})

    updatePayFormAction({
      ...updatedFields
    })
  }

  return (
    <Container className="PayPage">
      {!wallActivated && (
        <>
          <div className="PaySteps">
            <div className="PayPageHeader">
              <div className="PayPageHeader-cart-container">
                <CartIcon className={`CartIcon step_${currentStep}`} />
              </div>
              <div className="PayPageHeader-step-name">
                <div
                  className={`step_ACTIVATION_INFO ${
                    currentStep !== STEPS[0] ? 'disabled' : ''
                  }`}
                  onClick={() => setStep(STEPS[0])}>
                  activation info
                  {(currentStep === STEPS[1] || currentStep === STEPS[2]) && (
                    <SuccessCheckmark />
                  )}
                </div>
                <div
                  className={`step_PAYMENT_METHOD ${
                    currentStep !== STEPS[1] ? 'disabled' : ''
                  }`}
                  onClick={
                    currentStep !== 'PAYMENT_METHOD' ? () => setStep(STEPS[1]) : null
                  }>
                  payment method
                  {currentStep === STEPS[2] && <SuccessCheckmark />}
                </div>
                <div
                  className={`step_PLACE_ORDER ${
                    currentStep !== STEPS[2] ? 'disabled' : ''
                  }`}
                  onClick={() => setStep(STEPS[2])}>
                  place order
                </div>
              </div>
            </div>
            {currentStep === 'ACTIVATION_INFO' && (
              <>
                {dailyPrice !== undefined ? (
                  <>
                    <ActivationInfo />
                    <Button className="NextStep" onClick={() => setStep(STEPS[1])}>
                      go to next step
                    </Button>
                  </>
                ) : null}
              </>
            )}
            {currentStep === 'PAYMENT_METHOD' && (
              <>
                <PaymentMethod />
                <Button className="NextStep" onClick={() => setStep(STEPS[2])}>
                  go to next step
                </Button>
              </>
            )}
            {currentStep === 'PLACE_ORDER' && <PlaceOrder />}
          </div>
        </>
      )}
      {wallActivated && <WallActivated />}
    </Container>
  )
}

export default connect(
  state => ({
    dailyPrice: state.currentWall.plan.daily,
    payForm: state.payForm,
    wallActivated: state.currentWall.live
  }),
  {
    updatePayFormAction,
    updatePayFormFullAddressAction
  }
)(PayPage)
