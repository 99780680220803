const CLOSE_MODAL = 'CLOSE_MODAL'
const OPEN_DELETE_KEYWORD_MODAL = 'OPEN_DELETE_KEYWORD_MODAL'
const OPEN_DELETE_LAYOUT_MODAL = 'OPEN_DELETE_LAYOUT_MODAL'
const OPEN_DELETE_WALL_MODAL = 'OPEN_DELETE_WALL_MODAL'
const OPEN_MODAL = 'OPEN_MODAL'
const OPEN_PAY_MODAL = 'OPEN_PAY_MODAL'
const OPEN_UPGRADE_PLAN_MODAL = 'OPEN_UPGRADE_PLAN_MODAL'
const OPEN_ZOOM_MODAL = 'OPEN_ZOOM_MODAL'
const OPEN_ZOOM_MODERATION_MODAL = 'OPEN_ZOOM_MODERATION_MODAL'

export const closeModalAction = () => ({
  type: CLOSE_MODAL
})

export const openDeleteKeywordModalAction = payload => ({
  type: OPEN_DELETE_KEYWORD_MODAL,
  payload
})

export const openDeleteLayoutModalAction = payload => ({
  type: OPEN_DELETE_LAYOUT_MODAL,
  payload
})

export const openDeleteWallModalAction = payload => ({
  type: OPEN_DELETE_WALL_MODAL,
  payload
})

export const openModalAction = payload => ({
  type: OPEN_MODAL,
  payload
})

export const openPayModalAction = payload => ({
  type: OPEN_PAY_MODAL,
  payload
})

export const openUpgradePlanModalAction = payload => ({
  type: OPEN_UPGRADE_PLAN_MODAL,
  payload
})

export const zoomModalAction = payload => ({
  type: OPEN_ZOOM_MODAL,
  payload
})

export const zoomModerationModalAction = payload => ({
  type: OPEN_ZOOM_MODERATION_MODAL,
  payload
})

const initState = {
  applyTheme: null,
  cardId: null,
  createLayout: null,
  isOpen: false,
  keyword: {},
  layoutToDelete: null,
  modalName: null,
  payData: {},
  upgradeToPlan: null,
  wallToDelete: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return {
        ...state,
        applyTheme: null,
        createLayout: null,
        isOpen: false,
        keyword: {},
        layoutToDelete: null,
        modalName: null,
        upgradeToPlan: null,
        wallToDelete: null
      }

    case OPEN_DELETE_KEYWORD_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: 'DELETE_KEYWORD',
        keyword: action.payload
      }

    case OPEN_DELETE_LAYOUT_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: 'DELETE_LAYOUT',
        layoutSelected: action.payload
      }

    case OPEN_DELETE_WALL_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: 'DELETE_WALL',
        wallToDelete: action.payload
      }

    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: action.payload
      }

    case OPEN_PAY_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: 'PAY_MODAL',
        payData: action.payload
      }

    case OPEN_UPGRADE_PLAN_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: 'UPGRADE_PLAN',
        upgradeToPlan: (action.payload && action.payload.upgradeToPlan) || null,
        applyTheme: (action.payload && action.payload.applyTheme) || null,
        createLayout: (action.payload && action.payload.createLayout) || null
      }

    case OPEN_ZOOM_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: 'ZOOM_MODAL',
        layoutSelected: action.payload
      }

    case OPEN_ZOOM_MODERATION_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: 'ZOOM_MODERATION_MODAL',
        cardId: action.payload
      }

    default:
      return state
  }
}
