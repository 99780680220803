import React from 'react'
import { connect } from 'react-redux'
import FloatingActions from 'components/FloatingActions/FloatingActions'
import FloatingButton from 'components/FloatingActions/FloatingButton'
import Colors from 'setup/colors.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openModalAction } from 'store/modal/modalReducer'
import {
  isModerationPage,
  isSetupPage,
  isCustomizePage
} from 'routes/routesUtils'
import classNames from 'classnames'
import PropTypes from 'prop-types'

/**
 * A Container component used on every page of wallrus except Login/Signup, Dashboard and Fullscreen page
 */
const Container = ({
  children,
  className,
  innerClassName,
  openModalAction,
  type = ''
}) => (
  <div
    className={`${className} main-container${type === 'large' ? '-large' : ''}`.trim()}>
    {(isSetupPage() || isCustomizePage()) && (
      <FloatingActions>
        <FloatingButton onClick={() => openModalAction('HELP_SETUP')} label="info">
          <FontAwesomeIcon icon={'question'} style={{ color: Colors.brand }} />
        </FloatingButton>
      </FloatingActions>
    )}
    <div className={classNames('main-container-inner', innerClassName)}>{children}</div>
  </div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  innerClassName: PropTypes.string,
  /** Can be set to `large` */
  type: PropTypes.string
}

export default connect(
  state => ({}),
  { openModalAction }
)(Container)
