// EVENT FOR CURRENT WALL
export const FETCH_CURRENT_WALL = 'FETCH_CURRENT_WALL'
export const RESET_CURRENT_WALL = 'RESET_CURRENT_WALL'
export const SET_CURRENT_WALL = 'SET_CURRENT_WALL'
export const UPDATE_CURRENT_WALL = 'UPDATE_CURRENT_WALL'

export const ADD_SCREEN = 'ADD_SCREEN'
export const DELETE_SCREEN = 'DELETE_SCREEN'

export const APPLY_THEME = 'APPLY_THEME'

// ACTIONS FOR KEYWORDS
export const ADD_KEYWORDS = 'ADD_KEYWORDS'
export const ADD_KEYWORD = 'ADD_KEYWORD'
export const REMOVE_KEYWORD = 'REMOVE_KEYWORD'

// ACTIONS FOR BANWORDS
export const REQUEST_BANWORDS = 'REQUEST_BANWORDS'
export const ADD_BANWORDS = 'ADD_BANWORDS'
export const ADD_BANWORD = 'ADD_BANWORD'
export const REMOVE_BANWORD = 'REMOVE_BANWORD'

// ACTIONS FOR CARDS
export const ADD_CARDS = 'ADD_CARDS'
export const DELETE_CARD = 'DELETE_CARD'
export const APPROVE_ALL_CARDS = 'APPROVE_ALL_CARDS'
export const FILTER_RETWEET_CARDS = 'FILTER_RETWEET_CARDS'
export const DELETE_ALL_CARDS = 'DELETE_ALL_CARDS'
export const SET_LATEST_CARD_TO_ARRIVE = 'SET_LATEST_CARD_TO_ARRIVE'
export const UPDATE_CARD = 'UPDATE_CARD'
export const RESET_IMAGE_CARD = 'RESET_IMAGE_CARD'
export const RESET_TEXT_CARD = 'RESET_TEXT_CARD'

export const UPDATE_SCREEN = 'UPDATE_SCREEN'

export const UPDATE_STYLE = 'UPDATE_STYLE'
