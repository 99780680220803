import {
  ADD_CARDS,
  DELETE_CARD,
  APPROVE_ALL_CARDS,
  FILTER_RETWEET_CARDS,
  DELETE_ALL_CARDS,
  SET_LATEST_CARD_TO_ARRIVE,
  RESET_IMAGE_CARD,
  RESET_TEXT_CARD
} from '../currentWallReducerActionTypes'
import CardService from 'services/api/CardService'

export const remove = (wallId, cardId) => async dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      await CardService.destroy(wallId, cardId)
      dispatch(deleteCardAction(cardId))
    } catch (error) {
      console.log(error)
    } finally {
      if (cardId) {
        resolve(cardId)
      } else {
        reject()
      }
    }
  })
}

export const fetchAll = (wallId, approved) => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let data = {}
    let cards = []
    try {
      data = await CardService.getAll(wallId, approved)
      dispatch(setLatestCardToArrive(data.max_id))
      if (data.cards?.length) {
        dispatch(addCardsAction(data.cards))
      }
    } catch (error) {
      console.log(error)
    } finally {
      resolve(cards)
    }
  })
}

export const deleteAll = wallId => async dispatch => {
  try {
    dispatch(deleteAllCards())
    CardService.deleteAll(wallId)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

const addCardsAction = cards => ({
  type: ADD_CARDS,
  payload: cards
})

const setLatestCardToArrive = id => ({
  type: SET_LATEST_CARD_TO_ARRIVE,
  payload: id
})

export const approveAllCardsAction = () => ({ type: APPROVE_ALL_CARDS })

export const filterOutRetweetedCards = () => ({ type: FILTER_RETWEET_CARDS })

export const deleteAllCards = () => ({ type: DELETE_ALL_CARDS })

export const deleteCardAction = id => ({
  type: DELETE_CARD,
  payload: id
})

export const resetImageCardsAction = () => ({ type: RESET_IMAGE_CARD })

export const resetTextCardsAction = () => ({ type: RESET_TEXT_CARD })
