import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setSidebarActiveRouteId } from 'store/sidebar/sidebarReducer'
import Colors from 'setup/colors.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ErrorCheckmark from 'assets/icons/ErrorCheckmark'
import { closeModalAction } from 'store/modal/modalReducer'
import classNames from 'classnames'

const SidebarPanel = ({
  activeRouteId,
  closeModalAction,
  errorMessages,
  hasError,
  history,
  index,
  isActivePanel,
  panel: { items: pages, name },
  setSidebarActiveRouteId,
  styles,
  wallId
}) => {
  const navigate = item => {
    if (activeRouteId !== item.id) {
      closeModalAction()
      setSidebarActiveRouteId(item.id)
      history.push(`/wall/${wallId}/${item.route}`)
    }
  }

  const displayErrorMessage = item => {
    const error = errorMessages[item.name.toLowerCase()]
    return error !== '' ? error : null
  }

  return (
    <div
      className={classNames('SidebarPanel', {
        selected: isActivePanel,
        'no-collapse': pages.length === 1
      })}>
      <div
        className="SidebarPanel-Header"
        onClick={() => pages.length > 0 && navigate(pages[0])}>
        {hasError ? (
          <div className="SidebarPanel-error">
            <ErrorCheckmark style={{ color: Colors.danger, width: 23, height: 23 }} />
          </div>
        ) : (
          <div className="SidebarPanel-position">
            <FontAwesomeIcon
              icon={['far', 'circle']}
              style={{ color: Colors.info, width: 23, height: 23 }}
            />
            <span>{index}</span>
          </div>
        )}
        <div className="SidebarPanel-name">{name}</div>
        <FontAwesomeIcon
          icon={['fas', 'chevron-down']}
          style={{ height: 14, width: 14, color: '#fff' }}
        />
      </div>

      <div className="SidebarPanelChildren" style={styles}>
        {pages.map(item => (
          <div className="SidebarItem" key={item.id}>
            <div
              onClick={() => navigate(item)}
              className={activeRouteId === item.id ? 'selected' : null}>
              <span className="Icon">{item.icon}</span>
              <div className="ItemName">
                <div className="ItemName-Content">{item.name}</div>
                <div className="SidebarItem-Message">{displayErrorMessage(item)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default withRouter(
  connect(
    state => ({
      activeRouteId: state.sidebar.activeRouteId,
      wallId: state.currentWall.id
    }),
    {
      closeModalAction,
      setSidebarActiveRouteId
    }
  )(SidebarPanel)
)
