import UserService from 'services/api/UserService.js'
import request from 'services/requestWrapper.js'

function getAll() {
  return new Promise((resolve, reject) => {
    UserService.get().then(resp => resolve(resp.children))
  })
}

/**
 * Returns a promise
 * @param {String} username The username of the sub-user to be added
 * @param {String} password The password of the sub-user
 * @param {String} confirmation The confirmation of the sub-user
 */

function create({ username, password, confirmation }) {
  const data = {
    password: password,
    password_confirmation: confirmation,
    username: username
  }

  return request({
    url: `/users/create_subuser`,
    method: 'POST',
    data
  })
}

function destroy(userId) {
  return request({
    url: `/users/destroy_subuser/${userId}`,
    method: 'DELETE'
  })
}

function authorize(subuser_id, event_id) {
  return request({
    url: `/users/${subuser_id}/${event_id}`,
    method: 'POST'
  })
}

function unauthorize(subuser_id, event_id) {
  return request({
    url: `/users/${subuser_id}/${event_id}`,
    method: 'DELETE'
  })
}

const SubUsersService = { getAll, create, destroy, authorize, unauthorize }
export default SubUsersService
