import React from 'react'

const MinusCircle = props => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="510px"
    height="510px"
    viewBox="0 0 510 510"
    {...props}>
    <g>
      <g id="unknown-4">
        <path
          d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459
			c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z M153,280.5h204v-51H153V280.5z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)

export default MinusCircle
