import React, { useEffect, useRef, useState } from 'react'
import UpgradeButton from 'components/UpgradeButton/UpgradeButton'
import './SetupPage.scss'
import { connect } from 'react-redux'
import Input from 'components/Input/Input'
import { doFetchAllKeywordsAction, doUpdateWall } from 'store/currentWall/actions/index'
import Container from 'components/Container/Container'

import SetupPageTwitter from './SetupPageTwitter'
import SetupPageInstagram from './SetupPageInstagram'
import { CLOUDFRONT_ASSETS_URL } from 'setup/constants'
import _debounce from 'lodash/debounce'

const SetupPage = ({
  doFetchAllKeywordsAction,
  doUpdateWall,
  keywords,
  planType,
  wallId,
  wallName
}) => {
  const [saving, setSaving] = useState(false)
  const [tmpWallName, setTmpWallName] = useState('')

  const saveTimeout = useRef(null)

  useEffect(() => {
    if (tmpWallName === '' && wallName !== undefined) {
      setTmpWallName(wallName)
    }
  }, [tmpWallName, wallName])

  useEffect(() => {
    if (keywords.length === 0) {
      doFetchAllKeywordsAction(wallId)
    }
    return () => clearTimeout(saveTimeout.current)
  }, [keywords.length, doFetchAllKeywordsAction, wallId])

  const saveText = useRef(
    _debounce(async _text => {
      if (_text.length > 0) {
        setSaving(true)
        await doUpdateWall(wallId, { name: _text })

        saveTimeout.current = setTimeout(() => {
          setSaving(false)
        }, 500)
      } else {
        setTmpWallName(_text)
      }
    }, 500)
  )

  const handleWallNameOnChange = e => {
    setSaving(false)
    setTmpWallName(e.target.value)
    saveText.current(e.target.value)
  }

  return (
    <Container className="SetupPage" innerClassName="SetupPageInner">
      <div className="SetupPageHeader">
        {tmpWallName !== '' ? (
          <div className="Section">
            <div className="Title small">wall name</div>
            <Input
              className="simple"
              onChange={handleWallNameOnChange}
              dirty={saving}
              value={tmpWallName}
            />
          </div>
        ) : null}
        {planType !== undefined ? (
          <div className="Section">
            <div className="Upgrade">
              <div className="Title small">plan type</div>
              <UpgradeButton />
            </div>
            <div className="Upgrade">
              <label>{planType}</label>
            </div>
          </div>
        ) : null}
      </div>
      <div className="SetupPageContainer">
        <SetupPageTwitter wallId={+wallId} />
        <SetupPageInstagram wallId={+wallId} />

        <div className="page-legend">
          <p>
            In order to receive content from Instagram, your Facebook account needs to
            have administrative rights to a Facebook business page linked to your
            Instagram account. To learn how to do this,{' '}
            <a
              href={`${CLOUDFRONT_ASSETS_URL}assets/documents/facebook-connect.pdf`}
              rel="noopener noreferrer"
              target="_blank">
              click here
            </a>
            . It really only takes a minute.
          </p>
        </div>
      </div>

      {/* <div className="SetupPanel">
          <div className="SetupPanelWrapper">
            <ChipsContainer
              title="Get content from this page"
              placeholder="page url"
              type="facebook"
              wallId={wallId}
              onAdd={doCreateKeywordAction}
              onDelete={doRemoveKeywordAction}
              onExtraDelete={doRemoveKeywordAndContentAction}
              showModal={true}
              items={instagramKeywords}
            />
            <div className="Logo">
              <FontAwesomeIcon
                icon={['fab', 'facebook']}
                style={{ width: 40, height: 40, color: '#4267b2' }}
              />
            </div>
            <div className="ChipsOthers">
              <p>
                You can only get the content from a Facebook page you administor. The
                linked Facebook account must be admin of that specific page
              </p>
            </div>
          </div>
          <Linker
            type="instagram"
            status={this.props.user.instagram.status}
            unlink={this.unlinkInstagram}
            user={this.props.user.instagram}
          />
        </div> */}
    </Container>
  )
}

export default connect(
  state => ({
    planType: state.currentWall.plan.name,
    wallName: state.currentWall.name,
    keywords: state.currentWall.keywords
  }),
  {
    doFetchAllKeywordsAction,
    doUpdateWall
  }
)(SetupPage)
