import React from 'react'
import { connect } from 'react-redux'

import './CTAScreen.scss'
import Social from 'components/Social/Social'
import Logo from 'components/Logo/Logo'
import {
  getBackgroundColorStyle,
  getBackgroundImageStyle,
  getTextColorStyle,
  getText1Style,
  getText2Style,
  getLogoEnableStyle,
  getLogoStyle
} from 'services/app/screen'

const CTAScreen = ({
  animatedTheme = false,
  forceBackground = false,
  screen: { styles: screenStyles },
  styles
}) => {
  const currentStyles =
    animatedTheme && !forceBackground
      ? { color: getTextColorStyle(screenStyles) }
      : {
          backgroundColor: getBackgroundColorStyle(screenStyles),
          backgroundImage: `url('${getBackgroundImageStyle(screenStyles)}')`,
          color: getTextColorStyle(screenStyles)
        }
  return (
    <div className="CTAScreen" style={currentStyles}>
      <Logo enabled={getLogoEnableStyle(screenStyles) && getLogoStyle(styles)} />

      <div className="content">
        <p>{getText1Style(screenStyles)}</p>
        <Social screenStyles={screenStyles} />
        <p>{getText2Style(screenStyles)}</p>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    animatedTheme: state.currentWall.theme && state.currentWall.theme.animated,
    styles: state.currentWall.styles
  }),
  {}
)(CTAScreen)
