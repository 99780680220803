import React from 'react'
import 'components/Loader/Loader.scss'
import { connect } from 'react-redux'

const Loader = props => {
  return props.isLoading ? <div className="Loader">Loading...</div> : null
}

export default connect(state => ({
  isLoading: state.isLoading
}))(Loader)
