import React from 'react'
import Colors from 'setup/colors.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const style = {
  width: 20,
  height: 20,
  color: Colors.info
}
const SuccessCheckmark = ({ className = '', ...props }) => {
  return (
    <FontAwesomeIcon
      className={`SuccessCheckmark ${className ? className : ''}`.trim()}
      icon={['far', 'check-circle']}
      style={style}
      {...props}
    />
  )
}

export default SuccessCheckmark
