import React from 'react'
import {
  LAYOUT_PATH,
  THEME_PATH,
  CUSTOMIZE_PATH,
  SETUP_PATH,
  PLAYLIST_PATH,
  MODERATION_PATH,
  STATS_PATH,
  PAY_PATH,
  PREVIEW_PATH,
  EMBED_PATH
} from 'routes/routePaths'
import { isSubuser } from './utils'

export const SIDEBAR = [
  {
    name: 'Appearance',
    items: [
      {
        id: 1,
        name: 'Layout',
        route: LAYOUT_PATH,
        subuser: false,
        icon: (
          <svg viewBox="-94 4.8 24.7 16.2">
            <path d="M-71.1 4.8h-21.1c-1 0-1.8.8-1.8 1.8v12.6c0 1 .8 1.8 1.8 1.8h21.1c1 0 1.8-.8 1.8-1.8V6.6c0-1-.8-1.8-1.8-1.8zM-78.8 20h-13.4c-.4 0-.8-.4-.8-.8V6.6c0-.4.4-.8.8-.8h13.4V20zm8.5-.8c0 .4-.4.8-.8.8h-6.7v-4.4h7.5v3.6zm0-4.6h-7.5v-4h7.5v4zm0-5h-7.5V5.8h6.7c.4 0 .8.4.8.8v3z" />
          </svg>
        ),
        minPlan: 1
      },
      {
        id: 2,
        name: 'Theme',
        route: THEME_PATH,
        subuser: false,
        icon: (
          <svg viewBox="-96 -4 22.4 24">
            <path d="M-75.3 8.5L-77 9.6l-.9.6-5.7 3.7c-.7.4-1.8.4-2.4 0l-5.7-3.7-.9-.6-1.7-1.1c-.7-.4-.7-1.1 0-1.6l1.4-.9 2.9 1.9.9.6 2.2 1.4c.6.4 1.3.6 2.1.6s1.5-.2 2.1-.6l2.2-1.4.9-.6 2.9-1.9 1.4.9c.6.5.6 1.2 0 1.6zm0 3.4c.7.4.7 1.1 0 1.6l-8.3 5.4c-.7.4-1.8.4-2.4 0l-8.3-5.4c-.7-.4-.7-1.1 0-1.6l1.7-1.1 5.7 3.7c.6.4 1.3.6 2.1.6s1.5-.2 2.1-.6l5.7-3.7 1.7 1.1zm-19-7.9c-.7-.4-.7-1.1 0-1.6L-86-3c.3-.2.8-.3 1.2-.3.5 0 .9.1 1.2.3l8.3 5.4c.7.4.7 1.1 0 1.6l-1.4.9-.9.6-2.9 1.9-.9.6-2.2 1.4c-.7.4-1.8.4-2.4 0L-88.2 8l-.9-.6-2.9-1.9-.9-.6-1.4-.9zm19.8 2.4l-1.4-.9 1.4-.9c1.1-.7 1.1-1.9 0-2.7l-8.3-5.4c-1.1-.7-3-.7-4.1 0l-8.3 5.4c-1.1.7-1.1 1.9 0 2.7l1.4.9-1.4.9c-1.1.7-1.1 1.9 0 2.7l1.7 1.1-1.7 1.1c-1.1.7-1.1 1.9 0 2.7l8.3 5.4c.6.4 1.3.6 2.1.6s1.5-.2 2.1-.6l8.3-5.4c1.1-.7 1.1-1.9 0-2.7l-1.7-1.1 1.7-1.1c1.1-.8 1.1-2-.1-2.7z" />
          </svg>
        ),
        minPlan: 1
      },
      {
        id: 3,
        name: 'Customize',
        route: CUSTOMIZE_PATH,
        subuser: false,
        icon: (
          <svg viewBox="-96 -2 21.9 22">
            <path d="M-77.6 2c-.2 0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4m-15 0c-.2 0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4m17.7 7.2h-.9v-.8h.9v.8zm.5-1.7h-.6c-.2-1.2-.6-2.3-1.2-3.3-.1-.2-.4-.3-.6-.2s-.3.4-.2.6c.5.9.9 1.9 1.1 2.9h-.4c-.2 0-.4.2-.4.4v1.6c0 .2.2.4.4.4h1.8c.2 0 .4-.2.4-.4V8c.1-.3-.1-.5-.3-.5zm-8.9 7.1h-4.3l-1.3-3.8h1c.2 0 .4-.2.4-.4s-.2-.4-.4-.4h-.9l3-5.8v5.1c-.5.2-.8.7-.8 1.2 0 .7.6 1.3 1.2 1.3.7 0 1.2-.6 1.2-1.3 0-.6-.3-1.1-.8-1.2V4.1l3 5.8h-1c-.2 0-.4.2-.4.4s.2.4.4.4h1c0 .2-1.3 3.9-1.3 3.9zm.5 1.6c-.1.1-.2.1-.3.1H-88c-.2 0-.4-.2-.4-.4 0-.1 0-.2.1-.3s.2-.1.3-.1h4.9c.2 0 .4.2.4.4.1.2 0 .3-.1.3zm-4.7 2.9h4.1v-1.9h-4.1v1.9zm2-9.1c.2 0 .4.2.4.4s-.2.4-.4.4-.4-.2-.4-.4.2-.4.4-.4zm4.5.4v-.1l-4.1-8c-.1-.2-.2-.3-.4-.3s-.3.1-.4.2l-4.1 8v.5l1.5 4.2c-.1.1-.2.1-.3.2-.2.2-.4.6-.4.9 0 .6.3 1.1.8 1.2v2.4c0 .2.2.4.4.4h4.9c.2 0 .4-.2.4-.4v-2.4c.2-.1.3-.2.5-.3.2-.2.4-.6.4-.9 0-.5-.3-1-.7-1.2l1.5-4.2v-.2zm-13.2-1.2h-.9v-.8h.9v.8zm1-5.1c-.2-.1-.5 0-.6.2-.6 1-1 2.2-1.2 3.3h-.5c-.3-.1-.5.1-.5.4v1.6c0 .2.2.4.4.4h1.8c.2 0 .4-.2.4-.4V8c0-.2-.2-.4-.4-.4h-.4c.2-1 .5-2 1.1-2.9.2-.3.1-.5-.1-.6z" />
            <path d="M-75.3-.3c-.2 0-.4-.2-.4-.4s.2-.4.4-.4.4.2.4.4-.2.4-.4.4zm-9.3-.4v.4h-.9v-.9h.9v.5zm-10.1.4c-.2 0-.4-.2-.4-.4s.2-.4.4-.4.4.2.4.4-.2.4-.4.4zM-75.3-2c-.5 0-1 .4-1.2.9h-7.2v-.4c0-.2-.2-.4-.4-.4h-1.7c-.2 0-.4.2-.4.4v.4h-7.2c-.3-.5-.8-.9-1.3-.9-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3c.5 0 1-.4 1.2-.9h4.3c-.9.4-1.8.9-2.5 1.5-.2.2-.2.4 0 .6.1.2.4.2.6.1 1.2-1.1 3-1.8 4.8-2.1v.3c0 .2.2.4.4.4h1.7c.2 0 .4-.2.4-.4v-.3c1.8.3 3.5 1 4.8 2.1.2.2.4.1.6 0 .2-.2.1-.5-.1-.6-.7-.6-1.5-1.1-2.4-1.5h4.3c.2.5.7.9 1.2.9.7 0 1.3-.6 1.3-1.3.1-.8-.5-1.4-1.2-1.4z" />
          </svg>
        ),
        minPlan: 1
      },
      {
        id: 5,
        name: 'Playlist',
        route: PLAYLIST_PATH,
        subuser: false,
        icon: (
          <svg viewBox="-94 5.4 24.6 16.3">
            <path d="M-91.7 6.533c-.7 0-1.2.5-1.2 1.2 0 .6.5 1.1 1.1 1.1h20.1c.7 0 1.2-.6 1.1-1.2 0-.6-.5-1.1-1.1-1.1h-20zm20 3.3h-20.1c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2h20.1c1.2 0 2.2 1 2.2 2.2 0 1.3-1 2.2-2.2 2.2zM-91.7 12.433c-.7 0-1.2.6-1.1 1.2 0 .6.5 1.1 1.1 1.1h20.1c.7 0 1.2-.6 1.1-1.2 0-.6-.5-1.1-1.1-1.1h-20.1zm20 3.4h-20.1c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2h20.1c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2zM-91.7 18.333c-.7 0-1.2.6-1.1 1.2 0 .6.5 1.1 1.1 1.1h20.1c.7 0 1.2-.6 1.1-1.2 0-.6-.5-1.1-1.1-1.1h-20.1zm20 3.4h-20.1c-1.1 0-2.1-1-2.1-2.2s1-2.2 2.2-2.2h20.1c1.2 0 2.2 1 2.2 2.2-.1 1.2-1.1 2.2-2.3 2.2z" />
          </svg>
        ),
        minPlan: 1
      }
    ]
  },
  {
    name: 'Management',
    items: [
      {
        id: 4,
        name: 'Setup',
        route: SETUP_PATH,
        subuser: false,
        icon: (
          <svg viewBox="-97.1 -0.1 21 21.2">
            <path d="M-79.9 13.2c-.4 1-.2 2.2.6 3l.1.1-.1.1c-.4.4-.8.9-1.2 1.2l-.1.1-.1-.1c-.5-.5-1.3-.8-2-.8-.4 0-.7.1-1 .2-1 .4-1.7 1.4-1.7 2.5v.1h-.1c-.6.1-1.1.1-1.7 0h-.1v-.1c0-1.1-.7-2.1-1.7-2.6-.3-.1-.7-.2-1-.2-.8 0-1.5.3-2 .8l-.1.1-.1-.1-1.2-1.2-.1-.1.1-.1c.8-.8 1-2 .6-3s-1.4-1.7-2.5-1.7h-.1v-.1c-.1-.6-.1-1.2 0-1.7v-.1h.1c1.2-.1 2.1-.7 2.6-1.7.4-1 .2-2.2-.6-3l-.1-.1.1-.1c.4-.4.8-.9 1.2-1.2l.1-.1.1.1c.5.5 1.2.8 2 .8.4 0 .7-.1 1-.2 1-.4 1.7-1.4 1.7-2.5v-.1h.1c.6-.1 1.1 0 1.7 0h.1v.1c0 1.1.7 2.1 1.7 2.6.3.1.7.2 1 .2.7 0 1.5-.3 2-.8l.1-.1.1.1 1.2 1.2.1.1-.1.1c-.8.8-1 2-.6 3s1.4 1.7 2.5 1.7h.1v.1c.1.6.1 1.2 0 1.7v.1h-.1c-1.2 0-2.2.7-2.6 1.7zm2.6-.5c.7 0 1.1-.4 1.2-1 .1-.8.1-1.6 0-2.4-.1-.6-.7-1-1.2-1-.6 0-1.2-.4-1.5-1-.2-.6-.1-1.3.4-1.8.4-.4.5-1.1.1-1.6-.5-.6-1-1.2-1.7-1.7-.5-.4-1.2-.3-1.6.1-.3.3-.7.5-1.2.5-.2 0-.4 0-.6-.1-.6-.3-1-.9-1-1.6 0-.6-.4-1.2-1-1.2-.6.1-1.4 0-2.2.1-.6.1-1.1.6-1 1.2 0 .7-.4 1.3-1 1.5-.2.1-.4.1-.6.1-.5 0-.9-.2-1.2-.5-.4-.4-1.1-.5-1.6-.1-.6.5-1.2 1.1-1.7 1.7-.4.5-.3 1.2.1 1.6.5.4.7 1.2.4 1.8-.2.6-.9 1-1.6 1-.6 0-1.1.4-1.2 1-.1.8-.1 1.6 0 2.4.1.6.7 1 1.3 1s1.2.4 1.4 1 .1 1.3-.4 1.8c-.4.4-.5 1.1-.1 1.6.5.6 1 1.2 1.7 1.7.5.4 1.2.3 1.6-.1.3-.3.8-.5 1.2-.5.2 0 .4 0 .6.1.6.3 1 .9 1 1.5s.4 1.1 1 1.2c.8.1 1.6.1 2.4 0 .6-.1 1.1-.6 1-1.2 0-.7.4-1.3 1-1.5.2-.1.4-.1.6-.1.5 0 .9.2 1.2.5.4.4 1.1.5 1.6.1.6-.5 1.2-1.1 1.7-1.7.4-.5.3-1.2-.1-1.6-.5-.4-.7-1.2-.4-1.8.1-.6.7-1 1.4-1-.1 0 0 0 0 0z" />
            <path d="M-86.5 13c-1.4 0-2.5-1.1-2.5-2.5S-87.9 8-86.5 8s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5m0-6c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5S-84.6 7-86.5 7" />
          </svg>
        ),
        minPlan: 1
      },
      {
        id: 6,
        name: 'Moderation',
        route: MODERATION_PATH,
        subuser: true,
        icon: (
          <svg viewBox="-98.1 0 21.1 21">
            <path d="M-78.8 13.8c-.2.2-.2.5-.1.7 0 0 .2.3.2.7 0 .6-.3 1.1-.9 1.5-.2.2-.3.4-.2.7 0 0 .2.6-.1 1.1s-1 .9-2 1.1c-.8.2-1.9.2-3.3.1h-.1c-2.9.1-5.8-.3-5.9-.3l-.5-.1v-7.5c0-.2 0-.4-.1-.5.1-.3.3-.9.8-1.5 2-1.6 4-6.8 4.1-7.1v-.3c-.1-.5 0-1.1.1-1.3.2 0 .9.1 1.3.6.5.6.4 1.7-.1 3.2-.8 2.2-.8 3.4-.2 3.9.3.3.7.3 1 .2s.5-.1.8-.2h.1c1.4-.3 3.9-.5 4.7.3.7.6.2 1.5.2 1.6-.2.2-.1.6.1.8 0 0 .5.4.5 1 .2.5 0 .9-.4 1.3zm-17.3 5.8c-.4 0-.7-.3-.7-.6v-7.1c0-.4.3-.6.7-.6h2.8c.4 0 .7.3.7.6V19c0 .4-.3.6-.7.6h-2.8zm18.5-5.3c.4-.5.7-1.1.6-1.7 0-.7-.3-1.2-.6-1.5.3-.7.4-1.8-.6-2.7-.7-.6-1.9-.9-3.6-.8-1.2-.1-2.2.1-2.2.2-.2 0-.5.1-.7.1 0-.3 0-1 .6-2.6.6-1.9.6-3.3-.1-4.3-.8-.9-2-1-2.4-1-.3 0-.7.1-.9.4-.5.6-.4 1.6-.3 2.1-.6 1.6-2.3 5.4-3.7 6.4l-.1.1c-.4.4-.7.8-.9 1.3-.3-.1-.6-.2-.9-.2h-2.8c-1 0-1.9.8-1.9 1.8V19c0 1 .8 1.8 1.9 1.8h2.8c.4 0 .8-.1 1.1-.3l1.1.1c.2 0 3.1.4 6 .3.5 0 1 .1 1.5.1.8 0 1.5-.1 2.1-.2 1.4-.3 2.3-.9 2.8-1.7.4-.6.4-1.3.3-1.7.9-.8 1.1-1.7 1-2.3 0-.2 0-.5-.1-.8z" />
          </svg>
        ),
        minPlan: 1
      },
      {
        id: 7,
        name: 'Stats',
        route: STATS_PATH,
        subuser: false,
        icon: (
          <svg viewBox="-71.8 3 21.2 15">
            <path d="M-69.1 17c-.9 0-1.7-.8-1.7-1.7v-3.5l3.5-4.1 8 7.2 8.7-9.7V3.8l-8.7 9.8-8.1-7.2-3.4 3.9V3h-1v12.3c0 1.5 1.2 2.7 2.7 2.7h18.5v-1h-18.5z" />
          </svg>
        ),
        minPlan: 1
      }
    ]
  },
  {
    name: 'Pay and activate',
    items: [
      {
        id: 8,
        name: 'Pay and activate',
        route: PAY_PATH,
        subuser: false,
        icon: (
          <svg viewBox="-77.4 9.2 24.7 16.2">
            <path d="M-54.5 24.412h-21.1c-.4 0-.8-.4-.8-.8v-10.2h22.7v10.2c0 .4-.4.8-.8.8m-21.1-14.2h21.1c.4 0 .8.4.8.8v1.4h-22.7v-1.4c0-.5.4-.8.8-.8m21.1-1h-21.1c-1 0-1.8.8-1.8 1.8v12.6c0 1 .8 1.8 1.8 1.8h21.1c1 0 1.8-.8 1.8-1.8v-12.6c0-1-.8-1.8-1.8-1.8" />
            <path d="M-57.3 21.1c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8m-4.7 0c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8m4.7-4.6c-1 0-1.8.5-2.3 1.2-.9-1.3-2.6-1.6-3.9-.7-.8.5-1.2 1.4-1.2 2.3 0 1.6 1.3 2.8 2.8 2.8 1 0 1.8-.5 2.3-1.2.9 1.3 2.6 1.6 3.9.7.8-.5 1.2-1.4 1.2-2.3 0-1.6-1.3-2.8-2.8-2.8" />
          </svg>
        ),
        minPlan: 2
      }
    ]
  },
  {
    name: 'View and display',
    items: [
      {
        id: 9,
        name: 'Preview and go live',
        route: PREVIEW_PATH,
        subuser: false,
        icon: (
          <svg viewBox="-80.4 4 22.2 11">
            <path d="M-79 9.466c1.2 1.1 5.1 4.5 9.7 4.5s8.6-3.4 9.7-4.5c-1.2-1.1-5.1-4.5-9.7-4.5-4.6-.1-8.6 3.4-9.7 4.5m9.7 5.5c-5.9 0-10.6-5-10.8-5.2l-.3-.3.3-.3c.2-.2 4.9-5.2 10.8-5.2s10.6 5 10.8 5.2l.3.3-.3.3c-.2.2-4.9 5.2-10.8 5.2" />
            <path d="M-69.3 7.1c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3 2.3-1 2.3-2.3c0-1.2-1-2.3-2.3-2.3m0 5.6c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3S-66 7.6-66 9.4c0 1.9-1.5 3.3-3.3 3.3" />
          </svg>
        ),
        minPlan: 1
      },
      {
        id: 10,
        name: 'Embed',
        route: EMBED_PATH,
        subuser: false,
        icon: (
          <svg width="22" height="12" viewBox="0 0 22 12">
            <defs>
              <path id="a" d="M.305.796h4.667V9.16H.305z" />
            </defs>
            <g>
              <path d="M4.521 1.734a.5.5 0 0 0-.707 0l-3.67 3.67A.497.497 0 0 0 0 5.771a.496.496 0 0 0 .144.365l3.67 3.67a.498.498 0 0 0 .707 0 .5.5 0 0 0 0-.707L1.192 5.77l3.33-3.328a.5.5 0 0 0 0-.708M14.076.06a.495.495 0 0 0-.675.208L7.687 11.092a.5.5 0 0 0 .885.467L14.284.735a.498.498 0 0 0-.208-.675" />
              <g transform="translate(17 .825)">
                <mask id="b" fill="#fff">
                  <use href="#a" />
                </mask>
                <path
                  d="M4.828 4.613L1.158.943a.5.5 0 0 0-.707.707L3.78 4.979.45 8.307a.5.5 0 0 0 .708.707l3.67-3.67a.495.495 0 0 0 .144-.365.496.496 0 0 0-.144-.366"
                  mask="url(#b)"
                />
              </g>
            </g>
          </svg>
        ),
        minPlan: 1
      }
    ]
  }
]

export const getSidebarState = (user, planId = 3) => {
  const filter = isSubuser(user)
    ? item => item.subuser === true
    : item => item.minPlan <= planId
  return SIDEBAR.map(panel => {
    return { ...panel, items: panel.items.filter(filter) }
  }).filter(panel => panel.items.length > 0)
}
