import ThemeService from 'services/api/ThemeService'
import _uniqWith from 'lodash/uniqWith'
import _isEqual from 'lodash/isEqual'

const ADD_ALL_THEMES = 'ADD_ALL_THEMES'
const ADD_ALL_THEMES_CATEGORIES = 'ADD_ALL_THEMES_CATEGORIES'

export const doFetchAllThemesAction = () => async dispatch => {
  try {
    let themes = await ThemeService.getAll()
    dispatch(addThemesAction(themes))
    const unique = _uniqWith([...themes.all, ...themes.recently_created], _isEqual)
    return [...unique]
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export const doFetchAllThemesCategoriesAction = () => async dispatch => {
  try {
    const data = await ThemeService.getCategories()
    dispatch(addThemesCategoriesAction(data))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export const addThemesAction = themes => ({
  type: ADD_ALL_THEMES,
  payload: themes
})
export const addThemesCategoriesAction = categories => ({
  type: ADD_ALL_THEMES_CATEGORIES,
  payload: categories
})

const initState = {
  all: [],
  categories: [],
  recently_created: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case ADD_ALL_THEMES:
      return {
        ...state,
        ...action.payload
      }
    case ADD_ALL_THEMES_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }

    default:
      return state
  }
}
