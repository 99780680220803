import React from 'react'
import wallrusInfo from 'assets/images/wallrus-info.png'
import './StatsModal.scss'
import { connect } from 'react-redux'
const StatsModal = ({ eventType }) => {
  const message =
    eventType !== 'pro'
      ? 'Statistcs are only available in our Pro plan.'
      : 'Statistics will only be available once your wall has been activated.'
  return (
    <div className="StatsModal">
      <div className="Block">
        <span>{message}</span>
        <img src={wallrusInfo} alt="wallrus info" />
      </div>
      <div className="ModalFooter">*Stats currently shown are for demo purposes.</div>
    </div>
  )
}

export default connect(
  state => ({
    eventType: state.currentWall.plan.event_type
  }),
  {}
)(StatsModal)
