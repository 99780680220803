import React from 'react'

const PlayButton = props => (
  <svg viewBox="0 0 41.999 41.999" width={512} height={512} {...props}>
    <path
      d="M36.068 20.176l-29-20A1 1 0 1 0 5.5.999v40a1 1 0 0 0 1.568.823l29-20a.999.999 0 0 0 0-1.646z"
      fill="#FFF"
    />
  </svg>
)

export default PlayButton
