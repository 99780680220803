import { CLOUDFRONT_ASSETS_URL } from 'setup/constants'

export const CARDS_PLACEHOLDER = [
  {
    id: 73,
    feed_id: 2,
    id_from_source: '1118898546621722624',
    user_handle: 'denniswatkins',
    user_name: 'Dennis Watkins',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar1.jpg',
    text:
      'Donec lobortis nibh id quam mattis eleifend. Suspendisse a posuere arcu, ac mattis turpis. In bibendum venenatis imperdiet. Vestibulum arcu eros, blandit eu leo quis, iaculis condimentum massa. Vestibulum sagittis ex malesuada, iaculis justo ac, eleifend ipsum. Nulla odio felis.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder1.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:26:16.000Z',
    created_at: '2019-04-18T15:40:57.437Z',
    updated_at: '2019-04-18T15:40:57.437Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 8,
    friends_count: 9,
    location: 'Richmond, British Columbia',
    promoted: null,
    media_video_url: null
  },
  {
    id: 72,
    feed_id: 2,
    id_from_source: '1118898474878156801',
    user_handle: 'evaburton',
    user_name: 'Eva Burton',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar2.jpg',
    text:
      'Suspendisse purus est, porta ac odio imperdiet, fermentum vestibulum magna. Proin bibendum ex eget mauris auctor eleifend. Pellentesque interdum mattis mi, id efficitur lacus consequat sit amet. Morbi pretium, augue sit amet cursus aliquam, risus leo vulputate.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder2.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:25:59.000Z',
    created_at: '2019-04-18T15:40:57.436Z',
    updated_at: '2019-04-18T15:40:57.436Z',
    source: 'instagram',
    keyword_id: 1,
    is_retweet: true,
    followers_count: 1932,
    friends_count: 3184,
    location: 'Schaumburg, IL',
    promoted: null,
    media_video_url: null
  },
  {
    id: 172,
    feed_id: 2,
    id_from_source: '111889847487156801',
    user_handle: 'evaburton',
    user_name: 'Eva Burton',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar2.jpg',
    text:
      'Suspendisse purus est, porta ac odio imperdiet, fermentum vestibulum magna. Proin bibendum ex eget mauris auctor eleifend. Pellentesque interdum mattis mi, id efficitur lacus consequat sit amet. Morbi pretium, augue sit amet cursus aliquam, risus leo vulputate.',
    media_file_url: null,
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:25:59.000Z',
    created_at: '2019-04-18T15:40:57.436Z',
    updated_at: '2019-04-18T15:40:57.436Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: true,
    followers_count: 1932,
    friends_count: 3184,
    location: 'Schaumburg, IL',
    promoted: null,
    media_video_url: null
  },
  {
    id: 71,
    feed_id: 2,
    id_from_source: '1118898629710712833',
    user_handle: 'jaydenwong',
    user_name: 'Jayden Wong',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar3.jpg',
    text:
      'Phasellus tristique risus sed finibus lobortis. Pellentesque condimentum eleifend lacus id fermentum. Integer id eros lacus. Sed sed efficitur enim, a fermentum justo. Integer quis augue imperdiet, dictum metus nec, egestas arcu. Nunc tempor.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder3.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:26:36.000Z',
    created_at: '2019-04-18T15:40:57.405Z',
    updated_at: '2019-04-18T15:40:57.405Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 152,
    friends_count: 28,
    location: 'Adana, Türkiye',
    promoted: null,
    media_video_url: null
  },
  {
    id: 70,
    feed_id: 2,
    id_from_source: '1118898605388120070',
    user_handle: 'jenniecole',
    user_name: 'Jennie Cole',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar4.jpg',
    text:
      'Duis quis ipsum imperdiet, egestas risus ut, pulvinar enim. Nunc ut nibh a risus fermentum accumsan ac at purus. Morbi luctus augue velit, ac lacinia tellus scelerisque a. Phasellus ac est.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder4.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:26:30.000Z',
    created_at: '2019-04-18T15:40:57.403Z',
    updated_at: '2019-04-18T15:40:57.403Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 100929,
    friends_count: 942,
    location: null,
    promoted: null,
    media_video_url: null
  },
  {
    id: 69,
    feed_id: 2,
    id_from_source: '1118898726637121538',
    user_handle: 'blanchemendez',
    user_name: 'Blanche Mendez',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar5.jpg',
    text:
      'Nam vel ornare diam, a condimentum mi. Pellentesque fringilla sapien ligula, at pharetra neque porta id. Nulla facilisi. Vivamus ac justo ac ligula semper ultricies. Suspendisse nec ligula.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder5.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:26:59.000Z',
    created_at: '2019-04-18T15:40:57.384Z',
    updated_at: '2019-04-18T15:40:57.384Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: true,
    followers_count: 652,
    friends_count: 2865,
    location: 'The Capitol D',
    promoted: null,
    media_video_url: null
  },
  {
    id: 68,
    feed_id: 2,
    id_from_source: '1118898754197884928',
    user_handle: 'ianpalmer',
    user_name: 'Ian Palmer',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar6.jpg',
    text:
      'Vivamus vel risus mollis, eleifend neque eu, sagittis diam. Praesent purus augue, sollicitudin eu nunc vel, sagittis vehicula dolor. Phasellus viverra pulvinar urna non interdum.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder6.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:05.000Z',
    created_at: '2019-04-18T15:40:57.382Z',
    updated_at: '2019-04-18T15:40:57.382Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: true,
    followers_count: 82,
    friends_count: 24,
    location: null,
    promoted: null,
    media_video_url: null
  },
  {
    id: 67,
    feed_id: 2,
    id_from_source: '1118898791774609410',
    user_handle: 'maudescott',
    user_name: 'Maude Scott',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar7.jpg',
    text:
      'Aliquam blandit eros risus, in laoreet urna sollicitudin sed. Fusce a erat ut quam efficitur commodo vel in ante. Nullam varius augue. Cras varius mollis est, in faucibus est mattis at. Cras faucibus faucibus leo vel tincidunt. Class aptent taciti sociosqu.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder7.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:14.000Z',
    created_at: '2019-04-18T15:40:57.353Z',
    updated_at: '2019-04-18T15:40:57.353Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 6,
    friends_count: 9,
    location: 'Burlington nj',
    promoted: null,
    media_video_url: null
  },
  {
    id: 167,
    feed_id: 2,
    id_from_source: '111889871774609410',
    user_handle: 'maudescott',
    user_name: 'Maude Scott',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar7.jpg',
    text:
      'Aliquam blandit eros risus, in laoreet urna sollicitudin sed. Fusce a erat ut quam efficitur commodo vel in ante. Nullam varius augue. Cras varius mollis est, in faucibus est mattis at. Cras faucibus faucibus leo vel tincidunt. Class aptent taciti sociosqu.',
    media_file_url: null,
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:14.000Z',
    created_at: '2019-04-18T15:40:57.353Z',
    updated_at: '2019-04-18T15:40:57.353Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 6,
    friends_count: 9,
    location: 'Burlington nj',
    promoted: null,
    media_video_url: null
  },
  {
    id: 66,
    feed_id: 2,
    id_from_source: '1118898890990915585',
    user_handle: 'howardcunningham',
    user_name: 'Howard Cunningham',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar8.jpg',
    text:
      'Pellentesque ornare felis non mauris posuere, quis consequat lorem blandit. Nunc congue imperdiet nulla, ut lobortis. Nulla eget finibus orci, vel ornare mi. Vivamus varius venenatis mi, non bibendum.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder8.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:38.000Z',
    created_at: '2019-04-18T15:40:57.301Z',
    updated_at: '2019-04-18T15:40:57.301Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: true,
    followers_count: 7,
    friends_count: 38,
    location: null,
    promoted: null,
    media_video_url: null
  },
  {
    id: 166,
    feed_id: 2,
    id_from_source: '11188989099091555',
    user_handle: 'howardcunningham',
    user_name: 'Howard Cunningham',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar8.jpg',
    text:
      'Pellentesque ornare felis non mauris posuere, quis consequat lorem blandit. Nunc congue imperdiet nulla, ut lobortis. Nulla eget finibus orci, vel ornare mi. Vivamus varius venenatis mi, non bibendum.',
    media_file_url: null,
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:38.000Z',
    created_at: '2019-04-18T15:40:57.301Z',
    updated_at: '2019-04-18T15:40:57.301Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: true,
    followers_count: 7,
    friends_count: 38,
    location: null,
    promoted: null,
    media_video_url: null
  },
  {
    id: 65,
    feed_id: 2,
    id_from_source: '1118898888130408449',
    user_handle: 'henriettamendoza',
    user_name: 'Henrietta Mendoza',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar9.jpg',
    text:
      'Morbi pellentesque, arcu nec vulputate scelerisque, magna lectus interdum felis.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder9.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:37.000Z',
    created_at: '2019-04-18T15:40:57.300Z',
    updated_at: '2019-04-18T15:40:57.300Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 633,
    friends_count: 1082,
    location: 'Lyon, France',
    promoted: null,
    media_video_url: ''
  },
  {
    id: 165,
    feed_id: 2,
    id_from_source: '111889888130408449',
    user_handle: 'henriettamendoza',
    user_name: 'Henrietta Mendoza',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar9.jpg',
    text:
      'Morbi pellentesque, arcu nec vulputate scelerisque, magna lectus interdum felis.',
    media_file_url: null,
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:37.000Z',
    created_at: '2019-04-18T15:40:57.300Z',
    updated_at: '2019-04-18T15:40:57.300Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 633,
    friends_count: 1082,
    location: 'Lyon, France',
    promoted: null,
    media_video_url: ''
  },
  {
    id: 64,
    feed_id: 2,
    id_from_source: '1118898937337978884',
    user_handle: 'kennethyoung',
    user_name: 'Kenneth Young',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar10.jpg',
    text: 'Nullam sed velit diam. Sed ac massa neque. Nullam malesuada, velit ut.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder10.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:49.000Z',
    created_at: '2019-04-18T15:40:57.279Z',
    updated_at: '2019-04-18T15:40:57.279Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: true,
    followers_count: 17,
    friends_count: 83,
    location: 'Pittsburgh, PA',
    promoted: null,
    media_video_url: null
  },
  {
    id: 164,
    feed_id: 2,
    id_from_source: '111889893337978884',
    user_handle: 'kennethyoung',
    user_name: 'Kenneth Young',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar10.jpg',
    text: 'Nullam sed velit diam. Sed ac massa neque. Nullam malesuada, velit ut.',
    media_file_url: null,
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:27:49.000Z',
    created_at: '2019-04-18T15:40:57.279Z',
    updated_at: '2019-04-18T15:40:57.279Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: true,
    followers_count: 17,
    friends_count: 83,
    location: 'Pittsburgh, PA',
    promoted: null,
    media_video_url: null
  },
  {
    id: 63,
    feed_id: 2,
    id_from_source: '1118898995101929472',
    user_handle: 'jacksoncollier',
    user_name: 'Jackson Collier',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar11.jpg',
    text: 'Quisque nec leo vitae nibh tincidunt pharetra.',
    media_file_url: null,
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:28:03.000Z',
    created_at: '2019-04-18T15:40:57.244Z',
    updated_at: '2019-04-18T15:40:57.244Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 17747,
    friends_count: 9359,
    location: null,
    promoted: null,
    media_video_url: null
  },
  {
    id: 163,
    feed_id: 2,
    id_from_source: '111889899510929472',
    user_handle: 'jacksoncollier',
    user_name: 'Jackson Collier',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar11.jpg',
    text: 'Ut ut blandit orci.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder11.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:28:03.000Z',
    created_at: '2019-04-18T15:40:57.244Z',
    updated_at: '2019-04-18T15:40:57.244Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 17747,
    friends_count: 9359,
    location: null,
    promoted: null,
    media_video_url: null
  },
  {
    id: 16333,
    feed_id: 2,
    id_from_source: '118898995101929472',
    user_handle: 'jacksoncollier',
    user_name: 'Jackson Collier',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar11.jpg',
    text: 'Ut ut blandit orci.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder12.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:28:03.000Z',
    created_at: '2019-04-18T15:40:57.244Z',
    updated_at: '2019-04-18T15:40:57.244Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 17747,
    friends_count: 9359,
    location: null,
    promoted: null,
    media_video_url: null
  },
  {
    id: 113,
    feed_id: 2,
    id_from_source: '111898995101929472',
    user_handle: 'jacksoncollier',
    user_name: 'Jackson Collier',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar11.jpg',
    text: 'Ut ut blandit orci.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder12.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:28:03.000Z',
    created_at: '2019-04-18T15:40:57.244Z',
    updated_at: '2019-04-18T15:40:57.244Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 17747,
    friends_count: 9359,
    location: null,
    promoted: null,
    media_video_url: null
  },
  {
    id: 1133,
    feed_id: 2,
    id_from_source: '111898995101929472',
    user_handle: 'jacksoncollier',
    user_name: 'Jackson Collier',
    profile_image_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/Avatar11.jpg',
    text: 'Ut ut blandit orci.',
    media_file_url: CLOUDFRONT_ASSETS_URL + 'assets/placeholder/placeholder12.jpg',
    approved: false,
    deleted: false,
    source_created_at: '2019-04-18T15:28:03.000Z',
    created_at: '2019-04-18T15:40:57.244Z',
    updated_at: '2019-04-18T15:40:57.244Z',
    source: 'twitter',
    keyword_id: 1,
    is_retweet: false,
    followers_count: 17747,
    friends_count: 9359,
    location: null,
    promoted: null,
    media_video_url: null
  }
]
