import React from 'react'
import { connect } from 'react-redux'
import { TIME_FORMAT, DATE_FORMAT } from '../PayPageContants'

import wallrusWelcome from 'assets/images/wallrus-welcome.png'
import './WallActivated.scss'
import GoTo from 'components/Goto/GoTo'
import Button from 'components/Button/Button'
import moment from 'moment'

const WallActivated = ({ currentWall: { last_live_start, live_until, live_start } }) => {
  const startDate = live_start === null ? last_live_start : live_start
  const fromDate = moment(startDate).format(DATE_FORMAT)
  const fromTime = moment(startDate).format(TIME_FORMAT)
  const toDate = moment(live_until).format(DATE_FORMAT)
  const toTime = moment(live_until).format(TIME_FORMAT)
  return (
    <div className="WallActivatedContainer">
      <div className="WallActivated">
        <div className="Title">Yeah! Activated!</div>
        <div className="WallrusActivatedLogo">
          <img src={wallrusWelcome} alt="wallrus activated" />
        </div>
        <div className="Dates">
          {startDate ? (
            <div className="DateLine align-left">
              <span>from</span>
              <span className="Date">{fromDate}</span>
              <span className="Time">{fromTime}</span>
            </div>
          ) : null}
          {live_until ? (
            <div className="DateLine align-left">
              <span>to</span>
              <span className="Date">{toDate}</span>
              <span className="Time">{toTime}</span>
            </div>
          ) : null}
        </div>
        <div className="Buttons">
          <GoTo route="preview">
            <Button>go live</Button>
          </GoTo>
        </div>
      </div>
    </div>
  )
}

export default connect(
  state => ({ currentWall: state.currentWall }),
  {}
)(WallActivated)
