import React, { Component } from 'react'
import ThemePageSidebar from './ThemePageSidebar/ThemePageSidebar'
import ThemePageItem from './ThemePageItem'
import {
  doFetchAllThemesAction,
  doFetchAllThemesCategoriesAction
} from 'store/themes/themesReducer'
import './ThemePage.scss'
import { connect } from 'react-redux'
import Container from 'components/Container/Container'
import _uniqWith from 'lodash/uniqWith'
import _isEqual from 'lodash/isEqual'

class ThemePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      availableTypes: [],
      currentCategory: 'ALL',
      filteredThemes: [],
      allThemes: [],
      ready: false
    }
  }

  async componentDidMount() {
    if (this.props.themes.categories.length === 0) {
      this.props.doFetchAllThemesCategoriesAction()
    }

    await this.setState({
      filteredThemes: this.props.themes.all.length
        ? this.filterDuplicates()
        : await this.props.doFetchAllThemesAction()
    })
    await this.setState({ allThemes: this.state.filteredThemes, ready: true })
  }

  filter = async currentCategory => {
    await this.setState({
      currentCategory
    })
    this.filterThemes()
  }

  filterDuplicates = () =>
    _uniqWith([...this.props.themes.all, ...this.props.themes.recently_created], _isEqual)

  filterThemes = () => {
    let filteredThemes = this.filterDuplicates()

    if (this.state.currentCategory !== null && this.state.currentCategory !== 'ALL') {
      filteredThemes = filteredThemes.filter(
        theme => this.state.currentCategory.id === theme.theme_category_id
      )
    }

    if (filteredThemes.length >= 0) {
      this.setState({
        filteredThemes
      })
    }
  }

  render() {
    const { allThemes, filteredThemes } = this.state
    return (
      this.props.themes.all.length &&
      this.state.ready && (
        <Container type="large" className="ThemePage">
          {allThemes.length > 0 ? (
            <ThemePageSidebar
              setFilter={value => this.filter(value)}
              currentCategory={this.state.currentCategory}
              categories={this.props.themes.categories}
              themes={allThemes}
            />
          ) : null}
          <div className="listTheme">
            {filteredThemes.map(theme => {
                if (theme.theme_styles.length !== 0) {
                  return <ThemePageItem key={theme.id} theme={theme} />
                }
                return null;
            })}
          </div>
        </Container>
      )
    )
  }
}

export default connect(
  state => ({ themes: state.themes }),
  {
    doFetchAllThemesAction,
    doFetchAllThemesCategoriesAction
  }
)(ThemePage)
