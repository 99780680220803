import React from 'react'
import 'components/LowerBanner/LowerBanner.scss'

const LowerBanner = props => (
  <div className="LowerBanner">
    <svg viewBox="0 0 3038 277.8" preserveAspectRatio="none" {...props}>
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1={89.103}
        y1={218.331}
        x2={2705.338}
        y2={218.331}>
        <stop offset={0} stopColor="#3ffff6" />
        <stop offset={0.249} stopColor="#40fcf6" />
        <stop offset={0.403} stopColor="#44f4f7" />
        <stop offset={0.532} stopColor="#4be5f8" />
        <stop offset={0.647} stopColor="#55d1f9" />
        <stop offset={0.752} stopColor="#62b6fb" />
        <stop offset={0.849} stopColor="#7196fd" />
        <stop offset={0.922} stopColor="#7f78ff" />
      </linearGradient>
      <path
        d="M89.1 277.8h2616.2S2189 159 1499.6 159 467 277.8 89.1 277.8"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#a)"
      />
      <linearGradient
        id="b"
        gradientUnits="userSpaceOnUse"
        y1={158.906}
        x2={2961.601}
        y2={158.906}>
        <stop offset={0} stopColor="#e300e9" />
        <stop offset={0.041} stopColor="#df06e9" />
        <stop offset={0.097} stopColor="#d417ea" />
        <stop offset={0.161} stopColor="#c233ec" />
        <stop offset={0.231} stopColor="#a95bee" />
        <stop offset={0.306} stopColor="#888df0" />
        <stop offset={0.384} stopColor="#61caf3" />
        <stop offset={0.439} stopColor="#42faf6" />
        <stop offset={0.595} stopColor="#42faf6" />
        <stop offset={0.62} stopColor="#42faf6" />
        <stop offset={1} stopColor="#3ffff6" />
      </linearGradient>
      <path
        d="M1537.7 117.4c-40.7-7-80.2-10.1-119-10.1-280.6 0-523.6 161-894.7 170C107.8 266.6 0 40.1 0 40.1v237.7h2961.6c-1219 0-1080.6-101.4-1423.9-160.4z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#b)"
      />
      <linearGradient
        id="c"
        gradientUnits="userSpaceOnUse"
        x1={1594.02}
        y1={204.97}
        x2={3036.987}
        y2={204.97}>
        <stop offset={0} stopColor="#e300e9" />
        <stop offset={0.455} stopColor="#e100e9" />
        <stop offset={0.619} stopColor="#da00e9" />
        <stop offset={0.735} stopColor="#cf00e9" />
        <stop offset={0.83} stopColor="#be00e9" />
        <stop offset={0.91} stopColor="#a800e9" />
        <stop offset={0.981} stopColor="#8d00e9" />
        <stop offset={0.997} stopColor="#8600e9" />
      </linearGradient>
      <path
        d="M1594 277.8c590.8 0 1242 0 1443-145.6v145.6H1594z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#c)"
      />
    </svg>
  </div>
)

export default LowerBanner
