import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTransition, animated, config } from 'react-spring'
import useInterval from 'services/app/useInterval'
import TopBar from 'components/Visualizer/TopBar/TopBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BiggerText from 'components/BiggerText/BiggerText'
import { truncateString } from 'setup/utils.js'
import './TextPicScreen.scss'
import {
  getBackgroundColorStyle,
  getBackgroundImageStyle,
  getHandleColorStyle,
  getTextColorStyle,
  getCardsBackgroundColorStyle,
  getTopBarEnableStyle
} from 'services/app/screen'
import { SOCIAL_IMG_404_URL } from 'setup/constants'

export const generateTextPicGrid = () => {
  let grid = []
  const width = 576 //30
  const margin = 65
  for (let position = 0; position < showedCardNbr; position++) {
    const left = -(width / 2) + width * position + margin * position
    grid.push({
      from: {
        backgroundColor: '#000',
        xyz: [left + width + margin, 'px', 0, 'px', -10, 'px'],
        opacity: 0,
        width: width + 'px',
        index: 0,
        perspectiveOrigin: 'right center'
      },
      enter: {
        xyz: [left, 'px', 0, 'px', 0, 'px'],
        opacity: 1,
        index: 10 - position
      },
      leave: {
        xyz: [left - width - margin, 'px', 0, 'px', -20, 'px'],
        opacity: 0,
        index: 10 - position - 1
      },
      update: {
        xyz: [left, 'px', 0, 'px', 0, 'px'],
        index: 10 - position
      }
    })
  }
  return grid
}

const showedCardNbr = 5
const positions = Array.from({ length: showedCardNbr }, (item, index) => index)
const grid = generateTextPicGrid()
const transform3dInterpolation = (x, xUnit, y, yUnit, z, zUnit) =>
  `perspective(200px) translate3d(${x + xUnit}, ${y + yUnit}, ${z + zUnit})`
const zIndexInterpolation = index => parseInt(index, 10)

export const TextPicScreen = ({
  animatedTheme = false,
  cards: nextCards,
  forceBackground = false,
  getNextCard = null,
  isRunning = true,
  play_video,
  rotation_time,
  screen: { styles: screenStyles },
  showPlaceholder = false
}) => {
  const [cards, setCards] = useState(
    showPlaceholder
      ? nextCards
          .slice(0, showedCardNbr)
          .map((item, position) => ({ ...item, position: positions[position] }))
      : []
  )
  const [running, setRunning] = useState(isRunning)
  const screenCanRun = () => nextCards.length > showedCardNbr + 1

  const increase = () => {
    const _cards = cards.map(card => {
      if (card.position !== 0) {
        return { ...card, position: card.position - 1 }
      } else {
        const _card = getNextCard(1)
        return { ..._card, position: 4 }
      }
    })

    setCards(_cards)
  }

  useInterval(() => increase(), running ? rotation_time : null)

  useEffect(() => {
    if (!showPlaceholder) {
      let hidden, visibilityChange
      if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden'
        visibilityChange = 'visibilitychange'
      } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden'
        visibilityChange = 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        visibilityChange = 'webkitvisibilitychange'
      }
      const handleVisibilityChange = () => {
        if (document[hidden]) {
          setRunning(false)
        } else {
          setRunning(true)
        }
      }
      document.addEventListener(visibilityChange, handleVisibilityChange, false)
      return () => document.removeEventListener(visibilityChange, handleVisibilityChange)
    }
  }, [showPlaceholder])

  useEffect(() => {
    if (!showPlaceholder && cards.length === 0) {
      const initialize = () => {
        const _cards = getNextCard(showedCardNbr).map((card, index) => ({
          ...card,
          position: positions[index]
        }))
        setCards(_cards)
      }
      initialize()
    }
  }, [cards.length, getNextCard, showPlaceholder])

  const transitions = useTransition(
    cards.map(card => ({
      ...card,
      ...grid[card.position]
    })),
    card => card.id,
    {
      from: ({ from }) => ({ ...from }),
      enter: ({ enter }) => ({ ...enter }),
      update: ({ update }) => ({ ...update }),
      leave: ({ leave }) => ({ ...leave }),
      config: config.slow
    }
  )

  const styles =
    animatedTheme && !forceBackground
      ? { color: getTextColorStyle(screenStyles) }
      : {
          backgroundColor: getBackgroundColorStyle(screenStyles),
          backgroundImage: `url('${getBackgroundImageStyle(screenStyles)}')`,
          color: getTextColorStyle(screenStyles)
        }

  return (
    <div className="TextPicScreen" style={styles}>
      {getTopBarEnableStyle(screenStyles) ? <TopBar screenStyles={screenStyles} /> : null}
      <div className="CardsContainer">
        {screenCanRun() ? (
          transitions.map(({ item, props, key }) => (
            <Card
              key={'TextPicScreen' + key + item.id}
              style={{
                ...props,
                transform: props.xyz.interpolate(transform3dInterpolation),
                zIndex: props.index.interpolate(zIndexInterpolation)
              }}
              card={item}
              screenStyles={screenStyles}
              playVideo={play_video}
            />
          ))
        ) : (
          <p style={{ color: '#fff' }}>Not enough cards</p>
        )}
      </div>
    </div>
  )
}

export const Card = ({ style, card, screenStyles, playVideo }) => {
  return (
    <animated.div
      native="true"
      className={
        'Card' +
        (card.media_file_url !== null ? ' img' : ' txt') +
        (card.text.length < 150 ? ' bis' : '')
      }
      style={{
        ...style,
        backgroundColor: getCardsBackgroundColorStyle(screenStyles)
      }}>
      <div className="media">
        <img
          src={card.media_file_url}
          alt=""
          onError={e => (e.target.src = SOCIAL_IMG_404_URL)}
        />
        {card.media_video_url !== null ? (
          <video loop={playVideo} autoPlay={playVideo} muted src={card.media_video_url} />
        ) : null}
      </div>
      <div className="content">
        <div className="name">
          {card.source === 'twitter' ? (
            <>
              <p>{truncateString(card.user_name, 25)}</p>
              <p style={{ color: getHandleColorStyle(screenStyles) }}>
                {`@${truncateString(card.user_handle, 25)}`}
              </p>
            </>
          ) : null}
        </div>
        <BiggerText fontSizeMax={60}>{truncateString(card.text, 282)}</BiggerText>
        <div className="source">
          <FontAwesomeIcon icon={['fab', card.source]} />
        </div>
      </div>
    </animated.div>
  )
}

export default connect(state => ({
  animatedTheme: state.currentWall.theme && state.currentWall.theme.animated,
  play_video: state.currentWall.play_video,
  rotation_time: state.currentWall.rotation_time.time
}))(TextPicScreen)
