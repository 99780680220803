import React from 'react'
import 'components/Input/Input.scss'
import ErrorCheckmark from 'assets/icons/ErrorCheckmark'
import SuccessCheckmark from 'assets/icons/SuccessCheckmark'
import Colors from 'setup/colors.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

/** A custom Input used everywhere in the application */
const Input = ({
  children,
  className,
  dirty,
  errorMessage = undefined,
  labelText,
  name,
  onChange,
  placeholder,
  prefix,
  type = 'text',
  value,
  ...rest
}) => {
  const inputContainerClass = classNames('Input', className, type, {
    HasError: errorMessage,
    WithPrefix: prefix
  })
  const inputClass = classNames({ notEmpty: value !== '' })

  const getValue = () => (value !== undefined ? value : '')

  return (
    <div className={inputContainerClass}>
      {prefix && <span className="Prefix">{prefix}</span>}
      <input
        className={inputClass}
        name={name}
        type={type}
        onChange={onChange}
        autoComplete="off"
        placeholder={placeholder}
        value={getValue()}
        {...rest}
      />
      {labelText ? <label className="Material">{labelText}</label> : null}
      {children}
      <div className="CheckMarks">
        {errorMessage ? (
          <ErrorCheckmark style={{ width: 18, height: 18, fill: Colors.danger }} />
        ) : dirty ? (
          <SuccessCheckmark />
        ) : null}
      </div>
      {errorMessage ? <div className="InputError">{errorMessage}</div> : null}
    </div>
  )
}

Input.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dirty: PropTypes.bool,
  errorMessage: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  rest: PropTypes.object
}

export default Input
