import React, { memo, useState } from 'react'
import 'components/Collapser/Collapser.scss'
import { animated, config, useSpring } from 'react-spring'

import Colors from 'setup/colors.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePrevious, useMeasure } from 'setup/react-spring-utils'
import PropTypes from 'prop-types'

/**
 *
 * Collapser component used in Pay and Activate page to display billing form
 * and in Customize Page to display layouts options
 */
const Collapser = memo(
  ({
    animContent = true,
    canToggle = true,
    children,
    iconSize = 20,
    open = false,
    showCaret = true,
    styles = {},
    title
  }) => {
    const collapserContentPadding = 12
    const [isOpen, setOpen] = useState(open)
    const previous = usePrevious(isOpen)
    const [bind, { height: viewHeight }] = useMeasure()
    const { height, opacity, rotate, transform } = useSpring({
      from: {
        height: 0,
        opacity: 0,
        transform: `translate3d(-${collapserContentPadding}px,0,0)`,
        rotate: `rotate(0deg)`
      },
      to: {
        height: isOpen ? viewHeight + collapserContentPadding * 2 : 0,
        opacity: isOpen ? 1 : 0,
        transform: `translate3d(${isOpen ? 0 : -collapserContentPadding}px,0,0)`,
        rotate: `rotate(${isOpen ? -180 : 0}deg)`
      },
      config: config.default
    })
    return (
      <div className="Collapser" style={{ ...styles }}>
        <div
          className="Collapser-header "
          onClick={() => {
            canToggle && setOpen(!isOpen)
          }}>
          {title}
          {showCaret ? (
            <animated.div style={{ willChange: 'transform', transform: rotate }}>
              <FontAwesomeIcon
                icon={['fas', 'chevron-down']}
                style={{
                  height: iconSize,
                  width: iconSize,
                  color: isOpen ? Colors.brand : '#ccc'
                }}
              />
            </animated.div>
          ) : null}
        </div>
        <animated.div
          className="Collapser-content"
          style={{
            opacity,

            height: isOpen && previous === isOpen ? 'auto' : height
          }}>
          <animated.div
            style={
              animContent
                ? { transform, padding: collapserContentPadding }
                : { padding: collapserContentPadding }
            }
            {...bind}>
            {children}
          </animated.div>
        </animated.div>
      </div>
    )
  }
)

Collapser.propTypes = {
  animContent: PropTypes.bool,
  canToggle: PropTypes.bool,
  children: PropTypes.node,
  iconSize: PropTypes.number,
  open: PropTypes.bool,
  showCaret: PropTypes.bool,
  styles: PropTypes.object,
  title: PropTypes.element
}

export default Collapser
