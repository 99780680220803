import React, { useState } from 'react'
import moment from 'moment'
import './TimezoneSelector.scss'
const TIMEZONES = moment.tz && moment.tz.names()

const TimezoneSelector = ({
  defaultValue = moment.tz.guess(),
  handleSubmit,
  storeValue
}) => {
  const [showTimezones, setShowTimezones] = useState(false)
  const [timezone, setTimezone] = useState(defaultValue)

  return (
    <div className="TimezoneSelector-container">
      <input
        type="text"
        className="TimezoneSelector-input"
        value={timezone}
        onChange={e => setTimezone(e.target.value)}
        onFocus={() => setShowTimezones(true)}
      />
      {showTimezones ? (
        <>
          <div
            className="TimezoneSelector-backdrop"
            onClick={() => setShowTimezones(false)}
          />
          <div className="TimezoneSelector-list">
            {TIMEZONES.length > 0 ? (
              TIMEZONES.map((_timezone, index) => (
                <div
                  className={`TimezoneSelector-list-item ${
                    _timezone === storeValue ? 'selected' : ''
                  }`}
                  key={index}
                  onClick={() => {
                    setShowTimezones(false)
                    setTimezone(_timezone)
                    handleSubmit({
                      timezone: _timezone
                    })
                  }}>
                  {_timezone}
                </div>
              ))
            ) : (
              <div
                className="TimezoneSelector-list-item"
                onClick={() => {
                  setShowTimezones(false)
                  setTimezone(defaultValue)
                  handleSubmit({
                    timezone: defaultValue
                  })
                }}>
                No timezone found
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default TimezoneSelector
