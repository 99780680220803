import React, { Component } from 'react'
import { connect } from 'react-redux'
import Title from 'components/Title/Title'
import Button from 'components/Button/Button'
import { doApplyThemeAction } from 'store/currentWall/actions/index'
import {
  doUpdateWall
} from 'store/currentWall/actions/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapser from 'components/Collapser/Collapser'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import Toggle from 'components/Toggle/Toggle'
import Uploader from 'components/Uploader/Uploader'
import _toString from 'lodash/toString'
import FontSelector from 'components/FontSelector/FontSelector'
import HashtagSelector from 'components/HashtagSelector/HashtagSelector'
import Colors from 'setup/colors.scss'
import Textarea from 'components/Textarea/Textarea'
import { getColorFromString, isLightColor } from 'setup/utils'
import '../Modal.scss'

const isTransparent = color =>
  color.length > 7 ? color.split(',')[3].split(')')[0] === '0' : color === ''

class CustomizeOptionsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmDelete: false
    }
  }

  async componentDidMount() {
    if (this.props.currentWall.theme_id === null) {
      this.props.doApplyThemeAction(1, this.props.wallId)
    }
  }

  getCollapserHeader = style => {
    let color = ''
    if (style.style_type === 'color_selector') {
      color = getColorFromString(style.value)
    }
    const currentScreen = this.props.currentWall.screens[this.props.currentScreenId]
    const currentBackgroundColor = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_color')
        ? currentScreen.styles.find(style => style.style_name === 'background_color')
            .value
        : null
      : null
    const currentBackgroundImage = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_image')
        ? currentScreen.styles.find(style => style.style_name === 'background_image')
            .value
        : null
      : null
    const icon = currentScreen ? (
      style.style_type === 'color_selector' ? (
        isTransparent(color) ? (
          <div className="transparent-circle" />
        ) : (
          <div
            className="color circle"
            style={{
              backgroundColor: color,
              boxShadow: `0 0 0 1px ${isLightColor(color) ? '#dadada' : '#fff'}`
            }}
          />
        )
      ) : style.style_type === 'text' ? (
        <FontAwesomeIcon
          icon={['fas', 'font']}
          style={{ width: 16, height: 16, color: Colors.brand }}
        />
      ) : style.style_type === 'upload_image' ? (
        <FontAwesomeIcon
          icon={['far', 'image']}
          style={{ width: 16, height: 16, color: Colors.brand }}
        />
      ) : style.style_type === 'upload_video' ? (
        <FontAwesomeIcon
          icon={['far', 'file-video']}
          style={{ width: 16, height: 16, color: Colors.brand }}
        />
      ) : style.style_type === 'background_color_image' ? (
        currentBackgroundImage !== '' ? (
          <FontAwesomeIcon
            icon={['far', 'file-video']}
            style={{ width: 16, height: 16, color: Colors.brand }}
          />
        ) : getColorFromString(currentBackgroundColor) === '' ? (
          <div className="transparent-circle" />
        ) : (
          <div
            className="color circle"
            style={{
              backgroundColor: getColorFromString(currentBackgroundColor),
              boxShadow: `0 0 0 1px ${
                isLightColor(getColorFromString(currentBackgroundColor))
                  ? '#dadada'
                  : '#fff'
              }`
            }}
          />
        )
      ) : null
    ) : null
    return (
      <>
        {icon}
        <span>{style.label}</span>
      </>
    )
  }

  getSelectedHashtag = () => {
    if (this.props.currentWall.styles.length > 0) {
      const value = this.props.currentWall.styles.find(
        style => style.style_name === 'displayed_hashtag'
      ).value
      return { value, label: value }
    }
    return ''
  }

  updateStyles = (id, data, screenId) => {
    this.props.doUpdateWall(this.props.currentWall.id, {
      styles_attributes: [
        {
          id,
          value: data
        }
      ]
    })
  }

  getStyleContent = (style, open) => {
    const propsAttributes = {
      key: style.id,
      title: this.getCollapserHeader(style),
      animContent: false,
      iconSize: 14,
      open
    }
    const currentScreen = this.props.currentWall.screens[this.props.currentScreenId]
    const currentBackgroundColor = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_color')
        ? currentScreen.styles.find(style => style.style_name === 'background_color')
            .value
        : null
      : null
    const currentBackgroundColorID = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_color')
        ? currentScreen.styles.find(style => style.style_name === 'background_color').id
        : null
      : null
    const currentBackgroundImage = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_image')
        ? currentScreen.styles.find(style => style.style_name === 'background_image')
            .value
        : null
      : null
    const currentBackgroundImageID = currentScreen
      ? currentScreen.styles.find(style => style.style_name === 'background_image')
        ? currentScreen.styles.find(style => style.style_name === 'background_image').id
        : null
      : null

    return currentScreen ? (
      style.style_type === 'color_selector' ? (
        <Collapser {...propsAttributes}>
          <ColorPicker
            value={style.value}
            setColor={color => this.updateStyles(style.id, color)}
          />
        </Collapser>
      ) : style.style_type === 'font_selector' ? (
        <div className="Field" key={style.id}>
          <div className="Field-icon">
            <FontAwesomeIcon
              icon={['fas', 'font']}
              style={{ width: 16, height: 16, color: Colors.brand }}
            />
          </div>
          <FontSelector
            font={style.value}
            updateFont={font => this.updateStyles(style.id, font)}
          />
        </div>
      ) : style.style_type === 'hashtag_selector' ? (
        <div className="Field" key={style.id}>
          <div className="Field-icon">
            <FontAwesomeIcon
              icon={['fas', 'hashtag']}
              style={{ width: 16, height: 16, color: Colors.brand }}
            />
          </div>
          <HashtagSelector
            hashtags={this.props.currentWall.keywords}
            selectedHashtag={this.getSelectedHashtag()}
            updateHashtag={hashtag => this.updateStyles(style.id, hashtag)}
          />
        </div>
      ) : style.style_type === 'text' ? (
        <Collapser {...propsAttributes}>
          <div className="textarea">
            <Textarea
              value={style.value}
              updateText={text => this.updateStyles(style.id, text)}
            />
          </div>
        </Collapser>
      ) : style.style_type === 'boolean' ? (
        <div className="toggleOptions" key={style.id}>
          <Toggle
            isChecked={style.value}
            onToggle={checked => this.updateStyles(style.id, _toString(checked))}
            className="Toggle-small"
            textAfter={style.label}
          />
        </div>
      ) : style.style_type === 'upload_image' ? (
        <Collapser {...propsAttributes}>
          <Uploader
            file={style.value}
            currentScreenId={currentScreen.id}
            currentWallId={this.props.currentWall.id}
            updateFile={(url, screenId) => this.updateStyles(style.id, url, screenId)}
          />
        </Collapser>
      ) : style.style_type === 'upload_video' ? (
        <Collapser {...propsAttributes}>
          <Uploader
            file={style.value}
            currentScreenId={currentScreen.id}
            currentWallId={this.props.currentWall.id}
            type="video"
            updateFile={(url, screenId) => this.updateStyles(style.id, url, screenId)}
          />
        </Collapser>
      ) : style.style_type === 'background_color_image' ? (
        <Collapser {...propsAttributes}>
          <div className="uploader-colorpicker">
            <Uploader
              file={currentBackgroundImage}
              currentScreenId={currentScreen.id}
              currentWallId={this.props.currentWall.id}
              updateFile={(url, screenId) =>
                this.updateStyles(currentBackgroundImageID, url, screenId)
              }
            />
          </div>
          {currentBackgroundImage === '' ? (
            <ColorPicker
              value={currentBackgroundColor}
              setColor={color => this.updateStyles(currentBackgroundColorID, color)}
            />
          ) : null}
        </Collapser>
      ) : null
    ) : null
  }

  customSort = array => {
    const itemOrdered = [
      'font_family_1',
      'font_family_2',
      'displayed_hashtag',
      'logo',
      'background_color_image',
      'background_color',
      'background_image',
      'background_video',
      'text_1',
      'text_2',
      'text_color',
      'handle_color',
      'hashtag_color',
      'cards_background_color',
      'top_bar_background_color',
      'logo_enable',
      'hashtag_enable',
      'top_bar_enable'
    ]

    return [...array].sort(function(a, b) {
      var itemA = a['style_name'],
        itemB = b['style_name']

      if (itemOrdered.indexOf(itemA) > itemOrdered.indexOf(itemB)) {
        return 1
      } else {
        return -1
      }
    })
  }

  stylesInputs = styles => {
    if (styles !== undefined) {
      let _styles = styles.filter(style => style.style_name !== 'preview_img')

      _styles = _styles.filter(
        style =>
          style.style_name !== 'font_family_2' &&
          style.style_name !== 'preview_img' &&
          style.style_name !== 'background_color' &&
          style.style_name !== 'background_image' &&
          style.style_name !== 'background_video' &&
          style.style_name !== 'cards_background_color' &&
          style.style_name !== 'handle_color' &&
          style.style_name !== 'hashtag_color' &&
          style.style_name !== 'text_color' &&
          style.style_name !== 'top_bar_background_color'
      )

      return this.customSort(_styles).map(style =>
        this.getStyleContent(style, _styles.length === 1)
      )
    } else {
      return null
    }
  }

  render() {
  	const {
      currentWall: { id: wallId, styles, theme_id },
    } = this.props
    const hashtag_style = styles.filter(style => style.style_name === 'hashtag_selector')
	  return (
	    <div className="HelpModal CustomizePage">
	      <div className="SectionTitle">
	        <Title className="medium">general options</Title>
	      </div>
	      <div>
					{this.stylesInputs(styles)}
					{this.state.confirmDelete ? (
					<div className="confirm-reset-theme">
				    <p className="confirm-reset-theme__title">Are you sure?</p>
					  <div>
					    <Button
					      className="grey small half-width"
					      onClick={() => this.setState({ confirmDelete: false })}>
					      no
					    </Button>
					    <Button
					      className="danger small half-width"
					      onClick={async () => {
					        this.props.doApplyThemeAction(theme_id, wallId)
                  this.updateStyles(hashtag_style.id, hashtag_style)
					        this.setState({ confirmDelete: false })
					      }}>
					      yes
					    </Button>
					  </div>
					</div>
					) : (
					<Button
					  className="danger full-width"
					  onClick={() => this.setState({ confirmDelete: true })}>
					  reset theme to default
					</Button>
					)}
				</div>
	    </div>
	  )
  }
}

export default connect(
  state => ({
    currentWall: state.currentWall,
    currentScreenId: state.wallbar.selectedLayout
  }),
  {
    doApplyThemeAction,
    doUpdateWall,
  }
)(CustomizeOptionsModal)
