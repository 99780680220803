import React, { useEffect, useReducer, useRef } from 'react'
import './PayModal.scss'
import { connect } from 'react-redux'
import Title from 'components/Title/Title'
import classNames from 'classnames'
import PaymentService from 'services/api/paymentService'
import { updateCurrentWallAction } from 'store/currentWall/actions/index'
import { closeModalAction } from 'store/modal/modalReducer'
import Button from 'components/Button/Button'
const PROCESSING = 'PROCESSING'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

function reducer(state, action) {
  switch (action.type) {
    case PROCESSING:
      return {
        processState: PROCESSING,
        message: 'Your payment is currently being processed'
      }
    case SUCCESS:
      return {
        processState: SUCCESS,
        message: 'Your payment has been processed succesfully'
      }
    case ERROR:
      return {
        processState: ERROR,
        message: 'There was an error validating your payment.',
        reason: action.payload
      }
    default:
      throw new Error()
  }
}

export const PayModal = ({ closeModalAction, data, updateCurrentWallAction }) => {
  const [processingState, setProcessingState] = useReducer(reducer, {})
  let timer = useRef(null)

  useEffect(() => {
    setProcessingState({ type: PROCESSING })
    PaymentService.pay(data)
      .then(response => {
        updateCurrentWallAction(response.event)
        setProcessingState({ type: SUCCESS })
        timer.current = setTimeout(() => closeModalAction(), 1000)
      })
      .catch(error => {
        setProcessingState({
          type: ERROR,
          payload: error && error?.data?.errors ? error?.data?.errors?.message[0] : ''
        })
        timer.current = setTimeout(() => closeModalAction(), 10000)
      })

    return () => clearTimeout(timer.current)
  }, [closeModalAction, data, updateCurrentWallAction])

  return (
    <div className="PayModal">
      <Title className="medium">payment processing</Title>
      <span>{processingState.message}</span>
      {processingState.reason ? (
        <>
          <br />
          <strong className="danger-text-color">{processingState.reason}</strong>
          <br />
          <Button onClick={() => closeModalAction()}>close</Button>
        </>
      ) : (
        <div className={classNames('progress-bar', processingState.processState)}></div>
      )}
    </div>
  )
}

export default connect(
  state => ({
    data: state.modal.payData
  }),
  { closeModalAction, updateCurrentWallAction }
)(PayModal)
