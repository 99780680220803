import React from 'react'
import './ThemePageSidebar.scss'
import Title from 'components/Title/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const ThemePageSidebarItem = ({ className, count, setFilter, title }) => {
  return (
    <div
      className={classNames('sidebarItem', className)}
      onClick={count > 0 ? () => setFilter() : null}>
      <span>{title.toLowerCase()}</span>
      <span className="available-items">{count}</span>
    </div>
  )
}

const ThemePageSidebar = ({ categories, currentCategory, setFilter, themes }) => {
  const getCountCategory = value => {
    return value !== 'ALL'
      ? themes.filter(theme => value.id === theme.theme_category_id).length
      : themes.length
  }

  return (
    <div className="sidebarTheme">
      <div className="categorySidebar">
        <Title className="small no-bg">Category</Title>
        <ThemePageSidebarItem
          className={classNames({ active: !currentCategory.id })}
          setFilter={() => {
            setFilter('ALL')
          }}
          title="all"
          count={getCountCategory('ALL')}
        />

        {categories.map(category =>
          getCountCategory(category) > 0 ? (
            <ThemePageSidebarItem
              className={classNames({ active: category.name === currentCategory.name })}
              setFilter={() => setFilter(category)}
              key={category.id}
              title={category.name}
              count={getCountCategory(category)}
            />
          ) : null
        )}
      </div>

      <div className="legends">
        <div>
          <div className="round-background-danger">
            <FontAwesomeIcon
              className="logoFilm"
              icon="film"
              style={{ width: 16, height: 16, color: '#fff' }}
            />
          </div>
          <p>animated themes</p>
        </div>
      </div>
    </div>
  )
}

export default ThemePageSidebar
