// I'm using a state sanitizer, in order to make redux not crash, this one works.
export default state =>
  state.modal
    ? {
        ...state,
        modal: {
          ...state.modal,
          properties: {
            ...state.modal.properties,
            children: '<<CHILDREN>>',
            component: '<<COMPONENT>>'
          }
        }
      }
    : state
