import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import './Header.scss'
import AccountMenu from './AccountMenu'
import SessionService from 'services/api/SessionService'
import { withRouter } from 'react-router'
import { isHomePage } from 'routes/routesUtils'
import wallrusHeaderLogo from 'assets/images/wallrus-header-logo.png'
import Title from 'components/Title/Title'
import Countdown from 'components/Countdown/Countdown'
import { isValidScreen } from 'services/app/screen'
import { updateCurrentWallAction } from 'store/currentWall/actions/index'
import _every from 'lodash/every'
import GoTo from 'components/Goto/GoTo'
import Button from 'components/Button/Button'

const getStatus = validity => {
  if (_every(validity, ['valid', true]) === true) {
    return 'valid'
  } else if (_every(validity, ['valid', []]) === true) {
    return 'danger'
  } else {
    return 'warning'
  }
}

const Header = ({
  currentWall: { cards, live, live_until, name, screens, status },
  planType,
  updateCurrentWallAction
}) => {
  useEffect(() => {
    const check = () =>
      screens
        .filter(screen => screen.enabled)
        .map(screen => isValidScreen(screen, cards.items, false, true))

    if (cards.items.length > 0 && screens.length > 0) {
      const status_info = check()
      updateCurrentWallAction({ status_info, status: getStatus(status_info) })
    } else {
      if (status !== '') {
        updateCurrentWallAction({ status_info: [], status: '' })
      }
    }
  }, [cards, screens, status, updateCurrentWallAction])

  return (
    <header className="Header">
      {!isHomePage() && SessionService.isLoggedIn() ? (
        <div className="TitleHeader">
          <div>
            <Title>{name}</Title>
            <p className="plan">
              {planType}
              {live ? <Countdown liveUntil={live_until} /> : null}
            </p>
          </div>
          <div>
            {' '}
            <GoTo route="preview">
              <Button className="small"> {live ? 'Go live' : 'Preview your wall'}</Button>
            </GoTo>
          </div>
        </div>
      ) : (
        <div className="TitleButtonsContainer">
          <div className="WallrusHeaderLogo">
            <img src={wallrusHeaderLogo} alt="WallrusHeaderLogo" />
          </div>
        </div>
      )}
      {!SessionService.isLoggedIn() ? null : <AccountMenu />}
    </header>
  )
}

export default withRouter(
  connect(
    state => ({
      validity: state.currentWall.status_info,
      currentWall: state.currentWall,
      planType: state.currentWall.plan.name
    }),
    { updateCurrentWallAction }
  )(Header)
)
