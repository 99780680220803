import request from 'services/requestWrapper.js'

function getAll() {
  return request({
    url: `/layouts`,
    method: 'GET'
  })
}

const LayoutService = {
  getAll
}

export default LayoutService
