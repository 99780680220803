import React, { useEffect, useState } from 'react'
import { isHomePage } from 'routes/routesUtils'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SidebarPanel from './SidebarPanel'
import { setSidebarActiveRouteId } from 'store/sidebar/sidebarReducer'
import './Sidebar.scss'
import { Link } from 'react-router-dom'
import WallrusLogo from 'assets/images/wallrus-2016-white-copy@3x.png'
import WallrusLogoSolo from 'assets/images/wallrus-logo-solo.png'
import { Spring } from 'react-spring/renderprops'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { closeModalAction } from 'store/modal/modalReducer'
import _compact from 'lodash/compact'
import { getSidebarState, SIDEBAR } from 'setup/sidebar'

const ITEM_HEIGHT = 45
const PANEL_PADDING_BOTTOM = 20

const Sidebar = ({
  activeRouteId,
  closeModalAction,
  history,
  location,
  currentWall: { plan_id, screens, theme_id },
  setSidebarActiveRouteId,
  user
}) => {
  const [layoutErrorMessage, setLayoutErrorMessage] = useState('')
  const [themeErrorMessage, setThemeErrorMessage] = useState('')
  const [sidebarState, setSidebarState] = useState(SIDEBAR)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (user.email !== '') {
      setSidebarState(getSidebarState(user, plan_id))
      setReady(true)
    }
  }, [plan_id, user])

  useEffect(() => {
    const activeItem = _compact(
      sidebarState.map(panel =>
        panel.items.find(item => location.pathname.includes(item.route))
      )
    )[0]
    if (activeItem) {
      setSidebarActiveRouteId(activeItem.id)
    } else {
      history.push('/')
    }
  }, [history, location.pathname, setSidebarActiveRouteId, sidebarState])

  useEffect(() => {
    if (activeRouteId !== undefined && activeRouteId !== 1) {
      if (screens.length === 0) {
        setLayoutErrorMessage('has missing layout')
      } else {
        setLayoutErrorMessage('')
      }
    }
    if (activeRouteId !== undefined && activeRouteId === 3 && screens.length > 0) {
      if (theme_id === null) {
        setThemeErrorMessage('select a theme')
      } else {
        setThemeErrorMessage('')
      }
    }
  }, [activeRouteId, screens.length, theme_id])

  useEffect(() => {
    if (screens.length > 0) {
      setLayoutErrorMessage('')
    }
  }, [screens])

  useEffect(() => {
    if (theme_id !== null) {
      setThemeErrorMessage('')
    }
  }, [theme_id])

  const getActivePanel = () => {
    return sidebarState
      .map(
        panel =>
          panel.items.filter(item => location.pathname.includes(item.route)).length > 0
      )
      .findIndex(item => item)
  }

  const getPanelStyle = index => {
    const isActive =
      sidebarState[index].items.find(item => item.id === activeRouteId) !== undefined
    return {
      opacity: isActive ? 1 : 0,
      transform: isActive ? `translate3d(0,0,0)` : `translate3d(-15px,0,0)`,
      height: isActive
        ? sidebarState[index].items.filter(item => item.minPlan <= plan_id).length *
            ITEM_HEIGHT +
          PANEL_PADDING_BOTTOM
        : 0
    }
  }

  return !isHomePage() && ready ? (
    <div className="Sidebar">
      <div className="Sidebar-Wallrus-logo">
        {!isHomePage() ? (
          <Link to="/" onClick={() => closeModalAction()}>
            <img className="WallrusLogo" src={WallrusLogo} alt="Back Home" />
            <img className="WallrusLogoSolo" src={WallrusLogoSolo} alt="Back Home" />
          </Link>
        ) : null}
      </div>

      {sidebarState.map((panel, index) => (
        <Spring
          key={panel.name}
          from={{
            opacity: 0,
            transform:
              getActivePanel() === index
                ? `translate3d(0,0,0)`
                : `translate3d(-15px,0,0)`,
            height:
              getActivePanel() === index
                ? panel.items.filter(item => item.minPlan <= plan_id).length *
                    ITEM_HEIGHT +
                  PANEL_PADDING_BOTTOM
                : 0
          }}
          to={getPanelStyle(index)}>
          {styles => (
            <SidebarPanel
              errorMessages={{ layout: layoutErrorMessage, theme: themeErrorMessage }}
              index={index + 1}
              isActivePanel={getActivePanel() === index}
              panel={panel}
              styles={styles}
            />
          )}
        </Spring>
      ))}
      <Link className="Sidebar-back-dashboard" to="/" onClick={() => closeModalAction()}>
        <FontAwesomeIcon
          className="fa-fw"
          icon={['fas', 'chevron-left']}
          style={{ color: '#fff' }}
        />
        <span>back to wall list</span>
      </Link>
    </div>
  ) : null
}

export default withRouter(
  connect(
    state => ({
      activeRouteId: state.sidebar.activeRouteId,
      currentWall: state.currentWall,
      user: state.user
    }),
    {
      closeModalAction,
      setSidebarActiveRouteId
    }
  )(Sidebar)
)
