import React, { Component } from 'react'
import WallListItem from './WallListItem/WallListItem'
import { connect } from 'react-redux'
import { doDeleteWallAction, doFetchAllWallsAction } from 'store/walls/wallsReducer'
import './WallList.scss'
import Title from 'components/Title/Title'

export class WallList extends Component {
  render() {
    const { walls, doDeleteWallAction } = this.props

    return walls && walls.length ? (
      <div className="WallsList">
        <Title className="inverted">your existing walls</Title>
        <div className="WallsListContainer">
          {walls.map((wall, index) => (
            <WallListItem
              key={index}
              {...wall}
              doDeleteWallAction={doDeleteWallAction}
              user={this.props.user}
            />
          ))}
        </div>
      </div>
    ) : null
  }
}

export default connect(state => ({ walls: state.walls, user: state.user }), {
  doDeleteWallAction,
  doFetchAllWallsAction
})(WallList)
