import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { showLogger, nonEmptyValues } from './storeUtils'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import loginReducer from 'store/user/userReducer'
import payFormReducer from 'store/payFormReducer/payFormReducer'
import currentWallReducer from './currentWall/currentWallReducer'
import wallsReducer from './walls/wallsReducer'
import modalReducer from './modal/modalReducer'
import sidebarReducer from './sidebar/sidebarReducer'
import plansReducer from './plans/plansReducer'
import selectPlanListReducer from 'store/selectPlanList/selectPlanListReducer'
import themesReducer from './themes/themesReducer'
import wallbarReducer from './wallbar/wallbarReducer'
import layoutReducer from './layout/layoutReducer'
import stateSanitizer from './stateSanitizers'
import environment from 'services/app/environment'

const wallrusReducers = combineReducers({
  currentWall: currentWallReducer,
  modal: modalReducer,
  plans: plansReducer,
  layouts: layoutReducer,
  selectPlanList: selectPlanListReducer,
  sidebar: sidebarReducer,
  themes: themesReducer,
  user: loginReducer,
  wallbar: wallbarReducer,
  walls: wallsReducer,
  payForm: payFormReducer
})

const options = environment.isDev()
  ? { stateSanitizer, trace: true, traceLimit: 25 }
  : { stateSanitizer }

const composeEnhancers = composeWithDevTools(options)

const middlewares = [thunk, showLogger()].filter(nonEmptyValues())

export default createStore(
  wallrusReducers,
  composeEnhancers(applyMiddleware(...middlewares))
)
