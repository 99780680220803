import React from 'react'
import Badge from 'components/Badge/Badge'
import Toggle from 'components/Toggle/Toggle'
import DurationPicker from 'components/DurationPicker/DurationPicker'
import Colors from 'setup/colors.scss'
import { DragSource, DropTarget } from 'react-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CARDS_PLACEHOLDER } from 'services/app/cardsPlaceholder'
import {
  TEXT_POST_ONLY_1,
  PIC_POST_ONLY_1,
  PIC_POST_ONLY_2,
  MOSAIC_1,
  MASONRY_1,
  TEXT_PIC_1,
  TEXT_PIC_2,
  IMAGE_1,
  MULTIPLES_IMAGES_1,
  VIDEO_1,
  CTA_1
} from 'services/app/screen'
import CTAScreen from 'components/Visualizer/Screens/CTAScreen/CTAScreen'
import VideoScreen from 'components/Visualizer/Screens/VideoScreen/VideoScreen'
import ImageScreen from 'components/Visualizer/Screens/ImageScreen/ImageScreen'
import TextPicScreen from 'components/Visualizer/Screens/TextPicScreen/TextPicScreen'
import FadeScreen from 'components/Visualizer/Screens/FadeScreen/FadeScreen'
import TextPostScreen from 'components/Visualizer/Screens/TextPostScreen/TextPostScreen'

const screenSource = {
  beginDrag(props) {
    return {
      id: props.id
    }
  },

  endDrag(props) {
    props.sortScreens()
  }
}

const screenTarget = {
  hover(props, monitor) {
    const { id: draggedId } = monitor.getItem()
    const { id: overId } = props

    if (draggedId !== overId) {
      const { index: hoverIndex } = props.findScreen(overId)

      props.moveScreen(draggedId, hoverIndex)
    }
  }
}

const PlaylistScreen = ({
  connectDragSource,
  connectDropTarget,
  displayName,
  displayPosition,
  duration,
  enabled,
  id,
  isDragging,
  name,
  onChangeDuration,
  onToggle,
  planId,
  screen
}) => {
  const screensParams = {
    cards: CARDS_PLACEHOLDER,
    forceBackground: true,
    isRunning: false,
    screen,
    showPlaceholder: true
  }

  const scaledDownScreen = {
    [TEXT_POST_ONLY_1]: <TextPostScreen {...screensParams} />,
    [PIC_POST_ONLY_1]: <FadeScreen screenType={PIC_POST_ONLY_1} {...screensParams} />,
    [PIC_POST_ONLY_2]: <FadeScreen screenType={PIC_POST_ONLY_2} {...screensParams} />,
    [MOSAIC_1]: <FadeScreen screenType={MOSAIC_1} {...screensParams} />,
    [MASONRY_1]: <FadeScreen screenType={MASONRY_1} {...screensParams} />,
    [TEXT_PIC_1]: <FadeScreen screenType={TEXT_PIC_1} {...screensParams} />,
    [TEXT_PIC_2]: <TextPicScreen {...screensParams} />,
    [IMAGE_1]: <ImageScreen {...screensParams} />,
    [MULTIPLES_IMAGES_1]: <ImageScreen {...screensParams} />,
    [VIDEO_1]: <VideoScreen {...screensParams} />,
    [CTA_1]: <CTAScreen {...screensParams} />
  }
  return connectDragSource(
    connectDropTarget(
      <div
        style={{ opacity: isDragging ? 0.4 : 1 }}
        className={`PlaylistScreen ${enabled ? 'screen-on' : 'screen-off'}`}
        id={id}
        title={name}>
        <Badge type="text">{displayPosition}</Badge>
        <div className="screen-info">
          <div className="preview-screen">
            {screen && screen.screen_type ? scaledDownScreen[screen.screen_type] : null}
          </div>
          <div className="screen-name">{displayName}</div>
        </div>
        <div className="actions">
          <Toggle isChecked={enabled} onToggle={onToggle} />

          <div className="screen-duration">
            <DurationPicker
              duration={duration}
              onChange={onChangeDuration}
              planId={planId}
            />
          </div>
          <div className="screen-move-icon handle-ordering">
            <FontAwesomeIcon
              icon={'arrows-alt'}
              style={{ height: 20, width: 20, color: Colors.brand }}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default DropTarget('screen', screenTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(
  DragSource('screen', screenSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(PlaylistScreen)
)
