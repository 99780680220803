import React, { Component } from 'react'
import SessionService from 'services/api/SessionService'

class LogoutButton extends Component {
  handleClick = e => {
    e.stopPropagation()
    SessionService.logOut()
  }
  render() {
    return (
      <div className="logoutButton" onClick={this.handleClick}>
        Log out
      </div>
    )
  }
}

export default LogoutButton
