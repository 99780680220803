import WallService from 'services/api/WallService'
import { BASIC_PLAN } from 'setup/constants'

const ADD_WALL = 'ADD_WALL'
const REMOVE_WALL = 'REMOVE_WALL'
const SET_WALLS = 'SET_WALLS'
const DELETE_CHILDUSERS = 'DELETE_CHILDUSERS'
const UPDATE_CHILDUSERS = 'UPDATE_CHILDUSERS'

export const doFetchAllWallsAction = () => async dispatch => {
  try {
    const walls = await WallService.getAll()
    dispatch(addAllWallsAction(walls))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export const doCreateWallAction = (name, planId = BASIC_PLAN) => async dispatch => {
  let wall
  try {
    wall = await WallService.create(name, planId)
    dispatch(addWallAction(wall))
  } catch (error) {
    console.log(error)
    let message
    if (error.response) {
      if (error.response.data.errors) {
        message = error.response.data.errors[0].message
      } else if (error.response.data.name) {
        message = error.response.data.name[0]
      }
    }
    console.error(message)
  } finally {
    return new Promise(resolve => {
      resolve(wall)
    })
  }
}

export const doDeleteWallAction = id => async dispatch => {
  try {
    await WallService.destroy(id)
    dispatch(deleteWallAction(id))
  } catch (error) {
    console.log(error)
  }
}

export const addWallAction = wall => ({
  type: ADD_WALL,
  payload: wall
})

export const addAllWallsAction = walls => ({
  type: SET_WALLS,
  payload: walls
})

export const deleteWallAction = id => ({
  type: REMOVE_WALL,
  payload: id
})

export const deleteChildUsersAction = childuserId => ({
  type: DELETE_CHILDUSERS,
  payload: childuserId
})

export const updateChildUsersAction = (id, childusers) => ({
  type: UPDATE_CHILDUSERS,
  payload: { id, childusers }
})

const initState = []

export default (walls = initState, action) => {
  switch (action.type) {
    case ADD_WALL:
      return [...walls, action.payload]
    case REMOVE_WALL:
      let newWalls = walls.filter(wall => wall.id !== action.payload)
      return [...newWalls]
    case SET_WALLS:
      return [...action.payload]
    case DELETE_CHILDUSERS:
      return walls.map(wall => {
        return {
          ...wall,
          childusers: wall.childusers.filter(childuser => childuser.id !== action.payload)
        }
      })
    case UPDATE_CHILDUSERS:
      return walls.map(wall => {
        if (wall.id === action.payload.id) {
          return {
            ...wall,
            childusers: action.payload.childusers
          }
        }
        return wall
      })

    default:
      return walls
  }
}
