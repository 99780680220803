import React, { Component } from 'react'
import { connect } from 'react-redux'
import './PlaylistPage.scss'
import './PlaylistScreen.scss'
import Title from 'components/Title/Title'

import FloatingActions from 'components/FloatingActions/FloatingActions'
import FloatingButton from 'components/FloatingActions/FloatingButton'

import Colors from 'setup/colors.scss'

import { doSortScreens, doUpdateScreen } from 'store/currentWall/actions/index'
import { setSidebarActiveRouteId } from 'store/sidebar/sidebarReducer'

import Container from 'components/Container/Container'
import _sortBy from 'lodash/sortBy'
import PlaylistScreen from './PlaylistScreen'
import { truncateString } from 'setup/utils'
import Loader from 'components/Loader/Loader'
import update from 'immutability-helper'
import SettingsIcon from 'assets/icons/SettingsIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openModalAction } from 'store/modal/modalReducer'
import { Link } from 'react-router-dom'

class PlaylistPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screens: [],
      displayMode: 'LINE'
    }
  }

  static getDerivedStateFromProps(props, state) {
    return state.screens.length === 0
      ? { screens: _sortBy(props.screens, 'position') }
      : {}
  }

  toggleDisplayMode(mode) {
    if (mode && this.state.displayMode !== mode) this.setState({ displayMode: mode })
  }

  displayModeActiveClass(mode) {
    return this.state.displayMode === mode ? 'brand-fill-color' : 'grey-fill-color'
  }

  displayMode() {
    return this.state.displayMode === 'LINE' ? 'line-mode' : 'square-mode'
  }

  displayNameLength() {
    return this.state.displayMode === 'LINE' ? 200 : 40
  }

  moveScreen = (screenId, atIndex) => {
    const { screen, index } = this.findScreen(screenId)
    this.setState(
      update(this.state, {
        screens: {
          $splice: [[index, 1], [atIndex, 0, screen]]
        }
      })
    )
  }

  findScreen = screenId => {
    const { screens } = this.state
    const screen = screens.filter(_screen => _screen.id === screenId)[0]
    return {
      screen,
      index: screens.indexOf(screen)
    }
  }

  sortScreens = () => {
    const sortedScreensID = this.state.screens.map(screen => parseInt(screen.id, 10))
    this.props.doSortScreens(this.props.wallId, sortedScreensID)
  }

  updateScreen = (screenId, enabled) => {
    const screens = this.state.screens.map(screen => {
      if (screen.id !== screenId) {
        return screen
      }
      return {
        ...screen,
        enabled: enabled
      }
    })

    this.setState({ screens }, () => {
      this.props.doUpdateScreen(screenId, { enabled: enabled })
    })
  }

  render() {
    const {
      doUpdateScreen,
      openModalAction,
      planId,
      setSidebarActiveRouteId,
      wallId
    } = this.props
    const { screens } = this.state
    return (
      <Container className="PlaylistPage">
        <FloatingActions>
          <FloatingButton
            onClick={() => openModalAction('OPTIONS_PLAYLIST')}
            label="options">
            <SettingsIcon style={{ height: 20, width: 20, fill: Colors.brand }} />
          </FloatingButton>
          <FloatingButton onClick={() => openModalAction('HELP_PLAYLIST')} label="info">
            <FontAwesomeIcon icon={'question'} style={{ color: Colors.brand }} />
          </FloatingButton>
        </FloatingActions>
        <div className="PlaylistScreensContainer">
          <div className="PlaylistScreensDisplayToggle">
            <FontAwesomeIcon
              className={this.displayModeActiveClass('LINE')}
              onClick={() => this.toggleDisplayMode('LINE')}
              icon="grip-lines"
              style={{ width: 24, height: 24 }}
            />

            <FontAwesomeIcon
              className={this.displayModeActiveClass('SQUARE')}
              onClick={() => this.toggleDisplayMode('SQUARE')}
              icon="grip-horizontal"
              style={{ width: 24, height: 24 }}
            />
          </div>
          {screens.length > 0 ? (
            <div className={`PlaylistScreensList ${this.displayMode()}`}>
              {screens.map((screen, index) => (
                <PlaylistScreen
                  key={screen.id}
                  sortScreens={this.sortScreens}
                  planId={planId}
                  index={index}
                  findScreen={this.findScreen}
                  moveScreen={this.moveScreen}
                  displayName={truncateString(screen.name, this.displayNameLength())}
                  displayPosition={index + 1}
                  onChangeDuration={duration => doUpdateScreen(screen.id, { duration })}
                  screen={screen}
                  onToggle={() => {
                    this.updateScreen(screen.id, !screen.enabled)
                  }}
                  {...screen}
                />
              ))}
            </div>
          ) : (
            <div className="NoScreenPage">
              <Title className="medium bold">where are the screens?</Title>
              <span>You currently do not have any screens.</span>
              <span>
                Go to{' '}
                <Link
                  to={`/wall/${wallId}/layout`}
                  onClick={() => setSidebarActiveRouteId(1)}>
                  <b>LAYOUT</b>
                </Link>{' '}
                to add more screens to your wall
              </span>
              <Loader />
            </div>
          )}
        </div>
      </Container>
    )
  }
}

export default connect(
  state => ({
    planId: state.currentWall.plan_id,
    screens: state.currentWall.screens,
    currentWall: state.currentWall
  }),
  { doSortScreens, doUpdateScreen, openModalAction, setSidebarActiveRouteId }
)(PlaylistPage)
