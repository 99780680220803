export const ROOT_PATH = '/';
export const ABOUT = '/about';
export const CONTACT = '/about';

export const LAYOUT_PATH = 'layout';
export const THEME_PATH = 'theme';
export const CUSTOMIZE_PATH = 'customize';
export const SETUP_PATH = 'setup';
export const PLAYLIST_PATH = 'playlist';
export const MODERATION_PATH = 'moderation';
export const STATS_PATH = 'stats';
export const PREVIEW_PATH = 'preview';
export const PAY_PATH = 'pay';
export const EMBED_PATH = 'embed';
export const FULLSCREEN_PATH = 'fullscreen';

export const WALL_BASE_ROUTE = '/wall/:id(\\d+)';
export const LAYOUT_ROUTE = `${WALL_BASE_ROUTE}/${LAYOUT_PATH}`;
export const THEME_ROUTE = `${WALL_BASE_ROUTE}/${THEME_PATH}`;
export const CUSTOMIZE_ROUTE = `${WALL_BASE_ROUTE}/${CUSTOMIZE_PATH}`;
export const SETUP_ROUTE = `${WALL_BASE_ROUTE}/${SETUP_PATH}`;
export const PLAYLIST_ROUTE = `${WALL_BASE_ROUTE}/${PLAYLIST_PATH}`;
export const MODERATION_ROUTE = `${WALL_BASE_ROUTE}/${MODERATION_PATH}`;
export const STATS_ROUTE = `${WALL_BASE_ROUTE}/${STATS_PATH}`;
export const PREVIEW_ROUTE = `${WALL_BASE_ROUTE}/${PREVIEW_PATH}`;
export const PAY_ROUTE = `${WALL_BASE_ROUTE}/${PAY_PATH}`;
export const EMBED_ROUTE = `${WALL_BASE_ROUTE}/${EMBED_PATH}`;
export const FULLSCREEN_ROUTE = `${WALL_BASE_ROUTE}/${FULLSCREEN_PATH}`;
