import React, { useState } from 'react'
import SelectPlanItem from './SelectPlanItem/SelectPlanItem'
import { connect } from 'react-redux'
import Button from 'components/Button/Button'
import { doCreateWallAction } from 'store/walls/wallsReducer'
import './SelectPlanList.scss'
import Title from 'components/Title/Title'
import { withRouter } from 'react-router-dom'
import { hideSelectPlansAction } from 'store/selectPlanList/selectPlanListReducer'
import LowerBanner from '../LowerBanner/LowerBanner'

export const SelectPlanList = ({
  doCreateWallAction,
  hideSelectPlansAction,
  history,
  plans,
  showPlans,
  wallName,
  ...rest
}) => {
  const [selectedPlanId, setSelectedPlanId] = useState(null)

  const hidePlansAndUnselectPlan = () => {
    setSelectedPlanId(null)
    hideSelectPlansAction()
  }

  const handleSubmit = async (e, planId) => {
    e.preventDefault()
    setSelectedPlanId(null)
    let wall = await doCreateWallAction(wallName, planId)
    wall && history.push(`/wall/${wall.id}/layout`)
  }

  return (
    <form
      className={`SelectPlanList ${showPlans ? 'visible' : ''}`}
      onSubmit={e => handleSubmit(e, selectedPlanId)}
      {...rest}>
      <Button
        tabIndex="-1"
        className="BackButton"
        type="button"
        onClick={hidePlansAndUnselectPlan}>
        Back
      </Button>
      <Title>select your plan</Title>
      <div className="SelectPlanListItemsContainer">
        {plans
          ? plans.map((plan, index) => {
              const props = {
                selected: selectedPlanId === plan.id,
                ...plan
              }
              return (
                <SelectPlanItem selectPlan={setSelectedPlanId} key={index} {...props} />
              )
            })
          : null}
      </div>

      <Button
        tabIndex="-1"
        className="Next"
        disabled={!selectedPlanId}
        onClick={e => handleSubmit(e, selectedPlanId)}
        type="submit">
        Next
      </Button>
      <LowerBanner />
    </form>
  )
}

export default withRouter(
  connect(
    state => ({
      plans: state.plans.items,
      showPlans: state.selectPlanList.showPlans,
      wallName: state.selectPlanList.newWall.name
    }),
    { doCreateWallAction, hideSelectPlansAction }
  )(SelectPlanList)
)
