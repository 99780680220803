import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { updatePayFormAction } from 'store/payFormReducer/payFormReducer'

import {
  CountryDropdown,
  CountryRegionData,
  RegionDropdown
} from 'react-country-region-selector'
import Payment from 'payment'
import Input from 'components/Input/Input'
import Title from 'components/Title/Title'
import './PaymentMethod.scss'
import Collapser from 'components/Collapser/Collapser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSpring, animated } from 'react-spring'
import { getFieldIsEmptyError, isBlurEvent } from 'setup/formUtils'
import { userIsCanadian } from 'setup/utils'
import * as canadianTaxes from 'canadian-sales-tax'
import _isEmpty from 'lodash/isEmpty'
import UserService from 'services/api/UserService'

const creditCardIsValid = value => Payment.fns.validateCardNumber(value)

const creditCardIsExpired = value => Payment.fns.validateCardExpiry(value)

const creditCardCVCValid = (cvc, card) =>
  Payment.fns.validateCardCVC(cvc, Payment.fns.cardType(card))

const PaymentMethod = ({
  updatePayFormAction,
  payForm: {
    address_addr1,
    address_addr2,
    address_city,
    address_company,
    address_country,
    address_email,
    address_firstname,
    address_lastname,
    address_province,
    address_zip,
    couponCode,
    card_expm,
    card_expy,
    card_name,
    card_number,
    card_security,
    cardExpiry,
    price,
    showBillingInfo,
    user
  }
}) => {
  const isCanadian = userIsCanadian()

  const handleErrorMessagePaymentForm = e => {
    const response =
      getFieldIsEmptyError(e.target.value) ||
      (isBlurEvent(e) &&
        e.target.name === card_number.name &&
        !creditCardIsValid(e.target.value) &&
        'credit card not valid.') ||
      (isBlurEvent(e) &&
        e.target.name === cardExpiry.name &&
        !creditCardIsExpired(e.target.value) &&
        'invalid date.') ||
      (e.target.name === card_security.name &&
        !creditCardCVCValid(e.target.value, card_number.value) &&
        'invalid cvc.')
    return !response ? '' : response
  }

  const handlePaymentFormChange = e => {
    updatePayFormAction({
      [e.target.name]: {
        name: e.target.name,
        value: e.target.value,
        errorMessage: handleErrorMessagePaymentForm(e),
        dirty: true
      }
    })
  }

  const handleBillingInfoChange = e => {
    updatePayFormAction({
      [e.target.name]: {
        name: e.target.name,
        value: e.target.value,
        errorMessage: handleErrorMessagePaymentForm(e),
        dirty: true
      }
    })
  }

  const getProvinceCode = (country, province) => {
    return province !== null
      ? CountryRegionData.find(_country => _country[0] === country)[2]
          .split('|')
          .find(item => item.includes(province))
          .split('~')[1]
      : ''
  }

  useEffect(() => {
    Payment.formatCardNumber(document.querySelector(`[name="card_number"]`))
    Payment.formatCardExpiry(document.querySelector(`[name="cardExpiry"]`))
    Payment.formatCardCVC(document.querySelector(`[name="card_security"]`))
  })

  useEffect(() => {
    if (address_country.value !== 'Canada') {
      updatePayFormAction({
        address_province: { value: '' }
      })
    } else if (address_province.value === '') {
      updatePayFormAction({
        address_province: { value: 'Alberta' }
      })
    }
  }, [address_country.value, address_province.value, updatePayFormAction])

  useEffect(() => {
    const getTaxes = (country, province, price) => {
      if (
        country.value !== 'Canada' ||
        country.value === '' ||
        _isEmpty(province) ||
        province.value === undefined ||
        price === 0
      ) {
        return []
      }

      const getDiscountValue = () => {
        switch (couponCode.coupon_type) {
          case 'fixed_amount':
            return couponCode.coupon_value
          case 'percentage':
            return (price * couponCode.coupon_value) / 100
          default:
            return 0
        }
      }

      const provinceCode = getProvinceCode(country.value, province.value)
      return canadianTaxes[provinceCode]
        ? canadianTaxes[provinceCode].taxes.map(singleTax => ({
            name: singleTax.code,
            value: parseFloat(
              Math.round(singleTax.tax * (price - getDiscountValue()) * 100) / 100
            ).toFixed(2)
          }))
        : []
    }
    updatePayFormAction({
      taxes: getTaxes(address_country, address_province, price)
    })
  }, [
    address_country,
    address_province,
    couponCode.coupon_type,
    couponCode.coupon_value,
    price,
    updatePayFormAction
  ])

  useEffect(() => {
    const [_card_expm, _card_expy] =
      cardExpiry.value.length === 7 && cardExpiry.errorMessage === ''
        ? cardExpiry.value.split(' / ').map(date => parseInt(date, 10))
        : [0, 0]

    if (card_expm !== _card_expm && card_expy !== _card_expy) {
      updatePayFormAction({ card_expm: _card_expm, card_expy: _card_expy })
    }
  }, [cardExpiry, card_expm, card_expy, updatePayFormAction])

  const styles = useSpring({ transform: `rotate(${showBillingInfo ? -180 : 0}deg)` })

  useEffect(() => {
    UserService.get().then(data => {
      updatePayFormAction({
        address_firstname: { value: data.address.firstname || '' },
        address_lastname: { value: data.address.lastname || '' },
        address_addr1: { value: data.address.addr1 || '' },
        address_addr2: { value: data.address.addr2 || '' },
        address_city: { value: data.address.city || '' },
        address_company: { value: data.address.company || '' },
        address_email: { value: data.address.email },
        address_province: { value: data.address.province || '' },
        address_zip: { value: data.address.zip || '' },
        address_id: { value: data.address.id }
      })
      if (data.address.country !== null) {
        updatePayFormAction({
          address_country: { value: data.address.country }
        })
      }
    })
  }, [updatePayFormAction])

  return (
    <div>
      <form>
        <div className="Step PaymentMethod">
          <div className="PayPageBox Payment">
            <Title className="small">payment</Title>
            <div>
              <Input
                name="card_name"
                labelText="Cardholder Name"
                onChange={handlePaymentFormChange}
                value={card_name.value}
                errorMessage={card_name.errorMessage}
                dirty={card_name.dirty}
                pattern="[a-zA-Z ]+"
                className="simple"
              />
              <Input
                name="card_number"
                labelText="••••   ••••   ••••   ••••"
                onChange={handlePaymentFormChange}
                onBlur={handlePaymentFormChange}
                value={card_number.value}
                errorMessage={card_number.errorMessage}
                dirty={card_number.dirty}
                className="simple"
              />
              <div className="MonthAndCVC">
                <Input
                  name="cardExpiry"
                  labelText="MM/YY"
                  onChange={handlePaymentFormChange}
                  onBlur={handlePaymentFormChange}
                  value={cardExpiry.value}
                  errorMessage={cardExpiry.errorMessage}
                  dirty={cardExpiry.dirty}
                  maxLength="7"
                  className="simple"
                />
                <Input
                  name="card_security"
                  labelText="CVC"
                  onChange={handlePaymentFormChange}
                  value={card_security.value}
                  errorMessage={card_security.errorMessage}
                  dirty={card_security.dirty}
                  className="simple"
                />
              </div>
            </div>
          </div>
          <div className="PayPageBox PaymentBillingInfo">
            <Collapser
              canToggle={!isCanadian}
              open={showBillingInfo || isCanadian}
              showCaret={false}
              title={
                <div
                  className={`PaymentBillingHeader`}
                  onClick={() => {
                    updatePayFormAction({
                      showBillingInfo: !showBillingInfo
                    })
                  }}>
                  {!isCanadian ? (
                    <div className="OptionalCaret">
                      <animated.div style={styles}>
                        <FontAwesomeIcon
                          icon={['fas', 'chevron-down']}
                          style={{ height: 12, width: 12 }}
                        />
                      </animated.div>
                      <div className="SmallText">
                        {showBillingInfo ? 'close' : 'optional'}
                      </div>
                    </div>
                  ) : (
                    <div className="Spacer" />
                  )}

                  <Title className="small">billing information</Title>
                </div>
              }>
              <div className="FieldContainer">
                <Input
                  name="address_firstname"
                  onChange={handleBillingInfoChange}
                  value={address_firstname.value}
                  errorMessage={address_firstname.errorMessage}
                  dirty={address_firstname.dirty}
                  tabIndex={showBillingInfo || isCanadian ? 0 : -1}
                  labelText="First Name"
                  className="simple"
                />
                <Input
                  name="address_lastname"
                  onChange={handleBillingInfoChange}
                  value={address_lastname.value}
                  errorMessage={address_lastname.errorMessage}
                  dirty={address_lastname.dirty}
                  labelText="Last Name"
                  tabIndex={showBillingInfo || isCanadian ? 0 : -1}
                  className="simple"
                />
                <Input
                  name="address_email"
                  onChange={handleBillingInfoChange}
                  value={address_email.value}
                  errorMessage={address_email.errorMessage}
                  dirty={address_email.dirty}
                  type="email"
                  labelText="Email"
                  tabIndex={showBillingInfo || isCanadian ? 0 : -1}
                  className="simple"
                />
                <Input
                  name="address_company"
                  onChange={handleBillingInfoChange}
                  value={address_company.value}
                  labelText="Company (if any)"
                  tabIndex={showBillingInfo || isCanadian ? 0 : -1}
                  className="simple"
                />
                <Input
                  name="address_addr1"
                  onChange={handleBillingInfoChange}
                  value={address_addr1.value}
                  errorMessage={address_addr1.errorMessage}
                  dirty={address_addr1.dirty}
                  labelText="Address line 1"
                  tabIndex={showBillingInfo || isCanadian ? 0 : -1}
                  className="simple"
                />
                <Input
                  className="simple"
                  labelText="Address line 2"
                  name="address_addr2"
                  onChange={handleBillingInfoChange}
                  tabIndex={showBillingInfo || isCanadian ? 0 : -1}
                  value={address_addr2.value}
                />
                <Input
                  name="address_city"
                  onChange={handleBillingInfoChange}
                  value={address_city.value}
                  errorMessage={address_city.errorMessage}
                  dirty={address_city.dirty}
                  labelText="City"
                  tabIndex={showBillingInfo || isCanadian ? 0 : -1}
                  className="simple"
                />
                <Input
                  name="address_zip"
                  onChange={handleBillingInfoChange}
                  value={address_zip.value}
                  errorMessage={address_zip.errorMessage}
                  dirty={address_zip.dirty}
                  labelText="Zip / Postal Code"
                  tabIndex={showBillingInfo || isCanadian ? 0 : -1}
                  className="simple"
                />

                <CountryDropdown
                  value={address_country.value}
                  defaultOptionLabel="Country"
                  onChange={val => {
                    updatePayFormAction({
                      address_country: {
                        name: 'address_country',
                        value: val,
                        errorMessage: getFieldIsEmptyError(val),
                        dirty: true
                      }
                    })
                  }}
                />

                {(isCanadian || address_country.value === 'Canada') && (
                  <RegionDropdown
                    country={address_country.value}
                    value={address_province.value}
                    showDefaultOption={false}
                    onChange={val => {
                      updatePayFormAction({
                        address_province: {
                          name: 'address_province',
                          value: val,
                          errorMessage: getFieldIsEmptyError(val),
                          dirty: true
                        }
                      })
                    }}
                  />
                )}
              </div>
            </Collapser>
          </div>
        </div>
      </form>
    </div>
  )
}

export default connect(
  state => ({
    user: state.user,
    payForm: state.payForm
  }),
  {
    updatePayFormAction
  }
)(PaymentMethod)
