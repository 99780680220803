import React from 'react'
import { connect } from 'react-redux'

import './CustomizePage.scss'

import {
  TEXT_POST_ONLY_1,
  PIC_POST_ONLY_1,
  PIC_POST_ONLY_2,
  MASONRY_1,
  MOSAIC_1,
  TEXT_PIC_1,
  TEXT_PIC_2,
  IMAGE_1,
  MULTIPLES_IMAGES_1,
  VIDEO_1,
  CTA_1,
  getFontFamily1Style,
  getTextColorStyle,
  getBackgroundVideoStyle
} from 'services/app/screen'
import TextPostScreen from 'components/Visualizer/Screens/TextPostScreen/TextPostScreen'
import FadeScreen from 'components/Visualizer/Screens/FadeScreen/FadeScreen'
import TextPicScreen from 'components/Visualizer/Screens/TextPicScreen/TextPicScreen'
import CTAScreen from 'components/Visualizer/Screens/CTAScreen/CTAScreen'
import VideoScreen from 'components/Visualizer/Screens/VideoScreen/VideoScreen'
import ImageScreen from 'components/Visualizer/Screens/ImageScreen/ImageScreen'
import { CARDS_PLACEHOLDER } from 'services/app/cardsPlaceholder'
import { Transition, animated } from 'react-spring/renderprops'

const CustomizeScreen = ({ screen, styles }) => {
  const screensParams = {
    cards: CARDS_PLACEHOLDER,
    isRunning: false,
    screen,
    showPlaceholder: true
  }

  const screens = [
    { name: TEXT_POST_ONLY_1, component: <TextPostScreen {...screensParams} /> },
    {
      name: PIC_POST_ONLY_1,
      component: <FadeScreen screenType={PIC_POST_ONLY_1} {...screensParams} />
    },
    {
      name: PIC_POST_ONLY_2,
      component: <FadeScreen screenType={PIC_POST_ONLY_2} {...screensParams} />
    },
    {
      name: MOSAIC_1,
      component: <FadeScreen screenType={MOSAIC_1} {...screensParams} />
    },
    {
      name: MASONRY_1,
      component: <FadeScreen screenType={MASONRY_1} {...screensParams} />
    },
    {
      name: TEXT_PIC_1,
      component: <FadeScreen screenType={TEXT_PIC_1} {...screensParams} />
    },
    {
      name: TEXT_PIC_2,
      component: <TextPicScreen {...screensParams} />
    },
    { name: IMAGE_1, component: <ImageScreen {...screensParams} /> },
    { name: MULTIPLES_IMAGES_1, component: <ImageScreen {...screensParams} /> },
    { name: VIDEO_1, component: <VideoScreen {...screensParams} /> },
    { name: CTA_1, component: <CTAScreen {...screensParams} /> }
  ]

  const getActiveScreenIndex = () => {
    return screen.screen_type !== undefined
      ? screens.findIndex(_screen => _screen.name === screen.screen_type)
      : 0
  }

  return (
    <div
      className="CustomizeScreen"
      style={{
        fontFamily: `${getFontFamily1Style(styles, 'family')}`,
        color: getTextColorStyle(screen.styles)
      }}>
      {getBackgroundVideoStyle(styles) ? (
        <video
          src={getBackgroundVideoStyle(styles)}
          className="screen-video-bg"
          autoPlay={true}
          muted
          loop
        />
      ) : null}
      <Transition
        native
        items={getActiveScreenIndex()}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}>
        {index => styles => (
          <animated.div style={{ ...styles }}>{screens[index].component}</animated.div>
        )}
      </Transition>
    </div>
  )
}

export default connect(state => ({
  styles: state.currentWall.styles
}))(CustomizeScreen)
