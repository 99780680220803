import React from 'react'
import Title from 'components/Title/Title'
import Button from 'components/Button/Button'
import { connect } from 'react-redux'

import { closeModalAction } from 'store/modal/modalReducer'
import './DeleteKeywordModal.scss'
import { doRemoveKeywordAction } from 'store/currentWall/actions/index'

export const DeleteKeywordModal = ({
  closeModalAction,
  doRemoveKeywordAction,
  keyword: { id, label }
}) => {
  const deleteKeyword = () => {
    doRemoveKeywordAction(id, false)
    closeModalAction()
  }
  const deleteKeywordAndAssociatedContent = () => {
    doRemoveKeywordAction(id, true)
    closeModalAction()
  }
  return (
    <div className="DeleteLayoutModal">
      <Title className="medium">delete {label}</Title>
      <div className="ModalDescription">
        Do you want to delete all content associated with {label}?
      </div>
      <div className="ModalActions">
        <Button onClick={() => closeModalAction()}>cancel</Button>
        <Button
          className="info"
          onClick={() => {
            deleteKeyword()
          }}>
          keep content
        </Button>
        <Button
          className="danger"
          onClick={() => {
            deleteKeywordAndAssociatedContent()
          }}>
          delete content
        </Button>
      </div>
    </div>
  )
}
export default connect(state => ({ keyword: state.modal.keyword }), {
  closeModalAction,
  doRemoveKeywordAction
})(DeleteKeywordModal)
