import types, { socialMediaType } from 'setup/types'
import SessionService from 'services/api/SessionService'
import _startsWith from 'lodash/startsWith'

/**
 *
 * @param {string} text Represents the string you want to truncate.
 * @param {number} length Represents the length wanted.
 */
export const truncateString = (text = '', length = 1000) =>
  text.length < length ? text : `${text.slice(0, length)}...`

/**
 *
 * @param {string} type Represents the string type you want to get
 */
export const getType = type => (types.get(type) ? types.get(type) : undefined)

export const getSocialMediaType = type =>
  socialMediaType.get(type) ? socialMediaType.get(type) : undefined

export const focusOnElementByName = name => {
  document.querySelector(`input[name="${name}"]`).focus()
}

export const userIsCanadian = () => {
  return SessionService.isFromCanada()
}

export const isSubuser = user => {
  let email = user.email
  let temp = email.substring(0, 8)
  if (temp === 'subuser_') {
    return true
  } else return false
}

// https://stackoverflow.com/questions/72768/how-do-you-detect-credit-card-type-based-on-number
export const detectCardType = value => {
  let re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/
  }
  if (re.electron.test(value)) {
    return 'ELECTRON'
  } else if (re.maestro.test(value)) {
    return 'MAESTRO'
  } else if (re.dankort.test(value)) {
    return 'DANKORT'
  } else if (re.interpayment.test(value)) {
    return 'INTERPAYMENT'
  } else if (re.unionpay.test(value)) {
    return 'UNIONPAY'
  } else if (re.visa.test(value)) {
    return 'VISA'
  } else if (re.mastercard.test(value)) {
    return 'MASTERCARD'
  } else if (re.amex.test(value)) {
    return 'AMEX'
  } else if (re.diners.test(value)) {
    return 'DINERS'
  } else if (re.discover.test(value)) {
    return 'DISCOVER'
  } else if (re.jcb.test(value)) {
    return 'JCB'
  } else {
    return undefined
  }
}

export const getColorFromString = coloredString => {
  let color = coloredString
  if (_startsWith(coloredString, '{')) {
    const { r, g, b, a } = JSON.parse(coloredString)
    color = `rgba(${r},${g},${b},${a})`
  }
  return color
}

export const isLightColor = (color, lightAmount = 245) => {
  let r, g, b, hsp

  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  return hsp > lightAmount
}
