import React from 'react'
import './NavButtons.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Colors from 'setup/colors.scss'

const NavButton = props => (
  <button
    className={`NavButton ${props.className}`}
    onClick={props.onClick}
    style={props.style}>
    {props.children}
  </button>
)

export const LeftNavButton = props => {
  return (
    <NavButton {...props}>
      <FontAwesomeIcon
        className="chevronClass"
        icon={['fas', 'chevron-left']}
        style={{ height: 14, width: 14, color: Colors.brand }}
      />
    </NavButton>
  )
}

export const RightNavButton = props => {
  return (
    <NavButton {...props}>
      <FontAwesomeIcon
        icon={['fas', 'chevron-right']}
        style={{ height: 14, width: 14, color: Colors.brand }}
      />
    </NavButton>
  )
}
