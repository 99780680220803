import {
  SET_CURRENT_WALL,
  ADD_SCREEN,
  RESET_CURRENT_WALL,
  DELETE_SCREEN,
  APPLY_THEME,
  FETCH_CURRENT_WALL,
  UPDATE_SCREEN,
  UPDATE_CURRENT_WALL,
  UPDATE_CARD
} from '../currentWallReducerActionTypes'

import WallService from 'services/api/WallService'
import ScreenService from 'services/api/ScreenService'
import { hideSelectPlansAction } from 'store/selectPlanList/selectPlanListReducer'
import * as keywords from './currentWallKeywordsActions'
import * as banwords from './currentWallBanwordsActions'
import * as cards from './currentWallCardsActions'
import CardService from 'services/api/CardService'

export const doFetchCurrentWall = id => async dispatch => {
  let wall
  try {
    dispatch(fetchCurrentWallAction())
    wall = await WallService.get(id)
    dispatch(setCurrentWallAction(wall))
  } catch (error) {
    window.location.href = '/'
  } finally {
    dispatch(hideSelectPlansAction())
    return wall
  }
}

export const doFetchCurrentWallForVisualizer = id => async dispatch => {
  return new Promise(async (resolve, reject) => {
    let wall
    try {
      dispatch(fetchCurrentWallAction())
      wall = await WallService.get(id)
      dispatch(setCurrentWallAction(wall))
    } catch (error) {
    } finally {
      resolve(wall)
    }
  })
}

export const doApplyThemeAction = (themeId, wallId) => async dispatch => {
  try {
    const data = await ScreenService.applyTheme(themeId, wallId)
    dispatch(applyThemeCurrentWallAction(data))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export const doUpdateWall = (wallId, data, params) => async dispatch => {
  try {
    if (data.auto_approve) {
      WallService.autoApproveAll(wallId)
      dispatch(cards.approveAllCardsAction())

      params.forEach(id => {
        CardService.update(wallId, id, { approved: true })
      })
    } else if (data.no_retweet) {
      WallService.filterOutRetweet(wallId)
      dispatch(cards.filterOutRetweetedCards())

      params.forEach(id => {
        CardService.destroy(wallId, id)
      })
    } else if (data.rotation_time) {
      data.rotation_time = data.rotation_time.value
    }
    const wall = await WallService.update(wallId, { ...data })
    dispatch(updateCurrentWallAction(wall))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export const doUpdateScreen = (screenId, data) => async dispatch => {
  try {
    const screen = await ScreenService.update(screenId, { ...data })
    dispatch(updateScreenAction(screen))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export const doSortScreens = (wallId, sortedScreens) => async dispatch => {
  try {
    const updatedScreens = await ScreenService.sort(wallId, sortedScreens)
    updatedScreens.forEach(screen => {
      dispatch(updateScreenAction(screen))
    })
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export const doUpdateCard = (wallId, cardId, data) => async dispatch => {
  try {
    const card = await CardService.update(wallId, cardId, { ...data })
    dispatch(updateCardAction(card))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

const setCurrentWallAction = wall => ({
  type: SET_CURRENT_WALL,
  payload: wall
})

const fetchCurrentWallAction = () => ({
  type: FETCH_CURRENT_WALL
})

export const addLayoutToCurrentWallAction = layout => ({
  type: ADD_SCREEN,
  payload: layout
})

export const resetCurrentWallAction = () => ({
  type: RESET_CURRENT_WALL
})

export const deleteLayoutFromCurrentWallAction = layoutID => ({
  type: DELETE_SCREEN,
  payload: layoutID
})

const applyThemeCurrentWallAction = screens => ({
  type: APPLY_THEME,
  payload: screens
})

const updateScreenAction = screen => ({
  type: UPDATE_SCREEN,
  payload: screen
})

export const updateCurrentWallAction = wall => ({
  type: UPDATE_CURRENT_WALL,
  payload: wall
})

export const updateCardAction = card => ({
  type: UPDATE_CARD,
  payload: card
})

export const doFetchAllBanwordsAction = banwords.fetchAll
export const doCreateBanwordAction = banwords.create
export const doRemoveBanwordAction = banwords.remove
export const doFetchAllKeywordsAction = keywords.fetchAll
export const doCreateKeywordAction = keywords.create
export const doRemoveKeywordAction = keywords.remove
export const doRemoveKeywordAndContentAction = keywords.remove
export const doFetchAllCardsAction = cards.fetchAll
export const doDeleteCardAction = cards.remove
export const doDestroyAllModerationContent = cards.deleteAll
export const deleteAllCards = cards.deleteAllCards
