import request from 'services/requestWrapper.js'

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of the wall to get the cards from.
 */
function getAll(wallId, approved) {
  const url =
    approved !== undefined
      ? `/events/${wallId}/cards?approved=${approved}`
      : `/events/${wallId}/cards`
  return request({
    url,
    method: 'GET'
  })
}

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of the wall to get the cards from.
 */
function getAllSince(wallId, latestCardId) {
  return request({
    url: `/events/${wallId}/cards?approved=true&since_id=${latestCardId}`,
    method: 'GET'
  })
}

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of the wall to get the cards from.
 */
function approveAll(wallId) {
  return request({
    url: `/events/${wallId}/approve_all`,
    method: 'POST'
  })
}

/**
 * Returns a promise
 * @param {number} wallId Corresponds to the id of the wall to delete the cards from.
 * @param {number} cardId Correponds to the card id that needs to be deleted
 */

function destroy(wallId, cardId) {
  return request({
    url: `/events/${wallId}/cards/${cardId}`,
    method: 'DELETE'
  })
}

function deleteAll(wallId) {
  return request({
    url: `/events/${wallId}/reset_moderation`,
    method: 'DELETE'
  })
}

function update(wallId, cardId, data) {
  return request({
    url: `/events/${wallId}/cards/${cardId}`,
    method: 'PUT',
    data
  })
}

const CardService = {
  destroy,
  getAll,
  approveAll,
  deleteAll,
  getAllSince,
  update
}

export default CardService
