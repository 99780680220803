import React from 'react'
import 'components/Title/Title.scss'

const Title = ({ className = '', handleClickFunction = () => {}, ...rest }) => (
  <div className={`Title ${className}`} onClick={handleClickFunction}>
    {rest.children}
  </div>
)

export default Title
