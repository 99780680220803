import React from 'react'
import { connect } from 'react-redux'
import { doUpdateWall } from 'store/currentWall/actions/index'
import Select from 'react-select'

import './PlaylistModal.scss'
import Title from 'components/Title/Title'

const PlaylistModal = ({
  currentWall: { id, rotation_time, rotationTimeOptions },
  doUpdateWall
}) => {
  return (
    <div className="PlaylistModal">
      <Title className="medium">playlist options</Title>
      <div className="row-center">
        <span>content update time</span>
        <div className="select-container">
          <Select
            menuPortalTarget={document.body}
            classNamePrefix="react-select"
            value={rotation_time}
            onChange={rotation_time => {
              doUpdateWall(id, { rotation_time })
            }}
            options={rotationTimeOptions}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: styles => ({
                ...styles,
                borderColor: '#7617ce!important',
                boxShadow: 'none'
              }),
              option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isSelected
                  ? '#7617ce!important'
                  : '#fff!important',
                cursor: 'pointer',
                color: state.isSelected ? '#fff' : '#000'
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    currentWall: state.currentWall
  }),
  {
    doUpdateWall
  }
)(PlaylistModal)
