import { setPusherClient } from 'react-pusher'
import Pusher from 'pusher-js'
import { API_URL } from 'setup/constants'
import env from 'services/app/environment.js'

export default () => {
  const pusherAppCluster = process.env.REACT_APP_PUSHER_APP_CLUSTER
  const pusherAppKey = process.env.REACT_APP_PUSHER_APP_KEY
  const authorisationEndpoint = `${API_URL}/pusher/auth`
  Pusher.logToConsole = env.showPusherInConsole()
  const pusherClient = new Pusher(pusherAppKey, {
    cluster: pusherAppCluster,
    authEndpoint: authorisationEndpoint,
    ignoreNullOrigin: false
  })
  setPusherClient(pusherClient)
}
