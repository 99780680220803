import {
  ADD_CARDS,
  APPROVE_ALL_CARDS,
  DELETE_ALL_CARDS,
  DELETE_CARD,
  FILTER_RETWEET_CARDS,
  RESET_IMAGE_CARD,
  RESET_TEXT_CARD,
  SET_LATEST_CARD_TO_ARRIVE,
  UPDATE_CARD
} from './currentWallReducerActionTypes'

export default (cards = {}, action) => {
  switch (action.type) {
    case ADD_CARDS:
      const oldCards = cards.items

      const newCards = action.payload.map(newCard => {
        const oldCard = oldCards.find(_card => _card.id === newCard.id)
        return { ...newCard, shown: oldCard !== undefined ? oldCard.shown : false }
      })

      return {
        ...cards,
        items: [...newCards].sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        )
      }

    case UPDATE_CARD:
      return {
        ...cards,
        items: [
          ...cards.items.map(card => {
            if (card.id !== action.payload.id) {
              return card
            }
            return action.payload
          })
        ]
      }

    case DELETE_CARD:
      return {
        ...cards,
        items: cards.items.filter(i => i.id !== action.payload)
      }

    case APPROVE_ALL_CARDS:
      return {
        ...cards,
        items: [
          ...cards.items.map(card => {
            if (card.approved !== true) {
              card.approved = true
            }
            return card
          })
        ]
      }

    case SET_LATEST_CARD_TO_ARRIVE:
      return {
        ...cards,
        latestCardId: parseInt(action.payload, 10)
      }

    case RESET_IMAGE_CARD:
      return {
        ...cards,
        items: [
          ...cards.items.filter(card => !card.approved),
          ...cards.items.filter(card => card.approved && card.media_file_url === null),
          ...cards.items
            .filter(card => card.approved && card.media_file_url !== null)
            .map(card => ({ ...card, shown: false }))
        ].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
      }

    case RESET_TEXT_CARD:
      return {
        ...cards,
        items: [
          ...cards.items.filter(card => !card.approved),
          ...cards.items.filter(card => card.approved && card.media_file_url !== null),
          ...cards.items
            .filter(card => card.approved && card.media_file_url === null)
            .map(card => ({ ...card, shown: false }))
        ].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
      }

    case DELETE_ALL_CARDS:
      return {
        ...cards,
        items: []
      }

    case FILTER_RETWEET_CARDS:
      return {
        ...cards,
        items: cards.items.filter(item => !item.is_retweet)
      }

    default:
      return cards
  }
}
