import React, { Component } from 'react'
import { connect } from 'react-redux'
import Title from 'components/Title/Title'
import './SubUsersModal.scss'
import Input from 'components/Input/Input'
import isEmpty from 'lodash/isEmpty'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import SubUsersService from 'services/api/SubUsersService'
import { focusOnElementByName } from 'setup/utils'
import { getFieldIsEmptyError, fieldsAreEmpty } from 'setup/formUtils'
import Chip from '../../Chips/Chip'
import {
  deleteChildUsersAction,
  doFetchAllWallsAction,
  updateChildUsersAction
} from 'store/walls/wallsReducer'

const usernameFieldName = 'username'
const passwordFieldName = 'password'
const confirmPasswordFieldName = 'passwordConfirm'

export class SubUsersModal extends Component {
  state = {
    fields: {
      [usernameFieldName]: { value: '' },
      [passwordFieldName]: { value: '' },
      [confirmPasswordFieldName]: { value: '' }
    },
    subusers: []
  }

  handleBlurOrChange = e => {
    this.setState({
      fields: {
        ...this.state.fields,
        [e.target.name]: {
          ...this.state.fields[e.target.name],
          value: e.target.value,
          errorMessage: this.handleErrorMessage(e),
          isValid: isEmpty(this.handleErrorMessage(e)),
          dirty: true
        }
      }
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const subuser = {
      username: `${this.props.user.id}-${this.state.fields[usernameFieldName].value}`,
      password: this.state.fields[passwordFieldName].value,
      confirmation: this.state.fields[confirmPasswordFieldName].value
    }
    try {
      const newSubuser = await SubUsersService.create(subuser)
      this.setState({
        subusers: [...this.state.subusers, ...formatSubsersToFitChips([newSubuser])]
      })
    } catch (error) {
      const { password, password_confirmation, username } = error.data

      username && this.updateErrorStateAndFocusOn(usernameFieldName, username[0])
      password && this.updateErrorStateAndFocusOn(passwordFieldName, password[0])
      password_confirmation &&
        this.updateErrorStateAndFocusOn(
          confirmPasswordFieldName,
          password_confirmation[0]
        )
    }
    this.setState({
      fields: {
        [usernameFieldName]: {},
        [passwordFieldName]: {},
        [confirmPasswordFieldName]: {}
      }
    })
  }

  updateErrorStateAndFocusOn(elementName, errorMessage) {
    this.setState({
      fields: {
        ...this.state.fields,
        [elementName]: {
          ...this.state[elementName],
          errorMessage,
          isValid: false
        }
      }
    })
    focusOnElementByName(elementName)
  }

  handleErrorMessage = e => {
    let errorMessage
    errorMessage = getFieldIsEmptyError(e.target.value)
    if (!errorMessage) {
      errorMessage = this.state.subusers
        .map(user => user.value)
        .includes(`${this.props.userId}-${e.target.value}`)
        ? 'user already exists'
        : ''
    }
    if (!errorMessage) {
      errorMessage =
        e.target.value.length < 6 && isPasswordInput(e.target.type)
          ? 'password is too short'
          : ''
    }
    if (!errorMessage) {
      errorMessage =
        e.target.value.includes(' ') && !isPasswordInput(e.target.type)
          ? 'spaces are not allowed'
          : ''
    }
    if (!errorMessage) {
      errorMessage =
        e.target.name === confirmPasswordFieldName &&
        e.target.value !==
          document.querySelector(`input[name="${passwordFieldName}"]`).value
          ? 'password must match.'
          : ''
    }
    return errorMessage
  }

  handleRemove = id => {
    SubUsersService.destroy(id)
    this.setState({
      ...this.state,
      subusers: this.state.subusers.filter(user => user.id !== id)
    })
    this.props.deleteChildUsersAction(id)
  }

  async componentDidMount() {
    const subusers = await SubUsersService.getAll()
    this.setState({
      subusers: formatSubsersToFitChips(subusers)
    })
    this.props.doFetchAllWallsAction()
  }

  isDisabled() {
    const { fields } = this.state
    return this.fieldsHaveError(fields) || fieldsAreEmpty(fields)
  }

  fieldsHaveError(fields) {
    return Object.keys(fields)
      .map(key => fields[key].errorMessage)
      .filter(n => n).length
  }

  handleChange = (subUserId, wallId, event) => {
    event.preventDefault()
    if (event.target.checked === true) {
      SubUsersService.authorize(subUserId, wallId).then(data => {
        this.props.updateChildUsersAction(wallId, data.event.childusers)
      })
    } else {
      SubUsersService.unauthorize(subUserId, wallId).then(data => {
        this.props.updateChildUsersAction(wallId, data.event.childusers)
      })
    }
  }

  isChecked = (wall, subuserId) =>
    wall.childusers.length > 0 &&
    wall.childusers.find(subuser => subuser.id === subuserId) !== undefined

  emptyField = () => {}

  render() {
    const { id } = this.props.user
    const { walls } = this.props
    const { fields, subusers } = this.state

    return (
      <div className="SubUsersModal">
        <Title className="medium">moderators</Title>
        <p>
          Moderators will only be able to Add or Remove posts <br />
          in the moderation tab. They don't have access to any other tab
        </p>

        <form onSubmit={this.handleSubmit}>
          {subusers.length < 3 ? (
            <div className="SubUsersInputsContainer">
              <Input
                name={usernameFieldName}
                value={fields[usernameFieldName].value}
                errorMessage={fields[usernameFieldName].errorMessage}
                className="Username simple"
                labelText="Username"
                onChange={this.handleBlurOrChange}
                min="6"
                prefix={`${id}-`}
                dirty={fields[usernameFieldName].dirty}
              />
              <Input
                name={passwordFieldName}
                value={fields[passwordFieldName].value}
                errorMessage={fields[passwordFieldName].errorMessage}
                className="simple"
                labelText="Password"
                type="password"
                onChange={this.handleBlurOrChange}
                dirty={fields[passwordFieldName].dirty}
              />
              <Input
                name={confirmPasswordFieldName}
                value={fields[confirmPasswordFieldName].value}
                errorMessage={fields[confirmPasswordFieldName].errorMessage}
                className="simple"
                labelText="Password Confirmation"
                type="password"
                onChange={this.handleBlurOrChange}
                dirty={fields[confirmPasswordFieldName].dirty}
              />
            </div>
          ) : null}

          {subusers.length < 3 ? (
            <SubmitButton disabled={this.isDisabled()}>add moderator</SubmitButton>
          ) : null}
          {subusers.length > 0 && (
            <>
              <div className="ChipsContainer-list">
                {subusers.map((subuser, index) => (
                  <Chip key={index} {...subuser} removeItem={this.handleRemove}>
                    {subuser.value}
                  </Chip>
                ))}
              </div>
              <div className="SubUsersWallsContainer">
                <div>
                  <div className="SubUsersContainer">
                    <div className="SubUserHeader" />
                    <div className="SubUserNames">
                      {subusers.map(subuser => (
                        <div key={subuser.id}>
                          <span>{subuser.value}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="ScrollDiv">
                    {walls.map(wall => (
                      <div key={wall.id} className="WallRow">
                        <span className="WallName">{wall.name}</span>
                        <div className="SubWallsRows">
                          {subusers.map(subuser => (
                            <div key={subuser.id}>
                              <input
                                type="checkbox"
                                onChange={event =>
                                  this.handleChange(subuser.id, wall.id, event)
                                }
                                checked={this.isChecked(wall, subuser.id)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    )
  }
}

export default connect(state => ({ user: state.user, walls: state.walls }), {
  deleteChildUsersAction,
  doFetchAllWallsAction,
  updateChildUsersAction
})(SubUsersModal)

function formatSubsersToFitChips(subusers) {
  return subusers.map(user => ({ value: user.username, id: user.id }))
}

function isPasswordInput(type) {
  return type === 'password'
}
