import React from 'react'
import CountdownNow from 'react-countdown-now'
import PropTypes from 'prop-types'

/**
 *
 * A component to display a "live until" Countdown
 */
const Countdown = ({ liveUntil }) => (
  <CountdownNow date={new Date(liveUntil)} renderer={CountdownRenderer} />
)

const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return null
  } else {
    return (
      <span>
        &nbsp;- Live for&nbsp;
        {days > 0 ? <span>{days} days&nbsp;</span> : null}
        {hours > 0 ? <span>{hours}h&nbsp;</span> : null}
        {minutes > 0 || (hours > 0 && seconds > 0) ? <span>{minutes}m&nbsp;</span> : null}
        {seconds > 0 ? <span>{seconds}s</span> : null}
      </span>
    )
  }
}

Countdown.propTypes = {
  liveUntil: PropTypes.instanceOf(Date).isRequired
}
export default Countdown
