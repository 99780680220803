import LayoutService from 'services/api/LayoutService'

const SET_LAYOUTS = 'GET_LAYOUTS'

const initState = []

export default (state = initState, action) => {
  switch (action.type) {
    case SET_LAYOUTS:
      return [...state, ...action.payload]

    default:
      return state
  }
}

const setLayouts = layouts => ({
  type: SET_LAYOUTS,
  payload: layouts
})

export const doFetchAllLayoutsAction = () => async dispatch => {
  try {
    const layouts = await LayoutService.getAll()
    dispatch(setLayouts(layouts))
    return layouts
  } catch (error) {
    console.log(error)
  } finally {
  }
}
