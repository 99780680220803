import React from 'react'
import Title from 'components/Title/Title'
import SubTitle from 'components/SubTitle/SubTitle'
import '../Modal.scss'

const CustomizeHelpModal = () => {
  return (
    <div className="HelpModal">
      <div className="SectionTitle">
        <Title className="medium">customize</Title>
      </div>
      <div>
        <SubTitle>General vs specific options</SubTitle>
        <div className="SectionContent">
          The <b>general options</b> will affect all of your screens. If add your logo,
          for example, it will be added to all screens that can display a logo.
        </div>
        <div className="SectionContent">
          The <b>specific options</b> are screen-by-screen overrides. If you set a text
          color under <b>specific options</b>, it will be used for that screen instead of
          the <b>general</b> text color.
        </div>
        <div className="SectionContent">
          Changing a color under the <b>general options</b> will not replace the color
          under <b>specific options</b>. The override will still take precedent until you
          remove it.
        </div>
      </div>
    </div>
  )
}

export default CustomizeHelpModal
