import React from 'react'
import Colors from 'setup/colors.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { openDeleteKeywordModalAction } from 'store/modal/modalReducer'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

/**
 *
 * Chip component used to display users hashtags and search terms.
 * Also used to display sub users in Sub users Modal
 */
const Chip = ({
  children,
  id,
  openDeleteKeywordModalAction,
  removeItem,
  showModal = false
}) =>
  showModal ? (
    <div className="Chip">
      <span className="text">{children}</span>
      <FontAwesomeIcon
        icon="minus-circle"
        style={{ width: 18, height: 18, color: Colors.grey }}
        onClick={() => openDeleteKeywordModalAction({ children, id })}
      />
    </div>
  ) : (
    <div className="Chip">
      <span className="text">{children}</span>

      <FontAwesomeIcon
        icon="minus-circle"
        style={{ width: 18, height: 18, color: Colors.grey }}
        onClick={() => removeItem(id)}
      />
    </div>
  )

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
  /** this function will be called onClick if showModal is set to ``false`` */
  removeItem: PropTypes.func,
  showModal: PropTypes.bool
}

export default connect(
  null,
  { openDeleteKeywordModalAction }
)(Chip)
