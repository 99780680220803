import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from 'components/Button/Button'
import Container from 'components/Container/Container'
import Title from 'components/Title/Title'
import * as clipboard from 'clipboard-polyfill'

import './EmbedPage.scss'
import { APP_URL } from 'setup/constants'

const EmbedPage = ({ currentWallId }) => {
  const [copied, setCopied] = useState(false)
  const link = `<iframe width="560" height="315" src="${APP_URL}/wall/${currentWallId}/fullscreen"></iframe>`

  const handleCopyLink = () => {
    clipboard.writeText(link)
    setCopied(true)
  }

  const handleHoverOut = ({ target }) => {
    target.addEventListener('transitionend', () => setCopied(false))
  }

  return (
    <Container className="EmbedPage">
      <Title className="medium">how to embed Wallrus on your website?</Title>
      <p>
        To embed your Wallrus wall on your website, we will be using an &lt;iframe&gt;
        tag. Simply paste the following code in the &lt;body&gt; section of your website
      </p>
      <code className="link">{link}</code>
      <p>
        You can change the width and height of the embed directly in the above code. Keep
        in mind, Wallrus will always display in a 16x9 ratio.
      </p>

      <div className="CopyToClipboardButton" onMouseLeave={handleHoverOut}>
        <div className="ToolTip visible">
          {copied ? 'Link Copied' : 'Copy link to clipboard'}
        </div>
        <Button onClick={handleCopyLink}>copy code</Button>
      </div>
    </Container>
  )
}

export default connect(
  state => ({ currentWallId: state.currentWall.id }),
  {}
)(EmbedPage)
