import moment from 'moment'
import { userIsCanadian } from 'setup/utils'
import _isNull from 'lodash/isNull'

export const UPDATE_PAY_FORM = 'UPDATE_PAY_FORM'
export const UPDATE_PAY_FORM_ADDRESS = 'UPDATE_PAY_FORM_ADDRESS'

const options = Array.from({ length: 30 }, (item, index) => ({
  label: `${index + 1} day${index > 0 ? 's' : ''}`,
  value: index + 1
}))
const inputInitialProps = { value: '', errorMessage: '', dirty: false }
const payFormInitialState = {
  // Activation default props
  duration: options[0],
  options,
  datePickerDate: new Date(),
  timePickerTime: new Date(),
  live_start: '',
  timezone: moment.tz && moment.tz.guess(),
  showTimezone: false,
  showCoupon: false,
  couponCode: {
    value: '',
    coupon: '',
    coupon_type: '',
    coupon_value: 0
  },
  //   discount: 0,
  price: 0,
  // Payment default props
  showBillingInfo: userIsCanadian(),
  card_name: { ...inputInitialProps },
  card_number: { ...inputInitialProps },
  cardExpiry: { ...inputInitialProps },
  card_expm: 0,
  card_expy: 0,
  card_security: { ...inputInitialProps },
  address_firstname: { ...inputInitialProps },
  address_lastname: { ...inputInitialProps },
  address_email: { ...inputInitialProps },
  address_company: { ...inputInitialProps },
  address_addr1: { ...inputInitialProps },
  address_addr2: { ...inputInitialProps },
  address_zip: { ...inputInitialProps },
  address_city: { ...inputInitialProps },
  address_province: { ...inputInitialProps, name: 'address_province' },
  address_country: {
    ...inputInitialProps,
    name: 'address_country',
    value: userIsCanadian() ? 'Canada' : ''
  },
  taxes: []
}

export default (payForm = payFormInitialState, action) => {
  switch (action.type) {
    case UPDATE_PAY_FORM:
    case UPDATE_PAY_FORM_ADDRESS: {
      return {
        ...payForm,
        ...action.payload
      }
    }

    default:
      return payForm
  }
}

export const updatePayFormAction = payload => ({ type: UPDATE_PAY_FORM, payload })

const stringIfNull = str => {
  return _isNull(str) ? '' : str
}

export const updatePayFormFullAddressAction = address => {
  const payload = {
    // ...payFormInitialState,
    address_firstname: { value: stringIfNull(address.address_firstname) },
    address_lastname: { value: stringIfNull(address.address_lastname) },
    address_addr1: { value: stringIfNull(address.address_addr1) },
    address_addr2: { value: stringIfNull(address.address_addr2) },
    address_city: { value: stringIfNull(address.address_city) },
    address_zip: { value: stringIfNull(address.address_zip) },
    address_email: { value: stringIfNull(address.address_email) },
    address_company: { value: stringIfNull(address.address_company) },
    address_province: { ...address.address_province },
    address_country: { ...address.address_country },
    address_id: { ...address.address_id }
  }
  return {
    type: UPDATE_PAY_FORM_ADDRESS,
    payload
  }
}
