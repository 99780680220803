import React from 'react'
import 'components/Button/Button.scss'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 *
 * Button component, used everywhere in the application
 */
const Button = ({ className, type = 'button', ...rest }) => (
  <button type={type} className={classNames('Button', className)} {...rest}>
    {rest.children}
  </button>
)

Button.propTypes = {
  /** you can use **small**, **xsmall**, **danger**, **success**, **info**, **inverted**, **grey**,**full-width**, **half-width**, **disabled**  */
  className: PropTypes.string,
  /** you can use **onClick**, **disabled**... */
  rest: PropTypes.object,
  type: PropTypes.string
}

export default Button
