import React from 'react'
import { connect } from 'react-redux'

import 'components/Logo/Logo.scss'
import { LOGO_PLACEHOLDER_URL } from 'setup/constants'
import { getLogoStyle } from 'services/app/screen'

export const Logo = ({ enabled = true, styles }) => {
  const url = getLogoStyle(styles)
  return (
    <div className="Logo">
      {enabled && url !== false ? (
        <img src={url} alt="" onError={e => (e.target.src = LOGO_PLACEHOLDER_URL)} />
      ) : null}
    </div>
  )
}

export default connect(state => ({
  styles: state.currentWall.styles
}))(Logo)
