import {
  ADD_BANWORD,
  ADD_BANWORDS,
  ADD_CARDS,
  ADD_KEYWORD,
  ADD_KEYWORDS,
  ADD_SCREEN,
  APPLY_THEME,
  APPROVE_ALL_CARDS,
  DELETE_ALL_CARDS,
  DELETE_CARD,
  DELETE_SCREEN,
  FETCH_CURRENT_WALL,
  FILTER_RETWEET_CARDS,
  REMOVE_BANWORD,
  REMOVE_KEYWORD,
  REQUEST_BANWORDS,
  RESET_CURRENT_WALL,
  RESET_IMAGE_CARD,
  RESET_TEXT_CARD,
  SET_CURRENT_WALL,
  SET_LATEST_CARD_TO_ARRIVE,
  UPDATE_CARD,
  UPDATE_CURRENT_WALL,
  UPDATE_SCREEN,
  UPDATE_STYLE
} from './currentWallReducerActionTypes'

import cards from './currentWallReducerCards'
import styles from './currentWallReducerStyles'
import keywords from './currentWallReducerKeywords'
import screens from './currentWallReducerScreens'
import banwords from './currentWallReducerBanwords'

const rotationTimeOptions = [
  {
    label: '5 sec',
    value: 5,
    time: 5 * 1000
  },
  {
    label: '10 sec',
    value: 10,
    time: 10 * 1000
  },
  {
    label: '15 sec',
    value: 15,
    time: 15 * 1000
  },
  {
    label: '20 sec',
    value: 20,
    time: 20 * 1000
  }
]

const currentWallInitState = {
  plan: { id: 0 },
  rotationTimeOptions,
  rotation_time: rotationTimeOptions[0],
  cards: {
    items: [],
    latestCardId: null
  },
  keywords: [],
  banwords: {
    items: []
  },
  screens: [],
  status: '',
  status_info: [],
  styles: []
}

export default (wall = currentWallInitState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_WALL:
      return {
        ...wall,
        isFetching: true
      }

    case SET_CURRENT_WALL:
      return {
        ...wall,
        ...action.payload,
        rotation_time: rotationTimeOptions.find(
          option => option.value === action.payload.rotation_time
        ),
        isFetching: false
      }

    case APPLY_THEME:
      return {
        ...wall,
        theme_id: action.payload.theme.id,
        theme: { ...wall.theme, ...action.payload.theme },
        styles: [...action.payload.styles],
        screens: wall.screens.map(screen => ({
          ...screen,
          ...action.payload.screens.find(_screen => _screen.id === screen.id)
        }))
      }

    case ADD_SCREEN:
    case DELETE_SCREEN:
    case UPDATE_SCREEN:
      return {
        ...wall,
        screens: screens(wall.screens, action)
      }

    case ADD_KEYWORDS:
    case ADD_KEYWORD:
    case REMOVE_KEYWORD:
      return {
        ...wall,
        keywords: keywords(wall.keywords, action)
      }

    case REQUEST_BANWORDS:
    case ADD_BANWORDS:
    case ADD_BANWORD:
    case REMOVE_BANWORD:
      return {
        ...wall,
        banwords: banwords(wall.banwords, action)
      }

    case ADD_CARDS:
    case APPROVE_ALL_CARDS:
    case DELETE_ALL_CARDS:
    case DELETE_CARD:
    case FILTER_RETWEET_CARDS:
    case RESET_IMAGE_CARD:
    case RESET_TEXT_CARD:
    case SET_LATEST_CARD_TO_ARRIVE:
    case UPDATE_CARD:
      return {
        ...wall,
        cards: cards(wall.cards, action)
      }

    case RESET_CURRENT_WALL:
      return currentWallInitState

    case UPDATE_CURRENT_WALL:
      return action.payload.rotation_time
        ? {
            ...wall,
            ...action.payload,
            rotation_time: rotationTimeOptions.find(
              option => option.value === action.payload.rotation_time
            )
          }
        : {
            ...wall,
            ...action.payload
          }

    case UPDATE_STYLE:
      return {
        ...wall,
        styles: styles(wall.styles, action)
      }
    default:
      return wall
  }
}
